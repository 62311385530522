import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    image: {
        transform: 'scale(1.3)'
    }
}));

const CeciStudio = (props) => {
    const classes = useStyles();

    return (
        <img
            alt="CeCi"
            src={"/static/svg/dashboard/ceciStudio.svg"}
            height="60px"
            width="75px"
            {...props}
            className={classes.image}
        />
    );
};

export default CeciStudio;