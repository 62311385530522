import React from 'react';
import Header from '../../components/HeaderV2/Header';
import HeaderLinks from '../../components/HeaderV2/HeaderLinks';
import { feijoa8Menu } from '../../constants/routes';
import Footer from '../../components/Footer/Footer';
import Body from '../../components/Body';
import MetaTags from '../../components/Seo/MetaTags';
import Faq from './Faq';
import { makeStyles } from '@material-ui/core/styles';
import { MetaFaq } from './Seo/MetaFaq';
import { Divider, Paper } from '@material-ui/core';
import { SectionContainer } from '../../components/Body/Containers';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        border: '0.5px solid lightgrey',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    }
}))

const FaqMain = () => {
    const classes = useStyles();

    return (
        <>
            <Header
                brand="feijoa8"
                rightLinks={<HeaderLinks links={feijoa8Menu} />}
                fixed
            />
            <MetaTags {...MetaFaq} />
            <Body title={MetaFaq.pageTitle}>
                <Divider />
                <SectionContainer>
                    <Paper className={classes.paper}>
                        <Faq />
                    </ Paper>
                </SectionContainer>
            </Body>
            <Footer />
        </>
    );
};

export default FaqMain;
