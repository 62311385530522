import React from 'react';
import Header from '../../../components/HeaderV2/Header';
import HeaderLinks from '../../../components/HeaderV2/HeaderLinks';
import { ceciMenu } from '../../../constants/routes';
import Footer from '../../../components/Footer/Footer';
import Body from '../../../components/Body';
import { MetaUseCasesPage } from './Seo/MetaUseCasesPage';
import MetaTags from '../../../components/Seo/MetaTags';
import UseCasesCard from './UseCasesCard';
import { Divider } from '@material-ui/core';
import { SectionContainer } from '../../../components/Body/Containers';

const UseCasesPage = () => {
  return (
    <>
      <Header
        brand="ceci"
        rightLinks={<HeaderLinks links={ceciMenu} />}
        fixed
      />
      <MetaTags {...MetaUseCasesPage} />
      <Body title={MetaUseCasesPage.pageTitle}>
        <Divider />
        <SectionContainer>
          <UseCasesCard />
        </SectionContainer>
      </Body>
      <Footer />
    </>
  );
};

export default UseCasesPage;
