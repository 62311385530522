import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        padding: "100px 0",
    },
    conatiner: {
        paddingRight: "15px",
        paddingLeft: "15px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    headingContainer: {
        marginBottom: '70px'
    },
    heading: {
        position: "relative",
        marginTop: "10px",
        marginBottom: "15px",
        paddingBottom: "15px",
        textAlign: "center",
        '&:after': {
            position: "absolute",
            content: '""',
            background: "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)",
            height: "4px",
            width: "60px",
            bottom: "0",
            marginLeft: "-30px",
            left: "50%"
        }
    },
    grids: {
        textAlign: "center",
    },
    grid: {
        marginBottom: "30px"
    },
    helperText: {
        padding: "inherit"
    },
    textDescription: {
        padding: '5px'
    },
    number: {
        paddingBottom: "inherit",
        fontWeight: 700
    },
    responsiveImage: {
        maxWidth: "100%",
        height: "auto",
        maxHeight: '160px'
    }
}))

const sections = [
    { number: '1.', title: 'Create Idea', imageURL: '/static/images/ceci/home/createIdea.png', description: 'Give your idea a name and description' },
    { number: '2.', title: 'Add Feature and Goals', imageURL: '/static/images/ceci/home/featuresOutcomes.png', description: 'Provide the features and goals expected from your idea' },
    { number: '3.', title: 'Add Options', imageURL: '/static/images/ceci/home/addOptions.png', description: 'Add various options that you have to achieve your idea' },
    { number: '4.', title: 'Evaluate Options', imageURL: '/static/images/ceci/home/evaluateOptions.png', description: 'Evaluate all the options against the features and goals expected' },
    { number: '5.', title: 'View Summary', imageURL: '/static/images/ceci/home/summary.png', description: 'Our algorithm will provide you a summary of comparison between the options provided' },
    { number: '6.', title: 'Export Summary', imageURL: '/static/images/ceci/home/exportSummary.png', description: 'Export your summary and share with contributors and decision makes alike. Or just save a version of your idea for reuse in other parts or your project or for archival purposes.' },
];

const HowItWorks = () => {
    const classes = useStyles();

    return (
        <div id="howceciworks" className={classes.root}>
            <div className={classes.container}>
                <div className={classes.headingContainer}>
                    <Typography variant="h4" className={classes.heading}>How CECI Works</Typography>
                </div>
                <Grid container spacing={4} justify="center" className={classes.grids}>
                    {sections.map((section, index) => (
                        <Grid key={`grid_${index}`} item xs={6} sm={6} md={6} lg={4} xl={4} className={classes.grid}>
                            <Typography variant="h5" className={classes.number}>{section.number}</Typography>
                            <img src={section.imageURL}
                                alt={section.title}
                                title={section.title}
                                className={classes.responsiveImage} />
                            <div className={classes.helperText}>
                                <Typography variant="h5">{section.title}</Typography>
                                <Typography variant="body1" className={classes.textDescription}>{section.description}</Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default HowItWorks;
