
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NotFound from './NotFound';
import { useMsal } from "@azure/msal-react";
import { Button } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { loginRequest } from '../../config/authConfig';
import { useHistory } from 'react-router-dom';
import { admin } from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
    signInButton: {
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: theme.spacing(2)
    }
}));

const NotAuthorized = () => {
    const classes = useStyles();
    const { instance } = useMsal();
    const history = useHistory();

    React.useEffect(() => {
        instance.logoutPopup();
    }, []);

    const handleLogin = () => {
        return instance.loginPopup(loginRequest)
            .then(() => {
                history.push(admin.landingPage.url);
            }).catch(e => {
                // TODO: handle the error
                console.log(e);
            });
    }

    return (
        <NotFound title="Not logged in" subTitle="You are not logged in, try signing in using the button below"
            image="/static/svg/accessDenied.svg"
            imageAlt="Not logged in">
            <div className={classes.signInButton}>
                <Button size={"large"} startIcon={<AccountCircleOutlinedIcon />} onClick={handleLogin} variant="contained" color="secondary">
                    Sign In
                </Button>
            </div>
        </NotFound>
    );
};

export default NotAuthorized;
