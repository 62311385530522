import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { FIXED_COST_TYPE, RANGE_COST_TYPE, RESOURCE_COST_TYPE } from '../../constants/evaluation/options';
import { fetchDaysBetween, fetchResourceCost, formatCost } from '../../utils/common';
import { EVALUATION_AGILE, EVALUATION_GENERIC } from '../../constants/projectTypes';
import { Typography, Grid, Tooltip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { fetchOptionStrengthByOptionId } from '../../utils/projectUtil';
import SummaryGauge from '../Chart/SummaryGauge';

const StyledTableCell = withStyles(() => ({
  head: {
    wordBreak: 'break-word',
    '&:hover': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
      border: `3px solid ${green[600]}`,
      backgroundColor: `${green[50]}`,
      cursor: 'pointer',
    },
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const OptionSummaryTable = (props) => {
  const { options, projectType, updateSelectedOption, selectedOption, strength, hasGoals } = props;

  const cellWidth = Math.round(100 / (options.length + 1)) + '%';

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            {options.map((option, index) => {
              return (
                <Tooltip key={`tooltip_${index}`} title="Click to mark as selected option">
                  <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center" onClick={() => updateSelectedOption(option.id)}>
                    <Grid container>
                      <Grid item xs={option.id === selectedOption ? 10 : 12}>
                        <Typography variant="h6">
                          {option.summary}
                        </Typography>
                      </Grid>
                      {option.id === selectedOption &&
                        <Grid item xs={2}>
                          <img src="/static/svg/dashboard/projects/confirm.svg" alt="Selected option" width="25px" height="25px" />
                        </Grid>
                      }
                    </Grid>
                  </StyledTableCell>
                </Tooltip>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="h6">Summary</Typography>
            </StyledTableCell>
            {options.map((option, index) => {
              return (
                <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">{option.summary}</StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="h6">Description</Typography>
            </StyledTableCell>
            {options.map((option, index) => {
              return (
                <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">{option.description ? option.description : '-'}</StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="h6">Cost</Typography>
            </StyledTableCell>
            {options.map((option, index) => {
              return (
                <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                  {option.cost.type === FIXED_COST_TYPE && '$ ' + formatCost(option.cost.value.fixedCost)}
                  {option.cost.type === RANGE_COST_TYPE && '$ ' + formatCost(option.cost.value.minPrice) + ' - $ ' + formatCost(option.cost.value.maxPrice)}
                  {option.cost.type === RESOURCE_COST_TYPE && '$ ' + fetchResourceCost(option.cost.value.resourceCost, fetchDaysBetween(option.startDate, option.endDate))}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="h6">Timeframe</Typography>
            </StyledTableCell>
            {options.map((option, index) => {
              return (
                <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                  {projectType !== EVALUATION_AGILE && fetchDaysBetween(option.startDate, option.endDate) + ' days'}
                  {projectType === EVALUATION_AGILE && option.duration + ' sprints'}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="h6">Features</Typography>
            </StyledTableCell>
            {options.map((option, index) => {
              const currentOptionStrength = fetchOptionStrengthByOptionId(strength, option.id);
              return (
                <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                  <SummaryGauge id={`feature_chart_${option.id}`} series={currentOptionStrength.featureOptionStrength} label="" />
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          {hasGoals &&
            <StyledTableRow>
              <StyledTableCell>
                <Typography variant="h6">Goals</Typography>
              </StyledTableCell>
              {options.map((option, index) => {
                const currentOptionStrength = fetchOptionStrengthByOptionId(strength, option.id);
                return (
                  <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                    <SummaryGauge id={`goal_chart_${option.id}`} series={currentOptionStrength.goalOptionStrength} label="" />
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>
          }
          {projectType !== EVALUATION_GENERIC &&
            <StyledTableRow>
              <StyledTableCell>
                <Typography variant="h6">Technical debt</Typography>
              </StyledTableCell>
              {options.map((option, index) => {
                return (
                  <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                    {option.technicalDebt}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>}
          {projectType !== EVALUATION_GENERIC &&
            <StyledTableRow>
              <StyledTableCell>
                <Typography variant="h6">Are resources available</Typography>
              </StyledTableCell>
              {options.map((option, index) => {
                return (
                  <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                    {option.resourcesAvailable ? 'Yes' : 'No'}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>}
          {projectType === EVALUATION_AGILE &&
            <StyledTableRow>
              <StyledTableCell>
                <Typography variant="h6">Is squad available</Typography>
              </StyledTableCell>
              {options.map((option, index) => {
                return (
                  <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                    {option.squadAvailable ? 'Yes' : 'No'}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>}
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="h6">Key benefits</Typography>
            </StyledTableCell>
            {options.map((option, index) => {
              return (
                <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                  {option.benefits.length === 0 && '-'}
                  {option.benefits.map((benefit, benIndex) => {
                    return (
                      <div key={`benefit_${index}`}>{`${benIndex + 1}. ${benefit}`}<br /></div>
                    )
                  })}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>
              <Typography variant="h6">Major constraints</Typography>
            </StyledTableCell>
            {options.map((option, index) => {
              return (
                <StyledTableCell style={{ width: cellWidth }} key={index} id={index} align="center">
                  {option.constraints.length === 0 && '-'}
                  {option.constraints.map((constraint, conIndex) => {
                    return (
                      <div key={`constraint_${index}`}>{`${conIndex + 1}. ${constraint}`}<br /></div>
                    )
                  })}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

OptionSummaryTable.propTypes = {
  options: PropTypes.array,
  projectType: PropTypes.string,
  updateSelectedOption: PropTypes.func,
  selectedOption: PropTypes.string,
  strength: PropTypes.any,
  hasGoals: PropTypes.bool
};

export default OptionSummaryTable;