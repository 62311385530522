import React from 'react';
import { Grid, TextField, Button, DialogTitle, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/evaluationAction';
import { bindActionCreators } from 'redux';
import idx from 'idx';
import PropTypes from 'prop-types';
import { findCurrentProjectDetails } from '../../../../utils/projectUtil';
import { EVALUATOR_GROUP_SIZE_ALLOWED, EVALUATOR_NAME_SIZE_ALLOWED } from '../../../../constants/evaluation/goals';

function ManageEvaluatorDialog({ data, open, onSave, goalEvaluators, onCancel, actions }) {
    const defaultName = idx(data, _ => _.name) || '';
    const defaultGroup = idx(data, _ => _.group) || '';
    const defaultInfluence = idx(data, _ => _.influence) || 1;

    const [name, setName] = React.useState(defaultName);
    const [group, setGroup] = React.useState(defaultGroup);
    const [influence, setInfluence] = React.useState(defaultInfluence);

    const isDuplicateCustomer = () => {
        return goalEvaluators.some(customer => customer.name.toUpperCase() === name.toUpperCase()) && name !== defaultName;
    }

    const handleSave = () => {
        const id = data ? data.id : '';
        data ? actions.updateGoalEvaluator({ name, group, influence, id }) :
            actions.addGoalEvaluator({ name, group, influence });
        onSave && onSave();
        handleClose();
    };

    const handleClose = () => {
        onCancel();
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        setName(defaultName);
        setGroup(defaultGroup);
        setInfluence(defaultInfluence);
    }, [open]);


    return (
        <React.Fragment>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <DialogTitle>
                    <Typography component="div" align="center">
                        <strong>{data ? "Edit" : "Add"} Evaluator</strong>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true} ref={descriptionElementRef} tabIndex={-1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="evaluatorName"
                                name="evaluatorName"
                                label="Name"
                                inputProps={{ maxLength: EVALUATOR_NAME_SIZE_ALLOWED }}
                                fullWidth
                                variant="outlined"
                                placeholder="Enter your evaluator name"
                                autoFocus
                                error={isDuplicateCustomer()}
                                helperText={isDuplicateCustomer() && `${name} already exists`}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                name="description"
                                label="Group"
                                required
                                variant="outlined"
                                placeholder="Enter group name"
                                value={group}
                                inputProps={{ maxLength: EVALUATOR_GROUP_SIZE_ALLOWED }}
                                fullWidth
                                onChange={(e) => {
                                    setGroup(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" fullWidth>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave} fullWidth
                        disabled={name === '' || isDuplicateCustomer() || group === ''}
                        variant="contained" color="secondary">
                        {data ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

ManageEvaluatorDialog.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    goalEvaluators: PropTypes.array,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        goalEvaluators: idx(currentProjectData, _ => _.goalEvaluators) || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEvaluatorDialog);