
import { Typography, Button, CardActions, Card, CardContent, Divider, CardHeader, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import React from 'react';
import Dropzone from '../../../../components/Dropzone';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { fetchDisplayStatus, fetchEvaluatorsName, fetchOptionTitleByOptionId, fetchProjectTitle, isProjectJsonValid } from '../../../../utils/projectUtil';
import DisplayKeyValueText from '../../../../components/DisplayText/DisplayKeyValueText';
import NotFound from '../../../../components/Common/NotFound';
import * as actions from '../../../../actions/projectActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    saveButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2)
    },
    card: {
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(2)
    },
    accordionDetails: {
        flexDirection: 'column'
    }
}));

const UploadProjectJson = ({ actions }) => {
    const classes = useStyles();
    const [fileData, setFileData] = React.useState();
    const [isImportValid, setIsImportValid] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [fileObject, setFileObject] = React.useState();

    const onUploadFile = ({ data, file }) => {
        setFileData(data);
        if (isProjectJsonValid(data)) {
            setShow(false);
            setIsImportValid(true);
            setFileObject(file);
        }
    };

    const saveProject = () => {
        actions.importProjectJson(fileData.version, fileObject).then(() => {
            setFileData();
            setShow(true);
            setFileObject();
        })
    }

    return (
        <React.Fragment>
            {!fileData && <Typography variant="h6">Upload the project json file exported from view projects screen</Typography>}<br />
            <Dropzone
                onUploadFile={onUploadFile}
                acceptedFiles={['.json']}
                dropzoneText="Upload your project as json file"
                showAlerts={true}
                filesLimit={1}
                showPreviewsInDropzone={show}
                maxFileSize={500000000}
                onFileRemoval={() => setFileData()}
            />
            {fileData && isImportValid &&
                <React.Fragment>
                    <Card className={classes.card}>
                        <CardHeader title="Project details" />
                        <Divider />
                        <CardContent>
                            <DisplayKeyValueText fieldName="Project type:" value={fileData.type ? fetchProjectTitle(fileData.type) : '-'} />
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography variant="h5">Idea</Typography>
                                </AccordionSummary>
                                <Divider />
                                <AccordionDetails className={classes.accordionDetails}>
                                    <DisplayKeyValueText fieldName="Problem statement:" value={(fileData.idea && fileData.idea.problemStatement) ? fileData.idea.problemStatement : '-'} />
                                    <DisplayKeyValueText fieldName="Idea title:" value={(fileData.idea && fileData.idea.title) ? fileData.idea.title : '-'} />
                                    <DisplayKeyValueText fieldName="Idea description:" value={(fileData.idea && fileData.idea.description) ? fileData.idea.description : '-'} />
                                    <DisplayKeyValueText fieldName="Key assumptions:" value={(fileData.idea && fileData.idea.assumptions) ? fileData.idea.assumptions : '-'} />
                                    <DisplayKeyValueText fieldName="Customer benefits:" value={(fileData.idea && fileData.idea.customerBenefit) ? fileData.idea.customerBenefit : '-'} />
                                    <DisplayKeyValueText fieldName="Target segment:" value={(fileData.idea && fileData.idea.targetSegment) ? fileData.idea.targetSegment : '-'} />
                                    <DisplayKeyValueText fieldName="Value proposition:" value={(fileData.idea && fileData.idea.valueProposition) ? fileData.idea.valueProposition : '-'} />
                                    <DisplayKeyValueText fieldName="Competitive Landscape:" value={(fileData.idea && fileData.idea.competitiveLandscape) ? fileData.idea.competitiveLandscape : '-'} />
                                    <DisplayKeyValueText fieldName="Business impact summary:" value={(fileData.idea && fileData.idea.businessImpactSummary) ? fileData.idea.businessImpactSummary : '-'} />
                                    <DisplayKeyValueText fieldName="Key metrics:" value={(fileData.idea && fileData.idea.keyMetrics) ? fileData.idea.keyMetrics : '-'} />
                                    <DisplayKeyValueText fieldName="Cost structure (High level callouts):" value={(fileData.idea && fileData.idea.costStructure) ? fileData.idea.costStructure : '-'} />
                                    <DisplayKeyValueText fieldName="Revenue streams:" value={(fileData.idea && fileData.idea.revenueStreams) ? fileData.idea.revenueStreams : '-'} />
                                    <DisplayKeyValueText fieldName="Notes:" value={(fileData.idea && fileData.idea.notes) ? fileData.idea.notes : '-'} />
                                </AccordionDetails>
                            </Accordion>
                            <DisplayKeyValueText fieldName="Evaluation status:" value={fileData.status ? fetchDisplayStatus(fileData.status) : '-'} />
                            <DisplayKeyValueText fieldName="Selected option:" value={fileData.selectedOption ? fetchOptionTitleByOptionId(fileData.selectedOption, fileData.options) : '-'} />
                            <DisplayKeyValueText fieldName="Feature evaluators:" value={fetchEvaluatorsName(fileData.featureEvaluators)} />
                            <DisplayKeyValueText fieldName="Goal evaluators:" value={fetchEvaluatorsName(fileData.goalEvaluators)} />
                            <DisplayKeyValueText fieldName="Total features:" value={fileData.features ? fileData.features.length : 0} />
                            <DisplayKeyValueText fieldName="Total goals:" value={fileData.goals ? fileData.goals.length : 0} />
                            <DisplayKeyValueText fieldName="Total options:" value={fileData.options ? fileData.options.length : 0} />
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.saveButton}>
                            <Button color="secondary" startIcon={<SaveIcon />} onClick={saveProject} variant="contained">Save project</Button>
                        </CardActions>
                    </Card>
                    <Typography variant="body2" color="textSecondary">* by proceeding ahead you agree to our <a rel="noopener noreferrer" href="/terms" target="_blank">terms and conditions</a></Typography>
                </React.Fragment>}
            {fileData && !isImportValid && <NotFound image={"/static/svg/dashboard/projects/filter.svg"}
                imageAlt={"Invalid file"}
                title={"Invalid data in the file uploaded"}
                subTitle={"The file which was uploaded is having invalid data, try uploading new file by removing the existing uploaded file"} />}
        </React.Fragment>
    );
};

UploadProjectJson.propTypes = {
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(UploadProjectJson);