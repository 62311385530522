import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import XLSX from 'xlsx';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { isExcel, isImage, isJson } from '../../utils/fileReaderUtil';

const Dropzone = (props) => {
  const { ...rest } = props;
  const [isFileUploaded, setFileUploaded] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  const handleChange = (files) => {
    files.forEach((file) => {
      const filename = file.name;
      const fileType = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
      if (isExcel(fileType)) {
        const reader = new FileReader();
        reader.onload = (evt) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'array' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: ' ' });
          props.onUploadFile({ data, file });
          setFileUploaded(true);
          if (file.name) {
            setFileName(file.name);
          }
        };
        reader.readAsArrayBuffer(file);
      } else if (isJson(fileType)) {
        var reader = new FileReader();
        reader.onload = function () {
          const data = JSON.parse(reader.result);
          props.onUploadFile({ data: data, file });
          setFileUploaded(true);
          if (file.name) {
            setFileName(file.name);
          }
        };
        reader.readAsText(file);
      } else if (isImage(file.type)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          props.onUploadFile({ data: reader.result, file });
        }
      } else {
        props.onUploadFile({ data: "", file });
      }
    });
  };

  if (isFileUploaded && !props.showPreviewsInDropzone) {
    return (
      <Alert
        action={
          <Button
            onClick={() => {
              setFileUploaded(false);
              props.onFileRemoval && props.onFileRemoval();
            }}
            color="inherit"
            size="small"
          >
            Remove
          </Button>
        }
      >
        {fileName && <strong>{fileName} - </strong>} File Uploaded
      </Alert>
    );
  }
  return (
    <DropzoneArea
      {...rest}
      acceptedFiles={props.acceptedFiles}
      onChange={handleChange}
      dropzoneText={props.dropzoneText}
      showAlerts={props.showAlerts}
      filesLimit={props.filesLimit}
      showPreviewsInDropzone={props.showPreviewsInDropzone}
      maxFileSize={props.maxFileSize}
    />
  );
};

Dropzone.propTypes = {
  onUploadFile: PropTypes.func,
  acceptedFiles: PropTypes.array,
  dropzoneText: PropTypes.string,
  showAlerts: PropTypes.bool,
  filesLimit: PropTypes.number,
  showPreviewsInDropzone: PropTypes.bool,
  maxFileSize: PropTypes.number,
  fileUploadApi: PropTypes.string,
  onFileRemoval: PropTypes.func
};

Dropzone.defaultProps = {
  fileUploadApi: '',
  onFileRemoval: () => { },
};

export default Dropzone;
