// THEMING ACTION TYPES
export const SET_THEMING_XLS_DATA = 'SET_THEMING_XLS_DATA';
export const GENERATE_THEMING_DETAILS = 'GENERATE_THEMING_DETAILS';
export const THEMING_STEP = 'THEMING_STEP';
export const SENTIMENT_ANALYSIS_SUCCESS = 'SENTIMENT_ANALYSIS_SUCCESS';
export const SENTIMENT_ANALYSIS_LOADING = 'SENTIMENT_ANALYSIS_LOADING';
export const SENTIMENT_ANALYSIS_ERROR = 'SENTIMENT_ANALYSIS_ERROR';
export const THEMING_REINITIALIZE = 'THEMING_REINITIALIZE';
export const UPDATE_THEMEING_TABULAR_DATA = 'UPDATE_THEMEING_TABULAR_DATA';
export const SET_INDUSTRY_STANDARD = 'SET_INDUSTRY_STANDARD';

// NPS ACTION TYPES
export const SET_NPS_XLS_DATA = 'SET_NPS_XLS_DATA';
export const GENERATE_NPS_DETAILS = 'GENERATE_NPS_DETAILS';
export const NPS_STEP = 'NPS_STEP';
export const NPS_SENTIMENT_ANALYSIS_SUCCESS = 'NPS_SENTIMENT_ANALYSIS_SUCCESS';
export const NPS_SENTIMENT_ANALYSIS_LOADING = 'NPS_SENTIMENT_ANALYSIS_LOADING';
export const NPS_SENTIMENT_ANALYSIS_ERROR = 'NPS_SENTIMENT_ANALYSIS_ERROR';
export const NPS_RESET = 'NPS_RESET';
export const UPDATE_NPS_TABULAR_DATA = 'UPDATE_NPS_TABULAR_DATA';

// PLAN ACTION TYPES
export const INSERT_PLAN_DATA = 'INSERT_PLAN_DATA';
export const CLEAR_PLAN_DATA = 'CLEAR_PLAN_DATA';
export const UPDATE_PLAN_PRICE_PROJECTS = 'UPDATE_PLAN_PRICE_PROJECTS';

// STEPPER ACTION TYPES
export const INCREMENT_STEP = 'INCREMENT_STEP';
export const DECREMENT_STEP = 'DECREMENT_STEP';
export const SKIP_STEP = 'SKIP_STEP';
export const SET_STEP = 'SET_STEP';

// PROJECT ACTION TYPES
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const REPLACE_PROJECTS = 'REPLACE_PROJECTS';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const INJECT_FEATURES_GOALS_OPTIONS_IN_PROJECT = 'INJECT_FEATURES_GOALS_OPTIONS_IN_PROJECT';

//EVALUATION ACTION TYPES
export const CREATE_IDEA = 'CREATE_IDEA';

export const ADD_FEATURE = 'ADD_FEATURE';
export const UPDATE_FEATURE = 'UPDATE_FEATURE';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
export const ASSIGN_LABEL_TO_FEATURE = 'ASSIGN_LABEL_TO_FEATURE';
export const ADD_FEATURE_LABEL = 'ADD_FEATURE_LABEL';
export const REMOVE_FEATURE_LABEL = 'REMOVE_FEATURE_LABEL';
export const ADD_FEATURE_EVALUATOR = 'ADD_FEATURE_EVALUATOR';
export const UPDATE_FEATURE_EVALUATOR = 'UPDATE_FEATURE_EVALUATOR';
export const REMOVE_FEATURE_EVALUATOR = 'REMOVE_FEATURE_EVALUATOR';
export const UPDATE_FEATURE_RATING_BY_EVALUATOR = 'UPDATE_FEATURE_RATING_BY_EVALUATOR';
export const MARK_FEATURE_AS_DELIVERED = 'MARK_FEATURE_AS_DELIVERED';

export const ADD_GOAL = 'ADD_GOAL';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const REMOVE_GOAL = 'REMOVE_GOAL';
export const ASSIGN_LABEL_TO_GOAL = 'ASSIGN_LABEL_TO_GOAL';
export const ADD_GOAL_LABEL = 'ADD_GOAL_LABEL';
export const REMOVE_GOAL_LABEL = 'REMOVE_GOAL_LABEL';
export const ADD_GOAL_EVALUATOR = 'ADD_GOAL_EVALUATOR';
export const UPDATE_GOAL_EVALUATOR = 'UPDATE_GOAL_EVALUATOR';
export const REMOVE_GOAL_EVALUATOR = 'REMOVE_GOAL_EVALUATOR';
export const UPDATE_GOAL_RATING_BY_EVALUATOR = 'UPDATE_GOAL_RATING_BY_EVALUATOR';

export const ADD_OPTION = 'ADD_OPTION';
export const UPDATE_OPTION = 'UPDATE_OPTION';
export const REMOVE_OPTION = 'REMOVE_OPTION';
export const UPDATE_OPTION_IMAGE  = 'UPDATE_OPTION_IMAGE';
export const UPDATE_FEATURE_RATING_BY_OPTION = 'UPDATE_FEATURE_RATING_BY_OPTION';
export const UPDATE_GOAL_RATING_BY_OPTION = 'UPDATE_GOAL_RATING_BY_OPTION';
export const OPTION_SELECTED_FOR_PROJECT = 'OPTION_SELECTED_FOR_PROJECT';
export const EVALUATION_STATUS_FOR_PROJECT = 'EVALUATION_STATUS_FOR_PROJECT';

export const GLOBAL_LOADER = 'GLOBAL_LOADER';
export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const RESET_STATE = 'RESET_STATE';