export const MetaHomePage = {
  title: 'Home - Feijoa8',
  meta: [
    {
      name: 'title',
      content: 'Home - Feijoa8',
    },
    {
      name: 'description',
      content: 'Feijoa8 is an ecosystem of products that is built to digitalize ideation and product development process',
    },
    {
      name: 'keywords',
      content: 'Feijoa8, Product, Ideation, Owner, Manager, Business, Analyst, Strategy, Tool, Cloud, App, Application, Analysis',
    },
    {
      name: 'robots',
      content: 'index, follow',
    }
  ],
};
