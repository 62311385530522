import moment from "moment";
import React from "react";
import { CECI_DATE_FORMAT } from "../constants/static";

export const isNumber = (value) => {
    return !isNaN(value);
}

export const formatCost = (value) => {
    return (value === '' || value === undefined) ? 0 : new Intl.NumberFormat('en-EN').format(value);
}

export const fetchDaysBetween = (startDate, endDate) => {
    return moment(endDate, CECI_DATE_FORMAT).diff(moment(startDate, CECI_DATE_FORMAT), 'days')
}

export const fetchResourceCost = (resourceCost, days) => {
    return formatCost((resourceCost || 0) * days)
}

export const convertHTMLToText = (htmlText) => {
    if (htmlText === '') {
        return '';
    }
    let text = htmlText;
    text = text.replace(/\n/gi, "");
    text = text.replace(/<\/li>/gi, "\n");
    text = text.replace(/<li.*?>/gi, "  •  ");
    text = text.replace(/<\/ul>/gi, "\n\n");
    text = text.replace(/<\/ol>/gi, "\n\n");
    text = text.replace(/<\/p>/gi, "\n\n");
    text = text.replace(/<[^>]+>/gi, "");
    text = text.replace(/^\s*/gim, "");
    text = text.replace(/ ,/gi, ",");
    text = text.replace(/ +/gi, " ");
    text = text.replace(/\n+/gi, "\n\n");
    return text;
}

export const convertHTMLToListView = (htmlText) => {
    const plainText = convertHTMLToText(htmlText);
    return plainText.split("\n").map((i, key) => {
        return i !== '' && <p key={key}>{i}</p>;
    })
}