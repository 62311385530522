import React from 'react';
import { TextField, Typography, Button, Grid } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import idx from 'idx';
import * as actions from '../../../../actions/evaluationAction';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import SaveIcon from '@material-ui/icons/Save';
import { LABELS_CREATION_ALLOWED } from '../../../../constants/evaluation/evaluationMetaData';
import { findCurrentProjectDetails } from '../../../../utils/projectUtil';
import { LABEL_SIZE_ALLOWED } from '../../../../constants/evaluation/goals';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(1),
        padding: theme.spacing(0.5)
    },
    grid: {
        padding: theme.spacing(2)
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function ManageGoalLabelDialog({ goalLabels, open, onClose, actions }) {
    const classes = useStyles();

    const [newLabelValue, setNewLabelValue] = React.useState('');

    const handleSave = () => {
        actions.addGoalLabel({ title: newLabelValue });
        setNewLabelValue('');
    }

    const isDuplicateLabel = () => {
        return goalLabels.find((label) => label.title.toUpperCase() === newLabelValue.toUpperCase()) != undefined;
    }

    const handleClose = () => {
        setNewLabelValue('');
        onClose();
    };

    const handleDelete = (chip) => () => {
        actions.removeGoalLabel({ id: chip.id });
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <React.Fragment>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Manage Labels
                </DialogTitle>
                <DialogContent dividers={true} ref={descriptionElementRef} tabIndex={-1}>
                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={9}>
                            <TextField id="label"
                                name="label"
                                label="Label"
                                fullWidth
                                value={newLabelValue}
                                disabled={LABELS_CREATION_ALLOWED === goalLabels.length}
                                inputProps={{ maxLength: LABEL_SIZE_ALLOWED }}
                                error={isDuplicateLabel()}
                                helperText={isDuplicateLabel() ? `${newLabelValue} already exists` : `Max ${LABELS_CREATION_ALLOWED} labels are allowed`}
                                variant="outlined"
                                onChange={(e) => {
                                    setNewLabelValue(e.target.value);
                                }}
                                placeholder="Enter new label"
                                autoFocus />
                        </Grid>
                        <Grid item xs={2}>
                            <Button size="large" startIcon={<SaveIcon />} disabled={newLabelValue === '' || isDuplicateLabel() || LABELS_CREATION_ALLOWED === goalLabels.length} onClick={handleSave} variant="contained" color="secondary">Save</Button>
                        </Grid>
                    </Grid>
                    {goalLabels.map((goalLabel, index) => {
                        return (
                            <Chip
                                key={`chip${index}`}
                                label={goalLabel.title}
                                onDelete={handleDelete(goalLabel)}
                                className={classes.chip}
                            />
                        );
                    })}
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}

ManageGoalLabelDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    goalLabels: PropTypes.array,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        goalLabels: idx(currentProjectData, _ => _.goalLabels) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGoalLabelDialog);