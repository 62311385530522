import { EVALUATION_AGILE, EVALUATION_GENERIC, EVALUATION_ITERATIVE, EVALUATION_WATERFALL } from '../projectTypes';

// Stages
export const CREATE_IDEA_STAGE = { type: 'CREATE_IDEA_STAGE', title: 'Idea' };
export const MANAGE_FEATURES_STAGE = { type: 'MANAGE_FEATURES_STAGE', title: 'Features' };
export const MANAGE_GOALS_STAGE = { type: 'MANAGE_GOALS_STAGE', title: 'Goals' };
export const MANAGE_OPTIONS_STAGE = { type: 'MANAGE_OPTIONS_STAGE', title: 'Options' };
export const EVALUATE_FEATURS_STAGE = { type: 'EVALUATE_FEATURS_STAGE', title: 'Evaluate Features' };
export const EVALUATE_GOALS_STAGE = { type: 'EVALUATE_GOALS_STAGE', title: 'Evaluate Goals' };
export const EVALUATION_SUMMARY_STAGE = { type: 'EVALUATION_SUMMARY_STAGE', title: 'Summary' };
export const EXPORT_JIRA_STAGE = { type: 'EXPORT_JIRA_STAGE', title: 'Export' };

// Journey stages
export const AGILE_EVALUATION_STAGES = [CREATE_IDEA_STAGE, MANAGE_FEATURES_STAGE, MANAGE_GOALS_STAGE, MANAGE_OPTIONS_STAGE, EVALUATE_FEATURS_STAGE, EVALUATE_GOALS_STAGE, EVALUATION_SUMMARY_STAGE]
export const WATERFALL_EVALUATION_STAGES = [CREATE_IDEA_STAGE, MANAGE_FEATURES_STAGE, MANAGE_GOALS_STAGE, MANAGE_OPTIONS_STAGE, EVALUATE_FEATURS_STAGE, EVALUATE_GOALS_STAGE, EVALUATION_SUMMARY_STAGE]
export const ITERTIVE_EVALUATION_STAGES = [CREATE_IDEA_STAGE, MANAGE_FEATURES_STAGE, MANAGE_GOALS_STAGE, MANAGE_OPTIONS_STAGE, EVALUATE_FEATURS_STAGE, EVALUATE_GOALS_STAGE, EVALUATION_SUMMARY_STAGE]
export const GENERIC_EVALUATION_STAGES = [CREATE_IDEA_STAGE, MANAGE_FEATURES_STAGE, MANAGE_GOALS_STAGE, MANAGE_OPTIONS_STAGE, EVALUATE_FEATURS_STAGE, EVALUATE_GOALS_STAGE, EVALUATION_SUMMARY_STAGE]

// header
export const headerData = [{
    projectType: EVALUATION_AGILE,
    stages: [{ stage: CREATE_IDEA_STAGE, title: 'Your Idea - Agile', subTitle: 'basic details of your idea' },
    { stage: MANAGE_FEATURES_STAGE, title: 'Features', subTitle: 'add and rate features that are required for your idea' },
    { stage: MANAGE_GOALS_STAGE, title: 'Goals', subTitle: 'add and rate goals from your idea' },
    { stage: MANAGE_OPTIONS_STAGE, title: 'Options', subTitle: 'possible options for idea' },
    { stage: EVALUATE_FEATURS_STAGE, title: 'Feature Evaluation', subTitle: 'rate option for required feature' },
    { stage: EVALUATE_GOALS_STAGE, title: 'Goal Evaluation', subTitle: 'rate option for required goal' },
    { stage: EVALUATION_SUMMARY_STAGE, title: 'Summary', subTitle: 'summary of evaluation' },
    { stage: EXPORT_JIRA_STAGE, title: 'Export', subTitle: 'export features to JIRA/Azure Devops and create iteration' }]
}, {
    projectType: EVALUATION_ITERATIVE,
    stages: [{ stage: CREATE_IDEA_STAGE, title: 'Your Idea - Iterative', subTitle: 'basic details of your idea' },
    { stage: MANAGE_FEATURES_STAGE, title: 'Features', subTitle: 'add and rate features that are required for your idea' },
    { stage: MANAGE_GOALS_STAGE, title: 'Goals', subTitle: 'add and rate goals from your idea' },
    { stage: MANAGE_OPTIONS_STAGE, title: 'Options', subTitle: 'possible options for idea' },
    { stage: EVALUATE_FEATURS_STAGE, title: 'Feature Evaluation', subTitle: 'rate option for required feature' },
    { stage: EVALUATE_GOALS_STAGE, title: 'Goal Evaluation', subTitle: 'rate option for required goal' },
    { stage: EVALUATION_SUMMARY_STAGE, title: 'Summary', subTitle: 'summary of evaluation' },
    { stage: EXPORT_JIRA_STAGE, title: 'Export', subTitle: 'export features to JIRA/Azure Devops and create iteration' }]
}, {
    projectType: EVALUATION_WATERFALL,
    stages: [{ stage: CREATE_IDEA_STAGE, title: 'Your Idea - Waterfall', subTitle: 'basic details of your idea' },
    { stage: MANAGE_FEATURES_STAGE, title: 'Features', subTitle: 'add and rate features that are required for your idea' },
    { stage: MANAGE_GOALS_STAGE, title: 'Goals', subTitle: 'add and rate goals from your idea' },
    { stage: MANAGE_OPTIONS_STAGE, title: 'Options', subTitle: 'possible options for idea' },
    { stage: EVALUATE_FEATURS_STAGE, title: 'Feature Evaluation', subTitle: 'rate option for required feature' },
    { stage: EVALUATE_GOALS_STAGE, title: 'Goal Evaluation', subTitle: 'rate option for required goal' },
    { stage: EVALUATION_SUMMARY_STAGE, title: 'Summary', subTitle: 'summary of evaluation' }]
}, {
    projectType: EVALUATION_GENERIC,
    stages: [{ stage: CREATE_IDEA_STAGE, title: 'Your Idea - Generic', subTitle: 'basic details of your idea' },
    { stage: MANAGE_FEATURES_STAGE, title: 'Features', subTitle: 'add and rate features that are required for your idea' },
    { stage: MANAGE_GOALS_STAGE, title: 'Goals', subTitle: 'add and rate goals from your idea' },
    { stage: MANAGE_OPTIONS_STAGE, title: 'Options', subTitle: 'possible options for idea' },
    { stage: EVALUATE_FEATURS_STAGE, title: 'Feature Evaluation', subTitle: 'rate option for required feature' },
    { stage: EVALUATE_GOALS_STAGE, title: 'Goal Evaluation', subTitle: 'rate option for required goal' },
    { stage: EVALUATION_SUMMARY_STAGE, title: 'Summary', subTitle: 'summary of evaluation' }]
}]

// Sort Options
export const NONE = 'NONE';
export const SORT_BY_ALPHABETICALLY_ASC = 'SORT_BY_ALPHABETICALLY_ASC';
export const SORT_BY_ALPHABETICALLY_DSC = 'SORT_BY_ALPHABETICALLY_DSC';
export const SORT_BY_RATING_HIGH_TO_LOW = 'SORT_BY_RATING_HIGH_TO_LOW';
export const SORT_BY_RATING_LOW_TO_HIGH = 'SORT_BY_RATING_LOW_TO_HIGH';

export const OPTIONS_FOR_SORT_BY = [{ label: 'None', value: NONE },
{ label: 'Alphabetically, ascending', value: SORT_BY_ALPHABETICALLY_ASC },
{ label: 'Alphabetically, descending ', value: SORT_BY_ALPHABETICALLY_DSC },
{ label: 'Rating, High to Low ', value: SORT_BY_RATING_HIGH_TO_LOW },
{ label: 'Rating, Low to High', value: SORT_BY_RATING_LOW_TO_HIGH }];

export const ALPHA_SORT_BY = [{ label: 'None', value: NONE },
{ label: 'Alphabetically, ascending', value: SORT_BY_ALPHABETICALLY_ASC },
{ label: 'Alphabetically, descending ', value: SORT_BY_ALPHABETICALLY_DSC }];

// SUMMARY VIEW
export const EVALUATOR_VIEW = 'EVALUATOR_VIEW';
export const EVALUATION_SUMMARY = 'EVALUATION_SUMMARY';
export const EVALUATION_COMPARISON = 'EVALUATION_COMPARISON';
export const EVALUATION_CHART = 'EVALUATION_CHART';
export const GRID_VIEW = 'GRID_VIEW';
export const LIST_VIEW = 'LIST_VIEW';
export const CAROUSEL_VIEW = 'CAROUSEL_VIEW';

export const EVALUATION_SUMMARY_VIEW = [{ label: 'Evaluator view', value: EVALUATOR_VIEW },
{ label: 'Evaluation summary view', value: EVALUATION_SUMMARY }]

export const OPTION_EVALUATION_VIEW = [{ label: 'Grid view', value: EVALUATOR_VIEW, icon: 'apps' },
{ label: 'List view', value: EVALUATION_COMPARISON, icon: 'view_list' },
{ label: 'Graphical view', value: EVALUATION_CHART, icon: 'bar_chart' }]

export const SUMMARY_VIEW = [{ label: 'Grid view', value: GRID_VIEW, icon: 'apps' },
{ label: 'List view', value: LIST_VIEW, icon: 'view_list' },
{ label: 'Carousel view', value: CAROUSEL_VIEW, icon: 'view_carousel' }]

// Rating text
export const FEATURE_RATING_LABEL = { null: 'Not at all important', 1: 'Least important', 2: 'Slightly important', 3: 'Moderately important', 4: 'Very important', 5: 'Extremely important' };
export const OPTION_RATING_LABEL = { null: 'Not at all helpful', 1: 'Least helpful', 2: 'Slightly helpful', 3: 'Moderately helpful', 4: 'Very helpful', 5: 'Extremely helpful' };

// Limits
export const LABELS_CREATION_ALLOWED = 10;
export const LABELS_ASSIGNMENT_ALLOWED = 3;

export const FEATURES_PER_PAGE = 10;
export const GOALS_PER_PAGE = 10;
export const FEATURE_GOALS_PER_PAGE = 12;
export const OPTION_GOALS_PER_PAGE = 12;

export const INCLUDES_FILTER = 'INCLUDES';
export const NOT_INCLUDES_FILTER = 'NOT_INCLUDES';