import React from 'react';
import { Container, Paper, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EvaluationStepperTitle from '../../../../components/HeaderV2/EvaluationStepperTitle';
import { EVALUATE_FEATURS_STAGE, FEATURE_GOALS_PER_PAGE, OPTIONS_FOR_SORT_BY, OPTION_EVALUATION_VIEW } from '../../../../constants/evaluation/evaluationMetaData';
import { ArrowForward, Assignment } from '@material-ui/icons';
import * as actions from '../../../../actions/evaluationAction';
import { findCurrentProjectDetails, hasData, countTotalPages, filterAndSortOptionFeatures, calculatePageStartIndex, calculatePageEndIndex, isEvaluatorView, isEvaluationComparison, isEvaluationChart } from '../../../../utils/projectUtil';
import { bindActionCreators } from 'redux';
import idx from 'idx';
import StepperNavigtion from '../../../../components/Common/StepperNavigtion';
import SimpleDropDown from '../../../../components/Dropdown/SimpleDropDown';
import LabelsDropDown from '../../../../components/Dropdown/LabelsDropDown';
import SearchTextbox from '../../../../components/DisplayText/SearchTextbox';
import { Pagination } from '@material-ui/lab';
import NotFound from '../../../../components/Common/NotFound';
import OptionEvaluationComponent from '../../../../components/Option/OptionEvaluationComponent';
import OptionComparisonComponent from '../../../../components/Option/OptionComparisonComponent';
import ViewsButtonGroup from '../../../../components/Button/ViewsButtonGroup';
import OptionGraphicalSummary from '../../../../components/Option/OptionGraphicalSummary';
import ExportOptionsEval from '../../../../components/Export/ExportOptionsEval';
import { tourSteps } from '../../../../constants/evaluation/featureEvaluation';

const useStyles = makeStyles((theme) => ({
    titleRoot: {
        padding: theme.spacing(2),
        boxShadow: "rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px",
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
        borderRadius: theme.spacing(2)
    },
    formControl: {
        minWidth: 240,
        maxWidth: 240
    },
    pagination: {
        display: 'flex',
        padding: theme.spacing(2)
    },
    notFoundPaper: {
        marginBottom: theme.spacing(2)
    }
}));

function EvaluateFeatures({ projectType, idea, isStepSkipped, options, features, featureLabels, actions }) {
    const classes = useStyles();

    const [filteredFeatures, setFilteredFeatures] = React.useState(features);
    const [sortBy, setSortBy] = React.useState(OPTIONS_FOR_SORT_BY[0].value);
    const [searchFeatureText, setSearchFeatureText] = React.useState('');
    const [filterLabels, setFilterLabels] = React.useState([]);

    const [hoveredFeature, setHoveredFeature] = React.useState('');
    const [ratingHoverState, setRatingHoverState] = React.useState(-1);

    const [evaluationView, setEvaluationView] = React.useState(OPTION_EVALUATION_VIEW[0].value);

    const [activeOptionId, setActiveOptionId] = React.useState(options[0].id);

    const [page, setPage] = React.useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleBack = () => {
        actions.decrementStep();
    };

    const handleNext = () => {
        //TODO: check if all features are evaluated
        actions.incrementStep();
    };

    const handleSortBy = (event) => {
        setSortBy(event.target.value);
    };

    const handleFeatureRating = (optionId, featureId, rating) => {
        rating = rating === null ? 0 : rating;
        actions.updateFeatureRatingByOption({ optionId, featureId, rating });
    }

    React.useEffect(() => {
        const updatedFilteredFeatures = filterAndSortOptionFeatures(features, featureLabels, sortBy, searchFeatureText, filterLabels, activeOptionId);
        setFilteredFeatures(updatedFilteredFeatures);
        setPage(1);
    }, [features, featureLabels, sortBy, searchFeatureText, filterLabels, activeOptionId]);

    return (
        <React.Fragment>
            <Container maxWidth={false}>
                <Paper className={classes.titleRoot}>
                    <EvaluationStepperTitle stage={EVALUATE_FEATURS_STAGE} projectType={projectType} steps={tourSteps} />
                    <Grid container spacing={2} justify="center" id="searchFilterSortFeature">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <SearchTextbox placeholder="Search feature" value={searchFeatureText} onChange={(value) => setSearchFeatureText(value.trim())} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <SimpleDropDown className={classes.formControl}
                                text={"Sort feature by"} onChange={handleSortBy}
                                value={sortBy} options={OPTIONS_FOR_SORT_BY} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <LabelsDropDown labels={featureLabels} dropDownLabel="Filter by labels" value={filterLabels}
                                dropDownPlaceholder="Labels" dropDownVariant="outlined" noOptionsText="No labels"
                                onChange={(value) => {
                                    setFilterLabels(value);
                                }} />
                        </Grid>
                    </Grid>
                </Paper>
                <Box mt={2}>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item id="exportFeatures">
                            <ExportOptionsEval type="Features" idea={idea} options={options} features={features}
                                filteredFeatures={filteredFeatures} isFileredApplied={searchFeatureText !== '' || filterLabels.length !== 0} />
                        </Grid>
                        <Grid item>
                            <ViewsButtonGroup id="evaluationViews" value={evaluationView} onChange={(e, value) => {
                                if (value !== null) {
                                    setEvaluationView(value)
                                }
                            }} viewOptions={OPTION_EVALUATION_VIEW} />
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={2} mb={2}>
                    {isEvaluatorView(evaluationView) &&
                        <OptionEvaluationComponent projectType={projectType} options={options} features={filteredFeatures.slice(calculatePageStartIndex(page, FEATURE_GOALS_PER_PAGE), calculatePageEndIndex(page, FEATURE_GOALS_PER_PAGE))}
                            labels={featureLabels}
                            handleHoveredFeature={(value) => setHoveredFeature(value)} hoveredFeature={hoveredFeature}
                            ratingHoverState={ratingHoverState} handleRatingUpdateHoverState={(value) => setRatingHoverState(value)}
                            handleFeatureRating={handleFeatureRating} handleActiveOptionId={(optionId) => setActiveOptionId(optionId)} />}

                    {isEvaluationComparison(evaluationView) &&
                        <OptionComparisonComponent projectType={projectType} options={options} features={filteredFeatures} handleRatingChange={handleFeatureRating}
                            featureLabels={featureLabels} />}
                    {isEvaluationChart(evaluationView) && <OptionGraphicalSummary features={filteredFeatures} options={options} />}
                </Box>
                {/* Pagination */}
                {hasData(filteredFeatures) && isEvaluatorView(evaluationView) &&
                    <Grid container justify="center" className={classes.pagination}>
                        <Pagination count={countTotalPages(filteredFeatures.length, FEATURE_GOALS_PER_PAGE)} page={page} color="secondary" onChange={handlePageChange} />
                    </Grid>
                }
                {/* Show this when no results are found in search/filter  */}
                {!hasData(filteredFeatures) && hasData(features) && <NotFound image={"/static/svg/dashboard/projects/noSearchResults.svg"}
                    imageAlt={"No results found"} className={classes.notFoundPaper}
                    subTitle={"No results found"} />}
                <StepperNavigtion handleBack={handleBack} handleNext={handleNext} skipStep={false}
                    nextButtonIcon={isStepSkipped ? '' : <ArrowForward />}
                    nextButtonStartIcon={isStepSkipped ? <Assignment /> : ''}
                    nextButtonText={isStepSkipped ? 'Summary' : 'Next'}
                    hideNextButton={false} />
            </Container>
        </React.Fragment>
    );
}

EvaluateFeatures.propTypes = {
    projectType: PropTypes.string.isRequired,
    isStepSkipped: PropTypes.bool,
    idea: PropTypes.object,
    options: PropTypes.array,
    features: PropTypes.array,
    featureLabels: PropTypes.array,
    actions: PropTypes.object
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        projectType: currentProjectData.type || '',
        idea: idx(currentProjectData, _ => _.idea || {}),
        isStepSkipped: idx(currentProjectData, _ => _.isStepSkipped || false),
        features: idx(currentProjectData, _ => _.features) || [],
        featureLabels: idx(currentProjectData, _ => _.featureLabels) || [],
        options: idx(currentProjectData, _ => _.options) || []
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(EvaluateFeatures);