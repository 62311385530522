export const OPTION_SUMMARY_TEXT_LENGTH_ALLOWED = 50;
export const OPTION_DESCRIPTION_TEXT_LENGTH_ALLOWED = 200;
export const OPTION_BENEFITS_ALLOWED = 3;
export const OPTION_BENEFIT_TEXT_LENGTH_ALLOWED = 200;
export const OPTION_CONSTRAINTS_ALLOWED = 3;
export const OPTION_CONSTRAINT_TEXT_LENGTH_ALLOWED = 200;
export const MAX_IMAGE_FILE_SIZE = 5000000;

export const CURRENCIES = [{
    value: '$',
    label: '$',
},
{
    value: '£',
    label: '£',
},
{
    value: '₹',
    label: '₹',
},
{
    value: '€',
    label: '€',
},
{
    value: 'Kč',
    label: 'Kč',
},
{
    value: '¥',
    label: '¥',
}];

export const TIME_FRAME_UNITS = [
    {
        value: 'Sprints',
        label: 'Sprints',
    },
    {
        value: 'Days',
        label: 'Days',
    },
    {
        value: 'Weeks',
        label: 'Weeks',
    },
    {
        value: 'Months',
        label: 'Months',
    },
    {
        value: 'Years',
        label: 'Years',
    }
];

export const TECHNICAL_DEBTS = [
    {
        value: 'Low',
        label: 'Low'
    },
    {
        value: 'Medium',
        label: 'Medium'
    },
    {
        value: 'High',
        label: 'High'
    }
]

export const FIXED_COST_TYPE = 'FIXED';
export const RANGE_COST_TYPE = 'RANGE';
export const RESOURCE_COST_TYPE = 'RESOURCE';

export const COST_TYPES = [
    {
        value: FIXED_COST_TYPE,
        label: 'Fixed price'
    },
    {
        value: RANGE_COST_TYPE,
        label: 'Price range'
    },
    {
        value: RESOURCE_COST_TYPE,
        label: 'Resource costing'
    }
]

export const AGILE_COST_TYPES = [
    {
        value: FIXED_COST_TYPE,
        label: 'Fixed price'
    },
    {
        value: RANGE_COST_TYPE,
        label: 'Price range'
    }
]

export const ADD_IMAGE_OPERATION_TYPE = 'ADD_IMAGE';
export const UPDATE_IMAGE_OPERATION_TYPE = 'UPDATE_IMAGE';
export const DELETE_IMAGE_OPERATION_TYPE = 'DELETE_IMAGE';

export const tourSteps = [
    {
        target: '#stepTitle',
        title: 'Options',
        content: 'ptions are the potential “solutions” that could be applied to address the customer and business needs as represented by the Features and Goals',
        disableBeacon: true
    },
    {
        target: '#addOption',
        title: 'Add option',
        content: 'Click here to add an option. You can have up to 5 options',
        disableBeacon: true
    },{
        target: '#grid_0',
        title: 'Option',
        content: 'Once you have added an option a summary is shown here',
        disableBeacon: true
    },
    {
        target: '#nextButton',
        title: 'Next',
        content: 'Once you have added your options, click Next to advance',
        disableBeacon: true
    }];