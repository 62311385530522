import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    contentDiv: {
        display: 'flex'
    },
    displayText: {
        margin: theme.spacing(1)
    }
}));
const DisplayKeyValueText = ({ fieldName, value }) => {
    const classes = useStyles();
    return (
        <div className={classes.contentDiv}>
            <Typography className={classes.displayText} variant="h5" color="primary"><strong>{fieldName}</strong></Typography>
            <Typography className={classes.displayText} variant="h5">{value}</Typography>
        </div>
    );
}
DisplayKeyValueText.propTypes = {
    fieldName: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DisplayKeyValueText;