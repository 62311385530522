import React, { useState } from 'react';
import { Grid, TextField, Button, Container, Paper, Accordion, AccordionSummary, Typography, AccordionActions, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/evaluationAction';
import EvaluationStepperTitle from '../../../../components/HeaderV2/EvaluationStepperTitle';
import { CREATE_IDEA_STAGE } from '../../../../constants/evaluation/evaluationMetaData';
import { ASSUMPTION_CHARACTER_LIMIT, BUSINESS_IMPACT_CHARACTER_LIMIT, COMPETITIVE_LANDSCAPE_CHARACTER_LIMIT, COST_STRUCTURE_CHARACTER_LIMIT, CUSTOMER_BENEFIT_CHARACTER_LIMIT, DESCRIPTION_CHARACTER_LIMIT, KEY_METRICS_CHARACTER_LIMIT, NOTES_CHARACTER_LIMIT, PROBLEM_STATEMENT_CHARACTER_LIMIT, REVENUE_STREAMS_CHARACTER_LIMIT, TARGET_MARKET_CHARACTER_LIMIT, TITLE_CHARACTER_LIMIT, tourSteps, VALUE_PROPOSITION_CHARACTER_LIMIT } from '../../../../constants/evaluation/createIdea';
import { findCurrentProjectDetails } from '../../../../utils/projectUtil';
import idx from 'idx';
import { useMsal, useAccount } from "@azure/msal-react";
import { fetchName } from '../../../../utils/identity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QuillTextbox from '../../../../components/DisplayText/QuillTextbox';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '10px',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        border: '1px solid #c5ccd3',
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(3),
        },
    },
    accordionHeader: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

function CreateIdea({ projectType = '', idea, featureEvaluators = [], projectsCount, actions }) {
    const classes = useStyles();

    const [title, setTitle] = useState(idea.title ? idea.title : '');
    const [description, setDescription] = useState(idea.description ? idea.description : '');
    const [problemStatement, setProblemStatement] = useState(idea.problemStatement ? idea.problemStatement : '');
    const [assumptions, setAssumptions] = useState(idea.assumptions ? idea.assumptions : '');
    const [notes, setNotes] = useState(idea.notes ? idea.notes : '');
    const [customerBenefit, setCustomerBenefit] = useState(idea.customerBenefit ? idea.customerBenefit : '');
    const [targetSegment, setTargetSegment] = useState(idea.targetSegment ? idea.targetSegment : '');
    const [valueProposition, setValueProposition] = useState(idea.valueProposition ? idea.valueProposition : '');
    const [competitiveLandscape, setCompetitiveLandscape] = useState(idea.competitiveLandscape ? idea.competitiveLandscape : '');
    const [businessImpactSummary, setBusinessImpactSummary] = useState(idea.businessImpactSummary ? idea.businessImpactSummary : '');
    const [keyMetrics, setKeyMetrics] = useState(idea.keyMetrics ? idea.keyMetrics : '');
    const [costStructure, setCostStructure] = useState(idea.costStructure ? idea.costStructure : '');
    const [revenueStreams, setRevenueStreams] = useState(idea.revenueStreams ? idea.revenueStreams : '');

    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const handleNext = () => {
        //Checking in case there is any change
        if (title !== idea.title || description !== idea.description || problemStatement !== idea.problemStatement
            || assumptions !== idea.assumptions || notes !== idea.notes || customerBenefit !== idea.customerBenefit
            || targetSegment !== idea.targetSegment || valueProposition !== idea.valueProposition || competitiveLandscape !== idea.competitiveLandscape
            || businessImpactSummary !== idea.businessImpactSummary || keyMetrics !== idea.keyMetrics || costStructure !== idea.costStructure
            || revenueStreams !== idea.revenueStreams) {
            actions.createIdea({
                title, description, problemStatement, assumptions, notes, customerBenefit, targetSegment,
                valueProposition, competitiveLandscape, businessImpactSummary, keyMetrics, costStructure, revenueStreams
            }).then(() => {
                addEvaluatorAndnIncrementStep();
            });
        } else {
            addEvaluatorAndnIncrementStep();
        }
    };

    const addEvaluatorAndnIncrementStep = () => {
        if (featureEvaluators.length === 0) {
            actions.addFeatureEvaluator({ name: fetchName(account), group: 'Group 1', influence: 1 })
                .then(() => {
                    actions.incrementStep();
                });
        } else {
            actions.incrementStep();
        }
    }

    const modules = {
        toolbar: [
            [{ list: "ordered" }, { list: "bullet" }]
        ],
    };

    const isFirstProject = () => {
        return projectsCount === 1 && title === '' && description === '' && problemStatement === '';
    }

    const tourIconStep = {
        target: '#tourIcon',
        title: 'Welcome for your first evaluation',
        content: 'You can always click on this icon when you are not sure what to do on the current step during the whole journey',
        disableBeacon: true
    }

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <main className={classes.layout}>
                    <Paper className={classes.paper} id="ideaCanvas">
                        <EvaluationStepperTitle stage={CREATE_IDEA_STAGE} projectType={projectType}
                            steps={isFirstProject() ? [tourIconStep, ...tourSteps] : tourSteps}
                            startTour={isFirstProject()} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField name="title" autoFocus
                                    value={title} onChange={(e) => {
                                        setTitle(e.target.value)
                                    }}
                                    required id="title" label="Idea title"
                                    inputProps={{ maxLength: TITLE_CHARACTER_LIMIT }}
                                    helperText={`Total characters ${title.length}/${TITLE_CHARACTER_LIMIT}`}
                                    fullWidth variant="outlined" placeholder="Give your Idea a title" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="problemStatement"
                                    value={problemStatement} onChange={(e) => {
                                        setProblemStatement(e.target.value)
                                    }}
                                    required id="problemStatement" label="Opportunity/Problem statement"
                                    inputProps={{ maxLength: PROBLEM_STATEMENT_CHARACTER_LIMIT }}
                                    fullWidth variant="outlined" placeholder="What business problem or opportunity are you trying to solve?" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="description" onChange={(e) => {
                                    setDescription(e.target.value)
                                }}
                                    id="description" label="Idea description"
                                    fullWidth multiline rows={2} variant="outlined"
                                    value={description} required
                                    inputProps={{ maxLength: DESCRIPTION_CHARACTER_LIMIT }}
                                    placeholder="Provide some desciption of your idea" />
                            </Grid>
                            <Grid item xs={12}>
                                <QuillTextbox id="assumptions" textValue={assumptions} onChange={(data) => setAssumptions(data)} modules={modules}
                                    placeholder="List the key assumptions that you are considering" label="Key assumptions"
                                    maxLength={ASSUMPTION_CHARACTER_LIMIT} />
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="customer-panel">
                                        <Typography className={classes.accordionHeader}>Customer</Typography>
                                    </AccordionSummary>
                                    <Divider />
                                    <AccordionActions>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <QuillTextbox id="customerBenefit" textValue={customerBenefit} onChange={(data) => setCustomerBenefit(data)} modules={modules}
                                                    placeholder="List the customer benefits" label="Customer benefits"
                                                    maxLength={CUSTOMER_BENEFIT_CHARACTER_LIMIT} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <QuillTextbox id="targetSegment" textValue={targetSegment} onChange={(data) => setTargetSegment(data)} modules={modules}
                                                    placeholder="What is the target market?" label="Target segment"
                                                    maxLength={TARGET_MARKET_CHARACTER_LIMIT} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField name="valueProposition" onChange={(e) => {
                                                    setValueProposition(e.target.value)
                                                }}
                                                    id="valueProposition" label="Value proposition"
                                                    fullWidth multiline rows={2} variant="outlined"
                                                    value={valueProposition}
                                                    inputProps={{ maxLength: VALUE_PROPOSITION_CHARACTER_LIMIT }}
                                                    placeholder="What is the value create?" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <QuillTextbox id="competitiveLandscape" textValue={competitiveLandscape} onChange={(data) => setCompetitiveLandscape(data)} modules={modules}
                                                    placeholder="What else is going on in the market?" label="Competitive Landscape"
                                                    maxLength={COMPETITIVE_LANDSCAPE_CHARACTER_LIMIT} />
                                            </Grid>
                                        </Grid>
                                    </AccordionActions>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="business-panel">
                                        <Typography className={classes.accordionHeader}>Business</Typography>
                                    </AccordionSummary>
                                    <Divider />
                                    <AccordionActions>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField name="businessImpactSummary" onChange={(e) => {
                                                    setBusinessImpactSummary(e.target.value)
                                                }} multiline rows={2}
                                                    id="businessImpactSummary" label="Business impact summary"
                                                    fullWidth variant="outlined" value={businessImpactSummary}
                                                    inputProps={{ maxLength: BUSINESS_IMPACT_CHARACTER_LIMIT }}
                                                    placeholder="What are the business impacts?" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <QuillTextbox id="keyMetrics" textValue={keyMetrics} onChange={(data) => setKeyMetrics(data)} modules={modules}
                                                    placeholder="What are the key metrics, e.g conversion rate, NPS, market share" label="Key metrics"
                                                    maxLength={KEY_METRICS_CHARACTER_LIMIT} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <QuillTextbox id="costStructure" textValue={costStructure} onChange={(data) => setCostStructure(data)} modules={modules}
                                                    placeholder="Customer acquisition, distribution, hosting etc" label="Cost structure (High level callouts)"
                                                    maxLength={COST_STRUCTURE_CHARACTER_LIMIT} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <QuillTextbox id="revenueStreams" textValue={revenueStreams} onChange={(data) => setRevenueStreams(data)} modules={modules}
                                                    placeholder="Enter your revenue streams" label="Revenue streams"
                                                    maxLength={REVENUE_STREAMS_CHARACTER_LIMIT} />
                                            </Grid>
                                        </Grid>
                                    </AccordionActions>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="notes" onChange={(e) => {
                                    setNotes(e.target.value)
                                }}
                                    id="notes" label="Notes"
                                    fullWidth multiline rows={2} variant="outlined"
                                    value={notes}
                                    inputProps={{ maxLength: NOTES_CHARACTER_LIMIT }}
                                    placeholder="Add any additional notes" />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleNext}
                                    fullWidth
                                    id="startEvaluation"
                                    className={classes.button}
                                    disabled={title === '' || problemStatement === '' || description === ''}
                                >
                                    Start Evaluation
                                </Button>
                            </Grid>
                            <Typography variant="body2" color="textSecondary">* by proceeding ahead you agree to our <a rel="noopener noreferrer" href="/terms" target="_blank">terms and conditions</a></Typography>
                        </Grid>
                    </Paper>
                </main>
            </Container>
        </React.Fragment>
    );
}

CreateIdea.propTypes = {
    projectType: PropTypes.string.isRequired,
    actions: PropTypes.object,
    idea: PropTypes.object,
    featureEvaluators: PropTypes.array,
    projectsCount: PropTypes.number
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        projectType: currentProjectData.type || '',
        idea: idx(currentProjectData, _ => _.idea) || {},
        featureEvaluators: idx(currentProjectData, _ => _.featureEvaluators) || [],
        projectsCount: state.userProjects.projects.length || 0
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateIdea);