import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import * as actions from '../../../../actions/projectActions';
import DashboardBody from '../../../../components/Body/DashboardBody';
import DashboardTitle from '../../../../components/Body/DashboardTitle';
import { Container, Grid, Paper } from '@material-ui/core';
import { MetaDashboardProjects } from './Seo/MetaDashboardProjects';
import { admin } from '../../../../constants/routes';
import NotFound from '../../../../components/Common/NotFound';
import NavigationButton from '../../../../components/Button/NavigationButton';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import WaitingLoader from '../../../../components/Loader/WaitingLoader';
import SearchTextbox from '../../../../components/DisplayText/SearchTextbox';
import SimpleDropDown from '../../../../components/Dropdown/SimpleDropDown';
import { SHOW_PROJECTS_FILTER, SORT_PROJECTS_OPTIONS } from '../../../../constants/projectTypes';
import { filterAndSortByProjects, hasData } from '../../../../utils/projectUtil';
import ProjectCard from '../../../../components/Card/ProjectCard';
import DeleteConfirmation from '../../../../components/Dialog/DeleteConfirmation';
import ExportProjectConfirmation from '../../../../components/Dialog/ExportProjectConfirmation';

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    padding: "0 32px 32px 32px",
    textAlign: "center",
    justifyContent: "center"
  },
  formControl: {
    minWidth: 240,
    maxWidth: 240
  },
  searchBar: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  notFoundPaper: {
    marginBottom: theme.spacing(2)
  }
}));

const Projects = ({ actions }) => {
  const classes = useStyles();
  const history = useHistory();

  const breadcrumb = [{ title: admin.dashboard.label, link: admin.dashboard.url }];

  const [projectsData, setProjectsData] = React.useState([]);
  const [deletedProjectId, setDeletedProjectId] = React.useState('');
  const [filteredProjectsData, setFilteredProjectsData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [hasProjects, setHasProjects] = React.useState(false);
  const [searchProjectText, setSearchProjectText] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState(SHOW_PROJECTS_FILTER[0].value);
  const [sortBy, setSortBy] = React.useState(SORT_PROJECTS_OPTIONS[0].value);

  const [showExportDialogBox, setShowExportDialogBox] = React.useState(false);
  const [showDeleteDialogBox, setShowDeleteDialogBox] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState('');
  const [deleteAndExport, setDeleteAndExport] = React.useState(true);

  const handleStatusFilter = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
  };

  const viewProject = (projectId) => {
    actions.fetchProjectData(projectId)
      .then(() => {
        actions.setCurrentProject(projectId);
        history.push({ pathname: admin.editProject.url })
      })
  }

  const onDelete = (projectId) => {
    setSelectedProject(projectId);
    setShowDeleteDialogBox(true);
  }

  const onExport = (projectId) => {
    setSelectedProject(projectId);
    setShowExportDialogBox(true);
  }

  const deleteProject = () => {
    setShowDeleteDialogBox(false);
    actions.deleteProject(selectedProject).then(() => {
      setProjectsData(projectsData.filter(project => project.id !== selectedProject));
      setDeletedProjectId(selectedProject);
    });
  }

  const exportProject = () => {
    setShowExportDialogBox(false);
    actions.exportProject(selectedProject, deleteAndExport).then(() => {
      if (deleteAndExport) {
        setProjectsData(projectsData.filter(project => project.id !== selectedProject));
        setDeletedProjectId(selectedProject);
      }
    });
  }

  const fetchUserProjects = () => {
    if (projectsData.length === 0) {
      actions.fetchProjects()
        .then((data) => {
          setProjectsData(data);
          data.length > 0 ? setHasProjects(true) : setHasProjects(false);
          const filteredProjects = filterAndSortByProjects(data, searchProjectText, statusFilter, sortBy);
          setFilteredProjectsData(filteredProjects);
        }).finally(() => {
          setLoading(false);
        });
    } else {
      const filteredProjects = filterAndSortByProjects(projectsData, searchProjectText, statusFilter, sortBy);
      setFilteredProjectsData(filteredProjects);
      setLoading(false);
    }
  }


  React.useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      fetchUserProjects();
    }, 100);
    return () => clearTimeout(timer);
  }, [searchProjectText, statusFilter, sortBy, deletedProjectId]);

  return (
    <React.Fragment>
      {/* Dialog box for delete project confirmation */}
      <DeleteConfirmation open={showDeleteDialogBox} title="Project" textConfirmation="Once project is deleted, you cannot recover it."
        onDelete={deleteProject} onCancel={() => setShowDeleteDialogBox(false)} />

      {/* Dialog box for export project confirmation */}
      <ExportProjectConfirmation open={showExportDialogBox} deleteProject={deleteAndExport}
        onExport={exportProject} onCancel={() => setShowExportDialogBox(false)} handleChangeDeleteProject={(event) => setDeleteAndExport(event.target.checked)} />

      <DashboardBody metaData={MetaDashboardProjects}>
        <Container maxWidth="xl">
          <DashboardTitle title={admin.projects.label} breadcrumbs={breadcrumb} showNewProject={true} />
          {isLoading && <WaitingLoader loadingText="Fetching projects" />}

          {!isLoading && hasProjects &&
            <React.Fragment>
              <Paper className={classes.searchBar}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <SearchTextbox autoFocus placeholder="Search projects" value={searchProjectText} onChange={(value) => setSearchProjectText(value)} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SimpleDropDown fullWidth
                      text={"Sort projects"} onChange={handleSortBy}
                      value={sortBy} options={SORT_PROJECTS_OPTIONS} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SimpleDropDown fullWidth
                      text={"Show projects"} onChange={handleStatusFilter}
                      value={statusFilter} options={SHOW_PROJECTS_FILTER} />
                  </Grid>
                </Grid>
              </Paper>
            </React.Fragment>
          }
          {!isLoading && hasData(filteredProjectsData) && hasProjects &&
            <Grid container spacing={3}>
              {filteredProjectsData.map((project, index) => {
                return (
                  <Grid id={`grid_${index}`} key={`grid_${index}`} item xs={12} sm={6} xl={4}>
                    <ProjectCard id={`project_${index}`} key={`project_${index}`} project={project}
                      onClick={viewProject} onDelete={onDelete} onExport={onExport} />
                  </Grid>
                )
              })}
            </Grid>
          }
          {!isLoading && !hasProjects &&
            <NotFound image={"/static/svg/dashboard/projects/noProjects.svg"}
              imageAlt={"No Projects"}
              title={"No Projects Found"}
              subTitle={"We guess this is your first time! Create a project now to start evaluation"}
              type={"project"} >
              <NavigationButton title={"Create Project"}
                navigateTo={admin.newProjectType.url}
                className={classes.button}
                icon={AddIcon} />
            </ NotFound>
          }
          {/* Show this when no results are found in search/filter  */}
          {!isLoading && !hasData(filteredProjectsData) && hasProjects && <NotFound image={"/static/svg/dashboard/projects/noSearchResults.svg"}
            imageAlt={"No projects found"} className={classes.notFoundPaper}
            subTitle={"No projects found"} />}
        </Container>
      </DashboardBody>
    </React.Fragment>
  );
};

Projects.propTypes = {
  actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(Projects);