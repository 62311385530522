import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBody from '../../../../components/Body/DashboardBody';
import { admin } from '../../../../constants/routes';
import { MetaDashboardPlanSuccess } from './Seo/MetaDashboardPlanSuccess';
import WaitingLoader from '../../../../components/Loader/WaitingLoader';
import NotFound from "../../../../components/Common/NotFound";
import { Button } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import NavigationButton from '../../../../components/Button/NavigationButton';
import { validateToken } from '../../../../actions/paymentActions';

const useStyles = makeStyles((theme) => ({
    dashboardButton: {
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: theme.spacing(2)
    }
}));

const PlanSuccessBought = () => {
    const history = useHistory();
    const classes = useStyles();

    const [loading, setLoading] = React.useState(true);
    const [isSessionValid, setIsSessionValid] = React.useState(false);
    const [hasRequiredField, setHasRequiredField] = React.useState(true);

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const status = params.get('status');
        const sessionId = params.get('session_id');
        if (status === null || status === '' || status !== 'success' || sessionId === '' || sessionId === null) {
            setHasRequiredField(false);
            setLoading(false);
        } else {
            validateToken(sessionId)
                .then(response => {
                    setIsSessionValid(response.isSessionValid);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                })
        }
    }, []);

    return (
        <DashboardBody metaData={MetaDashboardPlanSuccess}>
            {loading && <WaitingLoader />}
            {!loading && !hasRequiredField && <NotFound image={"/static/svg/dashboard/plans/noPlans.svg"}
                imageAlt={"Wrong place"}
                title={"Invalid page"}
                subTitle={"You have landed on invalid page"} >
                <div className={classes.dashboardButton}>
                    <Button size={"large"} startIcon={<DashboardIcon />} onClick={() => history.push(admin.dashboard.url)} variant="contained" color="secondary">
                        Go to dashboard
                    </Button>
                </div>
            </NotFound>}
            {!loading && isSessionValid && <NotFound image={"/static/svg/dashboard/plans/success.svg"}
                imageAlt={"Success"}
                title={"Plan successfully subscribed"}
                subTitle={"You have successfully subscribed, let's create first project"} >
                <NavigationButton title={"New Project"}
                    navigateTo={admin.newProjectType.url}
                    className={classes.dashboardButton}
                    icon={AddIcon} />
            </ NotFound>}
            {!loading && !isSessionValid}
        </DashboardBody>
    );
};

export default PlanSuccessBought;
