import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ContactForm from './ContactForm';
import EmailIcon from '@material-ui/icons/Email';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    headText: {
        textAlign: 'center'
    },
    email: {
        textAlign: 'center',
        marginTop: theme.spacing(4)
    },
    mailText: {
        margin: theme.spacing(3)
    }
}));


const ContactInfo = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container spacing={3} justify="center">
                <Grid item xs={12} alignItems="center">
                    <div className={classes.headText}> <Typography variant="h5"> We will love to hear from you. Contact us via email or fill in the form </Typography></div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classes.email}> <EmailIcon /><Typography className={classes.mailText} variant="body1"> <a href="mailto:info@feijoa8.com">info@feijoa8.com</a> </Typography></div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ContactForm />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default ContactInfo;
