import React from 'react';
import Header from '../../../components/HeaderV2/Header';
import HeaderLinks from '../../../components/HeaderV2/HeaderLinks';
import { ceciMenu } from '../../../constants/routes';
import Footer from '../../../components/Footer/Footer';
import { MetaHomePage } from './Seo/MetaHomePage';
import MetaTags from '../../../components/Seo/MetaTags';
import Body from '../../../components/Body';
import HeaderImage from './HeaderImage';
import Features from './Features';
import AboutCeci from './AboutCeci';
import HowItWorks from './HowItWorks';
import Navigation from './Navigation';
import ScrollToHashElement from '../../../components/Common/ScrollToHashElement';
const HomePage = () => {
  return (
    <>
      <Header
        brand="ceci"
        rightLinks={<HeaderLinks links={ceciMenu} />}
        fixed
      />
      <MetaTags {...MetaHomePage} />
      <Body title={MetaHomePage.pageTitle}>
        <ScrollToHashElement>
          <HeaderImage />
          <Navigation />
          <AboutCeci />
          <Features />
          <HowItWorks />
        </ScrollToHashElement>
      </Body>
      <Footer />
    </>
  );
};

export default HomePage;
