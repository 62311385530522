
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Card, CardContent, Grid } from '@material-ui/core';
import OptionSummary from '../Option/OptionSummary';
import SummaryGauge from '../Chart/SummaryGauge';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        borderRadius: theme.spacing(2),
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
    }
}));

const OptionSummaryCard = (props) => {
    const classes = useStyles();
    const { option, projectType, hasGoals, strength, ...rest } = props;

    return (
        <Card className={classes.card} {...rest}>
            <CardContent className={classes.content}>
                <OptionSummary option={option} projectType={projectType} />
            </CardContent>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <SummaryGauge id={`feature_chart_${option.id}`} series={strength.featureOptionStrength} label="Features" />
                </Grid>
                <Grid item xs={6}>
                    {hasGoals && <SummaryGauge id={`goal_chart_${option.id}`} series={strength.goalOptionStrength} label="Goals" />}
                </Grid>
            </Grid>
        </Card>
    );
};

OptionSummaryCard.propTypes = {
    option: PropTypes.object,
    projectType: PropTypes.string,
    hasGoals: PropTypes.bool,
    strength: PropTypes.object
};

export default OptionSummaryCard;
