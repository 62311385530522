import DashboardLayout from "../DashboardLayout";
import React from 'react';
import { loginRequest } from "./../../../config/authConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import LoginLoading from './LoginLoading';
import HomePage from "./../../../containers/Ceci/Home/HomePage";
import { InteractionType } from "@azure/msal-browser";
import NotFound from "../../Common/NotFound";
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import { PRO_PLAN_PRICES, PLAN_PRO } from '../../../constants/plan';
import * as actions from '../../../actions/planActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BuyPlanCard from "../../Card/BuyPlanCard";
import WaitingLoader from '../../Loader/WaitingLoader';
import Fullpage from "../../Loader/Fullpage";
import OperationSnackbar from "../../Alert/OperationSnackbar";
import { isUserAllowedToViewDashboard } from "../../../utils/planUtil";

const useStyles = makeStyles((theme) => ({
    buyButtons: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2)
    }
}));

const SecuredDashboardLayout = (props) => {
    const { children, requirePlan, isGlobalLoading, loadingGlobalText, globalSeverity, globalMessage, actions } = props;
    const classes = useStyles();
    const [planData, setPlanData] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [hasError, setHasError] = React.useState(false);

    React.useEffect(() => {
        actions.fetchPlanData()
            .then(data => {
                setPlanData(data);
            })
            .catch(() => { setHasError(true) })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const authRequest = {
        ...loginRequest
    };

    const hasAccess = () => {
        return requirePlan ? isUserAllowedToViewDashboard(planData) : true;
    }

    const handleClose = () => {
        actions.setSeverityStatus('', '');
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Popup}
            authenticationRequest={authRequest}
            errorComponent={HomePage}
            loadingComponent={LoginLoading}>
            <DashboardLayout>
                {loading && <WaitingLoader />}
                {isGlobalLoading && <Fullpage loadingText={loadingGlobalText} />}

                <OperationSnackbar open={globalSeverity !== '' && globalMessage !== ''} handleClose={handleClose}
                    severity={globalSeverity} message={globalMessage} />

                {!loading && !hasError && (hasAccess() ? children :
                    <React.Fragment>
                        <NotFound image={"/static/svg/dashboard/plans/noPlans.svg"}
                            imageAlt={"No Plan"}
                            title={"No Active Plan"}
                            subTitle={"We guess you don't have any active plan, buy plan to start evaluation. Try refreshing the screen, in case you already have a active plan and still seeing this screen"} >
                            <div className={classes.buyButtons}>
                                {PRO_PLAN_PRICES.slice(0, 3).map((plan, index) => {
                                    return (
                                        <BuyPlanCard key={index} id={index} planType={PLAN_PRO} numberOfProjects={plan.project} planPrice={plan.price} />
                                    )
                                })}
                            </div>
                        </ NotFound>
                    </React.Fragment>)}
                {hasError && <NotFound image={"/static/svg/dashboard/5xxError.svg"}
                    imageAlt={"Error"}
                    title={"Error while fetching data"}
                    subTitle={"We have got an error while your fetching data. Try refreshing the page, if issue persists please try logging in again after some time"} />
                }
            </DashboardLayout>
        </MsalAuthenticationTemplate>
    );
};

SecuredDashboardLayout.propTypes = {
    requirePlan: PropTypes.bool,
    children: PropTypes.node,
    isGlobalLoading: PropTypes.bool,
    loadingGlobalText: PropTypes.string,
    globalSeverity: PropTypes.string,
    globalMessage: PropTypes.string,
    actions: PropTypes.any
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

function mapStateToProps(state) {
    return {
        isGlobalLoading: state.global.isLoading,
        loadingGlobalText: state.global.loadingText || '',
        globalSeverity: state.global.severity || '',
        globalMessage: state.global.message || ''
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecuredDashboardLayout);