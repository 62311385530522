export const steps = ['Upload file', 'Confirm', 'Display'];

export const themingApi = 'https://text-classification-engine-rest-dot-feijoa8-dev.ts.r.appspot.com/api/v1/text/classify/entity-sentiments';

export const headingCells = ['type', 'salience', 'relevance', 'count'];

export const bodyCells = ['type', 'salience', 'relevance', 'count', 'entities'];

export const fileUploadTermsAndConditions = 'I agree to <a target="_blank" href="/terms">Terms and Conditions</a> agreement.';

export const defaultVertical = { title: "Other Services" }
export const industryVerticals = [
    { title: "Accommodation and Food Services" },
    { title: "Administrative and Support Services" },
    { title: "Agriculture" },
    { title: "Arts and Recreation Services" },
    { title: "Construction" },
    { title: "Education and Training" },
    { title: "Electricity" },
    { title: "Financial and Insurance Services" },
    { title: "Forestry and Fishing" },
    { title: "Gas" },
    { title: "Health Care and Social Assistance" },
    { title: "Hiring and Real Estate Services" },
    { title: "Information Media and Telecommunications" },
    { title: "Manufacturing" },
    { title: "Mining" },
    { title: "Postal and Warehousing" },
    { title: "Professional" },
    { title: "Public Administration and Safety" },
    { title: "Rental" },
    { title: "Retail Trade" },
    { title: "Scientific and Technical Services" },
    { title: "Transport" },
    { title: "Water and Waste Services" },
    { title: "Wholesale Trade" },
    defaultVertical
  ];
  
