export const TITLE_CHARACTER_LIMIT = 50;
export const PROBLEM_STATEMENT_CHARACTER_LIMIT = 100;
export const DESCRIPTION_CHARACTER_LIMIT = 200;
export const ASSUMPTION_CHARACTER_LIMIT = 250;
export const NOTES_CHARACTER_LIMIT = 200;
export const CUSTOMER_BENEFIT_CHARACTER_LIMIT = 250;
export const TARGET_MARKET_CHARACTER_LIMIT = 250;
export const VALUE_PROPOSITION_CHARACTER_LIMIT = 200;
export const COMPETITIVE_LANDSCAPE_CHARACTER_LIMIT = 250;
export const BUSINESS_IMPACT_CHARACTER_LIMIT = 200;
export const KEY_METRICS_CHARACTER_LIMIT = 250;
export const COST_STRUCTURE_CHARACTER_LIMIT = 250;
export const REVENUE_STREAMS_CHARACTER_LIMIT = 250;

export const tourSteps = [
    {
        target: '#stepper',
        content: 'This ribbon bar display your progress through the journey. You can also use it for navigation, however you can only navigate directly to steps that have content (for the first pass, you need to use the “Next” button to advance.',
        disableBeacon: true
    },{
        target: '#ideaCanvas',
        title: 'Idea Canvas',
        content: 'This is the section where you describe your idea. It follows similar principles to a Lean Canvas, however there are on three compulsory fields: Idea Title, Opportunity/Problem Statement and Idea Description. These summary fields are used throughout he journey and are key to completing your summary for publication',
        disableBeacon: true
    },
    {
        target: '#title',
        title: 'Idea title',
        content: 'Give you idea a short but meaningful title! This will appear throughout your project and in the dashboard',
        disableBeacon: true
    }, {
        target: '#problemStatement',
        title: 'Problem Statement',
        content: 'This field is used to describe “why” you are doing this initiative. It is usually to solve a customer or business problem, or look to develop new opportunities',
        disableBeacon: true
    }, {
        target: '#description',
        title: 'Idea description',
        content: 'Describe you proposal/idea here',
        disableBeacon: true
    }, {
        target: '#assumptions',
        title: 'Assumptions',
        content: 'List what your key assumption are. It is expected that these will evolve as your progress through your discovery. Note that you can complete this as a bullet or numbered list',
        disableBeacon: true
    }, {
        target: '#customer-panel',
        title: 'Customer',
        content: 'Click to roll down and complete some customer related fields. This includes; Customer Benefits, Target Segments and Value Proposition. We have also included “Competitive Landscape” here so you can add some insights about what your competitors may be doing',
        disableBeacon: true
    }, {
        target: '#business-panel',
        title: 'Business',
        content: 'Click to roll down and complete some business related fields. This includes; a Business Impact summary (statement), Key Metrics, Cost Structure and revenue streams',
        disableBeacon: true
    }, {
        target: '#notes',
        title: 'Notes',
        content: 'Add any supplementary notes or supporting information',
        disableBeacon: true
    }, {
        target: '#startEvaluation',
        title: 'Start Evaluation',
        content: 'Once you have completed the compulsory fields, this button will turn green and you can advance to the next step',
        disableBeacon: true
    }];