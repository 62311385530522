import React from 'react';
import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types'
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const OperationSnackbar = (props) => {
    const { open, handleClose, message, severity } = props;

    return (
        <React.Fragment>
            {severity !== '' && <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>}
        </React.Fragment>
    );
}

OperationSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    severity: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

export default OperationSnackbar;