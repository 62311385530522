import React from 'react';
import Header from '../../components/HeaderV2/Header';
import HeaderLinks from '../../components/HeaderV2/HeaderLinks';
import { feijoa8Menu } from '../../constants/routes';
import Footer from '../../components/Footer/Footer';
import Body from '../../components/Body';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { SectionContainer } from '../../components/Body/Containers';
import MetaTags from '../../components/Seo/MetaTags';
import { MetaTNCPage } from './Seo/MetaTNCPage';

const useStyles = makeStyles(() => ({
  root: {
    "& *": {
      boxSizing: 'inherit',
      lineHeight: '1.5em',
      paddingBottom: 'inherit'
    },
  },
  'section': {
    padding: '5px'
  },
}));

const TermsConditionsPage = () => {
  const classes = useStyles();
  return (
    <>
      <Header
        brand="feijoa8"
        rightLinks={<HeaderLinks links={feijoa8Menu} />}
        fixed
      />
      <MetaTags {...MetaTNCPage} />
      <Body title={MetaTNCPage.pageTitle}>
        <Divider />
        <SectionContainer>
          <div className={classes.root}>
            <ol>
              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li>Application of terms</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <ol>
                    <li>
                      These Terms apply to your use of the Website / Application / Webapp.  By accessing and using the Website / Application / Webapp:
                </li>
                    <ol type="a">
                      <li>you agree to these Terms; and</li>
                      <li>where your access and use is on behalf of another person (e.g. a company), you confirm that you are authorised to, and do in fact, agree to these Terms on that person’s behalf and that, by agreeing to these Terms on that person’s behalf, that person is bound by these Terms.</li>
                    </ol>
                    <li>If you do not agree to these Terms, you are not authorised to access and use the Websites / Applications / Webapps, and you must immediately stop doing so.</li>
                  </ol>
                </Typography>
              </section>

              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li>Changes</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <ol>
                    <li>We may change these Terms at any time by updating them on the Website / Application / Webapp.  Unless stated otherwise, any change takes effect immediately.  You are responsible for ensuring you are familiar with the latest Terms.  By continuing to access and use the Website / Application / Webapp, you agree to be bound by the changed Terms.</li>
                    <li>We may change, suspend, discontinue, or restrict access to, the Websites / Applications / Webapps without notice or liability.</li>
                    <li>These Terms were last updated on 11 February 2021. </li>
                  </ol>
                </Typography>
              </section>

              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li> Definitions</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  In these Terms:
                <ul>
                    <li>“including” and similar words do not imply any limit</li>
                    <li>Loss includes loss of profits, savings, revenue or data, and any other claim, damage, loss, liability and cost, including legal costs on a solicitor and own client basis</li>
                    <li>Personal information means information about an identifiable, living person </li>
                    <li>Terms means these terms and conditions titled Websites / Applications / Webapps Terms of Use</li>
                    <li>Underlying System means any network, system, software, data or material that underlies or is connected to the Websites / Applications / Webapps</li>
                    <li>User ID means a unique name and/or password allocated to you to allow you to access certain parts of the Websites / Applications / Webapps</li>
                  </ul>
            We, us or our means Feijoa8 Limited<br /><br />
            Websites / Applications / Webapps means www.fejoa8.com and domain names redirected to this site including but not limited to the following; www.feijoa8.design, www.feijoa8.co.nz, www.ceci.app.<br /><br />
            You means you or, if clause 1.1b applies, both you and the other person on whose behalf you are acting.
            </Typography>
              </section>
              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li>Your obligations</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <ol>
                    <li>You must provide true, current, and complete information in your dealings with us (including when setting up an account) and must promptly update that information as required so that the information remains true, current, and complete.</li>
                    <li>If you are given a User ID, you must keep your User ID secure and:</li>
                    <ol type="a">
                      <li>not permit any other person to use your User ID, including not disclosing or providing it to any other person; and</li>
                      <li>immediately notify us if you become aware of any disclosure or unauthorised use of your User ID, by sending an email to [insert contact email].</li>
                    </ol>
                    <li>You must:</li>
                    <ol type="a">
                      <li>not act in a way, or use or introduce anything (including any virus, worm, Trojan horse, timebomb, keystroke logger, spyware or other similar feature) that in any way compromises, or may compromise, the Websites / Applications / Webapps or any Underlying System, or otherwise attempt to damage or interfere with the Websites / Applications / Webapps or any Underlying System; and </li>
                      <li>unless with our agreement, access the Websites / Applications / Webapps via standard web browsers only and not by any other method.  Other methods include scraping, deep-linking, harvesting, data mining, use of a robot or spider, automation, or any similar data gathering, extraction or monitoring method.</li>
                    </ol>
                    <li>You must obtain our written permission to establish a link to our Website / Application / Webapp.  If you wish to do so, email your request to <a href="mailto:info@feijoa8.com">info@feijoa8.com</a></li>
                    <li>You indemnify us against all Loss we suffer or incur as a direct or indirect result of your failure to comply with these Terms, including any failure of a person who accesses and uses our Websites / Applications / Webapps by using your User ID or other credentials which allow access.</li>
                  </ol>
                </Typography>
              </section>

              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li>Intellectual property</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <p>We (and our licensors) own all proprietary and intellectual property rights in the Websites / Applications / Webapps (including all information, data, text, graphics, artwork, photographs, logos, icons, sound recordings, videos and look and feel), and the Underlying Systems.</p>
                </Typography>
              </section>
              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li>Disclaimers</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <ol>
                    <li>To the extent permitted by law, we and our licensors have no liability or responsibility to you or any other person for any Loss in connection with:</li>
                    <ol type="a">
                      <li>the Websites / Applications / Webapps being unavailable (in whole or in part) or performing slowly;</li>
                      <li>any error in, or omission from, any information made available through the Website / Application / Webapp;</li>
                      <li>any exposure to viruses or other forms of interference which may damage your computer system or expose you to fraud when you access or use the Website / Application / Webapp.  To avoid doubt, you are responsible for ensuring the process by which you access and use the Websites / Applications / Webapps protects you from this; and</li>
                      <li>any site linked from the Website / Application / Webapp.  Any link on the Websites / Applications / Webapps to other sites does not imply any endorsement, approval or recommendation of, or responsibility for, those sites or their contents, operations, products or operators.</li>
                    </ol>
                    <li>We make no representation or warranty that the Websites / Applications / Webapps is appropriate or available for use in all countries or that the content satisfies the laws of all countries.  You are responsible for ensuring that your access to and use of the Websites / Applications / Webapps is not illegal or prohibited, and for your own compliance with applicable local laws.</li>
                  </ol>
                </Typography>
              </section>

              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li> Liability</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <ol>
                    <li>To the maximum extent permitted by law:</li>
                    <ol type="a">
                      <li>you access and use the Websites / Applications / Webapps at your own risk; and </li>
                      <li>we are not liable or responsible to you or any other person for any Loss under or in connection with these Terms, the Website / Application / Webapp, or your access and use of (or inability to access or use) the Website / Application / Webapp.  This exclusion applies regardless of whether our liability or responsibility arises in contract, tort (including negligence), equity, breach of statutory duty, or otherwise.</li>
                    </ol>
                    <li>Except to the extent permitted by law, nothing in these Terms has the effect of contracting out of the New Zealand Consumer Guarantees Act 1993 or any other consumer protection law that cannot be excluded.  To the extent our liability cannot be excluded but can be limited, our liability is limited to the indicated base product price or NZD100, whichever is the lesser amount. All free products that are provided are classified as demonstration or demo products and have base product price of NZD $0 as no amount is charged for the use of these products / services.</li>
                    <li>To the maximum extent permitted by law and only to the extent clauses 7.1 and 7.2 of these Terms do not apply, our total liability to you under or in connection with these Terms, or in connection with the Website / Application / Webapp, or your access and use of (or inability to access or use) the Website / Application / Webapp, must not exceed the base product price or NZD$100 whichever is the lesser amount. All free products that are provided are classified as demonstration or demo products and have base product price of NZD $0 as no amount is charged for the use of these products / services.</li>
                  </ol>
                </Typography>
              </section>
              <section id="privacy" className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li> Privacy policy</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <p>We recognize the importance of protecting the privacy of personal identifying information about you as a user of this Website / Application / Webapp. This Websites / Applications / Webapps does not collect/record personal identifying information about users. The tools enabled and exposed by these Websites / Applications / Webapps only collect metadata (data about data) which is not a representation of the actual data provided by you. Data provided for analysis by our software is transient and never retained on a long-term basis. We will NEVER share or sell this information or share it with any third parties. The company (Feijoa8 Limited) which owns this Websites / Applications / Webapps and tools, is based in New Zealand and is bound by the New Zealand Privacy Act 1993.</p>
                  <ol>
                    <li>You are not required to provide personal information to us. For example, we may need to have your contact information in order to provide you with updates from our Website / Application / Webapp. The tools enabled and exposed by this Websites / Applications / Webapps only collect metadata (data about data) which is not a representation of the actual data provided by you. Data provided for analysis by our software is transient and never retained on a long-term basis.</li>
                    <li>The company which owns this Websites / Applications / Webapps and tools, is based in New Zealand and is bound by the New Zealand Privacy Act 1993.When you provide personal information to us, we will comply with the New Zealand Privacy Act 1993.</li>
                    <li>The personal information you provide to us (including any information provided if you register for an account) is collected and may be used for communicating with you, statistical analysis, the marketing by us of products and services to you, credit checks (if necessary), and research and development.</li>
                    <li>We may also collect technical information whenever you log on to, or visit the public version of, our Website / Application / Webapp.  This may include information about the way users arrive at, browse through and interact with our Website / Application / Webapp. We use the technical information we collect to have a better understanding of the way people use our Website / Application / Webapp, to improve the way it works and to personalise it to be more relevant and useful to your particular needs.  We may also use this information to assist in making any advertising we display on the Websites / Applications / Webapps more personalised and applicable to your interests.</li>
                    <li>Generally, we do not disclose personal information to third parties for them to use for their own purposes.  However, some of the circumstances in which we may do this are:</li>
                    <ol type="a">
                      <li>to service providers and other persons working with us to make the Websites / Applications / Webapps available or improve or develop its functionality (e.g. we may use a third-party supplier to host the Website / Application / Webapp);</li>
                      <li>in relation to the proposed purchase or acquisition of our business or assets; or</li>
                      <li>where required by applicable law or any court, or in response to a legitimate request by a law enforcement agency.</li>
                    </ol>
                    <li>Any personal information you provide to us may be stored on the secure servers of our trusted service providers, which may be located outside New Zealand.  This may involve the transfer of your personal information to countries which have less legal protection for personal information than New Zealand.</li>
                    <li>You have the right to request access to and correction of any of the personal information we hold about you.  If you would like to exercise these rights, please email us at <a href="mailto:info@feijoa8.com">info@feijoa8.com</a>.</li>
                  </ol>
                </Typography>
              </section>
              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong> <li>Suspension and termination</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <ol>
                    <li>Without prejudice to any other right or remedy available to us, if we consider that you have breached these Terms or we otherwise consider it appropriate, we may immediately, and without notice, suspend or terminate your access to the Websites / Applications / Webapps (or any part of it).</li>
                    <li>On suspension or termination, you must immediately cease using the Websites / Applications / Webapps and must not attempt to gain further access.</li>
                  </ol>
                </Typography>
              </section>
              <section className={classes.section}>
                <Typography variant="h5" gutterBottom>
                  <strong><li>General</li></strong>
                </Typography>
                <Typography component="div" gutterBottom>
                  <ol>
                    <li>If we need to contact you, we may do so by email or by posting a notice on the Website / Application / Webapp.  You agree that this satisfies all legal requirements in relation to written communications.</li>
                    <li>These Terms, and any dispute relating to these Terms or the Website / Application / Webapp, are governed by and must be interpreted in accordance with the laws of New Zealand.  Each party submits to the non-exclusive jurisdiction of the Courts of New Zealand in relation to any dispute connected with these Terms or the Website / Application / Webapp.</li>
                    <li>For us to waive a right under these Terms, the waiver must be in writing.</li>
                    <li>Clauses which, by their nature, are intended to survive termination of these Terms, including clauses 4.5, 5, 6, 7, 10.1, continue in force.</li>
                    <li>If any part or provision of these Terms is or becomes illegal, unenforceable, or invalid, that part or provision is deemed to be modified to the extent required to remedy the illegality, unenforceability or invalidity.  If a modification is not possible, the part or provision must be treated for all purposes as severed from these Terms.  The remainder of these Terms will be binding on you.</li>
                    <li>These Terms set out everything agreed by the parties relating to your use of the Websites / Applications / Webapps and supersede and cancel anything discussed, exchanged or agreed prior to you agreeing to these Terms.  The parties have not relied on any representation, warranty or agreement relating to the Websites / Applications / Webapps that is not expressly set out in the Terms, and no such representation, warranty or agreement has any effect from the date you agreed to these Terms.</li>
                  </ol>
                </Typography>
              </section>
            </ol>
          </div>
        </SectionContainer>
      </Body>
      <Footer />
    </>
  );
};

export default TermsConditionsPage;