import React from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { Popover, Divider, Box, Typography, Button, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardOutlined, AccountCircleOutlined } from '@material-ui/icons';
import { admin } from './../../constants/routes';
import NavItem from '../layouts/DashboardLayout/NavBar/NavItem';
import { fetchName, fetchRole, logout } from '../../utils/identity';
import { useHistory } from 'react-router-dom';
import * as actions from '../../actions/projectActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles((theme) => ({
    box: {
        padding: theme.spacing(2)
    },
    boxTop: {
        paddingTop: theme.spacing(2)
    },
    logout: {
        borderRadius: theme.spacing(2),
    },
    item: {
        padding: '6px 16px'
    },
    popOver: {
        '& .MuiPaper-rounded': {
            borderRadius: theme.spacing(2),
            width: '240px'
        }
    },
    logoutAvatar: {
        cursor: "pointer"
    }
}));

const items = [
    {
        href: admin.dashboard.url,
        icon: DashboardOutlined,
        title: admin.dashboard.label
    },
    {
        href: admin.accounts.url,
        icon: AccountCircleOutlined,
        title: admin.accounts.label
    }
];



const SignOutButton = ({ actions }) => {
    const classes = useStyles();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleLogout = () => {
        setAnchorEl(null);
        logout(instance, history, actions);
    }

    return (
        <React.Fragment>
            {account &&
                <span
                    className={classes.logoutAvatar}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >My Account</span>
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                className={classes.popOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box className={classes.box}>
                    <Typography variant="subtitle1" >
                        {fetchName(account)}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2" gutterBottom >
                        {fetchRole(account)}
                    </Typography>
                </Box>
                <Divider />
                <Box className={classes.boxTop}>
                    <List component="div" disablePadding>
                        {items.map((item) => (
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                className={classes.item}
                            />
                        ))}
                    </List>
                </Box>
                <Box className={classes.box}>
                    <Button variant="outlined" fullWidth color="primary" className={classes.logout} onClick={() => handleLogout()}>Logout</Button>
                </Box>
            </Popover>
        </React.Fragment>
    )
};

SignOutButton.propTypes = {
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(SignOutButton);