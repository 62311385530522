export const MetaPlanPage = {
  title: 'Plans - CECI',
  pageTitle: 'Plans',
  meta: [
    {
      name: 'title',
      content: 'Plans - CECI',
    },
    {
      name: 'description',
      content: 'CECI has various plans with different features for continous evaluation and continuous ideation',
    },
    {
      name: 'keywords',
      content: 'CECI,Continous Evaluation and Continuous Ideation,evaluation,ideation,pricing plans,FREE,Lite',
    },
    {
      name: 'robots',
      content: 'index, follow',
    }
  ],
};
