import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { IconButton, Toolbar, Tooltip, Typography, Menu, List, ListSubheader, ListItem, Fade, Button, Grid, FormControl, InputLabel, Select, MenuItem, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import LabelsDropDown from '../Dropdown/LabelsDropDown';
import IterationDropDown from '../Dropdown/IterationDropDown';
import { INCLUDES_FILTER, NOT_INCLUDES_FILTER } from '../../constants/evaluation/evaluationMetaData';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        position: 'sticky',
        top: 0,
        zIndex: 1
    },
    highlight: {
        color: theme.palette.colors.black,
        backgroundColor: theme.palette.colors.feijoa,
    },
    title: {
        flex: '1 1 100%',
    },
    listRoot: {
        width: '100%',
        minWidth: 360,
        backgroundColor: theme.palette.background.paper,
    }
}));
const EnhancedFeatureFilterToolbar = (props) => {
    const deliveryConditionOptions = [{ label: 'Includes', value: INCLUDES_FILTER },
    { label: 'Not includes', value: NOT_INCLUDES_FILTER }];

    const classes = useToolbarStyles();
    const { version, featureLabels, iterations, saveFilter } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [filterLabels, setFilterLabels] = React.useState([]);
    const [deliveryCondition, setDeliveryCondition] = React.useState(deliveryConditionOptions[0].value);
    const [deliveryIterations, setDeliveryIterations] = React.useState([]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const applyFilter = () => {
        saveFilter(filterLabels, { condition: deliveryCondition, iterations: deliveryIterations });
        handleClose();
    }

    return (
        <React.Fragment>
            <Toolbar className={clsx(classes.root, classes.highlight)}>
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    Iteration {version}
                </Typography>
                <Tooltip title="Filter data">
                    <IconButton aria-label="filter data" onClick={(event) => setAnchorEl(event.currentTarget)}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted open={open} onClose={handleClose}
                TransitionComponent={Fade}>
                <List subheader={<ListSubheader>Filters</ListSubheader>} className={classes.listRoot}>
                    <Divider />
                    <ListItem>
                        <LabelsDropDown labels={featureLabels} dropDownLabel="Filter by labels" value={filterLabels}
                            dropDownPlaceholder="Labels" dropDownVariant="outlined" fullWidth
                            onChange={(value) => {
                                setFilterLabels(value);
                            }} />
                    </ListItem>
                    <ListItem>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="age-native-simple">Condition</InputLabel>
                                    <Select fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={deliveryCondition}
                                        onChange={(e) => setDeliveryCondition(e.target.value)}
                                    >
                                        {deliveryConditionOptions.map((condition, index) => {
                                            return (
                                                <MenuItem id={index} key={index} value={condition.value}>{condition.label}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <IterationDropDown labels={iterations} dropDownLabel="Iterations" value={deliveryIterations}
                                    dropDownPlaceholder="Iteration" dropDownVariant="outlined" fullWidth
                                    onChange={(value) => {
                                        setDeliveryIterations(value);
                                    }} />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Button fullWidth variant="outlined" color="secondary" onClick={() => applyFilter()}>Apply</Button>
                    </ListItem>
                </List>
            </Menu>
        </React.Fragment >
    );
};

EnhancedFeatureFilterToolbar.propTypes = {
    version: PropTypes.number.isRequired,
    featureLabels: PropTypes.array,
    iterations: PropTypes.array,
    saveFilter: PropTypes.func
};

export default EnhancedFeatureFilterToolbar;