import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types'

const SimpleDropDown = (props) => {

    const { text, value, options, onChange, className, ...rest } = props;

    return (
        <FormControl variant="outlined" className={className} {...rest}>
            <InputLabel id="demo-simple-select-outlined-label">{text}</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={value} onChange={onChange} label={text}>
                {options.map((option, index) => {
                    return (
                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
}

SimpleDropDown.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default SimpleDropDown;