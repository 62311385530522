export const EVALUATORS_ALLOWED = 5;
export const GOAL_TITLE_SIZE_ALLOWED = 200;
export const EVALUATOR_NAME_SIZE_ALLOWED = 70;
export const EVALUATOR_GROUP_SIZE_ALLOWED = 70;
export const LABEL_SIZE_ALLOWED = 30;

export const tourSteps = [
    {
        target: '#goalsCanvas',
        title: 'Goals',
        content: 'Goals represent the business (or personal) objectives or outcomes you are trying to achieve. These will typically fall into the Time, Financial or Quality. However they may also include items such as market acquisition or retention, volumetric increases or decreases (e.g. changes in demand) or items such as brand perception.',
        disableBeacon: true
    },
    {
        target: '#manageEvaluators',
        title: 'Manage evaluators',
        content: 'Ceci.Studio will default you (the facilitator) as the evaluator.\nIf you create additional Evaluators (in the adjacent button), you can view and edit them here.\nYou can add additional Evaluators as required. Evaluators should be the subject matter experts, customers, users or personas (perhaps representing a customer segment) that engage with product.',
        disableBeacon: true
    }, {
        target: '#addEvaluators',
        title: 'Add evaluators',
        content: 'Click here to add evaluators.\nYou can have up to 5 evaluators (although this will vary depending upon your plan).\nYou can also categorise the Evaluators into groups so that if your evaluation teams is loaded with representatives from one area, you can see how this has skewed your evaluation.\n In this version of Ceci-Studio, the facilitator needs to enter the importance rating on behalf of the Evaluator (i.e. facilitate the input). You can also change the evaluator here',
        disableBeacon: true
    }, {
        target: '#manageLabels',
        title: 'Manage labels',
        content: 'Labels are a great way of categorising data.\nYou can create up to 10 labels and define them any way you like.\nSuggestions would include; Functional, Non Functional, Market, Sales, Service Usability, Privacy, Security',
        disableBeacon: true
    }, {
        target: '#addGoal',
        title: 'Add goal',
        content: 'Click here to add a goal, a pop up will appear and you can create your goal',
        disableBeacon: true
    }, {
        target: '#searchFilterSortGoal',
        title: 'Search, filter and sort goal',
        content: 'You can apply filters and sort using these fields',
        disableBeacon: true
    }, {
        target: '#evaluationViews',
        title: 'Evaluation views',
        content: 'If you have multiple evaluators, you can change the view to see and edit side by side comparisons',
        disableBeacon: true
    }, {
        target: '#exportGoals',
        title: 'Export goals',
        content: 'You are able to export your options to review or add into other applications or documents. This export is a CSV file (not an archive format) and can not be imported back into Ceci-Studio',
        disableBeacon: true
    }, {
        target: '#title_0',
        title: 'Goal title',
        content: 'The description you entered for you goals is shown here',
        disableBeacon: true
    }, {
        target: '#editTitle_0',
        title: 'Edit goal title',
        content: 'Click here if you need to re edit you goal card',
        disableBeacon: true
    }, {
        target: '#rating_0',
        title: 'Goal rating',
        content: 'We default a value of “3 stars”. Click the appropriate rating that represents what you Evaluator think the importance should be',
        disableBeacon: true
    },{
        target: '#rating_0',
        title: 'Goal rating',
        content: 'Note that the star rating is scored using a Fibonacci sequence. This forces distribution or results when creating your summary and publishing your findings.',
        disableBeacon: true
    }, {
        target: '#labels_0',
        title: 'Goal labels',
        content: 'Assign a label to aid in sorting and categorising at a later stage. Note that you can no edit labels here (you need to use the Manage Labels button). This is so multiple duplicates with minor variation are not created.',
        disableBeacon: true
    }, {
        target: '#skipButton',
        title: 'Skip',
        content: 'If you only want to evaluate goals, you can skip this step',
        disableBeacon: true
    }, {
        target: '#nextButton',
        title: 'Next',
        content: 'Once you have added your goals, click Next to advance',
        disableBeacon: true
    }];