
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NotFound from './NotFound';
import { Button } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useHistory } from 'react-router-dom';
import { admin } from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
    dashboardButton: {
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: theme.spacing(2)
    }
}));

const AccessForbidden = () => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <NotFound title="Access denied" subTitle="You don't have permission to access this page/functionality"
            image="/static/svg/accessDenied.svg"
            imageAlt="Access denied">
            <div className={classes.dashboardButton}>
                <Button size={"large"} startIcon={<DashboardIcon />} onClick={() => history.push(admin.dashboard.url)} variant="contained" color="secondary">
                    Go to dashboard
                </Button>
            </div>
        </NotFound>
    );
};

export default AccessForbidden;
