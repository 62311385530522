const home = {
  url: '/',
  label: 'Home',
  subMenu: [],
};

const about = {
  url: '/#about-us',
  label: 'About us',
  subMenu: [],
};

// const guru8it = {
//   url: '/guru8it',
//   label: 'guru8it',
// };

const ceci = {
  url: '/ceci',
  label: 'CECI.app',
};

const ceciStudio = {
  url: '/ceci-studio',
  label: 'CECI Studio',
  subMenu: []
};

const products = {
  url: '',
  label: 'Products & services',
  subMenu: [ceci, ceciStudio],
};

const termsCondtions = {
  url: '/terms',
  label: 'Terms & condtions',
  subMenu: [],
};

const privacy = {
  url: '/terms#privacy',
  label: 'Privacy policy',
  subMenu: [],
};

const feedback = {
  url: '/feedback',
  label: 'Feedback',
  subMenu: [],
};

const faq = {
  url: '/faq',
  label: 'FAQ',
  subMenu: [],
}

const contact = {
  url: '/contact',
  label: 'Contact us',
  subMenu: [],
};

const guru8itHome = {
  url: '/guru8it',
  label: 'Guru8it Home',
  subMenu: [],
};

const guru8itAbout = {
  url: '/guru8it/about',
  label: 'About Guru8it',
  subMenu: [],
};

const guru8itHowToUse = {
  url: '/guru8it/how-to-use',
  label: 'How to use',
  subMenu: [],
};

const guru8itGetInfo = {
  url: '/guru8it/get-info',
  label: 'Get Info',
  subMenu: [],
};

const ceciHome = {
  url: '/ceci',
  label: 'Home',
  subMenu: [],
};

const ceciPlans = {
  url: '/ceci/plans',
  label: 'Plans',
  subMenu: [],
};

const ceciFaq = {
  url: '/ceci/faq',
  label: 'FAQ',
  subMenu: [],
};


const ceciUseCases = {
  url: '/ceci/examples',
  label: 'Examples',
  subMenu: [],
};

const dashboard = {
  url: '/admin/dashboard',
  label: 'Dashboard',
  subMenu: []
}

const projects = {
  url: '/admin/projects',
  label: 'View Projects',
  subMenu: []
}

const editProject = {
  url: '/admin/project/edit',
  label: 'Edit Project',
  subMenu: []
}

const newProject = {
  url: '/admin/project/new',
  label: 'New Project',
  subMenu: []
}

const importProject = {
  url: '/admin/project/import',
  label: 'Import Project',
  subMenu: []
}

const newProjectType = {
  url: '/admin/project/types',
  label: 'New Project',
  subMenu: []
}

const accounts = {
  url: '/admin/profile',
  label: 'Your profile',
  subMenu: []
}

const billing = {
  url: '/admin/account',
  label: 'Account',
  subMenu: []
}

const ideaBacklog = {
  url: '/admin/backlog/ideas',
  label: 'Ideas Backlog',
  subMenu: []
}

const featureBacklog = {
  url: '/admin/backlog/features',
  label: 'Features Backlog',
  subMenu: []
}

const accessDenied = {
  url: '/admin/accessDenied',
  label: 'Access Denied',
  subMenu: []
}

const notAuthorized = {
  url: '/admin/notAuthorized',
  label: 'Not Authorized',
  subMenu: []
}

const successBuyPlan = {
  url: '/admin/plan/success',
  label: 'Plan successfully bought',
  subMenu: []
}

const landingPage = {
  url: '/admin/landing',
  label: 'Landing Page',
  subMenu: []
}

export const admin = { dashboard, projects, accounts, contact, billing, ideaBacklog, featureBacklog, ceciHome, ceciPlans, importProject, newProjectType, editProject, newProject, successBuyPlan, landingPage, ceciStudio }

const route = {
  home: home,
  about: about,
  products: products,
  feedback: feedback,
  faq: faq,
  termsCondtions: termsCondtions,
  privacy: privacy,
  contact: contact,
  guru8itAbout: guru8itAbout,
  guru8itHowToUse: guru8itHowToUse,
  guru8itGetInfo: guru8itGetInfo,
  ceciHome: ceciHome,
  ceciPlans: ceciPlans,
  ceciUseCases: ceciUseCases,
  ceciFaq: ceciFaq,
  admin: admin,
  accessDenied: accessDenied,
  notAuthorized: notAuthorized,
  successBuyPlan: successBuyPlan,
  ceciStudio: ceciStudio
};

export const feijoa8Menu = [home, about, products, faq];

export const guru8itMenu = [
  guru8itHome,
  guru8itAbout,
  guru8itHowToUse,
  guru8itGetInfo,
];

const feijoaHome = { ...home, label: 'Feijoa8 Home' };

export const ceciMenu = [
  feijoaHome,
  ceciHome,
  ceciPlans,
  ceciFaq,
  ceciUseCases,
];

const ceciStudioAboutUs = { url: ceciStudio.url + '#aboutus', label: 'About Ceci Studio' };
const ceciStudioFeature = { url: ceciStudio.url + '#feature', label: 'Features' };
const ceciStudioPlanPrice = { url: ceciStudio.url + '#plan', label: 'Plans and Price' };

export const ceciStudioMenu = [
  feijoaHome,
  ceciStudio,
  ceciStudioAboutUs,
  ceciStudioFeature,
  ceciStudioPlanPrice
];


export default route;
