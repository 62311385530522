import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  content: {
    textAlign: 'center'
  }
}));

const Fullpage = (props) => {
  const { loadingText } = props;
  const classes = useStyles();
  return (
    <Dialog
      open
      aria-labelledby="alert-dialog-titless"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.content}>
        <DialogContentText id="alert-dialog-description">
          {"Processing your request"}
        </DialogContentText>
        <img src="/static/svg/loader.svg" alt="loader" />
        <DialogContentText id="alert-dialog-description">
          {loadingText ? loadingText : 'This could take up to 2 minutes to complete, please do not refresh the page.'}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

Fullpage.propTypes = {
  loadingText: PropTypes.string
};

export default Fullpage;
