import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/HeaderV2/Header';
import HeaderLinks from '../../components/HeaderV2/HeaderLinks';
import Footer from '../../components/Footer/Footer';
import { feijoa8Menu } from '../../constants/routes'
import MetaTags from '../../components/Seo/MetaTags';
import { MetaErrorPage } from './Seo/MetaErrorPage';

const NotFoundPage = () => {
  //TODO: fix this page
  return (
    <React.Fragment>
      <Header
        brand="feijoa8"
        rightLinks={<HeaderLinks links={feijoa8Menu} />}
        fixed
      />
      <MetaTags {...MetaErrorPage} />
      <div>
        <h4>404 Page Not Found</h4>
        <Link to={'/'}> Go back to homepage </Link>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default NotFoundPage;
