import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { DialogContentText, DialogContent, Dialog, DialogActions, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomDialogTitle from './CustomDialogTitle';

const useStyles = makeStyles((theme) => ({
    actions: {
        padding: '0 24px 24px 24px'
    },
    deleteAccount: {
        backgroundColor: theme.palette.error.main,
        color: '#FFFFFF',
        "&:hover,&:focus": {
            color: "#000000",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        }
    }
}));

const DeleteConfirmation = (props) => {
    const classes = useStyles();
    const { open, title, textConfirmation, onDelete, onCancel } = props;
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CustomDialogTitle id="alert-dialog-title" onClose={onCancel}>
                Delete {title}
            </CustomDialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {textConfirmation}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onCancel} variant="outlined" color="primary" fullWidth size="large">
                    Cancel
                </Button>
                <Button startIcon={<DeleteIcon />} onClick={onDelete} className={classes.deleteAccount} variant="outlined" fullWidth size="large" autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
DeleteConfirmation.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    textConfirmation: PropTypes.string,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func
};

export default DeleteConfirmation;