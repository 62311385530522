export const EVALUATION_AGILE = 'EVALUATION_AGILE';
export const EVALUATION_WATERFALL = 'EVALUATION_WATERFALL';
export const EVALUATION_ITERATIVE = 'EVALUATION_ITERATIVE';
export const EVALUATION_GENERIC = 'EVALUATION_GENERIC';

export const projectTypes = [{
    title: 'Evaluation - Agile',
    titleImg: '/static/images/dashboard/projects/agile.jpg',
    description: 'Tool to evaluate your the options that you have to achieve your idea using Agile methodology',
    type: EVALUATION_AGILE
},
{
    title: 'Evaluation - Waterfall',
    titleImg: '/static/images/dashboard/projects/waterfallModel.png',
    description: 'Tool to evaluate your the options that you have to achieve your idea using Waterfall methodology',
    type: EVALUATION_WATERFALL
},
// {
//     title: 'Evaluation - Iterative/SAFe',
//     titleImg: '/static/images/dashboard/projects/iterative.jpg',
//     description: 'Tool to evaluate your the options that you have to achieve your idea using Iterative/SAFe methodology',
//     type: EVALUATION_ITERATIVE
// },
{
    title: 'Evaluation - Generic',
    titleImg: '/static/images/dashboard/projects/generic.jpg',
    description: 'Tool to evaluate your the options that you have to achieve your idea',
    type: EVALUATION_GENERIC
}]

export const PROJECT_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const PROJECT_STATUS_COMPLETED = 'COMPLETED';

export const ALL = 'ALL';
export const IN_PROGRESS = 'IN_PROGRESS';
export const COMPLETED = 'COMPLETED';
export const CREATED_ASC = 'CREATED_ASC';
export const CREATED_DESC = 'CREATED_DESC';
export const UPDATED_ASC = 'UPDATED_ASC';
export const UPDATED_DESC = 'UPDATED_DESC';
export const DESC = 'DESC';
export const ASC = 'ASC';
const NONE = 'NONE';

export const SHOW_PROJECTS_FILTER = [{ label: 'All', value: ALL },
{ label: 'In Progress', value: IN_PROGRESS },
{ label: 'Completed', value: COMPLETED }];

export const SORT_PROJECTS_OPTIONS = [{ label: 'None', value: NONE },
{ label: 'Created on, Ascending', value: CREATED_ASC },
{ label: 'Created on, Descending', value: CREATED_DESC },
{ label: 'Last updated on, Ascending', value: UPDATED_ASC },
{ label: 'Last updated on, Descending', value: UPDATED_DESC }]