import React from 'react';
import idx from 'idx';
import { Container, Paper, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EvaluationStepperTitle from '../../../../components/HeaderV2/EvaluationStepperTitle';
import { MANAGE_OPTIONS_STAGE } from '../../../../constants/evaluation/evaluationMetaData';
import * as actions from '../../../../actions/evaluationAction';
import { findCurrentProjectDetails, hasData, isProjectAgile, isProjectGeneric, isProjectIterative, isProjectWaterfall } from '../../../../utils/projectUtil';
import { bindActionCreators } from 'redux';
import StepperNavigtion from '../../../../components/Common/StepperNavigtion';
import NotFound from '../../../../components/Common/NotFound';
import AddFeatureButton from '../../../../components/Button/AddFeatureButton';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import OptionCard from '../../../../components/Card/OptionCard';
import ManageAgileOptionDialog from './ManageAgileOptionDialog';
import ManageGenericOptionDialog from './ManageGenericOptionDialog';
import ManageIterativeOptionDialog from './ManageIterativeOptionDialog';
import ManageWaterfallOptionDialog from './ManageWaterfallOptionDialog';
import ExportOptions from '../../../../components/Export/ExportOptions';
import { tourSteps } from '../../../../constants/evaluation/options';

const useStyles = makeStyles((theme) => ({
    titleRoot: {
        padding: theme.spacing(2),
        boxShadow: "rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px",
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
        borderRadius: theme.spacing(2)
    },
    notFoundPaper: {
        marginBottom: theme.spacing(2)
    },
    addOptionButton: {
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: theme.spacing(2)
    }
}));

function ManageOptions({ plan, projectType, idea, options, actions }) {
    const classes = useStyles();

    const [optionsAllowed, setOptionsAllowed] = React.useState(0);

    const [openOptionDialog, setOpenOptionDialog] = React.useState(false);
    const [optionData, setOptionData] = React.useState({});

    const handleNext = () => {
        actions.incrementStep();
    };

    const handleBack = () => {
        actions.decrementStep();
    };

    const editOption = (option) => {
        setOptionData(option);
        setOpenOptionDialog(true);
    }

    const deleteOption = (optionId) => {
        actions.removeOption({ id: optionId });
    }

    const onSave = () => {
        setOpenOptionDialog(false);
    }

    const onCancel = () => { setOpenOptionDialog(false) }

    React.useEffect(() => {
        setOptionsAllowed(plan.optionsAllowed);
    }, [plan]);

    return (
        <React.Fragment>
            <Container maxWidth={false}>
                <Paper className={classes.titleRoot}>
                    <EvaluationStepperTitle stage={MANAGE_OPTIONS_STAGE} projectType={projectType} steps={tourSteps} />
                    <Grid container justify="flex-end">
                        <Grid item id="addOption">
                            <AddFeatureButton label={"Add option"} disabled={options.length === optionsAllowed}
                                maxAllowed={optionsAllowed} maxWhatAllowed={"options"} startIcon={<EmojiObjectsOutlinedIcon />}
                                onClick={() => {
                                    setOpenOptionDialog(true);
                                    setOptionData();
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                {hasData(options) && <Grid container spacing={3} justify="flex-end" align="center">
                    <Grid item>
                        <ExportOptions idea={idea} options={options} projectType={projectType} />
                    </Grid>
                </Grid>}
                <Box mt={2}>
                    {hasData(options) &&
                        <Grid container spacing={3}>
                            {options.map((option, index) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3} id={`grid_${index}`} key={`grid_${index}`}>
                                        <OptionCard projectType={projectType} option={option} editOption={editOption} deleteOption={deleteOption} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                </Box>
                {!hasData(options) && <NotFound image={"/static/svg/dashboard/projects/addPost.svg"}
                    imageAlt={"No options"} className={classes.notFoundPaper}
                    title={"No options"}
                    subTitle={"Click the option button to start adding the options required for your idea"} >
                    <AddFeatureButton label={"Add option"} startIcon={<EmojiObjectsOutlinedIcon />} className={classes.addOptionButton}
                        onClick={() => {
                            setOpenOptionDialog(true);
                            setOptionData();
                        }} /></NotFound>}
                <StepperNavigtion handleBack={handleBack} handleNext={handleNext} disableNextButton={!hasData(options)} skipStep={false} hideNextButton={false} />
            </Container>
            <ManageAgileOptionDialog open={isProjectAgile(projectType) && openOptionDialog} data={optionData}
                onSave={onSave} onCancel={onCancel} />
            <ManageGenericOptionDialog open={isProjectGeneric(projectType) && openOptionDialog} data={optionData}
                onSave={onSave} onCancel={onCancel} />
            <ManageWaterfallOptionDialog open={isProjectWaterfall(projectType) && openOptionDialog} data={optionData}
                onSave={onSave} onCancel={onCancel} />
            <ManageIterativeOptionDialog open={isProjectIterative(projectType) && openOptionDialog} data={optionData}
                onSave={onSave} onCancel={onCancel} />
        </React.Fragment>
    );
}

ManageOptions.propTypes = {
    projectType: PropTypes.string.isRequired,
    plan: PropTypes.object,
    idea: PropTypes.object,
    options: PropTypes.array,
    actions: PropTypes.object
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        plan: state.plan || {},
        projectType: currentProjectData.type || '',
        idea: idx(currentProjectData, _ => _.idea) || {},
        options: idx(currentProjectData, _ => _.options) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageOptions);