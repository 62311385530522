import React from 'react';
import Carousel from 'react-material-ui-carousel'
import PropTypes from 'prop-types'
import OptionSummaryCard from '../Card/OptionSummaryCard';
import { fetchOptionStrengthByOptionId } from '../../utils/projectUtil';

const OptionCarouselComponent = (props) => {
    const { options, projectType, hasGoals, strength } = props;

    return (
        <Carousel animation="fade">
            {options.map((option, index) => {
                const currentOptionStrength = fetchOptionStrengthByOptionId(strength, option.id);
                return (
                    <OptionSummaryCard hasGoals={hasGoals} strength={currentOptionStrength} key={index} id={index} option={option} projectType={projectType} />
                )
            })}
        </Carousel>
    );
}
OptionCarouselComponent.propTypes = {
    options: PropTypes.array,
    projectType: PropTypes.string,
    hasGoals: PropTypes.bool,
    strength: PropTypes.array
};

export default OptionCarouselComponent;