import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, Typography, CardMedia, CardContent } from '@material-ui/core';

const useStyles = makeStyles({
    media: {
        height: 140
    },
});

const useCases = [{
    title: 'Real Estate',
    description: 'Provide the ability to assess and compare a potential property purchase against key feature requirements and considerations',
    imageTitle: 'Real Estate',
    imageUrl: '/static/images/ceci/usecases/realEstate.jpg'
},
{
    title: 'New Kitchen',
    description: 'You can use our tool to evaluate which kitchen variant is suitable for your needs',
    imageTitle: 'Kitchen',
    imageUrl: '/static/images/ceci/usecases/kitchen.jpg'
},
{
    title: 'Quote Evaluation',
    description: 'Provide the ability to assess and compare a potential property purchase against key feature requirements and considerations',
    imageTitle: 'Quote Evaluation',
    imageUrl: '/static/images/ceci/usecases/quoteEvaluation.jpg'
},
{
    title: 'Vendor/Product Selection',
    description: 'Provide the ability to create a set of product features and assess if a vendor is able to meet your needs',
    imageTitle: 'Vendor/Product Selection',
    imageUrl: '/static/images/ceci/usecases/vendor.jpg'
}]

const UseCasesCard = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container spacing={2} justify="flex-start">
                {useCases.map((useCase, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={`grid_${index}`}>
                        <Card key={`card_${index}`}>
                            <CardMedia
                                className={classes.media}
                                image={useCase.imageUrl}
                                title={useCase.imageTitle}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {useCase.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {useCase.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
};

export default UseCasesCard;
