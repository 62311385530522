import React from 'react';
import { Slider, Grid, Card, Typography, Button, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useHistory } from 'react-router-dom';
import { loginRequest } from "../../../config/authConfig";
import route, { admin } from '../../../constants/routes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { PRO_PLAN_DETAILS as plan, PRO_PLAN_PRICES as prices, PLAN_PRO } from '../../../constants/plan';
import BuyPlanButton from '../../../components/Button/BuyPlanButton';
import * as actions from '../../../actions/planActions';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { isEmpty } from 'ramda';
import WaitingLoader from '../../../components/Loader/WaitingLoader';
import CachedIcon from '@material-ui/icons/Cached';
import AlertDowngradePlanDialog from '../../../components/Dialog/AlertDowngradePlanDialog';
import { isUserAllowedToUpgradePlan } from '../../../utils/planUtil';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    cardHeader: {
        backgroundColor: '#a8da83',
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',

    },
    priceHelpText: {
        marginBottom: theme.spacing(2),
        textAlign: "center"
    },
    liteApple: {
        margin: 'auto'
    },
    summary: {
        padding: '5px'
    },
    getStarted: {
        textDecoration: 'none'
    },
    projects: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2)
    },
    discountedPrice: {
        textDecorationLine: 'line-through',
        textDecorationStyle: 'solid',
        marginRight: theme.spacing(1),
        color: red[800]
    }
}));

const marks = [
    {
        value: 5,
        label: 5
    },
    {
        value: 10,
        label: 10
    },
    {
        value: 20,
        label: 20
    }
];

const ProPlan = (props) => {
    const classes = useStyles();
    const { xs, sm, md, actions } = props;

    const { instance } = useMsal();
    const history = useHistory();
    const isAuthenticated = useIsAuthenticated();

    const [openAlert, setOpenAlert] = React.useState(false);
    const [newProjectsAllowed, setNewProjectsAllowed] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [planData, setPlanData] = React.useState({});
    const [projectQuantity, setProjectQuantity] = React.useState(prices[0].project);
    const [projectPrice, setProjectPrice] = React.useState(prices[0].price);
    const [projectHasDiscountedPrice, setProjectHasDiscountedPrice] = React.useState(prices[0].hasDiscountedPrice);
    const [projectDiscountedPrice, setProjectDiscountedPrice] = React.useState(prices[0].discountedPrice);

    const fetchUserPlan = () => {
        setLoading(true);
        actions.fetchPlanData()
            .then(data => {
                setPlanData(data);
            })
            .catch(() => { setHasError(true) })
            .finally(() => {
                setLoading(false);
            });
    }

    React.useEffect(() => {
        if (isAuthenticated) {
            fetchUserPlan();
        }
    }, [isAuthenticated]);

    const valuetext = (value) => {
        const selectedProduct = prices.find(price => price.project === value);
        setProjectQuantity(selectedProduct.project);
        setProjectPrice(selectedProduct.price);
        setProjectHasDiscountedPrice(selectedProduct.hasDiscountedPrice);
        setProjectDiscountedPrice(selectedProduct.discountedPrice);
        return value;
    }

    const handleLogin = () => {
        return instance.loginPopup(loginRequest)
            .then(() => {
                history.push(route.ceciPlans.url);
            }).catch(() => {
                //TODO: handle failure
                return;
            });
    }

    const hasPlan = () => {
        return isEmpty(planData) ? false : (planData.subscriptionStatus.toLowerCase() === 'active' && planData.planName.toLowerCase() === 'pro');
    }

    const isCurrentPlan = () => {
        return isEmpty(planData) ? false : projectQuantity === planData.projectsAllowed;
    }

    const updatePlan = () => {
        const response = actions.updatePlan(planData.planName, projectQuantity);
        response.then(data => {
            if (data === 'PRE_CONDITION_FAILED') {
                setNewProjectsAllowed(projectQuantity);
                setOpenAlert(true);
            } else {
                fetchUserPlan();
            }
        })
    }

    return (
        <Grid item xs={xs} sm={sm} md={md}>
            <AlertDowngradePlanDialog
                newProjectsAllowed={newProjectsAllowed} open={openAlert}
                onCancel={() => { setOpenAlert(false) }} />
            <Card className={classes.card}>
                <CardHeader
                    title={plan.title}
                    subheader={plan.subheader}
                    titleTypographyProps={{ align: 'center', variant: 'h4' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                />
                <CardContent>
                    <div className={classes.cardPricing}>
                        {projectHasDiscountedPrice && <Typography component="h5" variant="h5" className={classes.discountedPrice}>
                            ${projectPrice}
                        </Typography>}
                        <Typography component="h2" variant="h2" color="textPrimary">
                            ${projectHasDiscountedPrice ? projectDiscountedPrice : projectPrice}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            {"/mo"}
                        </Typography>
                    </div>
                    {projectHasDiscountedPrice && <Typography className={classes.priceHelpText} variant="body1" color="textSecondary">use coupon WELCOME5 by 30th June 2023 for a discounted price for the first 3 months</Typography>}
                    <Slider
                        defaultValue={10}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider"
                        step={null}
                        marks={marks}
                        min={5}
                        max={20}
                    />
                    <div className={classes.projects}>
                        <Typography variant="h6" color="textSecondary">
                            {projectQuantity}{" concurrent projects"}
                        </Typography>
                    </div>
                    <ul>
                        {plan.description.map((line) => (
                            <Typography className={classes.summary} component="li" variant="subtitle1" align="center" key={line}>
                                {line}
                            </Typography>
                        ))}
                    </ul>
                </CardContent>
                <CardActions>
                    <Grid container spacing={2} justify="center" align="center">
                        <Grid item>
                            {loading && <WaitingLoader />}
                            {hasError && <Button fullWidth size={"large"} startIcon={<CachedIcon />} onClick={() => window.location.reload()} variant="contained" color="primary">
                                Refresh
                            </Button>
                            }
                            {!loading && !hasError && !isAuthenticated &&
                                <Button fullWidth size={"large"} startIcon={<PersonIcon />} onClick={handleLogin} variant="contained" color="primary">
                                    Sign In
                                </Button>
                            }
                            {!loading && !hasError && isAuthenticated && hasPlan() && isCurrentPlan() &&
                                <Button fullWidth size={"large"} startIcon={<DashboardIcon />} onClick={() => history.push(admin.dashboard.url)} variant="contained" color="primary">
                                    Go to dashboard
                                </Button>
                            }
                            {!loading && !hasError && isAuthenticated && !isCurrentPlan() && isUserAllowedToUpgradePlan(planData) &&
                                <Button fullWidth size={"large"} startIcon={<ImportExportIcon />} onClick={() => updatePlan()} variant="contained" color="primary">
                                    Update plan
                                </Button>
                            }
                            {!loading && !hasError && isAuthenticated && !isUserAllowedToUpgradePlan(planData) &&
                                <BuyPlanButton fullWidth color="primary" text="Buy Plan" numberOfProjects={projectQuantity} planType={PLAN_PRO} />
                            }
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
};

ProPlan.propTypes = {
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(ProPlan);
