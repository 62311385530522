import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

class SummaryGauge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [props.series],
            options: {
                chart: {
                    id: props.id,
                    height: 150,
                    type: 'radialBar',
                    offsetY: -10,
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 2000,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        dataLabels: {
                            name: {
                                fontSize: '14px',
                                color: '#000000',
                                offsetY: 20
                            },
                            value: {
                                offsetY: -15,
                                fontSize: '18px',
                                show: true,
                                color: undefined,
                                formatter: function (val) {
                                    return val;
                                },
                            }
                        }
                    }
                },
                fill: {
                    colors: [function ({ value }) {
                        if (value <= 20) {
                            return '#FF0000'
                        } else if (value > 20 && value <= 40) {
                            return '#FFA500'
                        } else if (value > 40 && value <= 60) {
                            return '#FFFF00'
                        } else if (value > 60 && value <= 80) {
                            return '#90ee90'
                        } else {
                            return '#00FF00'
                        }
                    }]
                },
                stroke: {
                    lineCap: 'round'
                },
                labels: [props.label],
            },


        };
    }
    render() {
        return (<div>
            <Chart
                options={this.state.options} series={this.state.series} type="radialBar" height={150}
            />
        </div>
        )
    }
}

SummaryGauge.propTypes = {
    series: PropTypes.any,
    id: PropTypes.string,
    label: PropTypes.string
};

export default SummaryGauge;