import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import styles from './headerStyle';
import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core';
const useStyles = makeStyles(styles);
import routes from '../../constants/routes'

export default function Header(props) {
  const classes = useStyles();

  const isPrimaryNav = !!props.changePrimaryNavOnScroll;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [displayPrimaryNav, setMainNav] = React.useState(isPrimaryNav);


  React.useEffect(() => {
    if (props.changePrimaryNavOnScroll) {
      window.addEventListener('scroll', navChangeState);
    }
    return function cleanup() {
      if (props.changePrimaryNavOnScroll) {
        window.removeEventListener('scroll', navChangeState);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getbrandLink = (brand) => {
    switch (brand) {
      case 'ceci':
        return routes.ceciHome.url;
      case 'guru8it':
        return '/guru8it';
      case 'cecistudio':
        return routes.ceciStudio.url;
      default:
        return routes.home.url;
    }
  };

  const getbrandTitle = (brand) => {
    switch (brand) {
      case 'ceci':
        return 'Ceci.app';
      case 'guru8it':
        return 'Guru8It';
      case 'cecistudio':
        return `<strong>Ceci Studio</strong><div class=${classes.brandTitleSubtext}>by Feijoa8 Limited</div>`;
      default:
        return 'Feijoa8 Limited';
    }
  };

  const navChangeState = () => {
    const { changePrimaryNavOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changePrimaryNavOnScroll.height) {
      setMainNav(false);
    } else {
      setMainNav(true);
    }
  };

  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: !displayPrimaryNav && fixed,
  });

  return (
    <>
      {displayPrimaryNav && (
        <nav className={classes.primaryNav}>
          <Container>
            <div className={classes.primaryNavContainer}>
              <Link className={classes.primaryNavLink} to={routes.home.url}>
                Feijoa8
              </Link>
            </div>
            <div
              className={`${classes.primaryNavContainer} ${classes.activePrimaryNav}`}
            >
              <Link className={classes.primaryNavLink} to={routes.ceciHome.url}>
                CECI
              </Link>
            </div>
          </Container>
        </nav>
      )}
      <AppBar className={appBarClasses}>
        <Container>
          <Toolbar className={classes.container}>
            <Link to={getbrandLink(brand)}>
              <img
                src={`/static/svg/${brand}.svg`}
                width="75"
                height="50"
                alt={brand}
                title={brand}
                className={classes.logo}
              />
              <span></span>
            </Link>
            <div className={classes.titleSection}>
              {/* <Link to={getbrandLink(brand)}> */}
              <div dangerouslySetInnerHTML={{__html: getbrandTitle(brand)}} />
              {/* </Link> */}
            </div>
            <div className={classes.flex}>
              <Hidden smDown implementation="css">
                {leftLinks}
              </Hidden>
            </div>
            <Hidden smDown implementation="css">
              {rightLinks}
            </Hidden>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Toolbar>
          <Hidden mdUp implementation="js">
            <Drawer
              variant="temporary"
              anchor={'right'}
              open={mobileOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
            >
              <div className={classes.appResponsive}>
                {leftLinks}
                {rightLinks}
              </div>
            </Drawer>
          </Hidden>
        </Container>
      </AppBar>
    </>
  );
}

Header.defaultProp = {
  color: 'white',
};

Header.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark',
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changePrimaryNavOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
  }),
};
