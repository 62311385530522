
import React from 'react';
import DashboardBody from '../../../../components/Body/DashboardBody';
import DashboardTitle from '../../../../components/Body/DashboardTitle';
import { Container } from '@material-ui/core';
import { MetaDashboardProjectType } from './Seo/MetaDashboardProjectType';
import { admin } from '../../../../constants/routes';
import ProjectTypeOptions from './ProjectTypeOptions';

const NewProjectType = () => {
    let breadcrumb = [{ title: admin.dashboard.label, link: admin.dashboard.url }]
    return (
        <>
            <DashboardBody metaData={MetaDashboardProjectType}>
                <Container maxWidth="lg">
                    <DashboardTitle title={admin.newProjectType.label} breadcrumbs={breadcrumb} />
                    <ProjectTypeOptions />
                </Container>
            </DashboardBody>
        </>
    );
};

export default NewProjectType;
