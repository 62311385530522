import axios from 'axios';
import * as types from '../constants/actionTypes';
import { FETCH_USER_PLAN, UPDATE_DISPLAYNAME_URL, UPDATE_PLAN_URL, DELETE_PROFILE_URL, UPDATE_TNC_STATUS_URL } from '../constants/apiEndpoints';
import { ERROR_SEVERITY, SUCCESS_SEVERITY, INFO_SEVERITY } from '../constants/static';

export function insertPlanData(payload) {
    return {
        type: types.INSERT_PLAN_DATA,
        payload: payload
    };
}

export function clearPlanData() {
    return {
        type: types.CLEAR_PLAN_DATA
    };
}

export function updatePlanProjectAndPriceInRedux(payload) {
    return {
        type: types.UPDATE_PLAN_PRICE_PROJECTS,
        payload: payload
    }
}

export const setLoading = (isLoading, loadingMessage) => {
    return {
        type: types.GLOBAL_LOADER,
        payload: { isLoading, loadingMessage },
    };
};

export const setSeverityStatus = (severity, message) => {
    return {
        type: types.GLOBAL_ERROR,
        payload: { severity, message },
    };
};


export const infoMessage = (message) => (dispatch) => {
    return dispatch(setSeverityStatus(INFO_SEVERITY, message));
}

export const fetchPlanData = () => async (dispatch, getState) => {
    if (getState().plan === null) {
        const res = await axios.get(FETCH_USER_PLAN)
        const planData = res.data === "" ? {} : res.data;
        dispatch(insertPlanData(planData));
        return planData;
    } else {
        return getState().plan;
    }
};

export const updatePlan = (plan, projects) => (dispatch) => {
    dispatch(setLoading(true, 'Updating your plan'));
    return axios
        .put(UPDATE_PLAN_URL, {
            plan: plan,
            projects: projects
        })
        .then(({ data }) => {
            dispatch(updatePlanProjectAndPriceInRedux({ projectsAllowed: data.projectsAllowed, planPrice: data.planPrice, planName: data.planName }))
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Congratulations!! Your plan has been successfully updated')
            );
        })
        .catch((err) => {
            const errorCode = err.response.status || 500;
            if (errorCode === 412) {
                return 'PRE_CONDITION_FAILED';
            } else {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            }
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateDisplayName = () => () => {
    return axios.
        put(UPDATE_DISPLAYNAME_URL);
}

export const updateTermsAndConditions = () => (dispatch) => {
    dispatch(setLoading(true, 'Storing your response'));
    return axios.
        put(UPDATE_TNC_STATUS_URL)
        .then(() => {
            return;
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const deleteProfile = (markForDeletion, retainEmail) => (dispatch) => {
    dispatch(setLoading(true, markForDeletion ? 'Deleting account' : 'Activating account'));
    const headers = { deleteProfile: markForDeletion, retainEmail: retainEmail };
    return axios
        .delete(DELETE_PROFILE_URL, {
            headers: headers
        })
        .then(({ data }) => {
            dispatch(clearPlanData());
            dispatch(insertPlanData(data));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, markForDeletion ? 'Your profile has been marked for deletion' : 'Your account has been activated'));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}