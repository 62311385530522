import React from 'react';
import Header from '../../components/HeaderV2/Header';
import HeaderLinks from '../../components/HeaderV2/HeaderLinks';
import { ceciStudioMenu } from '../../constants/routes';
import Footer from '../../components/Footer/Footer';
import Body from '../../components/Body';
import Home from './Home/Home';
import { MetaHomePage } from './Home/Seo/MetaHomePage';
import MetaTags from '../../components/Seo/MetaTags';
import ScrollToHashElement from '../../components/Common/ScrollToHashElement';

const CeciStudio = () => {
    return (
        <>
            <Header
                brand="cecistudio"
                rightLinks={<HeaderLinks links={ceciStudioMenu} />}
                fixed
            />
            <MetaTags {...MetaHomePage} />
            <Body>
                <ScrollToHashElement>
                    <Home />
                </ScrollToHashElement>

            </Body>
            <Footer />
        </>
    );
};

export default CeciStudio;
