import React from 'react';
import { CardHeader, Chip, CardContent, Card, Divider, Box, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { isPlanCancelledAndBillingPeriodActive, isPlanCancelledAndBillingPeriodEnded, planState } from '../../../utils/planUtil';
import { ALERT_DATE_FORMAT, PROJECTS_COOL_OFF_PERIOD } from '../../../constants/static';

const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: theme.spacing(2)
    },
    card: {
        borderRadius: theme.spacing(2)
    },
    plan: {
        margin: "0px 0px 0px 8px",
        textTransform: "uppercase",
        color: "rgb(107, 119, 140)"
    },
    planPrice: {
        color: "rgb(23, 43, 77)",
        display: "inline"
    },
    chip: {
        backgroundColor: theme.palette.colors.feijoa
    },
    cancelChip: {
        backgroundColor: theme.palette.error.main
    }
}));

const Plan = (props) => {
    const classes = useStyles();
    const { planDetails } = props;
    const [userState, setUserState] = React.useState('');

    React.useEffect(() => {
        setUserState(planState(planDetails));
    }, [planDetails]);

    return (
        <Card className={classes.card}>
            <CardHeader title="Your plan" />
            <Divider />
            <CardContent>
                <Paper className={classes.paper}>
                    {isPlanCancelledAndBillingPeriodEnded(userState) && <Alert severity="warning">All the projects created by you will be deleted on {moment.unix(planDetails.subscriptionCancelOnDate).add(PROJECTS_COOL_OFF_PERIOD + 1, 'days').format(ALERT_DATE_FORMAT)}</Alert>}
                    {isPlanCancelledAndBillingPeriodActive(userState) && <Alert severity="warning">Your plan is scheduled to be cancelled on {moment.unix(planDetails.subscriptionCancelOnDate).format(ALERT_DATE_FORMAT)}</Alert>}
                    <Box alignItems="flex-end" display="flex" flexDirection="column" mt={1} mr={1}>
                        <Chip className={planDetails.subscriptionStatus === 'active' ? classes.chip : classes.cancelChip} label={planDetails.subscriptionStatus} />
                    </Box>
                    <Box alignItems="center" display="flex" flexDirection="column" pr={2} pl={2} pb={2}>
                        <Box alignItems="center" display="flex" flexDirection="column">
                            <Typography variant="overline" component="span" className={classes.plan}>
                                {planDetails.planName}
                            </Typography>
                        </Box>
                        <div>
                            <Typography variant="h4" className={classes.planPrice}>${planDetails.planPrice}</Typography>
                            <Typography variant="subtitle1" className={classes.planPrice}>/mo</Typography>
                        </div>
                    </Box>
                    <Divider />
                    <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between" p={3}>
                        <div>
                            <Typography variant="body2">{planDetails.projectsAllowed} concurrent projects</Typography>
                            <Typography variant="body2">{planDetails.goalsAllowed} goals per project</Typography>
                        </div>
                        <div>
                            <Typography variant="body2">{planDetails.featuresAllowed} features per project</Typography>
                            <Typography variant="body2">{planDetails.optionsAllowed} options per project</Typography>
                        </div>
                    </Box>
                </Paper>
            </CardContent>
        </Card >
    );
};

Plan.propTypes = {
    planDetails: PropTypes.object,
}

export default Plan;