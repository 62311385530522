import React from 'react';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import { DialogContent, Dialog, DialogContentText, Button, DialogActions } from '@material-ui/core';
import CustomDialogTitle from './CustomDialogTitle';
import Slide from '@material-ui/core/Slide';
import { admin } from '../../constants/routes';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDowngradePlanDialog = (props) => {
    const { open, onCancel, newProjectsAllowed } = props;
    const history = useHistory();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            maxWidth={"sm"}
            open={open}
            onClose={onCancel}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <CustomDialogTitle onClose={onCancel} >
                {"Are you sure you want to downgrade plan?"}
            </CustomDialogTitle>
            <DialogContent ref={descriptionElementRef} tabIndex={-1}>
                <DialogContentText id="alert-dialog-slide-description">
                    Currently you have created more than {newProjectsAllowed} projects. Delete few existing projects to allow updation of plan.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {
                    history.push(admin.projects.url);
                    onCancel();
                }} variant="contained" color="secondary">
                    Delete projects
                </Button>
            </DialogActions>
        </Dialog>
    );
}
AlertDowngradePlanDialog.propTypes = {
    open: PropTypes.bool,
    newProjectsAllowed: PropTypes.number,
    onCancel: PropTypes.func
};

export default AlertDowngradePlanDialog;