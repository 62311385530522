import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ArrowBack, ArrowForward, SkipNext } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    button: {
        padding: '10px',
    },
}));

function StepperNavigtion(props) {
    const classes = useStyles();
    const { skipStep, nextButtonStartIcon, nextButtonIcon, hideNextButton, nextButtonText, handleBack, handleSkip, handleNext, disableNextButton, disableSkipButton } = props;
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={skipStep ? 4 : 6}>
                    <Button
                        color="primary"
                        onClick={handleBack}
                        fullWidth
                        className={classes.button}
                        startIcon={<ArrowBack />}
                    >
                        Back
                    </Button>
                </Grid>
                {skipStep &&
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            onClick={handleSkip}
                            fullWidth
                            id="skipButton"
                            disabled={disableSkipButton}
                            className={classes.button}
                            endIcon={<SkipNext />}
                        >
                            Skip
                        </Button>
                    </Grid>}
                {!hideNextButton &&
                    <Grid item xs={skipStep ? 4 : 6}>
                        <Button
                            variant="contained"
                            id="nextButton"
                            color="secondary"
                            onClick={handleNext}
                            fullWidth
                            disabled={disableNextButton}
                            className={classes.button}
                            startIcon={nextButtonStartIcon ? nextButtonStartIcon : ''}
                            endIcon={nextButtonIcon ? nextButtonIcon : <ArrowForward />}
                        >
                            {nextButtonText ? nextButtonText : 'Next'}
                        </Button>
                    </Grid>}
            </Grid>
        </React.Fragment>
    );
}

StepperNavigtion.propTypes = {
    skipStep: PropTypes.bool,
    hideNextButton: PropTypes.bool,
    nextButtonStartIcon: PropTypes.any,
    nextButtonIcon: PropTypes.any,
    nextButtonText: PropTypes.string,
    handleBack: PropTypes.func.isRequired,
    handleSkip: PropTypes.func,
    handleNext: PropTypes.func,
    disableNextButton: PropTypes.bool,
    disableSkipButton: PropTypes.bool
};


export default StepperNavigtion;