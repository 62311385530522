import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
import { CUSTOMER_PORTAL_URL, INITIATE_BUY_PLAN, VALIDATE_SESSION_ID } from "../constants/apiEndpoints";
import { ERROR_SEVERITY, WARNING_SEVERITY } from "../constants/static";
import { setLoading, setSeverityStatus } from "./planActions";

const stripePromise = loadStripe(process.env.STRIPE_CLIENT_KEY);

export const initiateBuyPlan = (projectsQuantity, planType) => (dispatch) => {
    dispatch(setLoading(true, ''));
    return axios
        .post(INITIATE_BUY_PLAN, {
            projects: projectsQuantity,
            plan: planType
        })
        .then(async response => {
            const stripe = await stripePromise;
            stripe.redirectToCheckout({
                sessionId: response.data.sessionId,
            })
        })
        .catch((err) => {
            const errorCode = err.response.status || 500;
            if (errorCode === 409) {
                dispatch(setSeverityStatus(WARNING_SEVERITY, 'You already have an active plan'));
            } else {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            }
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        })
}

export const openCustomerPortal = (custId) => (dispatch) => {
    dispatch(setLoading(true, ''));
    return axios
        .post(CUSTOMER_PORTAL_URL, {
            custId: custId
        }).then(({ data }) => {
            window.location.href = data.redirectUrl;
        }).catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        })
}

export const validateToken = async (token) => {
    const response = await axios.post(VALIDATE_SESSION_ID, {
        sessionId: token
    })
    return Promise.resolve(response.data);
}