import React from 'react';
import Header from '../../components/HeaderV2/Header';
import HeaderLinks from '../../components/HeaderV2/HeaderLinks';
import { feijoa8Menu } from '../../constants/routes';
import Footer from '../../components/Footer/Footer';
import Body from '../../components/Body';
import { MetaHomePage } from './Seo/MetaHomePage';
import MetaTags from '../../components/Seo/MetaTags';
// import ImageCarousel from './ImageCarousel';
import MainSection from './MainSection'
import ScrollToHashElement from '../../components/Common/ScrollToHashElement';
const HomePage = () => {
  return (
    <>
      <Header
        brand="feijoa8"
        rightLinks={<HeaderLinks links={feijoa8Menu} />}
        fixed
      />
      <MetaTags {...MetaHomePage} />
      <Body>
        {/* <ImageCarousel /> */}
        <ScrollToHashElement>
          <MainSection />
        </ScrollToHashElement>

      </Body>
      <Footer />
    </>
  );
};

export default HomePage;
