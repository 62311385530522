
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Card, Tooltip, CardContent, Chip, Typography, IconButton, CardActions, Menu, ListItemIcon, MenuItem, CardMedia } from '@material-ui/core';
import { Visibility, MoreVert, Delete } from '@material-ui/icons';
import { fetchTags } from '../../utils/projectUtil';
import * as actions from '../../actions/evaluationAction';
import OptionCostAndTimeline from '../Common/OptionCostAndTimeline';
import ImageLightBox from '../Dialog/ImageLightBox';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
    },
    itemTitle: {
        fontSize: '17px',
        wordBreak: 'break-word'
    },
    itemHelpText: {
        color: 'grey',
        fontSize: '12px'
    },
    chipGroup: {
        paddingLeft: theme.spacing(2)
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    cardMedia: {
        cursor: 'pointer'
    },
    loadingImage: {
        width: '10%',
        height: '10%'
    }
}));

const OptionCard = (props) => {
    const classes = useStyles();
    const { option, editOption, deleteOption, projectType, actions, ...rest } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;

    const [optionImage, setOptionImage] = React.useState('');
    const [openImage, setOpenImage] = React.useState(false);
    const [loadingImage, setLoadingImage] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (option.image && option.image !== '') {
            setOptionImage(option.image);
        } else {
            setLoadingImage(true);
            actions.fetchOptionImage({ optionId: option.id })
                .then((data) => {
                    setOptionImage(data);
                }).finally(() => {
                    setLoadingImage(false);
                })
        }
    }, [option.image]);

    return (
        <Card className={classes.card}
            {...rest}>
            {!loadingImage && option.image && option.image !== '' &&
                <CardMedia
                    className={classes.cardMedia}
                    component="img"
                    alt={option.summary}
                    height="220"
                    image={optionImage}
                    title={'Click here to view option image'}
                    onClick={() => setOpenImage(true)}
                />
            }
            {loadingImage &&
                <CardMedia
                    className={classes.loadingImage}
                    component="img"
                    alt={"loading"}
                    image={"/static/svg/loader.svg"}
                />
            }
            {openImage && <ImageLightBox onClose={() => setOpenImage(false)} images={optionImage === '' ? [] : [optionImage]} />}
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <CardContent>
                        <Typography variant="h5"> {option.summary}</Typography>
                        {option.description && <React.Fragment><br />
                            <Typography variant="body1"> {option.description}</Typography>
                        </React.Fragment>
                        }
                    </CardContent>
                </Grid>
                <Grid item xs={2}>
                    <CardActions disableSpacing>
                        <Tooltip title={`view actions`}>
                            <IconButton onClick={handleClick}>
                                <MoreVert />
                            </IconButton>
                        </Tooltip>
                    </CardActions>
                </Grid>
            </Grid>
            {fetchTags(option).length !== 0 && <div className={classes.chipGroup}>
                {fetchTags(option).map((tag, index) => {
                    return (
                        <Chip key={index} id={index} className={classes.chip} label={tag} />
                    )
                })}
            </div>}
            <CardContent color="primary">
                <OptionCostAndTimeline option={option} projectType={projectType} />
            </CardContent>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '25ch',
                    },
                }}
            ><MenuItem onClick={() => {
                handleClose();
                editOption(option);
            }}>
                    <ListItemIcon>
                        <Visibility />
                    </ListItemIcon>
                    <Typography variant="inherit">View option</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    deleteOption(option.id);
                }}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete option</Typography>
                </MenuItem></Menu>
        </Card>
    );
};

OptionCard.propTypes = {
    option: PropTypes.object,
    editOption: PropTypes.func,
    deleteOption: PropTypes.func,
    projectType: PropTypes.string,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(OptionCard);
