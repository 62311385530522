import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Card, CardContent, Chip, Tooltip, Typography, CardActions, IconButton, Collapse, Divider, Grid, CardMedia } from '@material-ui/core';
import { fetchTags } from '../../utils/projectUtil';
import OptionCostAndTimeline from '../Common/OptionCostAndTimeline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OptionSummary from '../Option/OptionSummary';
import SummaryGauge from '../../components/Chart/SummaryGauge';
import { green } from '@material-ui/core/colors';
import * as actions from '../../actions/evaluationAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles((theme) => ({
    chipGroup: {
        padding: theme.spacing(2)
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        borderRadius: theme.spacing(3),
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
            border: `3px solid ${green[600]}`,
            backgroundColor: `${green[50]}`,
            cursor: 'pointer',
        },
    },
    cardContent: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
    },
    cardActions: {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0 ${theme.spacing(0.5)}px`
    },
    cardMedia: {
        cursor: 'pointer'
    },
    loadingImage: {
        width: '10%',
        height: '10%'
    }
}));

const SummaryCard = (props) => {
    const classes = useStyles();
    const { option, projectType, updateSelectedOption, selectedOption, hasGoals, strength, index, actions, ...rest } = props;

    const [expanded, setExpanded] = React.useState(false);
    const [optionImage, setOptionImage] = React.useState('');
    const [loadingImage, setLoadingImage] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleSelectedOption = () => {
        updateSelectedOption(option.id)
    }

    React.useEffect(() => {
        if (option.image && option.image !== '') {
            setOptionImage(option.image);
        } else {
            setLoadingImage(true);
            actions.fetchOptionImage({ optionId: option.id })
                .then((data) => {
                    setOptionImage(data);
                }).finally(() => {
                    setLoadingImage(false);
                })
        }
    }, [option.image]);

    return (
        <React.Fragment>
            <Tooltip title="Click to mark as selected option">
                <Card className={classes.card} {...rest}>
                    <CardContent className={classes.cardContent} onClick={handleSelectedOption}>
                        <Grid container spacing={3}>
                            <Grid item xs={10}>
                                <Typography variant="h5">{option.summary}</Typography>
                                {option.description !== '' && <React.Fragment><br />
                                    <Typography variant="body1"> {option.description}</Typography>
                                </React.Fragment>
                                }
                            </Grid>
                            {option.id === selectedOption &&
                                <Grid item xs={2}>
                                    <img src="/static/svg/dashboard/projects/confirm.svg" alt="Selected option" width="35px" height="35px" />
                                </Grid>
                            }
                        </Grid>
                        {!loadingImage && option.image && option.image !== '' &&
                            <CardMedia
                                className={classes.cardMedia}
                                component="img"
                                alt={option.summary}
                                height="220"
                                image={optionImage}
                                title={option.summary}
                            />
                        }
                        {loadingImage &&
                            <CardMedia
                                className={classes.loadingImage}
                                component="img"
                                alt={"loading"}
                                image={"/static/svg/loader.svg"}
                            />
                        }
                        {fetchTags(option).length !== 0 && <div className={classes.chipGroup}>
                            {fetchTags(option).map((tag, index) => {
                                return (
                                    <Chip key={index} id={index} className={classes.chip} label={tag} />
                                )
                            })}
                        </div>}
                        <OptionCostAndTimeline option={option} projectType={projectType} />
                        <Grid container spacing={2} justify="center" id={`ratingGauge_${index}`}>
                            <Grid item xs={hasGoals ? 6 : 12}>
                                <SummaryGauge id={`feature_chart_${option.id}`} series={strength.featureOptionStrength} label="Features" />
                            </Grid>
                            {hasGoals &&
                                <Grid item xs={6}>
                                    <SummaryGauge id={`goal_chart_${option.id}`} series={strength.goalOptionStrength} label="Goals" />
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing className={classes.cardActions}>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Divider />
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <OptionSummary option={option} projectType={projectType} showTitleAndDescription={false} />
                        </CardContent>
                    </Collapse>
                </Card>
            </Tooltip>
        </React.Fragment >
    );
};

SummaryCard.propTypes = {
    option: PropTypes.object,
    index: PropTypes.number,
    projectType: PropTypes.string,
    updateSelectedOption: PropTypes.func,
    selectedOption: PropTypes.string,
    hasGoals: PropTypes.bool,
    strength: PropTypes.object,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(SummaryCard);