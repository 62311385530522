import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { exportSummaryExcel } from '../../utils/exportEvaluation';

const ExportSummary = ((props) => {
    const { idea, projectType, features, goals, options, selectedOption, featureEvaluators, goalEvaluators, strength } = props;

    const handleExportExcel = () => {
        exportSummaryExcel(projectType, idea, features, goals, options, selectedOption, featureEvaluators, goalEvaluators, strength);
    }

    return (
        <Button
            variant="outlined"
            fullWidth
            size="large"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={handleExportExcel}>
            Export summary to Excel
        </Button>
    );
});

ExportSummary.propTypes = {
    projectType: PropTypes.string.isRequired,
    idea: PropTypes.object.isRequired,
    features: PropTypes.array.isRequired,
    goals: PropTypes.array,
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.string,
    featureEvaluators: PropTypes.array,
    goalEvaluators: PropTypes.array,
    strength: PropTypes.array
};

export default ExportSummary;