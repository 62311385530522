import React from 'react';
import { Grid, TextField, Button, DialogContentText } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/evaluationAction';
import { bindActionCreators } from 'redux';
import idx from 'idx';
import PropTypes from 'prop-types';
import { findCurrentProjectDetails } from '../../../../utils/projectUtil';
import { GOAL_TITLE_SIZE_ALLOWED } from '../../../../constants/evaluation/goals';

function ManageGoalDialog({ data, open, goals, onSave, onCancel, actions }) {
    const defaultGoalTitle = idx(data, _ => _.title) || '';

    const [title, setTitle] = React.useState(defaultGoalTitle);

    const handleSave = () => {
        const id = data ? data.id : '';
        data ? actions.updateGoal({ title, id }) : actions.addGoal({ title });
        onSave && onSave();
        handleClose();
    };


    const handleClose = () => {
        onCancel();
    };

    const isDuplicateGoal = () => {
        return goals.some(goal => goal.title.toUpperCase() === title.toUpperCase()) && title !== defaultGoalTitle;
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        setTitle(defaultGoalTitle);
    }, [open]);

    return (
        <React.Fragment>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <DialogContent dividers={true} ref={descriptionElementRef} tabIndex={-1}>
                    <DialogContentText id="alert-dialog-description">
                        Give a brief description about goal for your idea
                    </DialogContentText>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="goalTitle"
                                name="goalTitle"
                                label="Goal details"
                                variant="outlined"
                                inputProps={{ maxLength: GOAL_TITLE_SIZE_ALLOWED }}
                                placeholder="Enter goal details"
                                multiline
                                rows={3}
                                autoFocus
                                value={title}
                                fullWidth
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" fullWidth>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} fullWidth disabled={title === '' || isDuplicateGoal()} variant="contained" color="secondary">
                        {data ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

ManageGoalDialog.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    goals: PropTypes.array,
    onCancel: PropTypes.func,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        goals: idx(currentProjectData, _ => _.goals) || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageGoalDialog);