
import React from 'react';
import DashboardBody from '../../../../components/Body/DashboardBody';
import DashboardTitle from '../../../../components/Body/DashboardTitle';
import { Container } from '@material-ui/core';
import { MetaDashboardImportProject } from './Seo/MetaDashboardImportProject';
import { admin } from '../../../../constants/routes';
import UploadProjectJson from './UploadProjectJson';

const ImportProject = () => {
    let breadcrumb = [{ title: admin.dashboard.label, link: admin.dashboard.url }];
    return (
        <>
            <DashboardBody metaData={MetaDashboardImportProject}>
                <Container maxWidth="lg">
                    <DashboardTitle title={admin.importProject.label} breadcrumbs={breadcrumb} />
                    <UploadProjectJson />
                </Container>
            </DashboardBody>
        </>
    );
};

export default ImportProject;
