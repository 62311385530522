import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        position: 'sticky',
        top: 0,
        zIndex: 1
    },
    highlight: {
        color: theme.palette.colors.black,
        backgroundColor: theme.palette.colors.feijoa,
    },
    unHighlight: {
        color: theme.palette.colors.black,
        backgroundColor: lighten(theme.palette.colors.feijoa, 0.20),
    },
    title: {
        flex: '1 1 100%',
    },
}));
const EnhancedFeatureDownloadToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, exportCsv } = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    return (
        <React.Fragment>
            <Toolbar className={clsx(classes.root, classes.highlight)}>
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} features selected
                </Typography>

                <div>
                    <Button
                        ref={anchorRef}
                        variant="outlined"
                        fullWidth
                        size="large"
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="primary"
                        startIcon={<GetAppIcon />}
                        onClick={() => setOpen((prevOpen) => !prevOpen)}>Export</Button>
                </div>
            </Toolbar>
            <Popper style={{ zIndex: 10 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={() => { setOpen(false); exportCsv('JIRA') }}>CSV (Features) - JIRA</MenuItem>
                                    <MenuItem onClick={() => { setOpen(false); exportCsv('AzureDevOps') }}>CSV (Features) - Azure DevOps</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment >
    );
};

EnhancedFeatureDownloadToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    exportCsv: PropTypes.func.isRequired
};

export default EnhancedFeatureDownloadToolbar;