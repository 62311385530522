import React from 'react';
import { TextField, } from '@material-ui/core';
import PropTypes from 'prop-types'
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Autocomplete } from '@material-ui/lab';

const LabelsDropDown = (props) => {

    const { labels, value, onChange, dropDownLabel, dropDownPlaceholder, dropDownVariant, noOptionsText, ...rest } = props;

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: option => option,
    });
    return (
        <Autocomplete
            {...rest}
            multiple
            id="tags-outlined"
            filterOptions={filterOptions}
            value={value}
            noOptionsText={noOptionsText ? noOptionsText : "No labels, create new by clicking manage labels"}
            options={labels.map((label) => label.title)}
            filterSelectedOptions
            onChange={(event, value) => {
                onChange(value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={dropDownVariant ? dropDownVariant : "outlined"}
                    label={dropDownLabel}
                    placeholder={dropDownPlaceholder}
                />
            )}
        />
    );
}

LabelsDropDown.propTypes = {
    labels: PropTypes.array.isRequired,
    value: PropTypes.array,
    onChange: PropTypes.func,
    noOptionsText: PropTypes.string,
    dropDownVariant: PropTypes.string,
    dropDownLabel: PropTypes.string,
    dropDownPlaceholder: PropTypes.string
};

export default LabelsDropDown;