export const MetaFeedbackPage = {
  title: 'Feedback - Feijoa8',
  pageTitle: 'Feedback',
  meta: [
    {
      name: 'title',
      content: 'Feedback - Feijoa8',
    },
    {
      name: 'description',
      content: 'We will love to hear your feedback about our website and product',
    },
    {
      name: 'keywords',
      content: 'Feijoa8,Feedback',
    },
    {
      name: 'robots',
      content: 'index, follow',
    }
  ],
};
