import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types'
import { Delete, Edit } from '@material-ui/icons';

const EvaluatorDropDown = (props) => {

    const { open, onClose, selectedEvaluator, onOpen, onChange, evaluators, onEdit, onDelete, className } = props;
    const evaluatorSelected = evaluators.find((evaluator) => evaluator.id === selectedEvaluator.id);
    let evaluatorName = '';
    if (evaluatorSelected !== undefined) {
        evaluatorName = evaluatorSelected.name;
    }
    return (
        <React.Fragment>
            <FormControl variant="outlined" className={className}>
                <InputLabel id="demo-controlled-open-select-label">Evaluators</InputLabel>
                <Select labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    name="evaluatorSelector" open={open}
                    value={evaluatorName}
                    label="Evaluators"
                    onClose={onClose}
                    onOpen={onOpen}
                    onChange={(e) => onChange(e)}>
                    {evaluators.map((evaluator, index) => {
                        return (
                            <MenuItem id={`evaluator_${index}`} key={`evaluator_${index}`}
                                value={evaluator.name} style={{ whiteSpace: 'normal' }}>
                                {evaluator.name}
                            </MenuItem>
                        )
                    })}
                </Select>
                <FormHelperText>View other evaluator ratings</FormHelperText>
            </FormControl>
            <Tooltip title="Edit evaluator">
                <IconButton onClick={onEdit}>
                    <Edit />
                </IconButton>
            </Tooltip>
            {evaluators.length > 1 &&
                <Tooltip title="Delete evaluator">
                    <IconButton onClick={onDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            }
        </React.Fragment>
    );
}

EvaluatorDropDown.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    selectedEvaluator: PropTypes.any,
    onDelete: PropTypes.func.isRequired,
    evaluators: PropTypes.array.isRequired,
    className: PropTypes.string
};

export default EvaluatorDropDown;