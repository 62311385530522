
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from "prop-types";
import { fetchDaysBetween, fetchResourceCost, formatCost } from '../../utils/common';
import { FIXED_COST_TYPE, RANGE_COST_TYPE, RESOURCE_COST_TYPE } from '../../constants/evaluation/options';
import { EVALUATION_AGILE, EVALUATION_GENERIC } from '../../constants/projectTypes';

const OptionSummary = (props) => {
    const { option, projectType, showTitleAndDescription } = props;

    return (
        <Grid container spacing={3}>
            {showTitleAndDescription && <React.Fragment><Grid item xs={12} md={6} lg={4}>
                <Typography color="textSecondary">{"Summary"}</Typography>
                <Typography>{option.summary}</Typography>
            </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Typography color="textSecondary">{"Description"}</Typography>
                    <Typography>{option.description ? option.description : '-'}</Typography>
                </Grid>
            </React.Fragment>}
            <Grid item xs={12} md={6} lg={4}>
                <Typography color="textSecondary">{"Cost"}</Typography>
                <Typography>
                    {option.cost.type === FIXED_COST_TYPE && '$ ' + formatCost(option.cost.value.fixedCost)}
                    {option.cost.type === RANGE_COST_TYPE && '$ ' + formatCost(option.cost.value.minPrice) + ' - $ ' + formatCost(option.cost.value.maxPrice)}
                    {option.cost.type === RESOURCE_COST_TYPE && '$ ' + fetchResourceCost(option.cost.value.resourceCost, fetchDaysBetween(option.startDate, option.endDate))}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Typography color="textSecondary">{"Timeframe"}</Typography>
                <Typography>
                    {projectType !== EVALUATION_AGILE && fetchDaysBetween(option.startDate, option.endDate) + ' days'}
                    {projectType === EVALUATION_AGILE && option.duration + ' sprints'}
                </Typography>
            </Grid>
            {projectType !== EVALUATION_GENERIC &&
                <Grid item xs={12} md={6} lg={4}>
                    <Typography color="textSecondary">{"Technical debt"}</Typography>
                    <Typography>{option.technicalDebt}</Typography>
                </Grid>}
            {projectType !== EVALUATION_GENERIC && <Grid item xs={12} md={6} lg={4}>
                <Typography color="textSecondary">{"Are resources available"}</Typography>
                <Typography>{option.resourcesAvailable ? 'Yes' : 'No'}</Typography>
            </Grid>
            }
            {projectType === EVALUATION_AGILE &&
                <Grid item xs={12} md={6} lg={4}>
                    <Typography color="textSecondary">{"Is squad available"}</Typography>
                    <Typography>{option.squadAvailable ? 'Yes' : 'No'}</Typography>
                </Grid>
            }
            <Grid item xs={12} md={6} lg={4}>
                <Typography color="textSecondary">{"Key benefits"}</Typography>
                {option.benefits.map((benefit, index) => {
                    return (
                        <Typography key={index} id={index}>{`${index + 1}. ${benefit}`}</Typography>
                    )
                })}
                {option.benefits.length === 0 &&
                    <Typography>{'-'}</Typography>
                }
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Typography color="textSecondary">{"Major constraints"}</Typography>
                {option.constraints.map((constraint, index) => {
                    return (
                        <Typography key={index} id={index}>{`${index + 1}. ${constraint}`}</Typography>
                    )
                })}
                {option.constraints.length === 0 &&
                    <Typography>{'-'}</Typography>
                }
            </Grid>
        </Grid>
    );
};

OptionSummary.propTypes = {
    option: PropTypes.object,
    projectType: PropTypes.string,
    showTitleAndDescription: PropTypes.bool
};

OptionSummary.defaultProps = {
    showTitleAndDescription: true
};

export default OptionSummary;
