import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Button, Popper, Paper, ClickAwayListener, MenuList, Grow } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { exportFeaturesExcel } from '../../utils/exportEvaluation';

const ExportFeatures = ((props) => {
    const { type, idea, features, filteredFeatures, evaluators, selectedFeaturesId, isFileredApplied } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleExportExcelAll = (e) => {
        exportFeaturesExcel(idea, features, evaluators, type);
        handleClose(e);
    }

    const handleExportExcelFiltered = (e) => {
        exportFeaturesExcel(idea, filteredFeatures, evaluators, type);
        handleClose(e);
    }

    const handleExportExcelSelected = (e) => {
        const selectedFeatures = selectedFeaturesId.map((featureId) => {
            return features.find((feature) => feature.id === featureId);
        });
        exportFeaturesExcel(idea, selectedFeatures, evaluators, type);
        handleClose(e);
    }

    return (
        <div>
            <Button
                ref={anchorRef}
                variant="outlined"
                fullWidth
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="primary"
                size="large"
                startIcon={<GetAppIcon />}
                onClick={handleToggle}
            >
                Export {type}
            </Button>
            <Popper style={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleExportExcelAll}>Excel (Export all)</MenuItem>
                                    {isFileredApplied && filteredFeatures.length !== 0 && <MenuItem onClick={handleExportExcelFiltered}>Excel (Export filtered)</MenuItem>}
                                    {selectedFeaturesId.length !== 0 && <MenuItem onClick={handleExportExcelSelected}>Excel (Export selected)</MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
});

ExportFeatures.propTypes = {
    type: PropTypes.string.isRequired,
    idea: PropTypes.object.isRequired,
    features: PropTypes.array.isRequired,
    filteredFeatures: PropTypes.array.isRequired,
    selectedFeaturesId: PropTypes.array,
    evaluators: PropTypes.array,
    isFileredApplied: PropTypes.bool.isRequired
};

export default ExportFeatures;