/*eslint-disable*/
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, Hidden } from '@material-ui/core';
import { Link } from '@material-ui/core';
// core components
import CustomDropdown from './CustomDropdown/CustomDropdown.js';
import SignInSignOutButton from '../Identity/SignInSignOutButton.js';

import styles from './headerLinksStyle.js';
const useStyles = makeStyles(styles);

export default function HeaderLinks({ links }) {
  const classes = useStyles();

  const generateNavLink = (url, label) => (
    <NavLink exact activeClassName={classes.activeNavLink} to={url} className={classes.navLink}
      isActive={(match, location) => {
        if (!match) {
          return false;
        }

        if (location.hash) {
          return url.includes(location.hash);
        }
        return url === match.url;
      }}>
      {label}
    </NavLink>
  );

  const genrateSubMenuLink = (url, label, subMenu) => {
    const dropdownItems = subMenu.map(({ label, url }) => {
      return <Fragment key={label}>{generateNavLink(url, label)}</Fragment>;
    });
    return (
      <CustomDropdown
        noLiPadding
        buttonText={label}
        buttonProps={{
          className: classes.navLink,
          color: 'transparent',
        }}
        dropdownList={dropdownItems}
      />
    );
  };

  const generateMenuItemList = (menuList) => {
    return (
      <React.Fragment>
        {menuList.map(({ label, url, subMenu = [] }) => {
          return (
            <ListItem className={classes.listItem} key={label}>
              {subMenu.length > 0
                ? genrateSubMenuLink(url, label, subMenu)
                : generateNavLink(url, label)}
            </ListItem>
          )
        })}
        <ListItem className={classes.listItem} key={'account'}><span className={classes.navLink}><SignInSignOutButton /></span></ListItem>
        {/* <Hidden smDown>
          <ListItem className={classes.listItem} >
            <ListItemIcon>
              <SignInSignOutButton />
            </ListItemIcon>
          </ListItem >
        </Hidden> */}
      </React.Fragment >
    )
  };

  return <List className={classes.list}>{generateMenuItemList(links)}</List>;
}