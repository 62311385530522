import axios from 'axios';
import * as types from '../constants/actionTypes';
import {
    ADD_SELECTED_OPTION_URL, ASSIGN_FEATURE_ITERATION_URL, ASSIGN_FEATURE_LABEL_URL, ASSIGN_GOAL_LABEL_URL, CREATE_FEATURE_EVALUATION_URL, CREATE_FEATURE_LABEL_URL,
    CREATE_FEATURE_URL, CREATE_GOAL_EVALUATION_URL, CREATE_GOAL_LABEL_URL, CREATE_GOAL_URL, DELETE_FEATURE_EVALUATION_URL, DELETE_FEATURE_LABEL_URL, DELETE_FEATURE_URL,
    DELETE_GOAL_EVALUATION_URL, CREATE_OPTION_URL, DELETE_GOAL_LABEL_URL,
    DELETE_GOAL_URL, DELETE_OPTION_URL, EVALUATOR_FEATURE_RATING_URL, EVALUATOR_GOAL_RATING_URL, UPDATE_FEATURE_EVALUATION_URL, UPDATE_FEATURE_URL,
    UPDATE_GOAL_EVALUATION_URL, UPDATE_GOAL_URL, UPDATE_IDEA_URL, UPDATE_PROJECT_STATUS_URL, UPDATE_OPTION_URL, OPTION_FEATURE_RATING_URL, OPTION_GOAL_RATING_URL, UPLOAD_OPTION_IMAGE_URL, UPDATE_OPTION_IMAGE_URL, DELETE_OPTION_IMAGE_URL, FETCH_OPTION_IMAGE_URL
} from '../constants/apiEndpoints';
import { ADD_IMAGE_OPERATION_TYPE, UPDATE_IMAGE_OPERATION_TYPE } from '../constants/evaluation/options';
import { ERROR_SEVERITY, SUCCESS_SEVERITY, WARNING_SEVERITY } from '../constants/static';
import { setLoading, setSeverityStatus } from './planActions';
import { translateOptionToRequest } from './util/projectUtility';
import { fetchProjectIdFromState } from './utility';

export const incrementStep = () => {
    return {
        type: types.INCREMENT_STEP
    };
}

export const decrementStep = () => {
    return {
        type: types.DECREMENT_STEP
    };
}

export const skipStep = (isSkipStepped) => {
    return {
        type: types.SKIP_STEP,
        payload: isSkipStepped
    };
}

export function setStep(step) {
    return {
        type: types.SET_STEP,
        step,
    };
}

const createIdeaInRedux = (payload) => {
    return {
        type: types.CREATE_IDEA,
        payload,
    };
}

export const createIdea = (payload) => (dispatch, getState) => {
    dispatch(setLoading(true, 'Saving your idea details'));
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .put(UPDATE_IDEA_URL, payload, {
            headers: headers
        })
        .then(() => {
            dispatch(createIdeaInRedux(payload))
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const addFeatureInRedux = (payload) => {
    payload.created = Date.now();
    return {
        type: types.ADD_FEATURE,
        payload,
    };
}

export const addFeature = (payload) => (dispatch, getState) => {
    const { title } = payload;
    dispatch(setLoading(true, 'Adding feature'));
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .post(CREATE_FEATURE_URL, {
            title: title
        }, {
            headers: headers
        })
        .then(({ data }) => {
            payload.id = data.id;
            dispatch(addFeatureInRedux(payload));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Successfully added feature'));
        })
        .catch((err) => {
            const errorCode = err.response.status || 500;
            if (errorCode === 402) {
                dispatch(setSeverityStatus(WARNING_SEVERITY, 'Sorry, you have run out of capacity. Please try consolidating your items'));
            } else {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            }
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateFeatureInRedux = (payload) => {
    return {
        type: types.UPDATE_FEATURE,
        payload,
    };
}

export const updateFeature = (payload) => (dispatch, getState) => {
    const { title, id } = payload;
    dispatch(setLoading(true, 'Updating feature'));
    const headers = { projectId: fetchProjectIdFromState(getState), featureId: id };
    return axios
        .put(UPDATE_FEATURE_URL, {
            title: title
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(updateFeatureInRedux(payload));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Successfully updated feature'));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const removeFeatureInRedux = (payload) => {
    return {
        type: types.REMOVE_FEATURE,
        payload,
    };
}

export const removeFeature = (featureId) => (dispatch, getState) => {
    dispatch(setLoading(true, 'Deleting feature'));
    const headers = { projectId: fetchProjectIdFromState(getState), featureId: featureId };
    return axios
        .delete(DELETE_FEATURE_URL, {
            headers: headers
        })
        .then(() => {
            dispatch(removeFeatureInRedux(featureId));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Successfully deleted feature'));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const assignLabelToFeatureInRedux = (payload) => {
    return {
        type: types.ASSIGN_LABEL_TO_FEATURE,
        payload,
    };
}

export const assignLabelToFeature = (payload) => (dispatch, getState) => {
    const { featureId, labelIds } = payload;
    dispatch(setLoading(true, 'Assigning label'));
    const headers = { projectId: fetchProjectIdFromState(getState), featureId: featureId };
    return axios
        .post(ASSIGN_FEATURE_LABEL_URL, {
            labels: labelIds
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(assignLabelToFeatureInRedux(payload));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

const addFeatureEvaluatorInRedux = (payload) => {
    return {
        type: types.ADD_FEATURE_EVALUATOR,
        payload,
    };
}

export const addFeatureEvaluator = (payload) => (dispatch, getState) => {
    const { name, group, influence } = payload;
    dispatch(setLoading(true, `Adding ${name} as feature evaluator`));
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .post(CREATE_FEATURE_EVALUATION_URL, {
            name: name,
            group: group,
            influence: influence
        }, {
            headers: headers
        })
        .then(({ data }) => {
            dispatch(addFeatureEvaluatorInRedux({ id: data.id, name, group, influence }));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, `Added ${name} as evaluator`));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateFeatureEvaluatorInRedux = (payload) => {
    return {
        type: types.UPDATE_FEATURE_EVALUATOR,
        payload,
    };
}

export const updateFeatureEvaluator = (payload) => (dispatch, getState) => {
    const { name, group, influence, id } = payload;
    dispatch(setLoading(true, `Updating evaluator ${name}`));
    const headers = { projectId: fetchProjectIdFromState(getState), evaluatorId: id };
    return axios
        .put(UPDATE_FEATURE_EVALUATION_URL, {
            name: name,
            group: group,
            influence: influence
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(updateFeatureEvaluatorInRedux({ name, group, influence, id }));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, `Successfully updated evaluator ${name}`));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const removeFeatureEvaluatorInRedux = (payload) => {
    return {
        type: types.REMOVE_FEATURE_EVALUATOR,
        payload,
    };
}

export const removeFeatureEvaluator = (payload) => (dispatch, getState) => {
    const { id, name } = payload;
    dispatch(setLoading(true, `Deleting evaluator ${name}`));
    const headers = { projectId: fetchProjectIdFromState(getState), evaluatorId: id };
    return axios
        .delete(DELETE_FEATURE_EVALUATION_URL, {
            headers: headers
        })
        .then(() => {
            dispatch(removeFeatureEvaluatorInRedux(payload));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, `Successfully deleted evaluator ${name}`));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const addFeatureLabelInRedux = (payload) => {
    return {
        type: types.ADD_FEATURE_LABEL,
        payload,
    };
}

export const addFeatureLabel = (payload) => (dispatch, getState) => {
    const { title } = payload;
    dispatch(setLoading(true, `Adding ${title} as label`));
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .post(CREATE_FEATURE_LABEL_URL, {
            title: title
        }, {
            headers: headers
        })
        .then(({ data }) => {
            dispatch(addFeatureLabelInRedux({ id: data.id, title }));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, `Added ${title} as label`));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const removeFeatureLabelInRedux = (payload) => {
    return {
        type: types.REMOVE_FEATURE_LABEL,
        payload,
    };
}

export const removeFeatureLabel = (payload) => (dispatch, getState) => {
    const { id } = payload;
    dispatch(setLoading(true, 'Deleting label'));
    const headers = { projectId: fetchProjectIdFromState(getState), labelId: id };
    return axios
        .delete(DELETE_FEATURE_LABEL_URL, {
            headers: headers
        })
        .then(() => {
            dispatch(removeFeatureLabelInRedux(id));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Successfully deleted label'));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateFeatureRatingByEvaluatorInRedux = (payload) => {
    return {
        type: types.UPDATE_FEATURE_RATING_BY_EVALUATOR,
        payload,
    };
}

export const updateFeatureRatingByEvaluator = (payload) => (dispatch, getState) => {
    const { featureId, rating, evaluatorId } = payload;
    dispatch(setLoading(true, 'Updating rating'));
    const headers = { projectId: fetchProjectIdFromState(getState), featureId: featureId };
    return axios
        .post(EVALUATOR_FEATURE_RATING_URL, {
            id: evaluatorId,
            rating: rating
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(updateFeatureRatingByEvaluatorInRedux(payload));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const markFeatureAsDeliveredInRedux = (payload) => {
    return {
        type: types.MARK_FEATURE_AS_DELIVERED,
        payload,
    };
}

export const markFeatureAsDelivered = (payload) => (dispatch, getState) => {
    const { featuresToUpdate, version } = payload;
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .post(ASSIGN_FEATURE_ITERATION_URL, {
            iteration: version,
            features: featuresToUpdate
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(markFeatureAsDeliveredInRedux(payload));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const addGoalInRedux = (payload) => {
    payload.created = Date.now();
    return {
        type: types.ADD_GOAL,
        payload,
    };
}

export const addGoal = (payload) => (dispatch, getState) => {
    const { title } = payload;
    dispatch(setLoading(true, 'Adding goal'));
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .post(CREATE_GOAL_URL, {
            title: title
        }, {
            headers: headers
        })
        .then(({ data }) => {
            payload.id = data.id;
            dispatch(addGoalInRedux(payload));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Successfully added goal'));
        })
        .catch((err) => {
            const errorCode = err.response.status || 500;
            if (errorCode === 402) {
                dispatch(setSeverityStatus(WARNING_SEVERITY, 'Sorry, you have run out of capacity. Please try consolidating your items'));
            } else {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            }
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateGoalInRedux = (payload) => {
    return {
        type: types.UPDATE_GOAL,
        payload,
    };
}

export const updateGoal = (payload) => (dispatch, getState) => {
    const { title, id } = payload;
    dispatch(setLoading(true, 'Updating goal'));
    const headers = { projectId: fetchProjectIdFromState(getState), goalId: id };
    return axios
        .put(UPDATE_GOAL_URL, {
            title: title
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(updateGoalInRedux(payload));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Successfully updated goal'));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const removeGoalInRedux = (payload) => {
    return {
        type: types.REMOVE_GOAL,
        payload,
    };
}

export const removeGoal = (goalId) => (dispatch, getState) => {
    dispatch(setLoading(true, 'Deleting goal'));
    const headers = { projectId: fetchProjectIdFromState(getState), goalId: goalId };
    return axios
        .delete(DELETE_GOAL_URL, {
            headers: headers
        })
        .then(() => {
            dispatch(removeGoalInRedux(goalId));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Successfully deleted goal'));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const assignLabelToGoalInRedux = (payload) => {
    return {
        type: types.ASSIGN_LABEL_TO_GOAL,
        payload,
    };
}

export const assignLabelToGoal = (payload) => (dispatch, getState) => {
    const { goalId, labelIds } = payload;
    dispatch(setLoading(true, 'Assigning label'));
    const headers = { projectId: fetchProjectIdFromState(getState), goalId: goalId };
    return axios
        .post(ASSIGN_GOAL_LABEL_URL, {
            labels: labelIds
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(assignLabelToGoalInRedux(payload));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const addGoalEvaluatorInRedux = (payload) => {
    return {
        type: types.ADD_GOAL_EVALUATOR,
        payload,
    };
}

export const addGoalEvaluator = (payload) => (dispatch, getState) => {
    const { name, group, influence } = payload;
    dispatch(setLoading(true, `Adding ${name} as goal evaluator`));
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .post(CREATE_GOAL_EVALUATION_URL, {
            name: name,
            group: group,
            influence: influence
        }, {
            headers: headers
        })
        .then(({ data }) => {
            dispatch(addGoalEvaluatorInRedux({ id: data.id, name, group, influence }));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, `Added ${name} as evaluator`));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateGoalEvaluatorInRedux = (payload) => {
    return {
        type: types.UPDATE_GOAL_EVALUATOR,
        payload,
    };
}

export const updateGoalEvaluator = (payload) => (dispatch, getState) => {
    const { name, group, influence, id } = payload;
    dispatch(setLoading(true, `Updating evaluator ${name}`));
    const headers = { projectId: fetchProjectIdFromState(getState), evaluatorId: id };
    return axios
        .put(UPDATE_GOAL_EVALUATION_URL, {
            name: name,
            group: group,
            influence: influence
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(updateGoalEvaluatorInRedux({ name, group, influence, id }));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, `Successfully updated evaluator ${name}`));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const removeGoalEvaluatorInRedux = (payload) => {
    return {
        type: types.REMOVE_GOAL_EVALUATOR,
        payload,
    };
}

export const removeGoalEvaluator = (payload) => (dispatch, getState) => {
    const { id, name } = payload;
    dispatch(setLoading(true, `Deleting evaluator ${name}`));
    const headers = { projectId: fetchProjectIdFromState(getState), evaluatorId: id };
    return axios
        .delete(DELETE_GOAL_EVALUATION_URL, {
            headers: headers
        })
        .then(() => {
            dispatch(removeGoalEvaluatorInRedux(payload));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, `Successfully deleted evaluator ${name}`));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const addGoalLabelInRedux = (payload) => {
    return {
        type: types.ADD_GOAL_LABEL,
        payload,
    };
}

export const addGoalLabel = (payload) => (dispatch, getState) => {
    const { title } = payload;
    dispatch(setLoading(true, `Adding ${title} as label`));
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .post(CREATE_GOAL_LABEL_URL, {
            title: title
        }, {
            headers: headers
        })
        .then(({ data }) => {
            dispatch(addGoalLabelInRedux({ id: data.id, title }));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, `Added ${title} as label`));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const removeGoalLabelInRedux = (payload) => {
    return {
        type: types.REMOVE_GOAL_LABEL,
        payload,
    };
}

export const removeGoalLabel = (payload) => (dispatch, getState) => {
    const { id } = payload;
    dispatch(setLoading(true, 'Deleting label'));
    const headers = { projectId: fetchProjectIdFromState(getState), labelId: id };
    return axios
        .delete(DELETE_GOAL_LABEL_URL, {
            headers: headers
        })
        .then(() => {
            dispatch(removeGoalLabelInRedux(id));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Successfully deleted label'));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateGoalRatingByEvaluatorInRedux = (payload) => {
    return {
        type: types.UPDATE_GOAL_RATING_BY_EVALUATOR,
        payload,
    };
}

export const updateGoalRatingByEvaluator = (payload) => (dispatch, getState) => {
    const { goalId, rating, evaluatorId } = payload;
    dispatch(setLoading(true, 'Updating rating'));
    const headers = { projectId: fetchProjectIdFromState(getState), goalId: goalId };
    return axios
        .post(EVALUATOR_GOAL_RATING_URL, {
            id: evaluatorId,
            rating: rating
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(updateGoalRatingByEvaluatorInRedux(payload));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const addOptionInRedux = (payload) => {
    return {
        type: types.ADD_OPTION,
        payload,
    }
}

export const addOption = (payload) => (dispatch, getState) => {
    dispatch(setLoading(true, 'Creating option'));
    const projectId = fetchProjectIdFromState(getState);
    const headers = { projectId: projectId };
    const { optionImage, ...optionData } = payload;
    return axios
        .post(CREATE_OPTION_URL, translateOptionToRequest(payload), {
            headers: headers
        })
        .then(({ data }) => {
            optionData.id = data.id;
            dispatch(addOptionInRedux(optionData));
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Option created successfully'));
            dispatch(addOptionImage({ projectId: projectId, optionId: optionData.id, image: optionImage }));
        })
        .catch((err) => {
            const errorCode = err.response.status || 500;
            if (errorCode === 402) {
                dispatch(setSeverityStatus(WARNING_SEVERITY, 'Sorry, you have run out of capacity. Please try consolidating your items'));
            } else {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            }
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateOptionImageInRedux = (payload) => {
    return {
        type: types.UPDATE_OPTION_IMAGE,
        payload,
    }
}

export const addOptionImage = (payload) => (dispatch) => {
    if (payload && payload.image && payload.image.file) {
        dispatch(setLoading(true, 'Uploading option image'));
        const headers = { projectId: payload.projectId, optionId: payload.optionId };
        const formData = new FormData();
        formData.append('file', payload.image.file);
        return axios
            .post(UPLOAD_OPTION_IMAGE_URL, formData, {
                headers: headers
            })
            .then(() => {
                dispatch(updateOptionImageInRedux({ optionId: payload.optionId, image: payload.image.data }));
            })
            .catch((err) => {
                const errorCode = err.response.status || 500;
                if (errorCode === 400) {
                    dispatch(setSeverityStatus(WARNING_SEVERITY, 'File not supported'));
                } else {
                    dispatch(setSeverityStatus(ERROR_SEVERITY, 'We are experiencing some error while uploading option image, please try again later'));
                }
            })
            .finally(() => {
                dispatch(setLoading(false, ''));
            });
    }
}

export const updateOptionImage = (payload) => (dispatch) => {
    dispatch(setLoading(true, 'Updating option image'));
    const headers = { projectId: payload.projectId, optionId: payload.optionId };
    const formData = new FormData();
    formData.append('file', payload.image.file);
    return axios
        .put(UPDATE_OPTION_IMAGE_URL, formData, {
            headers: headers
        })
        .then(() => {
            dispatch(updateOptionImageInRedux({ optionId: payload.optionId, image: payload.image.data }));
        })
        .catch((err) => {
            const errorCode = err.response.status || 500;
            if (errorCode === 400) {
                dispatch(setSeverityStatus(WARNING_SEVERITY, 'File not supported'));
            } else {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'We are experiencing some error while uploading option image, please try again later'));
            }
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const deleteOptionImage = (payload) => (dispatch) => {
    dispatch(setLoading(true, 'Deleting option image'));
    const headers = { projectId: payload.projectId, optionId: payload.optionId };
    return axios
        .delete(DELETE_OPTION_IMAGE_URL, {
            headers: headers
        })
        .then(() => {
            dispatch(updateOptionImageInRedux({ optionId: payload.optionId, image: '' }));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'We are experiencing some error while deleting option image, please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const fetchOptionImage = (payload) => (dispatch, getState) => {
    const headers = { projectId: fetchProjectIdFromState(getState), optionId: payload.optionId };
    return axios
        .get(FETCH_OPTION_IMAGE_URL, {
            headers: headers
        })
        .then(({ data }) => {
            dispatch(updateOptionImageInRedux({ optionId: payload.optionId, image: data.encodedImage }));
            return data.encodedImage;
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'We are experiencing some error while fetching option image, please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const removeOptionInRedux = (payload) => {
    return {
        type: types.REMOVE_OPTION,
        payload,
    }
}

export const removeOption = (payload) => (dispatch, getState) => {
    const { id } = payload;
    dispatch(setLoading(true, 'Deleting option'));
    const headers = { projectId: fetchProjectIdFromState(getState) };
    return axios
        .post(DELETE_OPTION_URL,
            [id]
            , {
                headers: headers
            })
        .then(() => {
            dispatch(removeOptionInRedux(payload));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateOptionInRedux = (payload) => {
    return {
        type: types.UPDATE_OPTION,
        payload,
    }
}

export const updateOption = (payload) => (dispatch, getState) => {
    const { id } = payload;
    const projectId = fetchProjectIdFromState(getState);
    dispatch(setLoading(true, 'Updating option'));
    const headers = { projectId: projectId, optionId: id };
    const { optionImage, ...optionData } = payload;
    return axios
        .put(UPDATE_OPTION_URL, translateOptionToRequest(payload), {
            headers: headers
        })
        .then(() => {
            dispatch(updateOptionInRedux(optionData));
            const imageOperation = payload.optionImage.operation;
            if (imageOperation) {
                if (imageOperation === ADD_IMAGE_OPERATION_TYPE) {
                    dispatch(addOptionImage({ projectId: projectId, optionId: id, image: optionImage }));
                } else if (imageOperation === UPDATE_IMAGE_OPERATION_TYPE) {
                    dispatch(updateOptionImage({ projectId: projectId, optionId: id, image: optionImage }));
                } else {
                    dispatch(deleteOptionImage({ projectId: projectId, optionId: id, image: {} }));
                }
            }
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateFeatureRatingByOptionInRedux = (payload) => {
    return {
        type: types.UPDATE_FEATURE_RATING_BY_OPTION,
        payload
    }
}

export const updateFeatureRatingByOption = (payload) => (dispatch, getState) => {
    const { optionId, featureId, rating } = payload;
    dispatch(setLoading(true, 'Updating rating'));
    const headers = { projectId: fetchProjectIdFromState(getState), featureId: featureId };
    return axios
        .post(OPTION_FEATURE_RATING_URL, {
            id: optionId,
            rating: rating
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(updateFeatureRatingByOptionInRedux(payload));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateGoalRatingByOptionInRedux = (payload) => {
    return {
        type: types.UPDATE_GOAL_RATING_BY_OPTION,
        payload
    }
}

export const updateGoalRatingByOption = (payload) => (dispatch, getState) => {
    const { optionId, goalId, rating } = payload;
    dispatch(setLoading(true, 'Updating rating'));
    const headers = { projectId: fetchProjectIdFromState(getState), goalId: goalId };
    return axios
        .post(OPTION_GOAL_RATING_URL, {
            id: optionId,
            rating: rating
        }, {
            headers: headers
        })
        .then(() => {
            dispatch(updateGoalRatingByOptionInRedux(payload));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateSelectedOptionInRedux = (payload) => {
    return {
        type: types.OPTION_SELECTED_FOR_PROJECT,
        payload
    }
}

export const updateSelectedOption = (optionId) => (dispatch, getState) => {
    dispatch(setLoading(true, 'Saving your selection'));
    const headers = { projectId: fetchProjectIdFromState(getState), optionId: optionId };
    return axios
        .put(ADD_SELECTED_OPTION_URL, {}, {
            headers: headers
        })
        .then(() => {
            dispatch(updateSelectedOptionInRedux(optionId));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const updateEvaluationStatusInRedux = (payload) => {
    return {
        type: types.EVALUATION_STATUS_FOR_PROJECT,
        payload
    }
}

export const updateEvaluationStatus = (status) => (dispatch, getState) => {
    dispatch(setLoading(true, 'Updating project status'));
    const headers = { projectId: fetchProjectIdFromState(getState), status: status };
    return axios
        .put(UPDATE_PROJECT_STATUS_URL, {}, {
            headers: headers
        })
        .then(() => {
            dispatch(updateEvaluationStatusInRedux(status));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}