import React from 'react';
import { CardHeader, Card, Divider, CardActions, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { PLAN_PRO, PRO_PLAN_PRICES } from '../../../constants/plan';
import BuyPlanCard from '../../../components/Card/BuyPlanCard';
import AlertDowngradePlanDialog from '../../../components/Dialog/AlertDowngradePlanDialog';
import * as actions from '../../../actions/planActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUserAllowedToUpgradePlan } from '../../../utils/planUtil';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: theme.spacing(2)
    },
}));

const UpgradePlan = (props) => {
    const { planDetails, fetchUserPlan, actions } = props;
    const classes = useStyles();

    const [openAlert, setOpenAlert] = React.useState(false);
    const [newProjectsAllowed, setNewProjectsAllowed] = React.useState(0);

    const plans = PRO_PLAN_PRICES.filter(p => p.project !== planDetails.projectsAllowed);

    const updatePlan = (projectsQuantity) => {
        if (isUserAllowedToUpgradePlan(planDetails)) {
            const response = actions.updatePlan(PLAN_PRO, projectsQuantity);
            response.then(data => {
                if (data === 'PRE_CONDITION_FAILED') {
                    setNewProjectsAllowed(projectsQuantity);
                    setOpenAlert(true);
                } else {
                    fetchUserPlan();
                }
            })
        } else {
            actions.infoMessage('You don\'t an active plan, please buy plan');
        }
    }

    return (
        <React.Fragment>
            <AlertDowngradePlanDialog
                newProjectsAllowed={newProjectsAllowed} open={openAlert}
                onCancel={() => { setOpenAlert(false) }} />
            {isUserAllowedToUpgradePlan(planDetails) ?
                <Card className={classes.card}>
                    <CardHeader title="Upgrade plan" />
                    <Divider />
                    <CardActions>
                        <Grid container spacing={3} justify="space-evenly">
                            {plans.map((plan, index) => {
                                return (
                                    <Grid item key={`grid_${index}`} id={`grid_${index}`}>
                                        <BuyPlanCard isUpgradePlan={true} upgradePlan={updatePlan} key={index} id={index} planType={PLAN_PRO} numberOfProjects={plan.project} planPrice={plan.price} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </CardActions>
                </Card> :
                <Card className={classes.card}>
                    <CardHeader title="Buy plan" />
                    <Divider />
                    <CardActions>
                        <Grid container spacing={3}>
                            {PRO_PLAN_PRICES.map((plan, index) => {
                                return (
                                    <Grid item xs={6} sm={3} key={`grid_${index}`} id={`grid_${index}`}>
                                        <BuyPlanCard isUpgradePlan={false} key={index} id={index} planType={PLAN_PRO} numberOfProjects={plan.project} planPrice={plan.price} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </CardActions>
                </Card>
            }
        </React.Fragment>
    );
};

UpgradePlan.propTypes = {
    planDetails: PropTypes.object,
    fetchUserPlan: PropTypes.func,
    actions: PropTypes.object
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(UpgradePlan);