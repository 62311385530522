import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Grid, Card, Collapse, Divider, Tooltip, FormControl, FormHelperText, CardContent, Tabs, Tab, Typography, Checkbox, IconButton, CardActions, Chip } from '@material-ui/core';
import { Edit, ExpandMore } from '@material-ui/icons';
import RatingSummary from '../DisplayText/RatingSummary';
import clsx from 'clsx';
import { Rating } from '@material-ui/lab';
import LabelsDropDown from '../Dropdown/LabelsDropDown';
import { fetchLabelIdLabelTitle, fetchLabelTitleLabelId, calculateAverageRating, sumAndGroup, calculateGroupAverage, isFeatureDelivered } from '../../utils/projectUtil';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import TabPanel from '../Tabs/TabPanel';
import { LABELS_ASSIGNMENT_ALLOWED } from '../../constants/evaluation/evaluationMetaData';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        }
    },
    cardAction: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
    },
    feature: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    featureLabels: {
        width: '100%'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    breakWord: {
        wordBreak: 'break-word'
    },
    iteration: {
        marginLeft: theme.spacing(1)
    }
}));

const ViewEvalutionSummaryCard = (props) => {
    const classes = useStyles();
    const { feature, selectedFeatures, evaluators, cardVariant, labels, handleSelectFeature, handleFeatureRating, handleEditFeature, handleFeatureLabels, handleExpandClick, isOpenCollapsable, handleRemoveIteration, ...rest } = props;
    const [evaluatorDataToRating, setEvaluatorDataToRating] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const isDelivered = isFeatureDelivered(feature);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRemoveFeatureIteration = () => {
        handleRemoveIteration(feature.id);
    }

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    React.useEffect(() => {
        let mergedEvaluatorDataToRating = [];
        for (let evaluator of evaluators) {
            mergedEvaluatorDataToRating.push({
                ...evaluator,
                ...(feature.evaluatorsRating.find((itmInner) => itmInner.evaluatorId === evaluator.id))
            }
            );
        }
        setEvaluatorDataToRating(mergedEvaluatorDataToRating);
    }, [feature, evaluators]);

    const evaluatorAverageRating = calculateAverageRating(evaluatorDataToRating);
    const groupSum = sumAndGroup(evaluatorDataToRating, ['rating']);
    const groupAverage = calculateGroupAverage(groupSum, ['rating']);
    const averageOfGroupAverage = calculateAverageRating(groupAverage);

    return (
        <Card className={classes.card} {...rest}>
            <Grid container spacing={3} justify="space-between" className={classes.cardAction}>
                <Grid item xs={6}>
                    <Tooltip title="Select">
                        <Checkbox
                            disabled={isDelivered}
                            inputProps={{ 'aria-label': 'expert checkbox' }}
                            value={feature.id}
                            onChange={(e) => { handleSelectFeature(e, feature) }}
                            checked={selectedFeatures.includes(feature.id)}
                            name={`check_${feature.id}`} />
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <RatingSummary total={averageOfGroupAverage} title="Groups average" />
                        </Grid>
                        <Grid item xs={6}>
                            <RatingSummary total={evaluatorAverageRating} title="Evaluators average" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <CardContent className={classes.feature}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography variant="h5"> {feature.title}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title={`Edit ${cardVariant}`}>
                            <IconButton onClick={() => !isDelivered && handleEditFeature(feature)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
            {isDelivered && <Chip className={classes.iteration}
                label={`Iteration ${feature.deliveryVersion}`}
                onDelete={handleRemoveFeatureIteration}
            />}
            <Grid container>
                <Grid item xs={9}>
                    <CardActions>
                        <FormControl className={classes.featureLabels}>
                            <LabelsDropDown disabled={isDelivered} labels={labels} dropDownPlaceholder={"Assign labels"} dropDownVariant="standard"
                                value={fetchLabelTitleLabelId(labels, feature.labels)} onChange={(v) => handleFeatureLabels(feature.id, fetchLabelIdLabelTitle(labels, v))} className={classes.featureLabels}
                                getOptionDisabled={() => ((feature.labels && feature.labels.length >= LABELS_ASSIGNMENT_ALLOWED) ? true : false)} />
                            {feature.labels && feature.labels.length >= LABELS_ASSIGNMENT_ALLOWED && <FormHelperText>max {LABELS_ASSIGNMENT_ALLOWED} labels are allowed</FormHelperText>}

                        </FormControl>
                    </CardActions>
                </Grid>
                <Grid item xs={3}>
                    <CardActions onClick={() => handleExpandClick(feature.id)}>
                        <Tooltip title={isOpenCollapsable ? "Hide details" : "Show more details"}>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: isOpenCollapsable,
                                })}
                                aria-expanded={isOpenCollapsable} aria-label="show more"> <ExpandMore />
                            </IconButton>
                        </Tooltip>
                    </CardActions>
                </Grid>
            </Grid>
            <Divider />
            <Collapse in={isOpenCollapsable} timeout="auto" unmountOnExit>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="icon label tabs example"
                >
                    <Tab label="Evaluators rating" icon={<PersonIcon />} {...a11yProps(0)} />
                    <Tab label="Groups rating" icon={<GroupIcon />} {...a11yProps(1)} />
                </Tabs>
                <Divider />
                <TabPanel value={value} index={0}>
                    {evaluatorDataToRating.map((evaluator, index) => {
                        return (
                            <CardContent key={`cardContent_${index + 1}`} id={`cardContent_${index + 1}`}>
                                <CardActions key={`cardAction_${index + 1}`} id={`cardAction_${index + 1}`}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography className={classes.breakWord} variant="body1">{evaluator.name}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Rating disabled={isDelivered} name={`ratings_${evaluator.id}_${feature.id}`} size="large"
                                                key={`ratings_${index + 1}`}
                                                id={`ratings_${index + 1}`}
                                                value={evaluator.rating} onChange={(e, newValue) => {
                                                    handleFeatureRating(feature.id, newValue, evaluator.id);
                                                }} />
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <Divider />
                            </CardContent>
                        )
                    })}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {groupAverage.map((group, index) => {
                        return (
                            <CardContent key={`cardContent_${index + 1}`} id={`cardContent_${index + 1}`}>
                                <CardActions key={`cardAction_${index + 1}`} id={`cardAction_${index + 1}`}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography className={classes.breakWord} variant="body1">{group.group}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <RatingSummary total={group.rating.toFixed(2).replace(/\.0+$/, '')} />
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <Divider />
                            </CardContent>
                        )
                    })}
                </TabPanel>
            </Collapse>
        </Card>
    );
};

ViewEvalutionSummaryCard.propTypes = {
    cardVariant: PropTypes.string,
    feature: PropTypes.object,
    labels: PropTypes.array,
    selectedFeatures: PropTypes.array,
    evaluators: PropTypes.array,
    handleSelectFeature: PropTypes.func,
    handleEditFeature: PropTypes.func,
    handleFeatureLabels: PropTypes.func,
    handleFeatureRating: PropTypes.func,
    handleExpandClick: PropTypes.func,
    isOpenCollapsable: PropTypes.bool,
    handleRemoveIteration: PropTypes.func
};

export default ViewEvalutionSummaryCard;
