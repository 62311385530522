import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { makeStyles, lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    headCell: {
        backgroundColor: lighten(theme.palette.colors.feijoa, 0.50)
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const EnhancedFeatureTableHead = (props) => {
    const classes = useStyles();

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCells = [
        { id: 'title', numeric: false, disablePadding: true, allowSort: true, label: 'Feature description' },
        { id: 'labels', numeric: false, disablePadding: true, allowSort: false, label: 'Feature labels' },
        { id: 'evalRating', numeric: true, disablePadding: false, allowSort: true, label: 'Avg. evaluators rating' },
        { id: 'itrDelivered', numeric: false, disablePadding: true, allowSort: false, label: 'Delivered iteration' }
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headCell} padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all features' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        className={classes.headCell}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={headCell.allowSort && (orderBy === headCell.id ? order : false)}
                    >
                        {!headCell.allowSort ? headCell.label :
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label}
                                {(orderBy === headCell.id) ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedFeatureTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default EnhancedFeatureTableHead;