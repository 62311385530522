import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

const MetaTags = ({ title, meta }) => {
  const canonicalUrl = window.location.href;
  return (
    <Helmet>
      {/* 
      Default Meta tags
         <meta charSet="utf-8" /> 
      */}
      {meta.map(({ name, content }) => (
        <meta key={name} name={name} content={content} />
      ))}
      <link rel="canonical" href={canonicalUrl} />
      {title && <title>{title}</title>}
    </Helmet>
  );
};

MetaTags.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.array,
};

export default MetaTags;
