
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Grid, Typography, Divider } from '@material-ui/core';
import { fetchDaysBetween, fetchResourceCost, formatCost } from '../../utils/common';
import { FIXED_COST_TYPE, RANGE_COST_TYPE, RESOURCE_COST_TYPE } from '../../constants/evaluation/options';
import { EVALUATION_AGILE } from '../../constants/projectTypes';

const useStyles = makeStyles(() => ({
    itemTitle: {
        fontSize: '17px',
        wordBreak: 'break-word'
    },
    itemHelpText: {
        color: 'grey',
        fontSize: '12px'
    }
}));

const OptionCostAndTimeline = (props) => {
    const classes = useStyles();
    const { option, projectType } = props;

    return (
        <Grid container alignItems="center">
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography component="div" align="center" className={classes.itemTitle}>
                    {option.cost.type === FIXED_COST_TYPE && '$ ' + formatCost(option.cost.value.fixedCost)}
                    {option.cost.type === RANGE_COST_TYPE && '$ ' + formatCost(option.cost.value.minPrice) + ' - $ ' + formatCost(option.cost.value.maxPrice)}
                    {option.cost.type === RESOURCE_COST_TYPE && '$ ' + formatCost(fetchResourceCost(option.cost.value.resourceCost, fetchDaysBetween(option.startDate, option.endDate)))}
                </Typography>
                <Typography align="center" className={classes.itemHelpText}>
                    Cost
                </Typography>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Typography component="div" align="center" className={classes.itemTitle}>
                    {projectType !== EVALUATION_AGILE && fetchDaysBetween(option.startDate, option.endDate) + ' days'}
                    {projectType === EVALUATION_AGILE && option.duration + ' sprints'}
                </Typography>
                <Typography align="center" className={classes.itemHelpText}>
                    Timeframe
                </Typography>
            </Grid>
        </Grid>
    );
};

OptionCostAndTimeline.propTypes = {
    option: PropTypes.object,
    editOption: PropTypes.func,
    deleteOption: PropTypes.func,
    projectType: PropTypes.string
};

export default OptionCostAndTimeline;
