
import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

const NavigationButton = (props) => {
    const { title, className, navigateTo, icon: SvgIcon } = props;
    const history = useHistory();

    const navigate = () => {
        history.push(navigateTo);
    }

    return (
        <div className={className}>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={navigate}
                startIcon={<SvgIcon size="large" />}
            >
                {title}
            </Button>
        </div>
    );
};

NavigationButton.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    navigateTo: PropTypes.string,
    icon: PropTypes.any
};

export default NavigationButton;
