import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { CardContent, Card, Typography, Grid, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CachedIcon from '@material-ui/icons/Cached';
import { green, blue, orange } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PROJECT_STATUS_IN_PROGRESS } from '../../../constants/projectTypes';
import WaitingLoader from '../../../components/Loader/WaitingLoader';
import { isPlanCancelledAndBillingPeriodActive, isPlanCancelledAndBillingPeriodEnded, planState } from '../../../utils/planUtil';
import { Alert } from '@material-ui/lab';
import { ALERT_DATE_FORMAT, PROJECTS_COOL_OFF_PERIOD } from '../../../constants/static';

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.spacing(2)
    },
    avatarDiv: {
        margin: theme.spacing(2)
    },
    avatar: {
        height: theme.spacing(7),
        width: theme.spacing(7)
    },
    icon: {
        fontSize: '2rem'
    },
    usedProjects: {
        backgroundColor: blue[500]
    },
    inProgressProjects: {
        backgroundColor: orange[500]
    },
    completedProjects: {
        backgroundColor: green[500]
    },
    accountStatus: {
        backgroundColor: '#000000'
    },
    loadingIcon: {
        height: theme.spacing(6)
    }
}));

const SummaryItems = (props) => {
    const classes = useStyles();

    const { plan, projects, isLoading } = props;
    const [userState, setUserState] = React.useState('');

    const [projectStatusMap, setProjectsStatusMap] = React.useState({
        inProgress: 0,
        completed: 0
    });

    React.useEffect(() => {
        setProjectsStatusMap(fetchProjectsStatusMap());
        setUserState(planState(plan));
    }, [projects]);

    const fetchProjectsStatusMap = () => {
        let inProgressProjects = 0;
        let completedProjects = 0;
        projects.forEach(project => {
            if (project.status === PROJECT_STATUS_IN_PROGRESS) {
                inProgressProjects = inProgressProjects + 1;
            } else {
                completedProjects = completedProjects + 1;
            }
        })
        return {
            inProgress: inProgressProjects,
            completed: completedProjects
        }
    }

    return (
        <React.Fragment>
            {isPlanCancelledAndBillingPeriodEnded(userState) && <Alert severity="warning">All the projects created by you will be deleted on {moment.unix(plan.subscriptionCancelOnDate).add(PROJECTS_COOL_OFF_PERIOD + 1, 'days').format(ALERT_DATE_FORMAT)}</Alert>}
            {isPlanCancelledAndBillingPeriodActive(userState) && <Alert severity="warning">Your plan is scheduled to be cancelled on {moment.unix(plan.subscriptionCancelOnDate).format(ALERT_DATE_FORMAT)}</Alert>}
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <Card className={classes.card}>
                        <div className={classes.avatarDiv}>
                            <Avatar className={clsx(classes.avatar, classes.usedProjects)}>
                                <EmojiObjectsIcon className={classes.icon} />
                            </Avatar>
                        </div>
                        <CardContent>
                            <Typography color={"textSecondary"} variant="h5" gutterBottom>
                                Projects used
                            </Typography>
                            <Typography color={"secondary"} variant="h4">
                                {isLoading ? <WaitingLoader className={classes.loadingIcon} /> : projects.length + '/' + plan.projectsAllowed}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card className={classes.card}>
                        <div className={classes.avatarDiv}>
                            <Avatar className={clsx(classes.avatar, classes.inProgressProjects)}>
                                <CachedIcon className={classes.icon} />
                            </Avatar>
                        </div>
                        <CardContent>
                            <Typography color={"textSecondary"} variant="h5" gutterBottom>
                                In progress
                            </Typography>
                            <Typography color={"secondary"} variant="h4">
                                {isLoading ? <WaitingLoader className={classes.loadingIcon} /> : projectStatusMap.inProgress}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card className={classes.card}>
                        <div className={classes.avatarDiv}>
                            <Avatar className={clsx(classes.avatar, classes.completedProjects)}>
                                <CheckCircleIcon className={classes.icon} />
                            </Avatar>
                        </div>
                        <CardContent>
                            <Typography color={"textSecondary"} variant="h5" gutterBottom>
                                Completed
                            </Typography>
                            <Typography color={"secondary"} variant="h4">
                                {isLoading ? <WaitingLoader className={classes.loadingIcon} /> : projectStatusMap.completed}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Card className={classes.card}>
                        <div className={classes.avatarDiv}>
                            <Avatar className={clsx(classes.avatar, classes.accountStatus)}>
                                <AccountCircleIcon className={classes.icon} />
                            </Avatar>
                        </div>
                        <CardContent>
                            <Typography color={"textSecondary"} variant="h5" gutterBottom>
                                Plan status
                            </Typography>
                            <Typography color={"secondary"} variant="h4">
                                {plan.subscriptionStatus}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

SummaryItems.propTypes = {
    plan: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    isLoading: PropTypes.bool
}

const mapStateToProps = (state) => {
    return {
        plan: state.plan || {}
    };
};

export default connect(mapStateToProps, null)(SummaryItems);