import React from 'react';
import { Toolbar, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    toolbarSecondary: {
        justifyContent: 'space-around',
        overflowX: 'auto',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    toolbarLink: {
        ...theme.navLink,
        ...theme.typography.h5,
        padding: theme.spacing(1),
        '&:hover': {
            borderBottom: "5px solid",
            borderImageSlice: "1",
            borderImageSource: "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)",
            textDecoration: "none"
        }
    },
}))

const sections = [
    { title: 'About CECI', url: '#about' },
    { title: 'Features', url: '#features' },
    { title: 'How CECI Works', url: '#howceciworks' },
    { title: 'Plans', url: '/ceci/plans' },
    { title: 'Try for Free', url: '/ceci/plans' }
];


const Navigation = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                {sections.map((section) => (
                    <NavLink
                        key={section.title}
                        to={section.url}
                        className={classes.toolbarLink}
                    >
                        {section.title}
                    </NavLink>
                ))}
            </Toolbar>
            <Divider />
        </React.Fragment>
    );
};

export default Navigation;
