import React from 'react';
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImageLightBox = (props) => {
    const { images, onClose, ...rest } = props;
    return (
        <Lightbox
            {...rest}
            mainSrc={images.length === 0 ? '' : images[0]}
            onCloseRequest={onClose}
        />

    )
}
ImageLightBox.propTypes = {
    images: PropTypes.array,
    onClose: PropTypes.func
};

export default ImageLightBox;