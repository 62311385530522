
import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    title: {
        paddingTop: theme.spacing(1),
        display: "flex",
        textAlign: "center",
        justifyContent: "center"
    },
    subTitle: {
        display: "flex",
        padding: "0 32px 32px 32px",
        textAlign: "center",
        justifyContent: "center"
    },
    image: {
        padding: theme.spacing(4),
        display: "flex",
        justifyContent: "center"
    }
}));

const NotFound = (props) => {
    const classes = useStyles();
    const { image, imageAlt, title, subTitle, children, ...rest } = props;

    return (
        <Paper {...rest}>
            <div className={classes.image}>
                <img src={image} alt={imageAlt} height="200px" />
            </div>
            <div className={classes.title}>
                <Typography variant="h4" gutterBottom>{title}</Typography>
            </div>
            <div className={classes.subTitle}>
                <Typography variant="body1" color="textSecondary" gutterBottom>{subTitle}</Typography>
            </div>
            {children}
        </Paper>
    );
};

NotFound.propTypes = {
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    children: PropTypes.any
};

export default NotFound;
