
import React from 'react';
import { Grid, Card, CardActionArea, CardContent, Typography, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { projectTypes } from '../../../../constants/projectTypes';
import { useHistory } from 'react-router-dom';
import { admin } from '../../../../constants/routes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../actions/projectActions';
import * as planActions from '../../../../actions/planActions';
import { isUserAllowedToCreateProject } from '../../../../utils/planUtil';

const useStyles = makeStyles((theme) => ({
    choose: {
        marginBottom: theme.spacing(2)
    },
    cardMedia: {
        borderBottom: '1px ridge'
    }
}));

const ProjectTypeOptions = ({ plan, planActions, actions }) => {
    const classes = useStyles();
    const history = useHistory();

    const createNewProject = (projectType) => {
        if (isUserAllowedToCreateProject(plan)) {
            actions.createProject(projectType)
                .then((projectId) => {
                    actions.setCurrentProject(projectId);
                    history.push({ pathname: admin.newProject.url });
                })
        } else {
            planActions.infoMessage('You are not allowed to create new project, please buy a plan');
        }
    }

    return (
        <>
            <Typography className={classes.choose} gutterBottom variant="h5" component="h2">What do you want to do today?</Typography>
            <Grid container spacing={3}>
                {projectTypes.map((projectType, index) => {
                    return (
                        <Grid id={`grid_${index}`} key={`grid_${index}`} item xs={12} sm={6} md={6} lg={6} xl={3}>
                            <Card id={`card_${index}`} key={`card_${index}`}>
                                <CardActionArea onClick={() => createNewProject(projectType.type)}>
                                    <CardMedia className={classes.cardMedia} height="175" component="img" title={projectType.title} image={projectType.titleImg} />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {projectType.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {projectType.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    );
};

ProjectTypeOptions.propTypes = {
    plan: PropTypes.object,
    planActions: PropTypes.object,
    actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        plan: state.plan || {}
    };
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        planActions: bindActionCreators(planActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTypeOptions);
