export const MetaUseCasesPage = {
  title: 'Examples - CECI',
  pageTitle: 'Examples',
  meta: [
    {
      name: 'title',
      content: 'Examples - CECI',
    },
    {
      name: 'description',
      content: 'CECI can be used in various business and personal use cases, it helps to evaluate options efficiently',
    },
    {
      name: 'keywords',
      content: 'CECI,new kitchen selection,real estate,quote evaluation,venor selection,product selection',
    },
    {
      name: 'robots',
      content: 'index, follow',
    }
  ],
};
