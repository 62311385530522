import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        padding: "100px 0 0 0",
        background: "#f6f6f6"
    },
    conatiner: {
        paddingRight: "15px",
        paddingLeft: "15px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    headingContainer: {
        marginBottom: '70px'
    },
    heading: {
        position: "relative",
        marginTop: "10px",
        marginBottom: "15px",
        paddingBottom: "15px",
        textAlign: "center",
        '&:after': {
            position: "absolute",
            content: '""',
            background: "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)",
            height: "4px",
            width: "60px",
            bottom: "0",
            marginLeft: "-30px",
            left: "50%"
        }
    },
    grids: {
        textAlign: "center",
    },
    grid: {
        marginBottom: "30px"
    },
    helperText: {
        padding: "inherit"
    },
    textDescription: {
        padding: '5px'
    },
    responsiveImage: {
        maxWidth: "100%",
        height: "auto",
        maxHeight: '160px'
    }
}))

const sections = [
    { title: 'Ideation Digitalization', imageURL: '/static/images/ceci/home/digital.png', description: 'Perform your ideation process digitally' },
    { title: 'Evaluate Options', imageURL: '/static/images/ceci/home/evaluate.png', description: 'Evaluate various options along with the features and goals required' },
    { title: 'Personal and Business Ideas', imageURL: '/static/images/ceci/home/idea.png', description: 'Use CECI for both personal and business problem solving' },
    { title: 'Compare Options', imageURL: '/static/images/ceci/home/compareOption.png', description: 'Our algorithm gives you summary of all options graphically' },
    { title: 'Data security', imageURL: '/static/images/ceci/home/dataSecurity.png', description: 'All your data stays securely in your device and we don\'t store or use your data' },
    { title: 'Works offline', imageURL: '/static/images/ceci/home/offline.png', description: 'Our app works offline as well, so no need to waste your data usage to perform ideation digitally' },
];

const Features = () => {
    const classes = useStyles();

    return (
        <div id="features" className={classes.root}>
            <div className={classes.container}>
                <div className={classes.headingContainer}>
                    <Typography variant="h4" className={classes.heading} color="textSecondary">FEATURES</Typography>
                </div>
                <Grid container spacing={4} justify="center" className={classes.grids}>
                    {sections.map((section, index) => (
                        <Grid key={`grid_${index}`} item xs={6} sm={6} md={6} lg={4} xl={4} className={classes.grid}>
                            <img src={section.imageURL}
                                alt={section.title}
                                title={section.title}
                                className={classes.responsiveImage} />
                            <div className={classes.helperText}>
                                <Typography variant="h5" color="textSecondary">{section.title}</Typography>
                                <Typography variant="body1" className={classes.textDescription} color="textSecondary">{section.description}</Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default Features;
