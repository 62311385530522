import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Card, CardContent, CardMedia } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    conatiner: {
        paddingRight: "15px",
        paddingLeft: "15px",
        marginRight: "auto",
        marginLeft: "auto",
    },
    headingContainer: {
        marginBottom: '70px',
        padding: "50px 0 0 0",
    },
    heading: {
        position: "relative",
        marginTop: "10px",
        marginBottom: "15px",
        paddingBottom: "15px",
        textAlign: "center",
        '&:after': {
            position: "absolute",
            content: '""',
            background: "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)",
            height: "4px",
            width: "60px",
            bottom: "0",
            marginLeft: "-30px",
            left: "50%"
        }
    },
    grid: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "40px",
    },
    description: {
        lineHeight: '1.5'
    },
    root : {
        height: '100%'
    }
}))

const sections = [
    { title: 'Solve Problem', imageURL: '/static/images/ceci/home/solveProblem.jpg', description: 'CECI is a simple tool that helps you to problem solve using a structured methodology. It allows you to list your features, needs or requirements, capture options for consideration and then rank how well these meet your needs' },
    { title: 'Problems', imageURL: '/static/images/ceci/home/problems.jpg', description: 'CECI can be useful in evaluating both business or personal problems and opportunities and can be a valuable addition to your existing toolset' },
    { title: 'Agile', imageURL: '/static/images/ceci/home/agile.jpg', description: 'CECI comes from Continuous Evaluation Continuous Ideation. It is based upon similar concepts such as traditional waterfall, improvement approaches and Agile used by large enterprise and consultants. But we have endeavoured to keep it simple and adaptable for personal and small business use.' },
    { title: 'Reduce Waste', imageURL: '/static/images/ceci/home/reduceWaste.jpg', description: 'CECI can help you or your business reduce waste through identifying and prioritising your needs, understanding dynamic points of tensions and informing discussion and debate before committing to a course of action' },
];

const AboutCeci = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div id="about" className={classes.headingContainer}>
                <Typography variant="h4" className={classes.heading}>About CECI</Typography>
            </div>
            <Grid
                container
                spacing={3}
                justify="center"
                className={classes.grid}
                direction="row"
                alignItems="stretch">
                {sections.map((section, index) => (
                    <Grid key={`grid_${index}`} item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <Card key={`card_${index}`} className={classes.root}>
                            <CardMedia
                                component="img"
                                alt={section.title}
                                height="140"
                                key={section.title}
                                image={section.imageURL}
                                title={section.title}
                            />
                            <CardContent key={`cardcontent_${index}`}>
                                <Typography className={classes.description} variant="subtitle1" component="p">
                                    <span dangerouslySetInnerHTML={{ __html: section.description }} />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
};

export default AboutCeci;
