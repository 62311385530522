import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';

const lightBeige = '#F1EFDA';

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#69696a',
      main: '#28282a',
      dark: '#1e1e1f',
    },
    secondary: {
      light: '#F1EFDA',
      main: '#a8da83',
      dark: '#a8da83',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
    colors: {
      black: '#F1EFDA',
      white: '#ffffff',
      feijoa: '#B2BE61',
      rawBlue: '#3f51b5',
      reseneVermont: '#B2BE61',
      reseneSmaltBlue: '#496267',
      lightBeige
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
  header: {
    height: '84px',
  },
  overrides: {
    MuiDialog: {
      paper: {
        margin: '8px'
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: lightBeige
      }
    }
  },
  navLink: {
    color: lightBeige,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
  },
  navLinkUnderline: {
    color: lightBeige,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const fontHeader = {
  color: rawTheme.palette.colors.lightBeige,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      ...rawTheme.palette.colors,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    }
  },
};

export default theme;
