export const MetaHomePage = {
  title: 'Home - CECI',
  pageTitle: 'Home',
  meta: [
    {
      name: 'title',
      content: 'CECI-app Home',
    },
    {
      name: 'description',
      content: 'A simple app available free from our website, from the iOS and/or the Google Play store that allows you to undertake simple idea evalautions',
    },
    {
      name: 'keywords',
      content: 'Product Owner, Product Manager, Product Development, Quality Function Deployment, QFD,  Product Strategy, Product Tools, CECI,Continous Evaluation and Continuous Ideation,evaluation, ideation, QFD, Customer and Business Needs, Business Requirement, Discovery,  Option evaluation, Agile, Design Thinking, Lean, Structured Problem Solving, On Device',
    },
    {
      name: 'robots',
      content: 'index, follow',
    }
  ],
};
