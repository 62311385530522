import React from 'react';
import { TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types'
import { Search } from '@material-ui/icons';

const SearchTextbox = (props) => {

    const { placeholder, value, onChange, ...rest } = props;

    return (
        <TextField
            {...rest}
            type="search"
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SvgIcon color="action"                                            >
                            <Search size="large" />
                        </SvgIcon>
                    </InputAdornment>
                )
            }}
            value={value}
            onChange={(e) => {
                onChange(e.target.value);
            }}
            placeholder={placeholder}
            variant="outlined"
        />
    );
}

SearchTextbox.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default SearchTextbox;