import { GLOBAL_LOADER, RESET_STATE, GLOBAL_ERROR } from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function globalReducer(state = initialState.global, action) {
  switch (action.type) {
    case GLOBAL_LOADER: {
      const updatedGlobalLoadingState = { ...state, isLoading: action.payload.isLoading, loadingText: action.payload.loadingMessage || '' }
      return objectAssign({}, updatedGlobalLoadingState);
    }
    case RESET_STATE: {
      return initialState.global;
    }
    case GLOBAL_ERROR: {
      const updatedGlobalErrorState = { ...state, severity: action.payload.severity, message: action.payload.message }
      return objectAssign({}, updatedGlobalErrorState);
    }
    default:
      return state;
  }
}
