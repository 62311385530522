import React from 'react';
import { Icon, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';


const ViewsButtonGroup = (props) => {
    const { value, onChange, viewOptions, ...rest } = props;
    return (
        <ToggleButtonGroup value={value} exclusive onChange={onChange} {...rest}>
            {viewOptions.map((view, index) => {
                return (
                    <ToggleButton key={index} id={index} value={view.value}>
                        <Tooltip title={view.label}>
                            <Icon>{view.icon}</Icon>
                        </Tooltip>
                    </ToggleButton>
                )
            })}
        </ToggleButtonGroup>
    );
}
ViewsButtonGroup.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    viewOptions: PropTypes.array
};

export default ViewsButtonGroup;