import React from 'react';
import { Button, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types'
import PostAddIcon from '@material-ui/icons/PostAdd';

const AddFeatureButton = (props) => {

    const { label, onClick, className, startIcon, maxAllowed, disabled, maxWhatAllowed, ...rest } = props;

    return (
        <div className={className}>
            <FormControl {...rest} >
                <Button color="secondary" onClick={onClick} disabled={disabled}
                    {...rest} size="large" style={{ minHeight: '53px', minWidth: '250px' }}
                    variant="contained" startIcon={startIcon ? startIcon : <PostAddIcon />}>{label}</Button>
                {disabled && <FormHelperText>Max {maxAllowed} {maxWhatAllowed} are allowed</FormHelperText>}
            </FormControl>
        </div>
    );
}
AddFeatureButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    startIcon: PropTypes.object,
    className: PropTypes.string,
    maxAllowed: PropTypes.number,
    disabled: PropTypes.bool,
    maxWhatAllowed: PropTypes.string
};

export default AddFeatureButton;