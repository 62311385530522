import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig.js";
import { admin } from '../../constants/routes';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    signIn: {
        cursor: "pointer"
    }
}));

const SignInButton = ({ label = 'Sign In' }) => {
    const { instance } = useMsal();
    const history = useHistory();
    const classes = useStyles();

    const handleLogin = () => {
        return instance.loginPopup(loginRequest)
            .then(() => {
                history.push(admin.landingPage.url);
            }).catch(() => {
                // TODO: handle the error
                return;
            });
    }

    return (
        <span className={classes.signIn} onClick={() => handleLogin()}> {label} </span>
    )
};

SignInButton.propTypes = {
    label: PropTypes.string
};

export default SignInButton;