export const HEADING_CELLS = ['NPS Type', 'Confidence %'];

export const TERMS_CONDTIONS_LOCAL_STORAGE_KEY = 'acceptedTNC';

export const CECI_DATE_FORMAT = 'DD-MMM-YYYY';

export const ALERT_DATE_FORMAT = 'Do MMMM YYYY';

export const PROJECTS_COOL_OFF_PERIOD = '14';

export const INFO_SEVERITY = 'info';

export const ERROR_SEVERITY = 'error';

export const WARNING_SEVERITY = 'warning';

export const SUCCESS_SEVERITY = 'success';

export const userPlanState = {
    DELETE_USER_NO_ACTIVE_PLAN: 'DELETE_USER_NO_ACTIVE_PLAN',
    DELETE_USER_ACTIVE_PLAN: 'DELETE_USER_ACTIVE_PLAN',
    DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE: 'DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE',
    DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED: 'DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED',
    UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE: 'UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE',
    UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED: 'UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED',
}

export const DELETE_USER_INSTRUCTIONS = {
    DELETE_USER_NO_ACTIVE_PLAN: ['You account will be deleted permanently and cannot be recovered',
        'All the projects(if any) created will be deleted'],
    DELETE_USER_ACTIVE_PLAN: ['Your plan will be cancelled',
        'All the projects created will be available till ' + PROJECTS_COOL_OFF_PERIOD + ' days after your current billing cycle is completed',
        'Your account will be deleted ' + PROJECTS_COOL_OFF_PERIOD + ' days after current billing cycle is completed'],
    DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE: ['All the projects created will be available till ' + PROJECTS_COOL_OFF_PERIOD + ' days after your current billing cycle is completed',
    'Your account will be deleted ' + PROJECTS_COOL_OFF_PERIOD + ' days after current billing cycle is completed'],
    DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED: ['You account will be deleted permanently and cannot be recovered',
        'All the projects(if any) created will be deleted'],
    UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE: ['Your plan will be activated',
        'Your account be won\'t be deleted'],
    UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED: ['Your account be won\'t be deleted']
}