import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MetaTags from '../../components/Seo/MetaTags';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'rgb(244, 246, 248)',
        minHeight: '100%',
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(3)
    }
}));

const DashboardBody = forwardRef(({
    children,
    metaData,
    ...rest
}, ref) => {
    const classes = useStyles();
    return (
        <div
            ref={ref}
            className={classes.root}
            {...rest}
        >
            <MetaTags {...metaData} />
            {children}
        </div>
    );
});

DashboardBody.propTypes = {
    children: PropTypes.node.isRequired,
    metaData: PropTypes.object
};
DashboardBody.displayName = 'DashboardBody';

export default DashboardBody;
