import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Carousel from 'react-material-ui-carousel';
import CarouselSlide from 'react-material-ui-carousel';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(() => ({
    title: {
        textAlign: 'center'
    }
}));

const pictures = [
    { image: "/static/images/home/image1.jpg", title: "" },
];

const ImageCarousel = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Carousel autoPlay>
                {pictures.map(({ image, title }) => (
                    <CarouselSlide key={image}>
                        <Card>
                            <CardMedia
                                image={image}
                                title={title}
                                style={{
                                    height: 0,
                                    paddingTop: '35%',
                                }}
                            />
                            {title && (<CardContent>
                                <Typography className={classes.title}>{title}</Typography>
                            </CardContent>
                            )}
                        </Card>
                    </CarouselSlide>
                ))}
            </Carousel>
        </React.Fragment>
    );
};

export default ImageCarousel;
