import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, List, Typography, makeStyles, Button } from '@material-ui/core';
import NavItem from './NavItem';
import { admin } from './../../../../constants/routes';
import { ReceiptOutlined, ViewList, Add, ImportExport, DashboardOutlined, AccountCircleOutlined, LocalMallOutlined } from '@material-ui/icons';
import CollapseNavBar from './CollapseNavBar';
import { useMsal, useAccount } from "@azure/msal-react";
import { fetchName, fetchRole } from '../../../../utils/identity';
import * as actions from '../../../../actions/planActions';
import { bindActionCreators } from 'redux';

const projectItems = [
  {
    href: admin.newProjectType.url,
    icon: Add,
    title: admin.newProjectType.label
  },
  {
    href: admin.projects.url,
    icon: ViewList,
    title: admin.projects.label
  },
  {
    href: admin.importProject.url,
    icon: ImportExport,
    title: admin.importProject.label
  }
];

const items = [
  {
    href: admin.dashboard.url,
    icon: DashboardOutlined,
    title: admin.dashboard.label,
    hasMoreItems: false,
    moreItems: []
  },
  {
    href: '',
    icon: LocalMallOutlined,
    title: 'Projects',
    hasMoreItems: true,
    moreItems: projectItems
  },
  {
    href: admin.accounts.url,
    icon: AccountCircleOutlined,
    title: admin.accounts.label,
    hasMoreItems: false,
    moreItems: []
  },
  {
    href: admin.billing.url,
    icon: ReceiptOutlined,
    title: admin.billing.label,
    hasMoreItems: false,
    moreItems: []
  }
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    zIndex: 500
  },
  desktopDrawer: {
    width: 256,
    top: 65,
    height: 'calc(100% - 64px)',
    zIndex: 1
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    textDecoration: 'none',
    fontSize: '26px',
    backgroundColor: theme.palette.colors.feijoa
  },
  nameBox: {
    backgroundColor: "#e6ffd4",
    borderRadius: theme.spacing(2),
    overflow: "hidden"
  },
  contactButton: {
    margin: '16px 0px 0px',
    cursor: "pointer",
    borderRadius: theme.spacing(2),
  },
  plan: {
    cursor: "pointer",
    margin: "0px",
    color: "rgb(86, 100, 210)",
    textDecoration: "none"
  }
}));

const NavBar = ({ onMobileClose, openMobile, actions }) => {
  const classes = useStyles();
  const location = useLocation();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [planName, setPlanName] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    actions.fetchPlanData()
      .then(data => {
        setPlanName(data.planName);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.pathname]);
  const history = useHistory();

  const content = (
    <React.Fragment>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Box style={{ padding: '16px' }}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            p={2}
            className={classes.nameBox}
          >
            <Avatar
              className={classes.avatar}
              component={RouterLink}
              to={admin.accounts.url}
            >{fetchName(account)[0]}</Avatar>
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h5"
            >
              {fetchName(account)}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {fetchRole(account)}
            </Typography>
            {!loading &&
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {planName && 'Your plan:'} <RouterLink to={admin.billing.url} className={classes.plan}>{planName}</RouterLink>
              </Typography>
            }
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            {items.map((item) => (
              item.hasMoreItems ?
                <CollapseNavBar key={item} title={item.title} titleIcon={item.icon} items={item.moreItems} />
                :
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
            ))}
          </List>
        </Box>
        <Box flexGrow={1} />
        <Divider />
        <Box alignItems="center"
          display="flex"
          flexDirection="column"
          p={2}>
          <Typography variant="subtitle2">Need help?</Typography>
          <Button variant="contained" color="secondary" fullWidth size="medium" className={classes.contactButton} onClick={() => history.push(admin.contact.url)}>Contact Us</Button>
        </Box>
      </Box>
    </React.Fragment >
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  actions: PropTypes.object
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(NavBar);