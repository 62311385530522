export const MetaErrorPage = {
    title: 'Oops your have reached wrong place',
    pageTitle: 'Oops your have reached wrong place',
    meta: [
      {
        name: 'title',
        content: 'Oops your have reached wrong place - Feijoa8',
      },
      {
        name: 'description',
        content: 'Get in touch with us by email or filling up form',
      },
      {
        name: 'keywords',
        content: 'Feijoa8',
      },
      {
        name: 'robots',
        content: 'noindex, nofollow',
      },
    ],
  };
  