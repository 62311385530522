import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Breadcrumbs, Divider } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { admin } from '../../constants/routes';
import NavigationButton from '../../components/Button/NavigationButton';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between'
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
    breadcrumbs: {
        margin: "8px 0px 0px"
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.main,
        "&:hover,&:focus": {
            textDecoration: "underline"
        }
    },
    newProject: {
        display: "flex",
        paddingBottom: theme.spacing(2),
        justifyContent: "flex-end"
    }
}));

const DashboardTitle = (props) => {
    const classes = useStyles();
    const { title, breadcrumbs = [], showNewProject } = props;
    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.title}>
                <Grid item>
                    <Typography variant="h5" color="primary">{title}</Typography>
                    <Breadcrumbs maxItems={3} separator={<NavigateNextIcon fontSize="small" />} className={classes.breadcrumbs} aria-label="breadcrumb">
                        {breadcrumbs.map((breadcrumb, index) => {
                            return (
                                <Link key={`link_${index}`} className={classes.link} to={breadcrumb.link}>
                                    {breadcrumb.title}
                                </Link>
                            )
                        })}
                        <Typography color="inherit">{title}</Typography>
                    </Breadcrumbs>
                </Grid>
                {showNewProject &&
                    <React.Fragment>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <NavigationButton title={"Import Project"}
                                        navigateTo={admin.importProject.url}
                                        className={classes.newProject}
                                        icon={ImportExportIcon} />
                                </Grid>
                                <Grid item>
                                    <NavigationButton title={"New Project"}
                                        navigateTo={admin.newProjectType.url}
                                        className={classes.newProject}
                                        icon={AddIcon} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
            <Divider className={classes.divider} />
        </React.Fragment>
    );
};

DashboardTitle.propTypes = {
    title: PropTypes.string,
    breadcrumbs: PropTypes.array,
    showNewProject: PropTypes.bool
};

export default DashboardTitle;
