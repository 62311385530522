export const PLAN_PRO = 'Pro';

export const PRO_PLAN_DETAILS = {
    title: PLAN_PRO,
    subheader: 'Most popular',
    description: ['Support for different development methodologies',
        'Projects dashboard',
        'Data is securely stored in cloud',
        'Advanced export options']
}

export const PRO_PLAN_PRICES = [
    {
        project: 5,
        price: 14.99,
        hasDiscountedPrice: true,
        discountedPrice: 9.99
    },
    {
        project: 10,
        price: 19.99,
        hasDiscountedPrice: true,
        discountedPrice: 14.99
    }, {
        project: 20,
        price: 24.99,
        hasDiscountedPrice: true,
        discountedPrice: 19.99
    }
]