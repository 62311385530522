import React from 'react';
import PropTypes from 'prop-types'
import { DialogContent, Dialog } from '@material-ui/core';
import CustomDialogTitle from './CustomDialogTitle';
import OptionSummary from '../Option/OptionSummary';

const OptionSummaryDialog = (props) => {
    const { open, option, onCancel, projectType } = props;

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            maxWidth={"md"}
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <CustomDialogTitle onClose={onCancel} />
            <DialogContent ref={descriptionElementRef} tabIndex={-1}>
                <OptionSummary option={option} projectType={projectType} />
            </DialogContent>
        </Dialog>
    );
}
OptionSummaryDialog.propTypes = {
    open: PropTypes.bool,
    option: PropTypes.object,
    projectType: PropTypes.string,
    onCancel: PropTypes.func
};

export default OptionSummaryDialog;