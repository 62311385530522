import React from 'react';
import Header from '../../components/HeaderV2/Header';
import HeaderLinks from '../../components/HeaderV2/HeaderLinks';
import { feijoa8Menu } from '../../constants/routes';
import Footer from '../../components/Footer/Footer';
import Body from '../../components/Body';
import { MetaContactUsPage } from './Seo/MetaContactUsPage';
import MetaTags from '../../components/Seo/MetaTags';
import ContactInfo from './ContactInfo';
import Divider from "@material-ui/core/Divider";
import { SectionContainer } from '../../components/Body/Containers';

const ContactPage = () => {
  return (
    <>
      <Header
        brand="feijoa8"
        rightLinks={<HeaderLinks links={feijoa8Menu} />}
        fixed
      />
      <MetaTags {...MetaContactUsPage} />
      <Body title={MetaContactUsPage.pageTitle}>
        <Divider />
        <SectionContainer>
          <ContactInfo />
        </SectionContainer>
      </Body>
      <Footer />
    </>
  );
};

export default ContactPage;
