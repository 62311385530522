import React from 'react';
import { Container, Link, Grid, Card, Typography, Button, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ProPlan from './ProPlan';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    cardHeader: {
        backgroundColor: '#a8da83',
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    liteApple: {
        margin: 'auto'
    },
    summary: {
        padding: '5px'
    },
    getStarted: {
        textDecoration: 'none'
    }
}));

const plans = [
    // {
    //     title: 'Free',
    //     price: '0',
    //     description: ['Available on Web Only',
    //         'Single Idea evaluation allowed',
    //         'Paid Publish PDF of your Idea Summary']
    // },
    {
        title: 'Lite',
        // subheader: 'Most popular',
        //price: '9.99',
        price: '0',
        description: [
            'Available on Web, Apple and Android app store',
            '4 Ideas allowed',
            'Free Export to Excel & PDF of your Idea Summary'
        ]
    }]

const PlanComparison = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container maxWidth="lg" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {plans.map((plan) => (
                        <Grid item key={plan.title} xs={12} sm={6} md={6}>
                            <Card className={classes.card}>
                                <CardHeader
                                    title={plan.title}
                                    subheader={plan.subheader}
                                    titleTypographyProps={{ align: 'center', variant: 'h4' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    className={classes.cardHeader}
                                />
                                <CardContent>
                                    <div className={classes.cardPricing}>
                                        <Typography component="h2" variant="h2" color="textPrimary">
                                            ${plan.price}
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary">
                                            {/* {plan.title === 'Free' ? '/mo' : 'one time'} */}
                                            {'/mo'}
                                        </Typography>
                                    </div>
                                    <ul>
                                        {plan.description.map((line) => (
                                            <Typography className={classes.summary} component="li" variant="subtitle1" align="center" key={line}>
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={2} justify="center" align="center">
                                        {plan.title === 'Free' &&
                                            <Grid item>
                                                <a className={classes.getStarted} href="https://ceci.app/" rel="noreferrer noopener" target="_blank">
                                                    <Button size={"large"} variant="contained" color="primary" startIcon={<PhoneIphoneIcon fontSize="large" />}>GET STARTED</Button>
                                                </a>
                                            </Grid>
                                        }
                                        {plan.title === 'Lite' &&
                                            <React.Fragment>
                                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                                    <a className={classes.getStarted} href="https://ceci.app/" rel="noreferrer noopener" target="_blank">
                                                        <Button size={"large"} variant="contained" color="primary" startIcon={<PhoneIphoneIcon fontSize="large" />}>Visit web app</Button>
                                                    </a>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.liteApple}>
                                                    <Link href="https://apps.apple.com/us/app/ceci-app/id1576481569" target="_blank">
                                                        <img src="/static/svg/appleStore.svg" alt="CECI.app" height="50px" width="170px" />
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                                    <Link href="https://play.google.com/store/apps/details?id=app.web.ceci_lite.twa" target="_blank">
                                                        <img src="/static/images/ceci/plan/googlePlay.png" alt="CECI.app" height="70px" width="170px" />
                                                    </Link>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                    <ProPlan xs={12} sm={6} md={6} />
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default PlanComparison;
