import React from 'react';
import faqs from './../../../data/ceci/faq.json';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    accordionSummary: {
        backgroundColor: '#F2F2F2'
    }
}))

const Faq = () => {
    const ceciFaq = [];
    const classes = useStyles();

    for (const [index, faq] of faqs.entries()) {
        ceciFaq.push(
            <React.Fragment key={`faq_${index}`}>
                <Accordion id={`faq_${index}`} key={`faq_${index}`}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.question}>{faq.question}</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {ceciFaq}
        </React.Fragment>
    );
};

export default Faq;
