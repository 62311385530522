
export const isExcel = (fileType) => {
    return (fileType === 'xls' || fileType === 'xlsx');
}

export const isJson = (fileType) => {
    return fileType === 'json';
}

export const isImage = (fileType) => {
    return fileType.startsWith('image/');
}