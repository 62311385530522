import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types'

const SelectAllCheckbox = (props) => {
    const { checked, onChange, label } = props;
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    name="selectAll"
                    color="secondary"
                />
            }
            label={label}
        />
    );
}
SelectAllCheckbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string
};

export default SelectAllCheckbox;