import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    center: {
        display: 'flex',
        justifyContent: 'center'
    },
    text: {
        position: 'absolute'
    }
}));

const WaitingLoader = (props) => {
    const classes = useStyles();
    const { loadingText, className } = props;
    return (
        <div className={clsx(classes.center, className)}>
            <img src="/static/svg/loader.svg" alt="loader" />
            <Typography variant="h5" className={classes.text}>{loadingText ? loadingText : ''}</Typography>
        </div>
    );
}

WaitingLoader.propTypes = {
    loadingText: PropTypes.string,
    className: PropTypes.string
};

export default WaitingLoader;