
import React from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from "prop-types";
import BarChart from '../Chart/BarChart';
import WaitingLoader from '../Loader/WaitingLoader';
import { fetchOptionRatings } from '../../utils/projectUtil';

const OptionGraphicalSummary = (props) => {
    const { options, features } = props;
    const [series, setSeries] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(false);

    React.useEffect(() => {
        setShowLoading(true);
        const featureTitles = features.map((feature) => { return feature.title });
        setLabels(featureTitles);
        const chartSeries = options.map((option) => {
            return ({
                name: option.summary,
                data: fetchOptionRatings(option.id, features)
            })
        })
        setSeries(chartSeries);
        setShowLoading(false);
    }, [features, options]);

    return (
        <Paper>
            {showLoading && <WaitingLoader />}
            <BarChart series={series} labels={labels} />
        </Paper>
    );
};

OptionGraphicalSummary.propTypes = {
    features: PropTypes.array,
    options: PropTypes.array
};

export default OptionGraphicalSummary;
