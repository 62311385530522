
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Grid, Card, Tooltip, CardContent, FormControl, FormHelperText, Typography, Checkbox, IconButton, CardActions, Chip } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { Edit } from '@material-ui/icons';
import LabelsDropDown from '../Dropdown/LabelsDropDown';
import { fetchLabelIdLabelTitle, fetchLabelTitleLabelId, fetchRatingByEvaluatorId, isFeatureDelivered } from '../../utils/projectUtil';
import { LABELS_ASSIGNMENT_ALLOWED, FEATURE_RATING_LABEL } from '../../constants/evaluation/evaluationMetaData';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
    },
    cardAction: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
    },
    feature: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    featureLabels: {
        width: '100%'
    },
    rating: {
        padding: 'inherit'
    },
    iteration: {
        marginLeft: theme.spacing(1)
    }
}));

const RateAndLabelCard = (props) => {
    const classes = useStyles();
    const { cardVariant, index, feature, evaluatorId, hoveredFeature, ratingHoverState, handleRatingUpdateHoverState, selectedFeatures, labels, handleHoveredFeature, handleSelectFeature, handleEditFeature, handleFeatureRating, handleFeatureLabels, handleRemoveIteration, ...rest } = props;
    const isDelivered = isFeatureDelivered(feature);

    const handleRemoveFeatureIteration = () => {
        handleRemoveIteration(feature.id);
    }

    return (
        <Card className={classes.card}
            {...rest} id={`card_${index}`}>
            <Grid container spacing={3} justify="space-between" className={classes.cardAction}>
                <Grid item>
                    <Tooltip title="Select">
                        <Checkbox
                            inputProps={{ 'aria-label': 'expert checkbox' }}
                            value={feature.id}
                            disabled={isDelivered}
                            onChange={(e) => { handleSelectFeature(e, feature) }}
                            checked={selectedFeatures.includes(feature.id)}
                            name={`check_${feature.id}`} />
                    </Tooltip>
                </Grid>
                <Grid item>
                    <CardActions disableSpacing >
                        {hoveredFeature === feature.id && <Typography className={classes.rating} variant="body1">{FEATURE_RATING_LABEL[ratingHoverState !== -1 ? ratingHoverState : 2]}</Typography>}
                        <Rating name={`ratings_${feature.id}`} size="large" id={`rating_${index}`}
                            value={fetchRatingByEvaluatorId(evaluatorId, feature.evaluatorsRating)}
                            onChangeActive={(e, newHover) => {
                                handleRatingUpdateHoverState(newHover);
                            }}
                            disabled={isDelivered}
                            onMouseOver={() => handleHoveredFeature(feature.id)}
                            onMouseOut={() => handleHoveredFeature('')}
                            onChange={(e, newValue) => {
                                !isDelivered && handleFeatureRating(feature.id, newValue);
                            }} />
                    </CardActions>
                </Grid>
            </Grid>
            <CardContent className={classes.feature}>
                <Grid container>
                    <Grid item xs={11} id={`title_${index}`}>
                        <Typography variant="h5"> {feature.title}</Typography>
                    </Grid>
                    <Grid item xs={1} id={`editTitle_${index}`}>
                        <Tooltip title={`Edit ${cardVariant}`}>
                            <IconButton onClick={() => !isDelivered && handleEditFeature(feature)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
            {isDelivered && <Chip className={classes.iteration}
                label={`Iteration ${feature.deliveryVersion}`}
                onDelete={handleRemoveFeatureIteration}
            />}
            <CardActions>
                <FormControl className={classes.featureLabels} id={`labels_${index}`}>
                    <LabelsDropDown disabled={isDelivered} labels={labels} dropDownPlaceholder={"Assign labels"} dropDownVariant="standard"
                        value={fetchLabelTitleLabelId(labels, feature.labels)} onChange={(value) => handleFeatureLabels(feature.id, fetchLabelIdLabelTitle(labels, value))} className={classes.featureLabels}
                        getOptionDisabled={() => ((feature.labels && feature.labels.length >= LABELS_ASSIGNMENT_ALLOWED) ? true : false)}
                    />
                    {feature.labels && feature.labels.length >= LABELS_ASSIGNMENT_ALLOWED && <FormHelperText>max {LABELS_ASSIGNMENT_ALLOWED} labels are allowed</FormHelperText>}
                </FormControl>
            </CardActions>
        </Card >
    );
};

RateAndLabelCard.propTypes = {
    index: PropTypes.number,
    cardVariant: PropTypes.string,
    feature: PropTypes.object,
    evaluatorId: PropTypes.string,
    hoveredFeature: PropTypes.string,
    ratingHoverState: PropTypes.any,
    handleRatingUpdateHoverState: PropTypes.func,
    labels: PropTypes.array,
    selectedFeatures: PropTypes.array,
    handleSelectFeature: PropTypes.func,
    handleEditFeature: PropTypes.func,
    handleFeatureRating: PropTypes.func,
    handleFeatureLabels: PropTypes.func,
    handleHoveredFeature: PropTypes.func,
    handleRemoveIteration: PropTypes.func
};

export default RateAndLabelCard;
