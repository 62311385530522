// Utility Function
export const formatDataInTabular = (stateData, uploadData) => {
  if (uploadData.length > 0) {
    const [first] = uploadData;
    const { firstRowAsHeader, canProceed } = stateData;

    const columns = first.map((value, key) => ({
      checked: false,
      title: 'Select',
      field: 'col' + key,
      fieldValue: key,
      headerLinkValue: value,
    }));

    const data = mapTableBodyData(uploadData);

    return { columns, data, firstRowAsHeader, canProceed };
  } else {
    const { firstRowAsHeader, columns, data } = stateData;
    const canProceed = columns.filter((col) => col.checked).length > 0;
    return { columns, data, firstRowAsHeader, canProceed };
  }
};

const mapTableBodyData = (data) => {
  return data.map((row) =>
    row.reduce(function (result, item, index) {
      result['col' + index] = item;
      return result;
    }, {})
  );
};

export const transformApiRequestData = (stateData) => {
  const selectedColumn = [];
  const selectedRows = [];
  const { columns, firstRowAsHeader } = stateData;
  const data = firstRowAsHeader ? stateData.data.slice(1) : stateData.data;
  columns.map((column) => {
    if (column.checked) {
      selectedColumn.push(column.field);
    }
  });

  data.map((data) => {
    const rowValue = [];
    selectedColumn.map((column) => {
      if (data[column]) {
        rowValue.push(data[column]);
      }
    });
    selectedRows.push(rowValue.join(' '));
  });

  return { data: selectedRows };
};

export const transformResponseDetails = (data) => {
  let textValue = '';
  const rows = [];
  data.map((obj) => {
    textValue += obj.text;
    obj.classifications.map((row) => {
      let singleRow = [row.tag_name, row.confidence * 100];
      rows.push(singleRow);
    });
  });
  return { rows, textValue };
};


export const fetchProjectIdFromState = (getState) => {
  return getState().userProjects.global.currentProjectId;
}