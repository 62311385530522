import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import content from './data';
import ImageCarousel from './ImageCarousel';
import { NavLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    listDisc: {
        listStyle: 'disc',
        paddingLeft: theme.spacing(3)
    },
    parentGrid: {
        marginBottom: theme.spacing(4)
    },
    navLink: {
        ...theme.navLink
    },
    navLinkUnderline: {
        ...theme.navLinkUnderline,

    },
    image :{
        width: '100%',
        height: '100%'
    }
}));

export default function MainSection() {

    const classes = useStyles();
    return (
        <>
            <Grid className={classes.parentGrid} container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {content.main.title}
                    </Typography>

                    {content.main.para.map(text =>
                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                    )}
                </Grid>

                <Grid item lg={6} xs={12}>
                    {content.main.images.length > 1 ?
                        <ImageCarousel />
                        : <img className={classes.image} src={content.main.images[0].image} alt={content.main.images[0].title} />
                    }
                    {/* <ImageCarousel /> */}
                </Grid>
            </Grid>


            <Grid id="about-us" className={classes.parentGrid} container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {content.section1.col1.title}
                    </Typography>

                    {content.section1.col1.para.map(text =>
                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                    )}
                </Grid>

                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {content.section1.col2.section1.title}
                    </Typography>

                    {content.section1.col2.section1.para.map(text =>
                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                    )}

                    <Typography variant="h4" gutterBottom>
                        {content.section1.col2.section2.title}
                    </Typography>

                    {content.section1.col2.section2.para.map(text =>
                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                    )}
                </Grid>
            </Grid>


            <Grid className={classes.parentGrid} container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {content.section2.col1.title}
                    </Typography>

                    {content.section2.col1.para.map(text =>
                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                    )}
                </Grid>

                <Grid item lg={6} xs={12}>
                    <Grid container >
                        <Grid item lg={6} xs={6}>
                            <NavLink className={classes.navLink} to={content.section2.col2.ceciApp.link}>
                                <img src={content.section2.col2.ceciApp.image} alt="CECI App" height="75px" />
                                <Typography variant="body1" gutterBottom paragraph> {content.section2.col2.ceciApp.linkText} </Typography>
                            </NavLink>
                            <Grid container spacing={3}>
                                <Grid item lg={6} xs={6}>
                                    <Typography variant="h6" gutterBottom>
                                        {content.section2.col2.ceciApp.webApp.title}
                                    </Typography>

                                    {content.section2.col2.ceciApp.webApp.para.map(text =>
                                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                                    )}
                                    <NavLink className={classes.navLinkUnderline} to={content.section2.col2.ceciApp.tryPlan}>
                                        <Typography variant="body1">Try me for free</Typography>
                                    </NavLink>
                                </Grid>

                                <Grid item lg={6} xs={6}>
                                    <Typography variant="h6" gutterBottom>
                                        {content.section2.col2.ceciApp.lite.title}
                                    </Typography>

                                    {content.section2.col2.ceciApp.lite.para.map(text =>
                                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={6} xs={6}>
                            <NavLink className={classes.navLink} to={content.section2.col2.ceciStudio.link}>
                                <img src={content.section2.col2.ceciStudio.image} alt="CECI Studio" height="75px" />
                                <Typography variant="body1" gutterBottom paragraph> {content.section2.col2.ceciStudio.linkText} </Typography>
                            </NavLink>

                            <Grid container spacing={2}>
                                <Grid item lg={6} xs={6}>
                                    <Typography variant="h6" gutterBottom>
                                        {content.section2.col2.ceciStudio.pro.title}
                                    </Typography>

                                    {content.section2.col2.ceciStudio.pro.para.map(text =>
                                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                                    )}
                                </Grid>

                                <Grid item lg={6} xs={6}>
                                    <Typography variant="h6" gutterBottom>
                                        {content.section2.col2.ceciStudio.enterprise.title}
                                    </Typography>

                                    {content.section2.col2.ceciStudio.enterprise.para.map(text =>
                                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid className={classes.parentGrid} container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {content.section3.col1.title}
                    </Typography>

                    <video width="100%" controls>
                        <source src={content.section3.col1.mp4} type="video/mp4" />
                        <source src={content.section3.col1.ogg} type="video/ogg" />
                                Your browser does not support HTML video.
                    </video>
                </Grid>

                <Grid item lg={6} xs={12}>

                </Grid>
            </Grid>



        </>
    )
}
