import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import * as actions from '../../actions/paymentActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const BuyPlanButton = ({ text, planType, numberOfProjects, actions, ...rest }) => {

    const buyPlan = () => {
        actions.initiateBuyPlan(numberOfProjects, planType)
    }
    return (
        <React.Fragment>
            <Button {...rest} size={"large"} startIcon={<ShoppingCartIcon />} onClick={buyPlan} variant="contained">
                {text}
            </Button>
        </React.Fragment>
    );
}
BuyPlanButton.propTypes = {
    text: PropTypes.string,
    numberOfProjects: PropTypes.number,
    planType: PropTypes.string,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(BuyPlanButton);