import routes from '../../constants/routes'

const content = {
    main: {
        title: 'Welcome',
        para: [
            'Whether you are and individual or an organisation, we have tools and capabilities that will help you.',
            'You could be a Product Manager or Product Owner trying to build a case for a significant investment, or a Business Analyst capturing features and trying to convert them into sets of user stories or requirements.',
            'You could have priority conflicts created due to dynamic points of tensions.',
            'We have tools available and in development that can help.',
            'Subscribe for updates, register for access to content, connect on social media, or check in regularly to see what is developing.'
        ],
        images: [
            { image: "/static/images/home/image1.jpg", title: "" },
        ]
    },
    news: {
        title: 'News',
        news: [
            {
                date: '4 March 2022',
                text: ['CECI.lite now available for free on iOS and Android (with banner advertising)']
            },
            {
                date: '4 March 2022',
                text: [
                    'CECI.Studio Pro released with special introductory pricing and available via monthly subscription',
                    'CECI.app iOS available in Apple Store',
                    'CECI.app Android available in Google Play store',
                    'CECI.app Webapp available for free online'
                ]
            }
        ]
    },
    subscribe: {
        title: 'Subscribe',
        list: [
            'Click {{here}} to subscribe to updates, change and announcements from the website.',
            'If you wish to become part of our community, click {{here}}. You will be directed to create a user name and password.',
            'Note that if you have already subscribed to CECI.studio, you are already registered and can access the community space'
        ]
    },
    section1: {
        col1: {
            title: 'About us',
            para: [
                'In New Zealand where I grew up, Feijoa’s are the “community” fruit (despite the tree actually being from South America).',
                'They generate copious amounts for fruit in late autumn which is shared with other, often by someone just dropping a big bag into a place of work or school, or by an industrious child creating a stall and selling them to passers by. ',
                'The produce is then either eaten just as it is, or made into preserves, jams, desserts...even wine.',
                'To me, Feijoa’s are all about sharing and being industrious, a value I endeavour to adopt as part of this companies DNA.',
                'The “8” simply comes from the infinity symbol stood upright which denotes continuousevolution and improvement.',
                'So for me, Feijoa8 represents a sense of aresilience, bountifulness, reusability and continuous learning and improvement.'
            ]
        },
        col2: {
            section1: {
                title: 'Our purpose ',
                para: [
                    'Our intent is to create content, templates and tools that helps you to be successful. Somewhere where you can collaborate with others securely, adapt your approach and learn.',
                    'We will develop applications and methodologies for both personal and business customers using simple modern coding standards and cloud based architecture.'
                ]
            },
            section2: {
                title: 'Our principles',
                para: [
                    'We respect your privacy and intellectual property.. We will not mine or sell user data to others. Privacy and security of our user content will be our prime concern.',
                    'We will not bombard customers with intrusive advertising within the applications. Only simple banner advertisement will be used on free applications.',
                    'User feedback resulting in continuous improvement will be incorporated into every application we created.',
                    'Modern microservice capability deployed on Cloud architecture will enable rapid response to changing market conditions and enable us to scale with demand'
                ]
            }
        }
    },
    section2: {
        col1: {
            title: 'Products and Services',
            para: [
                'Continuous | Evaluation | Continuous | Ideation',
                'CECI is a simple tool that helps you to problem solve using a structured methodology. It allows you to list your features, needs or requirements, capture options for consideration and then rank how well these meet your needs.',
                'CECI can be useful in evaluating problems and opportunities and can be a valuable addition to your existing toolset.',
                'CECI comes from Continuous Evaluation Continuous Ideation. It is based upon similar concepts such as traditional waterfall, improvement approaches and Agile But we have endeavoured to keep it simple and adaptable for all uses.',
                'CECI can help you or your business reduce waste through identifying and prioritising your needs, understanding dynamic points of tensions and informing discussion and debate before committing to a course of action.'
            ]
        },
        col2: {
            ceciApp: {
                image: '/static/svg/ceci.svg',
                linkText: 'Click for more info',
                link: routes.ceciHome.url,
                tryPlan: routes.ceciPlans.url,
                webApp: {
                    title: 'Webapp',
                    para: [
                        'Free (with banner advertising)',
                        'A simple browser based solution for demonstration and one off uses'
                    ]
                },
                lite: {
                    title: 'Lite',
                    para: [
                        'Free (with banner advertising)',
                        'A “lite” on device solution for simpler initiatives'
                    ]
                }
            },
            ceciStudio: {
                image: '/static/svg/cecistudio.svg',
                linkText: 'Click for more info',
                link: routes.ceciStudio.url,
                pro: {
                    title: 'Pro',
                    para: [
                        'Monthly Subscription',
                        'A single user cloud based solution for Product Owners/Managers Business Owners and more'
                    ]
                },
                enterprise: {
                    title: 'Enterprise & Consultant(Coming soon)',
                    para: [
                        'TBC',
                        'A cloud based solution for multiple users within an enterprise'
                    ]
                }
            }
        }
    },
    section3: {
        col1: {
            title: 'Have a quick look at the concept behind CECI',
            mp4: '/static/videos/ceci_work_flow.mp4',
            ogg: '/static/videos/ceci_work_flow.mp4'
        }
    }
};

export default content;