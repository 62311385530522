
import React from 'react';
import { AppBar, Grid, Tab, Tabs, Divider, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import TabPanel from '../Tabs/TabPanel';
import RateOptionCard from '../Card/RateOptionCard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OptionSummary from './OptionSummary';

const useStyles = makeStyles((theme) => ({
    title: {
        paddingTop: theme.spacing(1),
        display: "flex",
        justifyContent: "center"
    },
    tab: {
        textTransform: 'none',
        fontSize: '14px',
        wordBreak: 'break-word'
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs: {
        backgroundColor: '#FFFFFF'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
        marginBottom: theme.spacing(2)
    }
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

const OptionEvaluationComponent = (props) => {
    const classes = useStyles();
    const { projectType, options, features, hoveredFeature, ratingHoverState, handleRatingUpdateHoverState, labels, handleHoveredFeature, handleFeatureRating, handleActiveOptionId } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        handleActiveOptionId(options[newValue].id)
    };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        id="options"
                        className={classes.tabs}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {options.map((option, index) => {
                            return (
                                <Tab
                                    className={classes.tab}
                                    key={index} id={index}
                                    label={option.summary}
                                    {...a11yProps(index)}
                                />
                            )
                        })}
                    </Tabs>
                </AppBar>
            </div>

            {options.map((option, index) => {
                return (
                    <TabPanel value={value} index={index} key={index} id={index}>
                        <Accordion className={classes.accordion} id="optionSummary">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>View option details</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                                <OptionSummary option={option} projectType={projectType} />
                            </AccordionDetails>
                        </Accordion>
                        <Grid container spacing={3}>
                            {features.map((feature, index) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} id={`grid_${index}`} key={`grid_${index}`}>
                                        <RateOptionCard id={`card_${index}`} key={`card_${index}`} feature={feature} optionId={option.id}
                                            labels={labels} handleHoveredFeature={handleHoveredFeature} hoveredFeature={hoveredFeature}
                                            ratingHoverState={ratingHoverState} handleRatingUpdateHoverState={handleRatingUpdateHoverState}
                                            handleFeatureRating={(featureId, rating) => handleFeatureRating(option.id, featureId, rating)}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </TabPanel>
                )
            })}
        </React.Fragment >
    );
};

OptionEvaluationComponent.propTypes = {
    options: PropTypes.array,
    projectType: PropTypes.string,
    features: PropTypes.array,
    hoveredFeature: PropTypes.string,
    ratingHoverState: PropTypes.any,
    handleRatingUpdateHoverState: PropTypes.func,
    labels: PropTypes.array,
    handleFeatureRating: PropTypes.func,
    handleHoveredFeature: PropTypes.func,
    handleActiveOptionId: PropTypes.func
};

export default OptionEvaluationComponent;
