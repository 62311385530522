import { EVALUATION_AGILE, EVALUATION_WATERFALL, EVALUATION_ITERATIVE } from '../../constants/projectTypes';

export const translateProjectsToReduxStructure = (data) => {
    if (data.length === 0) {
        return [];
    }
    return data.map((project) => {
        return ({
            id: project.id,
            createdOn: project.createdOn,
            updatedOn: project.updatedOn,
            type: project.type,
            idea: {
                title: project.idea.title,
                description: project.idea.description,
                problemStatement: project.idea.problemStatement,
                assumptions: project.idea.assumptions,
                notes: project.idea.notes,
                customerBenefit: project.idea.customerBenefit,
                targetSegment: project.idea.targetSegment,
                valueProposition: project.idea.valueProposition,
                competitiveLandscape: project.idea.competitiveLandscape,
                businessImpactSummary: project.idea.businessImpactSummary,
                keyMetrics: project.idea.keyMetrics,
                costStructure: project.idea.costStructure,
                revenueStreams: project.idea.revenueStreams
            },
            status: project.status,
            createdBy: {
                name: project.createdBy.name,
                emailId: project.createdBy.emailId
            },
            selectedOption: project.selectedOption,
            currentStep: 0,
            isStepSkipped: false,
            featureLabels: project.featureLabels,
            featureEvaluators: project.featureEvaluators,
            goalLabels: project.goalLabels,
            goalEvaluators: project.goalEvaluators
        })
    })
}

export const translateProjectToReduxStructure = (project) => {
    return {
        id: project.projectId,
        createdOn: project.createdOn,
        updatedOn: project.updatedOn,
        type: project.type,
        status: project.status,
        createdBy: {
            name: project.createdBy.name,
            emailId: project.createdBy.emailId
        },
        currentStep: 0
    }
}

export const translateFeaturesToReduxStructure = (features) => {
    if (!features || features.length === 0) {
        return [];
    }
    return features.map((feature) => {
        return ({
            id: feature.id,
            title: feature.title,
            created: feature.created,
            deliveryVersion: feature.deliveryVersion,
            labels: feature.labels,
            createdBy: feature.createdBy,
            evaluatorsRating: translateEvaluatorRating(feature.evaluatorsRating),
            optionsRating: translateOptionRating(feature.optionsRating)
        })
    })
}

export const translateEvaluatorRating = (evaluations) => {
    if (!evaluations || evaluations.length === 0) {
        return [];
    }
    return evaluations.map((evaluation) => {
        return ({
            evaluatorId: evaluation.id,
            rating: evaluation.rating
        })
    })
}

export const translateOptionRating = (evaluations) => {
    if (!evaluations || evaluations.length === 0) {
        return [];
    }
    return evaluations.map((evaluation) => {
        return ({
            optionId: evaluation.id,
            rating: evaluation.rating
        })
    })
}

export const translateGoalsToReduxStructure = (goals) => {
    if (!goals || goals.length === 0) {
        return [];
    }
    return goals.map((goal) => {
        return ({
            id: goal.id,
            title: goal.title,
            created: goal.created,
            labels: goal.labels,
            createdBy: goal.createdBy,
            evaluatorsRating: translateEvaluatorRating(goal.evaluatorsRating),
            optionsRating: translateOptionRating(goal.optionsRating)
        })
    })
}

export const translateOptionsToReduxStructure = (options) => {
    if (!options || options.length === 0) {
        return [];
    }
    return options.map((option) => {
        const projectType = option.projectType;
        let response = {};
        response.cost = {};
        response.cost.value = {};
        {
            response.id = option.id;
            response.projectType = option.projectType;
            response.summary = option.summary;
            response.description = option.description;
            response.benefits = option.benefits;
            response.constraints = option.constraints;
            response.cost.type = option.cost.type;
            if (option.cost.fixedCost) {
                response.cost.value.fixedCost = option.cost.fixedCost
            } else if (option.cost.minPrice) {
                response.cost.value.minPrice = option.cost.minPrice
            } else if (option.cost.maxPrice) {
                response.cost.value.maxPrice = option.cost.maxPrice
            } else if (option.cost.resourceCost) {
                response.cost.value.resourceCost = option.cost.resourceCost
            }
            if (projectType === EVALUATION_AGILE) {
                response.resourcesAvailable = option.resourcesAvailable;
                response.squadAvailable = option.squadAvailable;
                response.duration = option.duration;
                response.technicalDebt = option.technicalDebt;
            } else if (projectType === EVALUATION_WATERFALL) {
                response.resourcesAvailable = option.resourcesAvailable;
                response.technicalDebt = option.technicalDebt;
                response.startDate = option.startDate;
                response.endDate = option.endDate;
            } else if (projectType === EVALUATION_ITERATIVE) {
                response.iterationSummary = option.iterationSummary;
                response.iterationDescription = option.iterationDescription;
                response.resourcesAvailable = option.resourcesAvailable;
                response.technicalDebt = option.technicalDebt;
                response.startDate = option.startDate;
                response.endDate = option.endDate;
            } else {
                response.startDate = option.startDate;
                response.endDate = option.endDate;
            }
        }
        return (response);
    })
}

export const translateOptionToRequest = (option) => {
    const projectType = option.projectType;
    let request = {};
    request.cost = {};
    request.projectType = projectType;
    request.summary = option.summary;
    request.description = option.description;
    request.benefits = option.benefits;
    request.constraints = option.constraints;
    request.cost.type = option.cost.type;
    if (option.cost.value.fixedCost) {
        request.cost.fixedCost = option.cost.value.fixedCost;
    } else if (option.cost.value.minPrice) {
        request.cost.minPrice = option.cost.value.minPrice;
    } else if (option.cost.value.maxPrice) {
        request.cost.maxPrice = option.cost.value.maxPrice;
    } else if (option.cost.value.resourceCost) {
        request.cost.resourceCost = option.cost.value.resourceCost;
    }
    if (projectType === EVALUATION_AGILE) {
        request.resourcesAvailable = option.resourcesAvailable;
        request.squadAvailable = option.squadAvailable;
        request.duration = option.duration;
        request.technicalDebt = option.technicalDebt;
    } else if (projectType === EVALUATION_WATERFALL) {
        request.resourcesAvailable = option.resourcesAvailable;
        request.technicalDebt = option.technicalDebt;
        request.startDate = option.startDate;
        request.endDate = option.endDate;
    } else if (projectType === EVALUATION_ITERATIVE) {
        request.iterationSummary = option.iterationSummary;
        request.iterationDescription = option.iterationDescription;
        request.resourcesAvailable = option.resourcesAvailable;
        request.technicalDebt = option.technicalDebt;
        request.startDate = option.startDate;
        request.endDate = option.endDate;
    } else {
        request.startDate = option.startDate;
        request.endDate = option.endDate;
    }
    return request;
}

