import {
    EVALUATOR_VIEW, EVALUATION_SUMMARY, SORT_BY_ALPHABETICALLY_ASC,
    SORT_BY_ALPHABETICALLY_DSC, SORT_BY_RATING_HIGH_TO_LOW, SORT_BY_RATING_LOW_TO_HIGH, EVALUATION_CHART, EVALUATION_COMPARISON,
    GRID_VIEW, LIST_VIEW, CAROUSEL_VIEW, INCLUDES_FILTER
}
    from '../constants/evaluation/evaluationMetaData';
import { CREATED_ASC, CREATED_DESC, EVALUATION_AGILE, EVALUATION_GENERIC, EVALUATION_ITERATIVE, EVALUATION_WATERFALL, projectTypes, PROJECT_STATUS_COMPLETED, PROJECT_STATUS_IN_PROGRESS, UPDATED_ASC, UPDATED_DESC } from '../constants/projectTypes';
import { RATING_MAPPING, MULTIPLICATION_FACTOR } from '../constants/evaluation/summary'
import Ajv from "ajv"
import { validProjectStructure } from '../config/importProjectStructure';
import { ADD_IMAGE_OPERATION_TYPE, DELETE_IMAGE_OPERATION_TYPE, FIXED_COST_TYPE, RANGE_COST_TYPE, RESOURCE_COST_TYPE, UPDATE_IMAGE_OPERATION_TYPE } from '../constants/evaluation/options';
import { fetchDaysBetween, fetchResourceCost, formatCost } from './common';

export const isProjectGeneric = (projectType) => {
    return projectType === EVALUATION_GENERIC;
}

export const isProjectAgile = (projectType) => {
    return projectType === EVALUATION_AGILE;
}

export const isProjectWaterfall = (projectType) => {
    return projectType === EVALUATION_WATERFALL;
}

export const isProjectIterative = (projectType) => {
    return projectType === EVALUATION_ITERATIVE;
}

export const fetchProjectTitle = (projectType) => {
    return projectTypes.find(project => project.type === projectType).title;
}

export const fetchDisplayStatus = (status) => {
    return status === PROJECT_STATUS_IN_PROGRESS ? 'IN PROGRESS' : 'COMPLETED';
}

export const updatedStatus = (status) => {
    return status.toUpperCase() === PROJECT_STATUS_IN_PROGRESS ? PROJECT_STATUS_COMPLETED : PROJECT_STATUS_IN_PROGRESS;
}

export const findCurrentProjectDetails = (currentprojectId, projects) => {
    return projects.find(project => project.id === currentprojectId)
};

export const countTotalPages = (totalData, elementsAllowed) => {
    return (Math.floor(totalData / elementsAllowed) + (totalData % elementsAllowed === 0 ? 0 : 1));
}

export const calculatePageStartIndex = (page, elementsAllowed) => {
    return (page - 1) * elementsAllowed;
}

export const calculatePageEndIndex = (page, elementsAllowed) => {
    return page * elementsAllowed;
}

export const isEvaluatorView = (view) => {
    return EVALUATOR_VIEW === view;
}

export const isEvaluationSummaryView = (view) => {
    return EVALUATION_SUMMARY === view;
}

export const isEvaluationComparison = (view) => {
    return EVALUATION_COMPARISON === view;
}

export const isEvaluationChart = (view) => {
    return EVALUATION_CHART === view;
}

export const isGridView = (view) => {
    return GRID_VIEW === view;
}

export const isListView = (view) => {
    return LIST_VIEW === view;
}

export const isCarouselView = (view) => {
    return CAROUSEL_VIEW === view;
}

export const hasData = (data) => {
    return data && data.length !== 0;
}

export const filterAndSortFeatures = (features, labels, sortBy, searchFeatureText, filterLabels, selectedEvaluator) => {
    const searchedFiltered = searchData(searchFeatureText, features);
    const filterLabelFeatures = filterByLabels(searchedFiltered, labels, filterLabels).filter(Boolean);
    return sortFeatures(filterLabelFeatures, sortBy, selectedEvaluator);
}

export const filterAndSortOptionFeatures = (features, labels, sortBy, searchFeatureText, filterLabels, optionId) => {
    const searchedFiltered = searchData(searchFeatureText, features);
    const filterLabelFeatures = filterByLabels(searchedFiltered, labels, filterLabels).filter(Boolean);
    return sortOptionFeatures(filterLabelFeatures, sortBy, optionId);
}

export const filterByLabels = (features, labels, filterLabels) => {
    if (filterLabels.length === 0) {
        return features;
    }
    const filterLabelIds = fetchLabelIdLabelTitle(labels, filterLabels);
    return features.map((feature) => {
        if (feature.labels && filterLabelIds.every(label => feature.labels.includes(label))) {
            return feature;
        }
    })
}

export const applyIterationFilter = (filterIterations, features) => {
    if (filterIterations.iterations.length === 0) {
        return features;
    }
    const iterations = filterIterations.iterations;
    const iterationsNumber = [];
    iterations.forEach(itr => {
        iterationsNumber.push(Number(itr.split(' ')[1]));
    })

    if (filterIterations.condition === INCLUDES_FILTER) {
        return features.filter(feature => {
            if (feature.deliveryVersion !== undefined && iterationsNumber.includes(feature.deliveryVersion)) {
                return feature;
            }
        })
    }
    return features.filter(feature => {
        if (feature.deliveryVersion === undefined || !iterationsNumber.includes(feature.deliveryVersion)) {
            return feature;
        }
    })
}

const searchData = (searchFeatureText, features) => {
    return searchFeatureText === '' ? features : features.filter(feature => feature.title.toLowerCase().includes(searchFeatureText.toLowerCase()));
}

export const sortFeaturesByAlphabeticallyDsc = (features) => {
    return features.sort((a, b) => b.title.toLowerCase().localeCompare(a.title.toLowerCase()));
}

export const sortFeaturesByAlphabeticallyAsc = (features) => {
    return features.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
}

export const sortFeaturesByEvaluationDesc = (features) => {
    return features.sort(function (a, b) {
        var nameA = calculateAverageRating(a.evaluatorsRating);
        var nameB = calculateAverageRating(b.evaluatorsRating);
        if (nameA > nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }
        return 0;
    });
}

export const sortFeaturesByEvaluationAsc = (features) => {
    return features.sort(function (a, b) {
        var nameA = calculateAverageRating(a.evaluatorsRating);
        var nameB = calculateAverageRating(b.evaluatorsRating);
        if (nameA > nameB) {
            return 1;
        }
        if (nameA < nameB) {
            return -1;
        }
        return 0;
    });
}

const sortFeatures = (features, sortBy, selectedEvaluator) => {
    const evaluatorId = selectedEvaluator ? selectedEvaluator.id : '';
    switch (sortBy) {
        case SORT_BY_ALPHABETICALLY_DSC:
            return sortFeaturesByAlphabeticallyDsc(features);
        case SORT_BY_ALPHABETICALLY_ASC:
            return sortFeaturesByAlphabeticallyAsc(features);
        case SORT_BY_RATING_HIGH_TO_LOW:
            return features.sort(function (a, b) {
                var nameA = a.evaluatorsRating.find(rate => rate.evaluatorId === evaluatorId).rating;
                var nameB = b.evaluatorsRating.find(rate => rate.evaluatorId === evaluatorId).rating;  // ignore upper and lowercase
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            });
        case SORT_BY_RATING_LOW_TO_HIGH:
            return features.sort(function (a, b) {
                var nameA = a.evaluatorsRating.find(rate => rate.evaluatorId === evaluatorId).rating;
                var nameB = b.evaluatorsRating.find(rate => rate.evaluatorId === evaluatorId).rating;  // ignore upper and lowercase
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA < nameB) {
                    return -1;
                }
                return 0;
            });
    }
    return features;
}

const sortOptionFeatures = (features, sortBy, optionId) => {
    switch (sortBy) {
        case SORT_BY_ALPHABETICALLY_DSC:
            return sortFeaturesByAlphabeticallyDsc(features);
        case SORT_BY_ALPHABETICALLY_ASC:
            return sortFeaturesByAlphabeticallyAsc(features);
        case SORT_BY_RATING_HIGH_TO_LOW:
            return features.sort(function (a, b) {
                var nameA = a.optionsRating.find(rate => rate.optionId === optionId).rating;
                var nameB = b.optionsRating.find(rate => rate.optionId === optionId).rating;
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            });
        case SORT_BY_RATING_LOW_TO_HIGH:
            return features.sort(function (a, b) {
                var nameA = a.optionsRating.find(rate => rate.optionId === optionId).rating;
                var nameB = b.optionsRating.find(rate => rate.optionId === optionId).rating;
                if (nameA > nameB) {
                    return 1;
                }
                if (nameA < nameB) {
                    return -1;
                }
                return 0;
            });
    }
    return features;
}

export const fetchLabelIdLabelTitle = (labels, labelTitles) => {
    if (labelTitles === null || labelTitles === undefined) {
        return [];
    }
    return labelTitles.map((title) => {
        const labelFound = labels.find((label) => label.title === title);
        return labelFound !== undefined ? labelFound.id : '';
    })
}

export const fetchLabelTitleLabelId = (labels, labelIds) => {
    if (labelIds === null || labelIds === undefined || labelIds.length === 0 || labels.length === 0) {
        return [];
    }
    return labelIds.map((id) => {
        const selectedLabel = labels.find((label) => label.id === id);
        return selectedLabel !== undefined ? selectedLabel.title : '';
    })
}

export const fetchRatingByEvaluatorId = (evaluatorId, evaluatorRatings) => {
    const evaluatorRating = evaluatorRatings.find((evaluator) => evaluator.evaluatorId === evaluatorId);
    return evaluatorRating === undefined ? 0 : evaluatorRating.rating;
}

export const fetchRatingByOptionId = (optionId, featureRatings) => {
    const featureRating = featureRatings.find((feature) => feature.optionId === optionId);
    return featureRating === undefined ? 0 : featureRating.rating;
}

export const fetchOptionRatings = (optionId, features) => {
    return features.map((feature) => feature.optionsRating.find(option => option.optionId === optionId).rating);
}

export const fetchFeaturesByFeatureId = (features, selectedFeaturesIds) => {
    return selectedFeaturesIds.map(id => { return (features.find(feature => feature.id === id)) });
}

export const fetchHighestDeliveryVersion = (features) => {
    let latestDeliveredVersion = 0;
    features.forEach((feature => {
        if (feature.deliveryVersion !== undefined && feature.deliveryVersion > latestDeliveredVersion) {
            latestDeliveredVersion = feature.deliveryVersion;
        }
    }))
    return latestDeliveredVersion;
}

export const isFeatureDelivered = (feature) => {
    if (feature.deliveryVersion === undefined || feature.deliveryVersion === 0) {
        return false;
    }
    return true;
}

export const calculateAverageRating = (arr) => {
    const { length } = arr;
    return arr.reduce((acc, val) => {
        return acc + (val.rating / length);
    }, 0).toFixed(2).replace(/\.0+$/, '');
}

export const sumAndGroup = (arr, whitelist) => {
    return arr.reduce(function (m, d) {
        if (!m[d.group]) {
            m[d.group] = {
                ...d,
                count: 1
            };
            return m;
        }
        whitelist.forEach(function (key) {
            m[d.group][key] += d[key];
        });
        m[d.group].count += 1;
        return m;
    }, {});
}

export const calculateGroupAverage = (arr, whitelist) => {
    return Object.keys(arr).map(function (k) {
        const item = arr[k];
        const itemAverage = whitelist.reduce(function (m, key) {
            m[key] = item[key] / item.count;
            return m;
        }, {})
        return {
            ...item, // Preserve any non white-listed keys
            ...itemAverage // Add computed averege for whitelisted keys
        }
    })
}

export const fetchTags = (option) => {
    let tags = [];
    if (option.technicalDebt) {
        tags.push(option.technicalDebt + ' technical debt')
    }
    if (option.squadAvailable !== undefined) {
        const tag = 'Squad is ' + (option.squadAvailable ? '' : 'not ') + 'available'
        tags.push(tag)
    }
    if (option.resourcesAvailable !== undefined) {
        const tag = 'Resources are ' + (option.resourcesAvailable ? '' : 'not ') + 'available'
        tags.push(tag)
    }
    return tags;
}

export const fetchOptionStrengthByOptionId = (optionsStrength, optionId) => {
    return optionsStrength.find(option => option.optionId === optionId);
}

export const calculateSolutionsStrength = (options, features, goals) => {
    const optionsFeaturePromise = calculateOptionNeedStrength(options, features);
    const optionsGoalPromise = calculateOptionGoalStrength(options, goals);
    return Promise.all([optionsFeaturePromise, optionsGoalPromise]);
}

export const mapRatingValues = (num) => RATING_MAPPING[num];


export const calculateOptionGoalStrength = (options, needs) => {
    return new Promise((resolve) => {
        const sumOfIndividulNeedEvaluatorRating = needs.map(need => {
            const sumOfEvaluatorsRating = need.evaluatorsRating.reduce((accumulator, currentValue) => {
                return accumulator + mapRatingValues(currentValue.rating)
            }, 0);
            return { needId: need.id, sumOfEvaluatorsRating }
        });

        const sumOfAllNeedsRating = sumOfIndividulNeedEvaluatorRating.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.sumOfEvaluatorsRating
        }, 0);

        const needImportance = sumOfIndividulNeedEvaluatorRating.map(need => {
            return { ...need, importance: Math.round((need.sumOfEvaluatorsRating / sumOfAllNeedsRating) * 100) }
        });

        const sumOfIndividulOptions = options.map((option) => {
            const sumOfRating = needs.reduce((accumulator, currentValue) => {
                const currentNeedImportance = needImportance.find((need) => need.needId === currentValue.id);
                const optionRating = fetchRatingByOptionId(option.id, currentValue.optionsRating)
                return accumulator + (mapRatingValues(optionRating) * currentNeedImportance.importance);
            }, 0);
            return { id: option.id, sumOfRating }
        })

        const sumOfNeedImpFactor = needImportance.reduce((accumulator, currentValue) => {
            return accumulator + (MULTIPLICATION_FACTOR * currentValue.importance);
        }, 0);

        const optionStrength = sumOfIndividulOptions.map(option => {
            const strength = Math.round((option.sumOfRating / sumOfNeedImpFactor) * 100)
            return { optionId: option.id, goalOptionStrength: Number.isNaN(strength) ? 0 : strength }
        });

        return resolve(optionStrength);
    });
}

export const calculateOptionNeedStrength = (options, needs) => {
    return new Promise((resolve) => {
        const sumOfIndividulNeedEvaluatorRating = needs.map(need => {
            const sumOfEvaluatorsRating = need.evaluatorsRating.reduce((accumulator, currentValue) => {
                return accumulator + mapRatingValues(currentValue.rating)
            }, 0);
            return { needId: need.id, sumOfEvaluatorsRating }
        });

        const sumOfAllNeedsRating = sumOfIndividulNeedEvaluatorRating.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.sumOfEvaluatorsRating
        }, 0);

        const needImportance = sumOfIndividulNeedEvaluatorRating.map(need => {
            return { ...need, importance: Math.round((need.sumOfEvaluatorsRating / sumOfAllNeedsRating) * 100) }
        });

        const sumOfIndividulOptions = options.map((option) => {
            const sumOfRating = needs.reduce((accumulator, currentValue) => {
                const currentNeedImportance = needImportance.find((need) => need.needId === currentValue.id);
                const optionRating = fetchRatingByOptionId(option.id, currentValue.optionsRating)
                return accumulator + (mapRatingValues(optionRating) * currentNeedImportance.importance);
            }, 0);
            return { id: option.id, sumOfRating }
        })

        const sumOfNeedImpFactor = needImportance.reduce((accumulator, currentValue) => {
            return accumulator + (MULTIPLICATION_FACTOR * currentValue.importance);
        }, 0);

        const optionStrength = sumOfIndividulOptions.map(option => {
            const strength = Math.round((option.sumOfRating / sumOfNeedImpFactor) * 100)
            return { optionId: option.id, featureOptionStrength: Number.isNaN(strength) ? 0 : strength }
        });

        return resolve(optionStrength);
    });
}

export const filterAndSortByProjects = (projects, searchProjectText, statusFilter, sortBy) => {
    const searchedFiltered = searchProject(projects, searchProjectText);
    const statusFiltered = filterProjectsByStatus(searchedFiltered, statusFilter);
    return sortProjects(statusFiltered, sortBy);
}

const filterProjectsByStatus = (projects, statusFilter) => {
    return statusFilter === 'ALL' ? projects : projects.filter(project => project.status.toLowerCase().includes(statusFilter.toLowerCase()));
}

const searchProject = (projects, searchProjectText) => {
    return searchProjectText === '' ? projects : projects.filter(project => project.idea.title.toLowerCase().includes(searchProjectText.toLowerCase()));
}

const sortProjects = (projects, sortBy) => {
    if (sortBy === CREATED_ASC) {
        return projects.sort(function (a, b) {
            if (a.createdOn > b.createdOn) {
                return -1;
            }
            if (a.createdOn < b.createdOn) {
                return 1;
            }
            return 0;
        });
    }
    else if (sortBy === CREATED_DESC) {
        return projects.sort(function (a, b) {
            if (a.createdOn > b.createdOn) {
                return 1;
            }
            if (a.createdOn < b.createdOn) {
                return -1;
            }
            return 0;
        });
    }
    else if (sortBy === UPDATED_ASC) {
        return projects.sort(function (a, b) {
            if (a.updatedOn > b.updatedOn) {
                return -1;
            }
            if (a.updatedOn < b.updatedOn) {
                return 1;
            }
            return 0;
        });
    } else if (sortBy === UPDATED_DESC) {
        return projects.sort(function (a, b) {
            if (a.updatedOn > b.updatedOn) {
                return 1;
            }
            if (a.updatedOn < b.updatedOn) {
                return -1;
            }
            return 0;
        });
    }
    else {
        return projects;
    }
}

export const isProjectJsonValid = (fileData) => {
    const ajv = new Ajv();
    const validate = ajv.compile(validProjectStructure);
    const valid = validate(fileData);
    if (!valid) { return false; }
    return true;
}

export const fetchOptionTitleByOptionId = (optionId, options) => {
    return options.find(option => option.id === optionId).summary;
}

export const fetchEvaluatorsName = (evaluators) => {
    if (evaluators.length === 0) {
        return '-';
    }
    return evaluators.map(evaluator => {
        return (evaluator.name + ' (' + evaluator.group) + ')'
    }).join(', ');
}

export const checkOptionImageOperationType = (data, defaultOptionImage) => {
    if (data !== defaultOptionImage) {
        if (defaultOptionImage === '') {
            return ADD_IMAGE_OPERATION_TYPE;
        } else if (defaultOptionImage !== data) {
            return UPDATE_IMAGE_OPERATION_TYPE;
        } else if (defaultOptionImage !== '' && !data) {
            return DELETE_IMAGE_OPERATION_TYPE;
        }
    }
}

export const isStepReachable = (project, stepIndex, currentActiveStep) => {
    if (stepIndex === currentActiveStep) {
        return true;
    }
    switch (stepIndex) {
        case 0: {
            return true;
        }
        case 1: {
            return hasData(project.features);
        }
        case 2: {
            return hasData(project.features);
        }
        case 3: {
            return hasData(project.features);
        }
        case 4: {
            return hasData(project.features) && hasData(project.options);
        }
        case 5: {
            return hasData(project.goals) && hasData(project.options);
        }
        case 6: {
            return hasData(project.features) && hasData(project.options);
        }
        default: {
            return false;
        }
    }
}

export const translateProjectSummaryExport = (option, selectedOption, optionStrength) => {
    const strength = optionStrength.find((optionStrength) => optionStrength.optionId === option.id);
    return {
        summary: option.summary,
        id: option.id,
        description: option.description,
        tags: fetchTags(option),
        keyBenefits: option.keyBenefits ? option.keyBenefits : [],
        constraints: option.constraints ? option.constraints : [],
        cost: formatOptionCost(option),
        timeframe: formatOptionTimeframe(option),
        featureRating: strength.featureOptionStrength,
        goalRating: strength.goalOptionStrength,
        isSelected: selectedOption === '' ? false : option.id === selectedOption,
        optionImage: option.image ? option.image : ''
    }
}

export const translateDetailScoreExport = (option, selectedOption, optionStrength) => {
    const strength = optionStrength.find((optionStrength) => optionStrength.optionId === option.id);
    return {
        summary: option.summary,
        description: option.description,
        tags: fetchTags(option),
        keyBenefits: option.keyBenefits ? option.keyBenefits : [],
        constraints: option.constraints ? option.constraints : [],
        cost: formatOptionCost(option),
        timeframe: formatOptionTimeframe(option),
        featureRating: strength.featureOptionStrength,
        goalRating: strength.goalOptionStrength,
        isSelected: selectedOption === '' ? false : option.id === selectedOption,
        optionImage: option.image ? option.image : ''
    }
}

export const formatOptionTimeframe = (option) => {
    if (option.projectType !== EVALUATION_AGILE) {
        return fetchDaysBetween(option.startDate, option.endDate) + ' days'
    } else {
        return option.duration + ' sprints';
    }
}

export const formatOptionCost = (option) => {
    if (option.cost.type === FIXED_COST_TYPE) {
        return '$ ' + formatCost(option.cost.value.fixedCost);
    }
    if (option.cost.type === RANGE_COST_TYPE) {
        return '$ ' + formatCost(option.cost.value.minPrice) + ' - $ ' + formatCost(option.cost.value.maxPrice);
    }
    if (option.cost.type === RESOURCE_COST_TYPE) {
        return '$ ' + formatCost(fetchResourceCost(option.cost.value.resourceCost, fetchDaysBetween(option.startDate, option.endDate)));
    }
}