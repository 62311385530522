import { defaultVertical } from '../constants/theming';

export default {
  theming: {
    uploadData: [],
    step: 0,
    tabularData: {
      firstRowAsHeader: true,
      data: [],
      columns: [],
      canProceed: false,
    },
    sentimentAnalysis: {
      error: {},
      data: {},
      loading: false,
    },
    industryVertical: defaultVertical.title,
  },
  nps: {
    uploadData: [],
    step: 0,
    tabularData: {
      firstRowAsHeader: true,
      data: [],
      columns: [],
      canProceed: false,
    },
    sentimentAnalysis: {
      error: '',
      data: {},
      loading: false,
    },
  },
  initialProjects: {
    projects: [],
    global: {
      currentProjectId: ''
    }
  },
  plan: null,
  user: {

  },
  global: {
    isLoading: false,
  },
};
