import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Container, Box } from '@material-ui/core';
import routes from '../../constants/routes'

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.background.feijoa,
        padding: theme.spacing(1),
    }
}));

const DashboardFooter = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <footer className={classes.footer}>
                <Container maxWidth="md">
                    <Box mt={3}>
                        <Typography variant="body2" color="textSecondary" align="center">
                            {'© '}
                            {new Date().getFullYear()}
                            {' Feijoa8 Limited. All rights reserved. '}
                            <br />
                            <Link color="inherit" href={routes.termsCondtions.url}>
                                Terms & Conditions
                            </Link>
                            {' | '}
                            <Link color="inherit" href={routes.privacy.url}>
                                Privacy Policy
                            </Link>
                        </Typography>
                    </Box>
                </Container>
            </footer>
        </React.Fragment>
    );
}

export default DashboardFooter;
