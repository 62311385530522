import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

class BarChart extends Component {
    getChartOptions = (data) => {
        return {
            series: data.series,
            options: {
                chart: {
                    type: 'bar',
                    height: 430
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        width: 1,
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    colors: ['#fff']
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: data.labels
                },
                yaxis: {
                    show: true,
                    showAlways: true,
                    labels: {
                        show: true,
                        style: {
                            fontSize: '14px'
                        }
                    }
                }
            },
        };
    }
    render() {
        const chartConfig = this.getChartOptions(this.props);
        return (
            <div>
                <Chart options={chartConfig.options} series={chartConfig.series} type="bar" />
            </div>
        )
    }
}
BarChart.propTypes = {
    series: PropTypes.array,
    labels: PropTypes.array
};

export default BarChart;