import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        justifyContent: 'center',
        margin: 'inherit'
    },
    tableHead: {
        backgroundColor: theme.palette.colors.feijoa
    },
    greenTick: {
        color: theme.palette.success.main
    },
    redCross: {
        color: theme.palette.error.main
    },
    rowData: {
        display: 'flex',
        justifyContent: 'center'
    },
    avatar: {
        backgroundColor: '#4caf50'
    },
    tableContainer: {
        overflowX: 'initial'
    },
    '@global': {
        ".MuiTableCell-stickyHeader": {
            top: "80px",
            left: "0",
            zIndex: 2,
            position: "sticky"
        }
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.colors.feijoa
    },
}))(TableCell);

const formatRowData = (rowData) => {
    const classes = useStyles();
    if (rowData === "true") {
        return <CheckCircleIcon fontSize="large" className={classes.greenTick} />
    }
    if (rowData === "false") {
        return <CancelIcon fontSize="large" className={classes.redCross} />
    }
    if (typeof rowData === 'number') {
        return <Avatar className={classes.avatar} component="span">{rowData}</Avatar>
    }
    return rowData;
}

const features = [
    { "title": "Idea allowed", "free": 1, "lite": 4, "pro": "Refer above" },
    { "title": "Storage", "free": "Your device", "lite": "Your device", "pro": "Secured on cloud" },
    { "title": "Autosave idea", "free": "true", "lite": "true", "pro": "true" },
    { "title": "Platform", "free": "Web", "lite": "Android and Apple store", "pro": "Web" },
    { "title": "Features allowed", "free": 20, "lite": 20, "pro": 100 },
    { "title": "Feature Evaluators(contributors)", "free": 3, "lite": 3, "pro": 5 },
    { "title": "Outcomes allowed", "free": 5, "lite": 5, "pro": 20 },
    { "title": "Outcome Evaluators(contributors)", "free": 3, "lite": 3, "pro": 5 },
    { "title": "Options allowed", "free": 3, "lite": 3, "pro": 5 },
    { "title": "Idea evaluation summary", "free": "Basic", "lite": "Basic", "pro": "Advanced" },
    { "title": "Export Idea summary to PDF", "free": "In App Purchase", "lite": "true", "pro": "true" },
    { "title": "Export Idea summary to Excel", "free": "false", "lite": "true", "pro": "true" },
    { "title": "Manage number of projects on plan", "free": "false", "lite": "false", "pro": "true" },
    { "title": "Dashboard view for plan and projects", "free": "false", "lite": "false", "pro": "true" },
    { "title": "Import/Export", "free": "false", "lite": "false", "pro": "true" },
    { "title": "Export features to JIRA", "free": "false", "lite": "false", "pro": "true" },
    { "title": "Export features to Azure Devops", "free": "false", "lite": "false", "pro": "true" }
]

const PlanFeatures = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography className={classes.title} color='primary' variant="h3">All Features</Typography>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader aria-label="simple table" >
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <StyledTableCell align="left"><Typography variant="h5">Feature</Typography></StyledTableCell>
                            {/* <StyledTableCell align="center"><Typography variant="h5">Free</Typography></StyledTableCell> */}
                            <StyledTableCell align="center"><Typography variant="h5">Lite</Typography></StyledTableCell>
                            <StyledTableCell align="center"><Typography variant="h5">Pro</Typography></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {features.map((feature, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row"><Typography variant="body1">{feature.title}</Typography></StyledTableCell>
                                {/* <StyledTableCell align="center"><Typography className={classes.rowData}>{formatRowData(feature.free)}</Typography></StyledTableCell> */}
                                <StyledTableCell align="center"><Typography className={classes.rowData}>{formatRowData(feature.lite)}</Typography></StyledTableCell>
                                <StyledTableCell align="center"><Typography className={classes.rowData}>{formatRowData(feature.pro)}</Typography></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment >
    );
};

export default PlanFeatures;
