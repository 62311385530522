
import React from 'react';
import DashboardBody from '../../../components/Body/DashboardBody';
import { Container } from '@material-ui/core';
import { MetaDashboardHome } from './Seo/MetaDashboardHome';
import DashboardHomeTitle from '../../../components/Body/DashboardHomeTitle';
import { useMsal, useAccount } from "@azure/msal-react";
import { fetchName } from '../../../utils/identity';
import SummaryItems from './SummaryItems';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions/projectActions';
import ProjectsSummary from './ProjectsSummary';

const Dashboard = ({ actions }) => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [userProjects, setUserProjects] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    actions.fetchProjects()
      .then((data) => {
        setUserProjects(data);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <DashboardBody metaData={MetaDashboardHome}>
        <Container maxWidth="lg">
          <DashboardHomeTitle name={fetchName(account)} />
          <SummaryItems projects={userProjects} isLoading={isLoading} />
          <ProjectsSummary projects={userProjects} isLoading={isLoading} />
        </Container>
      </DashboardBody>
    </>
  );
};

Dashboard.propTypes = {
  actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(Dashboard);
