import React from "react";
import { hot } from "react-hot-loader";
import { Route, Switch, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AboutPage from "./About/AboutPage";
import HomePage from "./Home/HomePage";
import Faq from "./Faq";
import NotFoundPage from "./Error/NotFoundPage";
import CeciPage from "./Ceci/Home/HomePage";
import TermsConditionsPage from "./TermsConditions/TermsConditionsPage";
import FeedbackPage from "./Feedback";
import routes, { admin } from '../constants/routes'
import ContactPage from "./Contact/ContactPage";
import CeciPlan from "./Ceci/Plan/PlanPage";
import CeciUseCases from "./Ceci/UseCases/UseCasesPage";
import CeciFaq from './Ceci/Faq';
import Dashboard from "./Dashboard/home";
import SecuredDashboardLayout from './../components/layouts/SecuredDashboardLayout';
import Accounts from "./Dashboard/accounts";
import Projects from "./Dashboard/projects/viewProjects";
import Billing from "./Dashboard/billing";
import NewProjectType from './Dashboard/projects/projectType';
import ImportProject from './Dashboard/projects/importProject';
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import EditProject from "./Dashboard/projects/editProject";
import { b2cPolicies } from "../config/authConfig.js";
import AccessForbidden from "../components/Common/AccessForbidden";
import DashboardHeaderOnlyLayout from "../components/layouts/DashboardHeaderOnlyLayout";
import SecuredHeaderOnlyLayout from "../components/layouts/SecuredDashboardLayout/SecuredHeaderOnlyLayout";
import NotAuthorized from "../components/Common/NotAuthorized";
import PlanSuccessBought from "./Dashboard/Plan/success";
import LandingPage from "./Dashboard/landing";
import CeciStudio from "./CeciStudio";
import { ThemeProvider } from '@material-ui/core/styles';
import adminTheme from '../mainTheme';
import siteTheme from '../updatedTheme';

const App = () => {
  const { instance } = useMsal();
  const { pathname } = useLocation();
  const [theme, setTheme] = React.useState(siteTheme);

  React.useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          instance.loginPopup(b2cPolicies.authorities.forgotPassword)
            .catch(e => {
              console.log(e);
            });
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (event && event.payload) {
          if (event.payload.idTokenClaims["tfp"] === b2cPolicies.names.forgotPassword) {
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  React.useEffect(() => {
    const isAdminRoute = pathname.includes('admin');
    document.body.classList.toggle('home-site', !isAdminRoute);
    setTheme(isAdminRoute ? adminTheme : siteTheme);
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path={routes.home.url} component={HomePage} />
        <RouteWrapper path={admin.dashboard.url} requirePlan={true} component={Dashboard} layout={SecuredDashboardLayout} />
        <RouteWrapper path={admin.importProject.url} requirePlan={true} component={ImportProject} layout={SecuredDashboardLayout} />
        <RouteWrapper path={admin.newProjectType.url} requirePlan={true} component={NewProjectType} layout={SecuredDashboardLayout} />
        <RouteWrapper exact path={admin.projects.url} requirePlan={true} component={Projects} layout={SecuredDashboardLayout} />
        <RouteWrapper exact path={admin.editProject.url} requirePlan={true} component={EditProject} layout={SecuredDashboardLayout} />
        <RouteWrapper exact path={admin.newProject.url} requirePlan={true} component={EditProject} layout={SecuredDashboardLayout} />
        <RouteWrapper path={admin.accounts.url} requirePlan={false} component={Accounts} layout={SecuredDashboardLayout} />
        <RouteWrapper path={admin.billing.url} requirePlan={true} component={Billing} layout={SecuredDashboardLayout} />
        <RouteWrapper path={routes.successBuyPlan.url} requirePlan={false} component={PlanSuccessBought} layout={SecuredHeaderOnlyLayout} />
        <RouteWrapper path={admin.landingPage.url} requirePlan={false} component={LandingPage} layout={SecuredHeaderOnlyLayout} />
        <RouteWrapper path={routes.accessDenied.url} requirePlan={false} component={AccessForbidden} layout={SecuredDashboardLayout} />
        <RouteWrapper path={routes.notAuthorized.url} requirePlan={false} component={NotAuthorized} layout={DashboardHeaderOnlyLayout} />
        <Route path={routes.about.url} component={AboutPage} />
        <Route path={routes.faq.url} component={Faq} />
        <Route path={routes.termsCondtions.url} component={TermsConditionsPage} />
        <Route path={routes.feedback.url} component={FeedbackPage} />
        <Route path={routes.contact.url} component={ContactPage} />
        <Route path={routes.ceciFaq.url} component={CeciFaq} />
        <Route path={routes.ceciPlans.url} component={CeciPlan} />
        <Route path={routes.ceciUseCases.url} component={CeciUseCases} />
        <Route path={routes.ceciHome.url} component={CeciPage} />
        <Route path={routes.ceciStudio.url} component={CeciStudio} />
        <Route component={NotFoundPage} />
      </Switch>
    </ThemeProvider>
  );
}

function RouteWrapper({
  component: Component,
  layout: Layout,
  requirePlan,
  ...rest
}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...{ ...props, requirePlan }}>
        <Component {...props} />
      </Layout>
    } />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  requirePlan: PropTypes.bool
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
