import React from 'react';
import DashboardBody from '../../../components/Body/DashboardBody';
import { MetaLandingPage } from './Seo/MetaLandingPage';
import { Container, Typography } from '@material-ui/core';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { admin } from '../../../constants/routes';
import WaitingLoader from "../../../components/Loader/WaitingLoader";
import * as actions from '../../../actions/planActions';
import { Redirect } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import NotFound from '../../../components/Common/NotFound';
import { useMsal, useAccount } from "@azure/msal-react";
import { hasUserAcceptedTermsAndConditions } from '../../../utils/identity';
import UpdateTermsAndConditions from '../../../components/Dialog/UpdateTermsAndConditions';
import { TERMS_CONDTIONS_LOCAL_STORAGE_KEY } from '../../../constants/static';

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: 'center',
    margin: theme.spacing(5)
  }
}));

const LandingPage = (props) => {
  const { actions } = props;
  const classes = useStyles();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [hasAcceptedTNC, setHasAcceptedTNC] = React.useState(true);
  const [openUpdatedTNCDialog, setOpenUpdatedTNCDialog] = React.useState(false);

  React.useEffect(() => {
    checkName();
    if (hasUserAcceptedTermsAndConditions(account)) {
      setHasAcceptedTNC(true);
      actions.fetchPlanData()
        .catch(() => { setHasError(true) })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setHasAcceptedTNC(false);
      setOpenUpdatedTNCDialog(true);
    }
  }, [hasAcceptedTNC]);

  const checkName = () => {
    if (account.name === '' || account.name === 'unknown') {
      actions.updateDisplayName();
    }
  }

  const acceptUpdatedTermsAndConditions = () => {
    actions.updateTermsAndConditions()
      .then(() => {
        setOpenUpdatedTNCDialog(false);
        setHasAcceptedTNC(true);
        window.localStorage.setItem(TERMS_CONDTIONS_LOCAL_STORAGE_KEY, true);
      })
  }

  return (
    <DashboardBody metaData={MetaLandingPage}>
      <Container maxWidth="lg">
        {loading && <div className={classes.main}>
          <Typography variant="h4">Fetching your details</Typography>
          <WaitingLoader />
        </div>
        }
        <UpdateTermsAndConditions open={openUpdatedTNCDialog}
          onCancel={() => setOpenUpdatedTNCDialog(false)} onAccept={acceptUpdatedTermsAndConditions} />
        {!loading && !hasError && <Redirect to={admin.dashboard.url} />}
        {!loading && hasError && <NotFound image={"/static/svg/dashboard/5xxError.svg"}
          imageAlt={"Error"}
          title={"Error while fetching data"}
          subTitle={"We have got an error while your fetching data. Try refreshing the page, if issue persists please try logging in again after some time"} />}
      </Container>
    </DashboardBody>
  );
};

LandingPage.propTypes = {
  actions: PropTypes.any
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(LandingPage);