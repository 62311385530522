import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types'

export default function DisplayStaticTextbox({ label, name, value, helperText }) {
    return (
        <TextField
            fullWidth
            label={label}
            name={name}
            helperText={helperText}
            disabled
            value={value}
            variant="outlined"
        />
    );
}
DisplayStaticTextbox.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    helperText: PropTypes.string
};