import React from 'react';
import Header from '../../components/HeaderV2/Header';
import HeaderLinks from '../../components/HeaderV2/HeaderLinks';
import { feijoa8Menu } from '../../constants/routes';
import Footer from '../../components/Footer/Footer';
import Body from '../../components/Body';
import FeedbackForm from './FeedbackForm';
import { MetaFeedbackPage } from './Seo/MetaFeedbackPage';
import MetaTags from '../../components/Seo/MetaTags';
import { Divider } from '@material-ui/core';
import { SectionContainer } from '../../components/Body/Containers';

const Feedback = () => {
  return (
    <>
      <Header
        brand="feijoa8"
        rightLinks={<HeaderLinks links={feijoa8Menu} />}
        fixed
      />
      <MetaTags {...MetaFeedbackPage} />
      <Body title={MetaFeedbackPage.pageTitle}>
        <Divider />
        <SectionContainer>
          <FeedbackForm />
        </SectionContainer>
      </Body>
      <Footer />
    </>
  );
};

export default Feedback;
