export const MetaFaq = {
    title: 'Frequently asked questions - CECI',
    pageTitle: 'Frequently asked questions',
    meta: [
      {
        name: 'title',
        content: 'Frequently asked questions - CECI',
      },
      {
        name: 'description',
        content: 'FAQ related to CECI which will answer maximum all your questions',
      },
      {
        name: 'keywords',
        content: 'CECI,Frequently asked questions,faq',
      },
      {
        name: 'robots',
        content: 'index, follow',
      }
    ],
  };
  