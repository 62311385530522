import React from 'react';
import idx from 'idx';
import { Box, Container, Grid, Paper, Typography, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EvaluationStepperTitle from '../../../../components/HeaderV2/EvaluationStepperTitle';
import { EVALUATION_SUMMARY_STAGE, SUMMARY_VIEW } from '../../../../constants/evaluation/evaluationMetaData';
import * as actions from '../../../../actions/evaluationAction';
import { calculateSolutionsStrength, findCurrentProjectDetails, fetchOptionStrengthByOptionId, isCarouselView, isGridView, isListView, updatedStatus } from '../../../../utils/projectUtil';
import { bindActionCreators } from 'redux';
import StepperNavigtion from '../../../../components/Common/StepperNavigtion';
import SummaryCard from '../../../../components/Card/SummaryCard';
import ViewsButtonGroup from '../../../../components/Button/ViewsButtonGroup';
import OptionSummaryTable from '../../../../components/Tables/OptionSummaryTable';
import OptionCarouselComponent from '../../../../components/Option/OptionCarouselComponent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import IterativeExport from '../../../../components/Export/IterativeExport';
import { PROJECT_STATUS_IN_PROGRESS } from '../../../../constants/projectTypes';
import IdeaSummary from '../../../../components/Accordion/IdeaSummary';
import { tourSteps } from '../../../../constants/evaluation/summary';

const useStyles = makeStyles((theme) => ({
    titleRoot: {
        padding: theme.spacing(2),
        boxShadow: "rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px",
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
        borderRadius: theme.spacing(2)
    },
    greenTick: {
        color: green[600]
    }
}));
function IterativeEvaluationSummary({ projectType, selectedOption, status, idea, options, features, goals, featureEvaluators, goalEvaluators, actions }) {
    const classes = useStyles();

    const [evaluationView, setEvaluationView] = React.useState(SUMMARY_VIEW[0].value);
    const [optionsStrength, setOptionsStrength] = React.useState([]);

    const handleBack = () => {
        actions.decrementStep();
    };

    const handleSelectedOption = (optionId) => {
        actions.updateSelectedOption(optionId)
    }

    const handleEvaluationCompleted = () => {
        actions.updateEvaluationStatus(updatedStatus(status));
    }

    React.useEffect(() => {
        calculateSolutionsStrength(options, features, goals)
            .then((response) => {
                const featureOptionStrength = response[0];
                const goalOptionStrength = response[1];
                const needOptionStrength = featureOptionStrength.map((item, i) => {
                    if (item.optionId === goalOptionStrength[i].optionId) { return Object.assign({}, item, goalOptionStrength[i]) }
                });
                setOptionsStrength(needOptionStrength);
            })
    }, [features, goals, options])

    return (
        <React.Fragment>
            <Container maxWidth={false}>
                <Paper className={classes.titleRoot}>
                    <EvaluationStepperTitle stage={EVALUATION_SUMMARY_STAGE} projectType={projectType} steps={tourSteps} />
                    <Grid container spacing={3} align="center" justify="space-between">
                        <Grid item id="ideaDetails">
                            <Typography variant="h5">{idea.title}</Typography>
                            {idea.description !== '' && <Typography color="textSecondary">{idea.description}</Typography>}
                        </Grid>
                        <Grid item id="evaluationStatus">
                            {status.toUpperCase() !== PROJECT_STATUS_IN_PROGRESS && <IconButton onClick={() => handleEvaluationCompleted()}>
                                <CheckCircleIcon className={classes.greenTick} /> <Typography variant="body1"> Evaluation is completed</Typography>
                            </IconButton>}
                            {status.toUpperCase() === PROJECT_STATUS_IN_PROGRESS && <Button variant="outlined" size="large" onClick={() => handleEvaluationCompleted()}>Mark evaluation as completed</Button>}
                        </Grid>
                    </Grid>
                </Paper>
                <Box mt={2} mb={2}>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item id="exportData">
                            <IterativeExport projectType={projectType} idea={idea} features={features} goals={goals} options={options}
                                selectedOption={selectedOption} strength={optionsStrength} featureEvaluators={featureEvaluators} goalEvaluators={goalEvaluators} />
                        </Grid>
                        <Grid item id="evaluationViews">
                            <ViewsButtonGroup value={evaluationView} onChange={(e, value) => {
                                if (value !== null) {
                                    setEvaluationView(value)
                                }
                            }} viewOptions={SUMMARY_VIEW} />
                        </Grid>
                    </Grid>
                </Box>
                <IdeaSummary id="ideaSummary" idea={idea} />
                <Box mt={1}>
                    <Typography variant="body2" color="textSecondary">{'* Values showed in gauge indicate the strength of option to achieve the feature/goal '}</Typography>
                </Box>
                <Box mt={1} mb={2}>
                    {isGridView(evaluationView) && optionsStrength.length !== 0 &&
                        <Grid container spacing={3}>
                            {options.map((option, index) => {
                                const currentOptionStrength = fetchOptionStrengthByOptionId(optionsStrength, option.id);
                                return (
                                    <Grid item key={index} id={index} xs={12} sm={6} lg={6} xl={4}>
                                        <SummaryCard projectType={projectType} option={option} index={index} id={`card_${index}`} key={index}
                                            selectedOption={selectedOption} updateSelectedOption={handleSelectedOption} hasGoals={goals.length > 0}
                                            strength={currentOptionStrength} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    }
                    {isListView(evaluationView) && optionsStrength.length !== 0 && <OptionSummaryTable hasGoals={goals.length > 0} options={options} projectType={projectType} selectedOption={selectedOption} updateSelectedOption={handleSelectedOption} strength={optionsStrength} />}
                    {isCarouselView(evaluationView) && optionsStrength.length !== 0 && <OptionCarouselComponent options={options} projectType={projectType} hasGoals={goals.length > 0} strength={optionsStrength} />}
                </Box>
                <StepperNavigtion handleBack={handleBack} hideNextButton={true} skipStep={false} showNextButton={false} />
            </Container>
        </React.Fragment>
    );
}

IterativeEvaluationSummary.propTypes = {
    projectType: PropTypes.string,
    selectedOption: PropTypes.string,
    status: PropTypes.string,
    idea: PropTypes.object,
    options: PropTypes.array,
    goals: PropTypes.array,
    features: PropTypes.array,
    featureEvaluators: PropTypes.array,
    goalEvaluators: PropTypes.array,
    actions: PropTypes.object
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        projectType: currentProjectData.type || '',
        selectedOption: currentProjectData.selectedOption || '',
        status: currentProjectData.status || '',
        idea: idx(currentProjectData, _ => _.idea) || {},
        options: idx(currentProjectData, _ => _.options) || [],
        goals: idx(currentProjectData, _ => _.goals) || [],
        features: idx(currentProjectData, _ => _.features) || [],
        featureEvaluators: idx(currentProjectData, _ => _.featureEvaluators) || [],
        goalEvaluators: idx(currentProjectData, _ => _.goalEvaluators) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IterativeEvaluationSummary);