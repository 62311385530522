import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  feedbackContainer: {
    width: '100%',
    '& iframe': {
      borderWidth: '0px',
      '& body': {
        backgroundColor: theme.palette.colors.white,
      },
    },
  },
}));

const googleForm =
  '<iframe width="100%" height="1100px" scrolling="yes" src="https://docs.google.com/forms/d/e/1FAIpQLSf-UKkj3toOrZed-06DOm3N4r5bNNkmPGxHO5hL6XbXs2jtqQ/viewform"></iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
    />
  );
}

Iframe.propTypes = {
  iframe: PropTypes.any,
};

function FeedbackForm() {
  const classes = useStyles();
  return (
    <div className={classes.feedbackContainer}>
      <Iframe iframe={googleForm} />
    </div>
  );
}

export default FeedbackForm;
