import { isEmpty } from 'ramda';
import { userPlanState } from '../constants/static';
import moment from 'moment';

const isBillingPeriodActive = (date) => {
    const currentTime = moment().unix();
    return currentTime < date;
}

export const isUserAllowedToViewDashboard = (plan) => {
    const userCurrentPlanState = planState(plan);
    return userCurrentPlanState !== userPlanState.DELETE_USER_NO_ACTIVE_PLAN;
}

export const isUserAllowedToCreateProject = (plan) => {
    const userCurrentPlanState = planState(plan);
    return userCurrentPlanState !== userPlanState.DELETE_USER_NO_ACTIVE_PLAN &&
        userCurrentPlanState !== userPlanState.DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED;
}

export const isUserAllowedToUpgradePlan = (plan) => {
    const userCurrentPlanState = planState(plan);
    return userCurrentPlanState === userPlanState.DELETE_USER_ACTIVE_PLAN ||
        userCurrentPlanState === userPlanState.DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE ||
        userCurrentPlanState === userPlanState.UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE;
}

export const planState = (plan) => {
    if (!plan.deleteUser) {
        if (isEmpty(plan)) {
            return userPlanState.DELETE_USER_NO_ACTIVE_PLAN;
        } else if (plan.subscriptionStatus.toLowerCase() === 'active' && plan.subscriptionCancelOnDate === null) {
            return userPlanState.DELETE_USER_ACTIVE_PLAN;
        } else if (plan.subscriptionStatus.toLowerCase() === 'active' && isBillingPeriodActive(plan.subscriptionCancelOnDate)) {
            return userPlanState.DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE;
        } else {
            return userPlanState.DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED;
        }
    } else {
        if (plan.subscriptionStatus.toLowerCase() === 'active' && isBillingPeriodActive(plan.subscriptionCancelOnDate)) {
            return userPlanState.UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE;
        } else {
            return userPlanState.UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED;
        }
    }
}

export const isPlanCancelledAndBillingPeriodActive = (userState) => {
    return userState === userPlanState.DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE || userState === userPlanState.UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ACTIVE;
}

export const isPlanCancelledAndBillingPeriodEnded = (userState) => {
    return userState === userPlanState.DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED || userState === userPlanState.UNDELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED;
}