import React from 'react';
import { loginRequest } from "./../../../config/authConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import LoginLoading from './LoginLoading';
import HomePage from "./../../../containers/Ceci/Home/HomePage";
import { InteractionType } from "@azure/msal-browser";
import PropTypes from "prop-types";
import DashboardHeaderOnlyLayout from "../DashboardHeaderOnlyLayout";
import Fullpage from '../../Loader/Fullpage';
import OperationSnackbar from '../../Alert/OperationSnackbar';
import * as actions from '../../../actions/planActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const SecuredHeaderOnlyLayout = (props) => {
    const { children, isGlobalLoading, loadingGlobalText, globalSeverity, globalMessage, actions } = props;

    const authRequest = {
        ...loginRequest
    };

    const handleClose = () => {
        actions.setSeverityStatus('', '');
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Popup}
            authenticationRequest={authRequest}
            errorComponent={HomePage}
            loadingComponent={LoginLoading}>
            <DashboardHeaderOnlyLayout>
                {isGlobalLoading && <Fullpage loadingText={loadingGlobalText} />}

                <OperationSnackbar open={globalSeverity !== '' && globalMessage !== ''} handleClose={handleClose}
                    severity={globalSeverity} message={globalMessage} />
                {children}
            </DashboardHeaderOnlyLayout>
        </MsalAuthenticationTemplate>
    );
};

SecuredHeaderOnlyLayout.propTypes = {
    children: PropTypes.node,
    isGlobalLoading: PropTypes.bool,
    loadingGlobalText: PropTypes.string,
    globalSeverity: PropTypes.string,
    globalMessage: PropTypes.string,
    actions: PropTypes.any
};

function mapStateToProps(state) {
    return {
        isGlobalLoading: state.global.isLoading,
        loadingGlobalText: state.global.loadingText || '',
        globalSeverity: state.global.severity || '',
        globalMessage: state.global.message || ''
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SecuredHeaderOnlyLayout);