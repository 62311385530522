import React from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import SignInButton from "./SignInButton";
import SignOutButton from "./SignOutButton";

const SignInSignOutButton = () => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <SignOutButton />;
    } else {
        return <SignInButton />;
    }
}

export default SignInSignOutButton;