export const tourSteps = [
    {
        target: '#stepTitle',
        title: 'Goal evaluation',
        content: 'Goal evaluation is where we evaluate how well a solution addresses the Goal need or requirements. The key objective is to have an option comparison presented at Summary across the options for stakeholders and decision makers',
        disableBeacon: true
    }, {
        target: '#searchFilterSortGoal',
        title: 'Search, filter and sort goal',
        content: 'You can apply filters and sort using these fields',
        disableBeacon: true
    }, {
        target: '#exportGoals',
        title: 'Export goal evaluation',
        content: 'Click here if you want to export a CSV file that shows your scoring.',
        disableBeacon: true
    }, {
        target: '#evaluationViews',
        title: 'Evaluation views',
        content: 'There are three presentation modes; Card – shows each card and you need to select which option you are evaluating. List – shows all options on the horizontal access with all goals shown vertically. Great for side by side evaluation. Graph – shows a simple read only view of option performance (based upon entry in Card or List views) which can be exported',
        disableBeacon: true
    }, {
        target: '#options',
        title: 'Options',
        content: 'When in Card mode, click here switch between the options being evaluated',
        disableBeacon: true
    }, {
        target: '#optionSummary',
        title: 'Option summary',
        content: 'If you need to see more detail on the option being evaluated, click here',
        disableBeacon: true
    }, {
        target: '#card_0',
        title: 'Goal',
        content: 'Score your goal here. Note that the star rating use a Fibonacci sequence for scoring which forces distribution in the results and ensure that high value items are emphasised in the outcome',
        disableBeacon: true
    },
    {
        target: '#nextButton',
        title: 'Next',
        content: 'Once you have added your goals, click Next to advance',
        disableBeacon: true
    }];