import React from 'react';
import DashboardBody from '../../../components/Body/DashboardBody';
import DashboardTitle from '../../../components/Body/DashboardTitle';
import { MetaDashboardAccounts } from './Seo/MetaDashboardAccounts';
import { Container } from '@material-ui/core';
import General from './general';
import { admin } from '../../../constants/routes';

const Accounts = () => {
  let breadcrumb = [{ title: admin.dashboard.label, link: admin.dashboard.url }]
  return (
    <DashboardBody metaData={MetaDashboardAccounts}>
      <Container maxWidth="lg">
        <DashboardTitle title={admin.accounts.label} breadcrumbs={breadcrumb} />
        <General />
      </Container>
    </DashboardBody>
  );
};

export default Accounts;
