import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types'
import { isNumber, formatCost } from '../../utils/common';

const CostTextbox = (props) => {

    const { label, value, handleChange, ...rest } = props;

    return (
        <TextField
            required
            {...rest}
            label={label}
            variant="outlined"
            helperText={"includes GST."}
            value={formatCost(value)}
            InputProps={{
                startAdornment: <InputAdornment position="start"> $</InputAdornment>
            }}
            onChange={(e) => {
                const cost = e.target.value.replaceAll(',', '')
                if (isNumber(cost) && cost.length < 16) { handleChange(cost) }
            }} />
    );
}
CostTextbox.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    handleChange: PropTypes.func
};

export default CostTextbox;