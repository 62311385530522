
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@material-ui/core';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { convertHTMLToListView } from '../../utils/common';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

const IdeaSummary = (props) => {
    const classes = useStyles();

    const { idea, ...rest } = props;

    return (
        <Accordion {...rest}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>View idea details</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Idea title"}</Typography>
                        <Typography>{idea.title}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Problem statement"}</Typography>
                        <Typography>{idea.problemStatement}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Idea description"}</Typography>
                        <Typography>{idea.description}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Key assumptions"}</Typography>
                        <Typography component="div">{idea.assumptions ? convertHTMLToListView(idea.assumptions) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Customer benefits"}</Typography>
                        <Typography component="div">{idea.customerBenefit ? convertHTMLToListView(idea.customerBenefit) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Target segment"}</Typography>
                        <Typography component="div">{idea.targetSegment ? convertHTMLToListView(idea.targetSegment) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Value proposition"}</Typography>
                        <Typography>{idea.valueProposition ? idea.valueProposition : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Competitive Landscape"}</Typography>
                        <Typography component="div">{idea.competitiveLandscape ? convertHTMLToListView(idea.competitiveLandscape) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Business impact summary"}</Typography>
                        <Typography>{idea.businessImpactSummary ? idea.businessImpactSummary : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Key metrics"}</Typography>
                        <Typography component="div">{idea.keyMetrics ? convertHTMLToListView(idea.keyMetrics) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Cost structure (High level callouts)"}</Typography>
                        <Typography component="div">{idea.costStructure ? convertHTMLToListView(idea.costStructure) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Revenue streams"}</Typography>
                        <Typography component="div">{idea.revenueStreams ? convertHTMLToListView(idea.revenueStreams) : '-'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography color="textSecondary">{"Notes"}</Typography>
                        <Typography>{idea.notes ? idea.notes : '-'}</Typography>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

IdeaSummary.propTypes = {
    idea: PropTypes.object
};

export default IdeaSummary;
