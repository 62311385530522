import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ListItem, makeStyles, ListItemIcon, ListItemText, Collapse, List } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%'
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto'
    },
    active: {
        color: theme.palette.colors.feijoa,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '& $icon': {
            color: theme.palette.colors.feijoa
        }
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const CollapseNavBar = ({
    className,
    items,
    titleIcon: SvgIcon,
    title }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <React.Fragment>
            <ListItem
                className={clsx(classes.item, className, classes.button)}
                button disableGutters onClick={handleClick}>
                <ListItemIcon>
                    <SvgIcon
                        className={classes.icon}
                        size="20"
                    />
                </ListItemIcon>
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {items.map((item, index) => (
                        <NavItem
                            href={item.href}
                            key={index}
                            title={item.title}
                            icon={item.icon}
                            className={classes.nested}
                        />
                    ))}
                </List>
            </Collapse>
        </React.Fragment >
    );
};

CollapseNavBar.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    titleIcon: PropTypes.elementType,
    title: PropTypes.string
};

export default CollapseNavBar;
