import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { admin } from '../../constants/routes';
import NavigationButton from '../../components/Button/NavigationButton';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between'
    },
    welcome: {
        fontWeight: 600
    },
    newProject: {
        display: "flex",
        paddingBottom: theme.spacing(2),
        justifyContent: "flex-end"
    }
}));

const DashboardHomeTitle = (props) => {
    const classes = useStyles();
    const { name } = props;
    return (
        <Grid container spacing={3} className={classes.title}>
            <Grid item>
                <Typography variant="overline" color="inherit">Dashboard</Typography>
                <Typography variant="h5" className={classes.welcome} color="primary">Welcome, {name}</Typography>
            </Grid>
            <Grid item>
                <NavigationButton title={"New Project"}
                    navigateTo={admin.newProjectType.url}
                    className={classes.newProject}
                    icon={AddIcon} />
            </Grid>
        </Grid>
    );
};

DashboardHomeTitle.propTypes = {
    name: PropTypes.string
};

export default DashboardHomeTitle;
