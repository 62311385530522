import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { admin } from '../../../constants/routes';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, makeStyles, Box } from '@material-ui/core';
import CeciStudio from '../../Logos/CeciStudio';
import SignInSignOutButton from '../../Identity/SignInSignOutButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    border: '0',
    borderRadius: '3px',
    marginBottom: '20px',
    color: '#555',
    width: '100%',
    backgroundColor: theme.palette.colors.feijoa,
    boxShadow:
      '0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
    transition: 'all 150ms ease 0s',
    justifyContent: 'flex-start',
  },
  ceciStudioLogo: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const TopBar = ({
  className,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to={admin.dashboard.url} className={classes.ceciStudioLogo}>
          <CeciStudio />
        </RouterLink>
        <Box flexGrow={1} />
        <SignInSignOutButton />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
