import React from 'react';
import PropTypes from 'prop-types';
import CreateIdea from './createIdea'
import ManageFeatures from './features';
import ManageGoals from './goals';
import ManageOptions from './options';
import EvaluateFeatures from './evaluateFeatures';
import EvaluateGoals from './evaluateGoals';
import IterativeEvaluationSummary from './summary/IterativeEvaluationSummary';
import { ITERTIVE_EVALUATION_STAGES } from '../../../constants/evaluation/evaluationMetaData';
import { makeStyles } from '@material-ui/core/styles';
import { ScrollToTopOnPropsUpdate } from '../../../components/Common/ScrollToTop';
import { Stepper, Step, StepButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions/evaluationAction';
import { isStepReachable } from '../../../utils/projectUtil';

const useStyles = makeStyles((theme) => ({
    stepper: {
        borderRadius: theme.spacing(2),
        boxShadow: "rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        padding: theme.spacing(2),
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: theme.palette.background.feijoa
        }
    },
    reachableStep: {
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: theme.palette.background.feijoa
        }
    },
    currentStep: {
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: theme.palette.background.black
        }
    }
}));

function getStepContent(step) {
    switch (step) {
        case 0:
            return <CreateIdea />;
        case 1:
            return <ManageFeatures />;
        case 2:
            return <ManageGoals />;
        case 3:
            return <ManageOptions />;
        case 4:
            return <EvaluateFeatures />;
        case 5:
            return <EvaluateGoals />;
        default:
            return '';
    }
}

const IterativeEvaluation = (props) => {
    const { project, actions } = props;
    const classes = useStyles();
    const currentStep = project.currentStep;

    const handleStep = (step) => () => {
        actions.setStep(step);
    };

    return (
        <>
            <ScrollToTopOnPropsUpdate update={currentStep} />
            <Stepper id="stepper" activeStep={currentStep} className={classes.stepper} alternativeLabel>
                {ITERTIVE_EVALUATION_STAGES.map((label, index) => {
                    const canClickStep = isStepReachable(project, index, currentStep);
                    return (
                        <Step key={index} className={(canClickStep && index !== currentStep) ? classes.reachableStep : classes.currentStep}>
                            <StepButton expanded={false} completed={index < currentStep}
                                disabled={!canClickStep} active={currentStep || canClickStep} onClick={handleStep(index)}>{label.title}</StepButton>
                        </Step>
                    )
                })}
            </Stepper>
            {currentStep === ITERTIVE_EVALUATION_STAGES.length - 1 ? <IterativeEvaluationSummary /> : getStepContent(currentStep)}
        </>
    );
};

IterativeEvaluation.propTypes = {
    project: PropTypes.object,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(IterativeEvaluation);