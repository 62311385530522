import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import * as actions from '../../actions/paymentActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        margin: theme.spacing(1),
        borderRadius: theme.spacing(2),
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
            border: `3px solid ${green[600]}`,
            backgroundColor: `${green[50]}`,
            cursor: 'pointer',
        },
    },
    planPrice: {
        color: "rgb(23, 43, 77)",
        display: "inline"
    }
}));

const BuyPlanCard = ({ planType, numberOfProjects, planPrice, isUpgradePlan, upgradePlan, actions, ...rest }) => {
    const classes = useStyles();

    const buyPlan = () => {
        if (isUpgradePlan) {
            upgradePlan(numberOfProjects);
        } else {
            actions.initiateBuyPlan(numberOfProjects, planType);
        }
    }
    return (
        <React.Fragment>
            <Card onClick={buyPlan} className={classes.card} {...rest}>
                <CardActionArea>
                    <CardContent>
                        <Typography variant="h4" component="h2" className={classes.planPrice}>
                            {numberOfProjects}
                        </Typography>
                        <Typography variant="h5" component="h2" className={classes.planPrice}>
                            {" projects"}
                        </Typography><br /><br />
                        <div>
                            <Typography variant="h6" className={classes.planPrice}>${planPrice}</Typography>
                            <Typography variant="subtitle1" className={classes.planPrice}>/mo</Typography>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}
BuyPlanCard.propTypes = {
    numberOfProjects: PropTypes.number.isRequired,
    planPrice: PropTypes.number.isRequired,
    planType: PropTypes.string,
    isUpgradePlan: PropTypes.bool,
    upgradePlan: PropTypes.func,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(BuyPlanCard);