import React, { useState } from 'react'
import { IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Joyride, { STATUS } from 'react-joyride';
import PropTypes from "prop-types";

const Tour = (props) => {

    const { toolTipText, steps, startTour, ...rest } = props;

    const [started, setStarted] = useState(startTour);

    const handleJoyrideCallback = (data) => {
        const { action, status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        if (finishedStatuses.includes(status) || action == 'close') {
            setStarted(false);
        }
    };
    return (
        <>
            <Tooltip title={toolTipText}>
                <IconButton id="tourIcon" onClick={() => setStarted(true)}>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Joyride
                {...rest}
                continuous={true}
                disableScrolling={true}
                steps={steps}
                spotlightClicks={true}
                run={started}
                spotlightPadding={10}
                callback={handleJoyrideCallback}
            />
        </>
    )
}

Tour.propTypes = {
    toolTipText: PropTypes.string,
    steps: PropTypes.array,
    startTour: PropTypes.bool
};


Tour.defaultProps = {
    startTour: false
};

export default Tour;

