import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import idx from 'idx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Pagination } from '@material-ui/lab';
import { Label } from '@material-ui/icons';
import ManageFeatureDialog from './ManageFeatureDialog';
import ManageEvaluatorDialog from './ManageEvaluatorDialog';
import ManageFeatureLabelDialog from './ManageFeatureLabelDialog';
import WaitingLoader from '../../../../components/Loader/WaitingLoader';
import LabelsDropDown from '../../../../components/Dropdown/LabelsDropDown';
import SimpleDropDown from '../../../../components/Dropdown/SimpleDropDown';
import RateAndLabelCard from '../../../../components/Card/RateAndLabelCard';
import SearchTextbox from '../../../../components/DisplayText/SearchTextbox';
import StepperNavigtion from '../../../../components/Common/StepperNavigtion';
import DeleteConfirmation from '../../../../components/Dialog/DeleteConfirmation'
import SelectAllCheckbox from '../../../../components/Checkbox/SelectAllCheckbox';
import EvaluatorDropDown from '../../../../components/Dropdown/EvaluatorDropDown';
import DeleteSelectedButton from '../../../../components/Button/DeleteSelectedButton';
import ViewEvalutionSummaryCard from '../../../../components/Card/ViewEvalutionSummaryCard';
import EvaluationStepperTitle from '../../../../components/HeaderV2/EvaluationStepperTitle';
import * as actions from '../../../../actions/evaluationAction';
import { MANAGE_FEATURES_STAGE, OPTIONS_FOR_SORT_BY, EVALUATION_SUMMARY_VIEW, FEATURES_PER_PAGE } from '../../../../constants/evaluation/evaluationMetaData';
import { EVALUATORS_ALLOWED, tourSteps } from '../../../../constants/evaluation/features';
import { findCurrentProjectDetails, countTotalPages, hasData, isEvaluatorView, calculatePageEndIndex, calculatePageStartIndex, filterAndSortFeatures, isEvaluationSummaryView } from '../../../../utils/projectUtil';
import { Grid, Button, Container, Box, Paper, Typography } from '@material-ui/core';
import AddFeatureButton from '../../../../components/Button/AddFeatureButton';
import NotFound from '../../../../components/Common/NotFound';
import AddEvaluatorButton from '../../../../components/Button/AddEvaluatorButton';
import { useMsal, useAccount } from "@azure/msal-react";
import { fetchName } from '../../../../utils/identity';
import ExportFeatures from '../../../../components/Export/ExportFeatures';

const useStyles = makeStyles((theme) => ({
    pagination: {
        display: 'flex',
        padding: theme.spacing(2)
    },
    addFeatureButton: {
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: theme.spacing(2)
    },
    notFoundPaper: {
        marginBottom: theme.spacing(2)
    },
    titleRoot: {
        padding: theme.spacing(2),
        boxShadow: "rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px",
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
        borderRadius: theme.spacing(2)
    },
    formControl: {
        minWidth: 240,
        maxWidth: 240
    },
    deleteButton: {
        justifyContent: 'flex-end',
        display: 'flex'
    },
    totalSelectText: {
        paddingTop: theme.spacing(2)
    }
}));

const ManageFeatures = ({ plan, projectType, idea, features, featureEvaluators, featureLabels, goalEvaluators, actions }) => {
    const classes = useStyles();

    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [featuresAllowed, setFeaturesAllowed] = React.useState(0);

    const [filteredFeatures, setFilteredFeatures] = React.useState(features);
    const [hoveredFeature, setHoveredFeature] = React.useState('');
    const [ratingHoverState, setRatingHoverState] = React.useState(-1);
    const [evaluationView, setEvaluationView] = React.useState(EVALUATION_SUMMARY_VIEW[0].value);
    const [showLoading, setShowLoading] = React.useState(false);

    const [sortBy, setSortBy] = React.useState(OPTIONS_FOR_SORT_BY[0].value);
    const [searchFeatureText, setSearchFeatureText] = React.useState('');
    const [filterLabels, setFilterLabels] = React.useState([]);
    const [selectedFeatures, setSelectedFeatures] = React.useState([]);
    const [isAllFeaturesSelected, setIsAllFeaturesSelected] = React.useState(false);
    const [expandedFeature, setExpandedFeature] = React.useState('');

    const [openEvaluatorDialog, setOpenEvaluatorDialog] = React.useState(false);
    const [openEvaluatorDropDown, setOpenEvaluatorDropDown] = React.useState(false);
    const [selectedEvaluator, setSelectedEvaluator] = React.useState(featureEvaluators[0]);
    const [evaluatorData, setEvaluatorData] = React.useState({});

    const [openFeatureDialog, setOpenFeatureDialog] = React.useState(false);
    const [featureData, setFeatureData] = React.useState({});

    const [openLabelsDialog, setOpenLabelsDialog] = React.useState(false);

    const [openDeleteFeatureDialog, setOpenDeleteFeatureDialog] = React.useState(false);

    const [page, setPage] = React.useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleExpandClick = (collapseId) => {
        setExpandedFeature(collapseId === expandedFeature ? "" : collapseId)
    };

    const handleNext = () => {
        if (goalEvaluators.length === 0) {
            actions.addGoalEvaluator({ name: fetchName(account), group: 'Group 1', influence: 1 })
                .then(() => {
                    actions.incrementStep();
                });
        } else {
            actions.incrementStep();
        }
    };

    const handleBack = () => {
        actions.decrementStep();
    };

    const handleSortBy = (event) => {
        setSortBy(event.target.value);
    };

    const handleSelectAll = () => {
        setIsAllFeaturesSelected(!isAllFeaturesSelected);
        if (!isAllFeaturesSelected) {
            var selectFeature = [];
            filteredFeatures.forEach((feature) => {
                if (feature.deliveryVersion === undefined || feature.deliveryVersion === 0) {
                    selectFeature.push(feature.id)
                }
            });
            setSelectedFeatures(selectFeature)
        } else {
            setSelectedFeatures([]);
        }
    }

    const handleSelectFeature = (e, feature) => {
        setIsAllFeaturesSelected(false);
        if (e.target.checked) {
            if (isAllFeaturesSelected) {
                setSelectedFeatures([feature.id]);
            } else {
                setSelectedFeatures([...selectedFeatures, feature.id]);
            }
        } else {
            const updatedFilteredFeatures = selectedFeatures.filter((selectedFeature) => {
                return selectedFeature !== feature.id;
            });
            setSelectedFeatures(updatedFilteredFeatures)
        }
    }

    const handleEditFeature = (feature) => {
        setFeatureData(feature);
        setOpenFeatureDialog(true);
    }

    const handleFeatureRating = (featureId, rating) => {
        handleFeatureEvaluatorRating(featureId, rating, selectedEvaluator.id);
    }

    const handleFeatureEvaluatorRating = (featureId, rating, evaluatorId) => {
        rating = rating === null ? 0 : rating;
        actions.updateFeatureRatingByEvaluator({ featureId, rating, evaluatorId })
    }

    const handleFeatureLabels = (featureId, labelIds) => {
        actions.assignLabelToFeature({ featureId, labelIds });
    }

    const confirmDeleteFeature = () => {
        setOpenDeleteFeatureDialog(true);
    }

    const closeDeleteFeatureDialog = () => {
        setOpenDeleteFeatureDialog(false);
    }

    const handleDeleteSelected = () => {
        setOpenDeleteFeatureDialog(false);
        setShowLoading(true);
        const featuresToDelete = selectedFeatures;
        featuresToDelete.forEach(featureDel => {
            actions.removeFeature(featureDel);
            setSelectedFeatures(featuresToDelete.filter(feature => feature.id !== featureDel.id));
        });
        setIsAllFeaturesSelected(false);
        setShowLoading(false);
    }

    const handleDeleteEvaluator = () => {
        actions.removeFeatureEvaluator(selectedEvaluator);
        const newSelectedEvaluator = featureEvaluators.find((evaluator) => evaluator.id !== selectedEvaluator.id);
        setSelectedEvaluator(newSelectedEvaluator);
    }

    React.useEffect(() => {
        const updatedFilteredFeatures = filterAndSortFeatures(features, featureLabels, sortBy, searchFeatureText, filterLabels, selectedEvaluator);
        setFilteredFeatures(updatedFilteredFeatures);
        setPage(1);
        setFeaturesAllowed(plan.featuresAllowed);
    }, [plan, features, featureLabels, sortBy, searchFeatureText, filterLabels, selectedEvaluator]);

    return (
        <React.Fragment>
            <Container maxWidth={false}>
                <Paper className={classes.titleRoot} id="featuresCanvas">
                    <EvaluationStepperTitle stage={MANAGE_FEATURES_STAGE} projectType={projectType} steps={tourSteps} />
                    <Grid container spacing={1} justify="space-between">
                        <Grid item id="manageEvaluators">
                            <EvaluatorDropDown className={classes.formControl} open={openEvaluatorDropDown}
                                onClose={() => {
                                    setOpenEvaluatorDropDown(false);
                                }}
                                onOpen={() => {
                                    setOpenEvaluatorDropDown(true);
                                }}
                                selectedEvaluator={selectedEvaluator}
                                onChange={(e) => {
                                    setSelectedEvaluator(featureEvaluators.find(evaluator => evaluator.name === e.target.value));
                                }} evaluators={featureEvaluators}
                                onEdit={() => {
                                    setOpenEvaluatorDialog(true);
                                    setEvaluatorData(selectedEvaluator);
                                }}
                                onDelete={() => handleDeleteEvaluator()} />
                        </Grid>
                        <Grid item id="addEvaluators">
                            <AddEvaluatorButton label="Add evaluator" maxWhatAllowed={"evaluators"} onClick={() => {
                                setOpenEvaluatorDialog(true);
                                setEvaluatorData();
                            }} maxAllowed={EVALUATORS_ALLOWED} disabled={featureEvaluators.length === EVALUATORS_ALLOWED} />
                        </Grid>
                        <Grid item id="manageLabels">
                            <Button color="primary" onClick={() => {
                                setOpenLabelsDialog(true);
                            }}
                                fullWidth size="large" style={{ minHeight: '53px' }}
                                variant="outlined" startIcon={<Label />}>Manage labels</Button>
                        </Grid>
                        <Grid item id="addFeature">
                            <AddFeatureButton disabled={features.length === featuresAllowed} maxAllowed={featuresAllowed} maxWhatAllowed={"features"} fullWidth label={"Add feature"} onClick={() => {
                                setOpenFeatureDialog(true);
                                setFeatureData();
                            }} />
                        </Grid>
                    </Grid>
                    {hasData(features) && <Grid container spacing={2} justify="center" id="searchFilterSortFeature">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <SearchTextbox placeholder="Search feature" value={searchFeatureText} onChange={(value) => setSearchFeatureText(value.trim())} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <SimpleDropDown className={classes.formControl}
                                text={"Sort feature by"} onChange={handleSortBy}
                                value={sortBy} options={OPTIONS_FOR_SORT_BY} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <LabelsDropDown labels={featureLabels} dropDownLabel="Filter by labels" value={filterLabels}
                                dropDownPlaceholder="Labels" dropDownVariant="outlined"
                                onChange={(value) => {
                                    setFilterLabels(value);
                                }} />
                        </Grid>
                    </Grid>}
                    {hasData(filteredFeatures) &&
                        <Grid container spacing={2} justify="space-between">
                            <Grid item>
                                <SelectAllCheckbox label="Select all features" checked={isAllFeaturesSelected} onChange={handleSelectAll} />
                            </Grid>
                            <Grid item>
                                <SimpleDropDown id="evaluationViews" className={classes.formControl}
                                    text={"Evaluation view"} onChange={(e) => setEvaluationView(e.target.value)}
                                    value={evaluationView} options={EVALUATION_SUMMARY_VIEW} />
                            </Grid>
                        </Grid>}
                </Paper>
                {/* Display total and filtered count */}
                {hasData(features) &&
                    <Grid container spacing={3} justify="space-between" align="center">
                        <Grid item>
                            <Typography variant="h6">Total features : {features.length}</Typography>
                        </Grid>
                        {(filterLabels.length !== 0 || searchFeatureText !== '') &&
                            <Grid item>
                                <Typography variant="h6">Total filtered features : {filteredFeatures.length}</Typography>
                            </Grid>
                        }
                        <Grid item>
                            <ExportFeatures id="exportFeatures" type="features" idea={idea} features={features} evaluators={featureEvaluators}
                                filteredFeatures={filteredFeatures} selectedFeaturesId={selectedFeatures}
                                isFileredApplied={(filterLabels.length !== 0 || searchFeatureText !== '')} />
                        </Grid>
                    </Grid>}
                <Box mt={2}>
                    {showLoading && <WaitingLoader />}
                    {hasData(selectedFeatures) && !showLoading &&
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography className={classes.totalSelectText}>{selectedFeatures.length} {selectedFeatures.length === 1 ? 'feature is' : 'features are'} selected</Typography>
                            </Grid>
                            <Grid item>
                                <DeleteSelectedButton className={classes.deleteButton} displayText={'Delete selected'} handleDeleteSelected={confirmDeleteFeature} />
                            </Grid>
                        </Grid>}
                    <DeleteConfirmation title="feature" open={openDeleteFeatureDialog} textConfirmation="Are you sure you want to delete this feature? Once deleted it won`t be recoverable" onCancel={closeDeleteFeatureDialog} onDelete={handleDeleteSelected} />
                    {/* Display the features card */}
                    {!showLoading &&
                        <Grid container spacing={3}>
                            {filteredFeatures.slice(calculatePageStartIndex(page, FEATURES_PER_PAGE), calculatePageEndIndex(page, FEATURES_PER_PAGE)).map((feature, index) => {
                                const isOpenCollapsable = expandedFeature === feature.id;
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4} id={`grid_${index}`} key={`grid_${index}`}>
                                        {isEvaluationSummaryView(evaluationView) &&
                                            <ViewEvalutionSummaryCard id={`card_${index}`} key={`card_${index}`}
                                                feature={feature} selectedFeatures={selectedFeatures} handleFeatureRating={handleFeatureEvaluatorRating}
                                                labels={featureLabels} handleSelectFeature={handleSelectFeature}
                                                handleEditFeature={handleEditFeature} cardVariant={"feature"}
                                                isOpenCollapsable={isOpenCollapsable} handleExpandClick={handleExpandClick}
                                                handleFeatureLabels={handleFeatureLabels} evaluators={featureEvaluators} handleRemoveIteration={(id) => { actions.markFeatureAsDelivered({ featuresToUpdate: [id], version: 0 }) }}
                                            />}
                                        {isEvaluatorView(evaluationView) &&
                                            <RateAndLabelCard index={index} id={`card_${index}`} key={`card_${index}`} evaluatorId={selectedEvaluator.id}
                                                feature={feature} selectedFeatures={selectedFeatures} handleHoveredFeature={(value) => setHoveredFeature(value)}
                                                labels={featureLabels} handleSelectFeature={handleSelectFeature} hoveredFeature={hoveredFeature}
                                                handleEditFeature={handleEditFeature} cardVariant={"feature"} ratingHoverState={ratingHoverState} handleRatingUpdateHoverState={(value) => setRatingHoverState(value)}
                                                handleFeatureRating={handleFeatureRating} handleFeatureLabels={handleFeatureLabels} handleRemoveIteration={(id) => { actions.markFeatureAsDelivered({ featuresToUpdate: [id], version: 0 }) }}
                                            />}
                                    </Grid>
                                )
                            })}
                        </Grid>}
                </Box>
                {/* Pagination */}
                {!showLoading && hasData(filteredFeatures) &&
                    <Grid container justify="center" className={classes.pagination}>
                        <Pagination count={countTotalPages(filteredFeatures.length, FEATURES_PER_PAGE)} page={page} color="secondary" onChange={handlePageChange} />
                    </Grid>
                }
                {/* Show this when no results are found in search/filter  */}
                {!hasData(filteredFeatures) && hasData(features) && <NotFound image={"/static/svg/dashboard/projects/noSearchResults.svg"}
                    imageAlt={"No results found"} className={classes.notFoundPaper}
                    subTitle={"No results found"} />}
                {/* Show this when no features created  */}
                {!hasData(features) && <NotFound image={"/static/svg/dashboard/projects/addPost.svg"}
                    imageAlt={"No features"} className={classes.notFoundPaper}
                    title={"No features"}
                    subTitle={"Click the feature button to start adding the features required for your idea"} >
                    <AddFeatureButton label={"Add feature"} onClick={() => {
                        setOpenFeatureDialog(true);
                        setFeatureData();
                    }} className={classes.addFeatureButton} /></NotFound>}

                <Grid container spacing={3} justify="flex-end">
                    <Grid item>
                        <AddFeatureButton disabled={features.length === featuresAllowed} maxAllowed={featuresAllowed} maxWhatAllowed={"features"} fullWidth label={"Add feature"} onClick={() => {
                            setOpenFeatureDialog(true);
                            setFeatureData();
                        }} />
                    </Grid>
                </Grid>

                <StepperNavigtion handleBack={handleBack} disableNextButton={!hasData(features)} handleNext={handleNext} hideNextButton={false} skipStep={false} />
            </Container>

            <ManageEvaluatorDialog open={openEvaluatorDialog} data={evaluatorData}
                onSave={() => { setOpenEvaluatorDialog(false); }}
                onCancel={() => { setOpenEvaluatorDialog(false) }} />

            <ManageFeatureDialog open={openFeatureDialog} data={featureData}
                onSave={() => {
                    setOpenFeatureDialog(false);
                    // For updating the filters
                    const updatedFilteredLabels = featureLabels.filter(label => filterLabels.includes(label));
                    setFilterLabels(updatedFilteredLabels);
                }} onCancel={() => { setOpenFeatureDialog(false) }} />

            <ManageFeatureLabelDialog open={openLabelsDialog}
                onClose={() => {
                    setOpenLabelsDialog(false);
                    // Doing this in order avoid deleted label to be selected, can be improved later
                    setFilterLabels([]);
                }} />
        </React.Fragment >
    );
}

ManageFeatures.propTypes = {
    projectType: PropTypes.string.isRequired,
    plan: PropTypes.object,
    idea: PropTypes.object,
    features: PropTypes.array,
    featureEvaluators: PropTypes.array,
    featureLabels: PropTypes.array,
    goalEvaluators: PropTypes.array,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        plan: state.plan || {},
        projectType: idx(currentProjectData, _ => _.type) || '',
        idea: idx(currentProjectData, _ => _.idea) || {},
        features: idx(currentProjectData, _ => _.features) || [],
        featureEvaluators: idx(currentProjectData, _ => _.featureEvaluators) || [],
        featureLabels: idx(currentProjectData, _ => _.featureLabels) || [],
        goalEvaluators: idx(currentProjectData, _ => _.goalEvaluators) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageFeatures);