import React from 'react';
import idx from 'idx';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DialogContent, Dialog, TableBody, TableRow, TableCell, Checkbox, TableContainer, Table, Typography, Chip } from '@material-ui/core';
import CustomDialogTitle from './CustomDialogTitle';
import * as actions from '../../actions/evaluationAction';
import EnhancedFeatureDownloadToolbar from '../Toolbar/EnhancedFeatureDownloadToolbar';
import { makeStyles } from '@material-ui/core/styles';
import {
    calculateAverageRating, fetchLabelTitleLabelId, fetchFeaturesByFeatureId,
    fetchHighestDeliveryVersion, isFeatureDelivered, sortFeaturesByAlphabeticallyAsc,
    sortFeaturesByAlphabeticallyDsc, sortFeaturesByEvaluationDesc, sortFeaturesByEvaluationAsc,
    findCurrentProjectDetails, filterByLabels, applyIterationFilter
} from '../../utils/projectUtil';
import EnhancedFeatureTableHead from '../Tables/EnhancedFeatureTableHead';
import { exportCSVForAzure, exportCSVForJIRA } from '../../utils/exportEvaluation';
import EnhancedFeatureFilterToolbar from '../Toolbar/EnhancedFeatureFilterToolbar';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    content: {
        margin: theme.spacing(3),
        padding: '0 24px'
    },
    chip: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

const FeaturesToolExport = (props) => {
    const { open, ideaTitle, features, featureLabels, onCancel, actions } = props;
    const classes = useStyles();

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        setFilteredFeatures(features);
        const iterationsDone = []
        features.forEach(feature => {
            if (isFeatureDelivered(feature)) {
                iterationsDone.push(`Iteration ${feature.deliveryVersion}`);
            }
        })
        setAvailIterations(iterationsDone)
    }, [open, features]);

    const [selected, setSelected] = React.useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('title');
    const [filteredFeatures, setFilteredFeatures] = React.useState(features);
    const [availIterations, setAvailIterations] = React.useState([]);

    const closeDialog = () => {
        setSelected([]);
        onCancel && onCancel();
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = filteredFeatures.map(n => {
                if (!isFeatureDelivered(n)) {
                    return n.id;
                }
            });
            var selectedFeatures = newSelecteds.filter(function (x) {
                return x !== undefined;
            });
            setSelected(selectedFeatures);
            return;
        }
        setSelected([]);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const exportCsv = (type) => {
        closeDialog();
        const selectedFeatures = fetchFeaturesByFeatureId(features, selected);
        if (type.toLowerCase() === 'jira') {
            exportCSVForJIRA(ideaTitle, selectedFeatures, featureLabels);
        } else {
            exportCSVForAzure(ideaTitle, selectedFeatures, featureLabels);
        }
        const latestDeliveredVersion = fetchHighestDeliveryVersion(features) + 1;
        actions.markFeatureAsDelivered({ featuresToUpdate: selected, version: latestDeliveredVersion });
    }

    const sortFeatures = (featuresData) => {
        if (orderBy === 'evalRating') {
            return order === 'desc' ? sortFeaturesByEvaluationDesc(featuresData) : sortFeaturesByEvaluationAsc(featuresData);
        }
        return order === 'desc' ? sortFeaturesByAlphabeticallyDsc(featuresData) : sortFeaturesByAlphabeticallyAsc(featuresData);
    }

    const applyFilter = (filterLabels, iterationsCondition) => {
        const labelFilteredFeatures = filterByLabels(features, featureLabels, filterLabels);
        var labelFilteredFeaturesNonUndefined = labelFilteredFeatures.filter(function (x) {
            return x !== undefined;
        });
        const iterationsFilteredFeatures = applyIterationFilter(iterationsCondition, labelFilteredFeaturesNonUndefined)
        setFilteredFeatures(iterationsFilteredFeatures);
    }

    return (
        <Dialog
            maxWidth={"lg"}
            open={open}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <CustomDialogTitle onClose={closeDialog} />
            <DialogContent className={classes.content} ref={descriptionElementRef} tabIndex={-1}>
                {selected.length !== 0 && <EnhancedFeatureDownloadToolbar numSelected={selected.length} exportCsv={exportCsv} />}
                <EnhancedFeatureFilterToolbar version={fetchHighestDeliveryVersion(features) + 1} iterations={availIterations} featureLabels={featureLabels} saveFilter={applyFilter} />
                <TableContainer>
                    <Table aria-label="sticky table" className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedFeatureTableHead classes={classes}
                            numSelected={selected.length} order={order}
                            orderBy={orderBy} onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={filteredFeatures.length} />
                        <TableBody>
                            {sortFeatures(filteredFeatures)
                                .map((feature, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const isItemSelected = isSelected(feature.id);
                                    const isNotDelivered = feature.deliveryVersion === undefined || feature.deliveryVersion === 0;
                                    const labels = fetchLabelTitleLabelId(featureLabels, feature.labels)
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox" aria-checked={isItemSelected}
                                            tabIndex={-1} key={feature.id}
                                            selected={isItemSelected} >
                                            <TableCell padding="checkbox">
                                                <Checkbox checked={isItemSelected} disabled={!isNotDelivered}
                                                    onClick={(event) => isNotDelivered && handleClick(event, feature.id)}
                                                    inputProps={{ 'aria-labelledby': labelId }} />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <Typography variant="body1">{feature.title}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <div className={classes.chip}>
                                                    {labels.map((label, chipIndex) => {
                                                        return (
                                                            <Chip key={chipIndex} id={chipIndex} label={label} variant="outlined" />
                                                        )
                                                    })}
                                                    {labels.length === 0 && '-'}
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">{calculateAverageRating(feature.evaluatorsRating)}</TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <Typography variant="body1">{isFeatureDelivered(feature) ? 'Iteration ' + feature.deliveryVersion : '-'}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
}
FeaturesToolExport.propTypes = {
    open: PropTypes.bool,
    ideaTitle: PropTypes.string,
    features: PropTypes.array,
    featureLabels: PropTypes.array,
    onCancel: PropTypes.func,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        features: idx(currentProjectData, _ => _.features) || [],
        featureLabels: idx(currentProjectData, _ => _.featureLabels) || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesToolExport);