
import React from 'react';
import DashboardBody from '../../../../components/Body/DashboardBody';
import { MetaDashboardEditProject } from './Seo/MetaDashboardEditProject';
import PropTypes from 'prop-types';
import AgileEvaluation from '../../evaluation/AgileEvaluation';
import IterativeEvaluation from '../../evaluation/IterativeEvaluation';
import WaterfallEvaluation from '../../evaluation/WaterfallEvaluation';
import { EVALUATION_AGILE, EVALUATION_GENERIC, EVALUATION_ITERATIVE, EVALUATION_WATERFALL } from '../../../../constants/projectTypes';
import { findCurrentProjectDetails } from '../../../../utils/projectUtil';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { admin } from '../../../../constants/routes';
import GenericEvaluation from '../../evaluation/GenericEvaluation';

const EditProject = ({ project }) => {
  const startProject = () => {
    const projectType = project.type;
    switch (projectType) {
      case EVALUATION_AGILE:
        return <AgileEvaluation project={project} />
      case EVALUATION_ITERATIVE:
        return <IterativeEvaluation project={project} />
      case EVALUATION_WATERFALL:
        return <WaterfallEvaluation project={project} />
      case EVALUATION_GENERIC:
        return <GenericEvaluation project={project} />
    }
  }

  return (
    <>
      {Object.is(project, null) ? <Redirect to={admin.newProjectType.url} /> :
        <DashboardBody metaData={MetaDashboardEditProject}>
          {startProject()}
        </DashboardBody>
      }
    </>
  );
};

EditProject.propTypes = {
  project: PropTypes.object,
}

const mapStateToProps = (state) => {
  const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
  return {
    project: currentProjectData || null
  };
};

export default connect(mapStateToProps, null)(EditProject);