import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    contactFormContainer: {
        width: '100%',
        '& iframe': {
            borderWidth: '0px',
            '& body': {
                backgroundColor: theme.palette.colors.white,
            },
        },
    },
}));

const googleForm =
    '<iframe width="100%" height="800px" scrolling="yes" src="https://docs.google.com/forms/d/1ihJYTwI7ry8N4gxx9e6HYbkiYdL4paow_uGqGNNx5Ts/viewform"></iframe>';

function Iframe(props) {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
        />
    );
}

Iframe.propTypes = {
    iframe: PropTypes.any,
};

const ContactForm = () => {
    const classes = useStyles();
    return (
        <div className={classes.contactFormContainer}>
            <Iframe iframe={googleForm} />
        </div>
    );
};

export default ContactForm;
