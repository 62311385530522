
import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Grid, CardContent, Card, Divider, Dialog, DialogActions, DialogContentText, DialogContent, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useMsal, useAccount } from "@azure/msal-react";
import { fetchAccountDetails, logout } from '../../../utils/identity';
import { b2cPolicies } from '../../../config/authConfig';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DisplayStaticTextbox from '../../../components/DisplayText/DisplayStaticTextbox';
import CustomDialogTitle from '../../../components/Dialog/CustomDialogTitle';
import * as actions from '../../../actions/planActions';
import * as projectActions from '../../../actions/projectActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { DELETE_USER_INSTRUCTIONS, userPlanState } from '../../../constants/static';
import { planState } from '../../../utils/planUtil';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: theme.spacing(2)
    },
    editProfileButton: {
        borderRadius: theme.spacing(2),
    },
    actionButton: {
        justifyContent: 'space-between',
        padding: theme.spacing(2)
    },
    dialogAction: {
        justifyContent: 'space-around',
    },
    deleteAccount: {
        backgroundColor: theme.palette.error.main,
        color: '#FFFFFF',
        "&:hover,&:focus": {
            color: "#000000",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        }
    },
    deleteAccountButton: {
        borderRadius: theme.spacing(2),
        backgroundColor: theme.palette.error.main,
        color: '#FFFFFF',
        "&:hover,&:focus": {
            color: "#000000",
            boxShadow:
                "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
    }
}));

const styles = (theme) => ({
    root: {
        color: theme.palette.colors.feijoa,
        '&$checked': {
            color: theme.palette.colors.feijoa,
        },
    },
    checked: {},
});

const GreenCheckbox = withStyles(styles)((props) => (
    <Checkbox color="default" {...props} />
));

const General = ({ plan, projectActions, actions }) => {
    const classes = useStyles();
    const { accounts, instance } = useMsal();
    const history = useHistory();
    const account = useAccount(accounts[0] || {});
    const accountDetails = fetchAccountDetails(account);

    const [showDialog, setShowDialog] = React.useState(false);
    const [isCheckBoxSelected, toggleCheckBoxState] = React.useState(true);
    const [accountCurrentStatus, setAccountCurrentStatus] = React.useState(''); // will be used for displaying different message on dialog box and separate action will be taken

    const updateProfile = () => {
        instance.loginPopup(b2cPolicies.authorities.editProfile);
    }

    const handleChange = (event) => {
        toggleCheckBoxState(event.target.checked);
    };

    const handleDialogDisplay = (show) => {
        if (show) {
            setAccountCurrentStatus(planState(plan));
        } else {
            setAccountCurrentStatus('');
        }
        setShowDialog(show);
    }

    const onClickDeleteAccount = () => {
        actions.deleteProfile(!plan.deleteUser, isCheckBoxSelected).then(() => {
            handleDialogDisplay(false);
            if (accountCurrentStatus === userPlanState.DELETE_USER_NO_ACTIVE_PLAN ||
                accountCurrentStatus === userPlanState.DELETE_USER_CANCELLED_PLAN_BILLING_PERIOD_ENDED) {
                // no plan - redirect to login screen
                logout(instance, history, projectActions);
            }
        })
    }

    const activateAccount = (
        <Button color="secondary" size="small" variant="contained" onClick={onClickDeleteAccount}>
            Activate account
        </Button>
    );

    return (
        <>
            {plan.deleteUser && <Alert action={activateAccount} severity="warning">Your account is currently marked for deletion</Alert>}
            <br />
            <Card className={classes.card}>
                <CardHeader title="Profile" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <DisplayStaticTextbox label="First Name" name="firstName" value={accountDetails.firstName} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DisplayStaticTextbox label="Last Name" name="lastName" value={accountDetails.lastName} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DisplayStaticTextbox label="Email Address" name="emailAddress" value={accountDetails.emailId} helperText="We will use this email to contact you" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DisplayStaticTextbox label="Usage" name="usage" value={accountDetails.usage} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DisplayStaticTextbox label="Job Title" name="jobTitle" value={accountDetails.role} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DisplayStaticTextbox label="Industry Vertical" name="industryVertical" value={accountDetails.industryVertical} />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Grid container spacing={3} className={classes.actionButton}>
                    <Grid item>
                        {!plan.deleteUser &&
                            <Button size="large" startIcon={<DeleteIcon />} variant="contained" onClick={() => handleDialogDisplay(true)} className={classes.deleteAccountButton}>
                                {'Delete account'}
                            </Button>}
                    </Grid>
                    <Grid item>
                        <Button size="large" startIcon={<EditIcon />} color="secondary" variant="contained" onClick={() => updateProfile()} className={classes.editProfileButton}>
                            Edit profile
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            <Dialog fullWidth open={showDialog} onClose={() => handleDialogDisplay(false)} aria-label="Are you sure?">
                <CustomDialogTitle onClose={() => handleDialogDisplay(false)}>Are you sure you want to delete your account?</CustomDialogTitle>
                <DialogContent>
                    <DialogContentText component="div">
                        Once you click on delete account button
                        <ul>
                            {accountCurrentStatus !== '' && DELETE_USER_INSTRUCTIONS[accountCurrentStatus].map((instrction, index) => {
                                return (<li key={index}>{instrction}</li>)
                            })}
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container className={classes.dialogAction}>
                        <Grid item>
                            <Button variant="outlined" fullWidth size="large" onClick={() => handleDialogDisplay(false)} color="primary">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<DeleteIcon />} variant="outlined" fullWidth size="large" onClick={onClickDeleteAccount} className={classes.deleteAccount}>
                                Delete Account
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                checked={isCheckBoxSelected}
                                onChange={handleChange}
                                name="checkedG"
                            />
                        }
                        label={
                            <div
                                dangerouslySetInnerHTML={{ __html: 'I consent to receive promotional and update emails' }}
                            ></div>
                        }
                    />

                </DialogContent>
            </Dialog>
        </>
    );
};

General.propTypes = {
    plan: PropTypes.object,
    projectActions: PropTypes.object,
    actions: PropTypes.object
}


const mapStateToProps = (state) => {
    return {
        plan: state.plan || {}
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(General);
