import React from 'react';
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillTextbox = (props) => {
    const { textValue, maxLength, label, placeholder, modules, onChange, ...rest } = props;
    const reactQuillRef = React.useRef();

    const checkCharacterCount = (event) => {
        const unprivilegedEditor = reactQuillRef.current.props.value;
        if (unprivilegedEditor && unprivilegedEditor.length > maxLength && event.key !== 'Backspace')
            event.preventDefault();
    };

    return (
        <div {...rest}>
            <Typography gutterBottom variant="body2">{label}</Typography>
            <ReactQuill modules={modules}
                theme="snow"
                onKeyDown={checkCharacterCount}
                style={{ height: '56px', marginBottom: '46px' }}
                ref={reactQuillRef}
                value={textValue}
                onChange={(value) => onChange(value)}
                placeholder={placeholder} />
        </div>
    );
}

QuillTextbox.propTypes = {
    textValue: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    modules: PropTypes.object,
    onChange: PropTypes.func
};

export default QuillTextbox;