import React from 'react';
import { CardHeader, CardContent, Button, Card, Divider, Box, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as actions from '../../../actions/paymentActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: theme.spacing(2)
    },
    card: {
        borderRadius: theme.spacing(2)
    },
}));

const CustomerPortal = (props) => {
    const { planDetails, actions } = props;
    const classes = useStyles();

    const customerPortal = () => {
       actions.openCustomerPortal(planDetails.custId);
    }

    return (
        <React.Fragment>
            <Card className={classes.card}>
                <CardHeader title="Customer portal" />
                <Divider />
                <CardContent>
                    <Paper className={classes.paper}>
                        <Box alignItems="left" display="flex" flexDirection="column" p={2}>
                            <Typography variant="body1">Customer portal is a secured simplest way to manage subscription and billing. The portal provides features that allows you to:</Typography>
                            <ul>
                                <li><Typography variant="body2">View active subscription</Typography></li>
                                <li><Typography variant="body2">View and download invoice history</Typography></li>
                                <li><Typography variant="body2">Manage payment method</Typography></li>
                                <li><Typography variant="body2">Cancel subscription</Typography></li>
                                <li><Typography variant="body2">Renew subscription</Typography></li>
                            </ul>
                            <Button color="secondary" variant="contained" onClick={customerPortal}>Customer portal</Button>
                        </Box>
                    </Paper>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

CustomerPortal.propTypes = {
    planDetails: PropTypes.object,
    actions: PropTypes.object
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(CustomerPortal);