import React from 'react';
import { Grid, TextField, Button, DialogContentText } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import * as actions from '../../../../actions/evaluationAction';
import { bindActionCreators } from 'redux';
import idx from 'idx';
import PropTypes from 'prop-types';
import { findCurrentProjectDetails } from '../../../../utils/projectUtil';
import { FEATURE_TITLE_SIZE_ALLOWED } from '../../../../constants/evaluation/features';

function ManageFeatureDialog({ data, open, features, onSave, onCancel, actions }) {
    const defaultFeatureTitle = idx(data, _ => _.title) || '';

    const [title, setTitle] = React.useState(defaultFeatureTitle);

    const handleSave = () => {
        const id = data ? data.id : '';
        data ? actions.updateFeature({ title, id }) : actions.addFeature({ title });
        onSave && onSave();
        handleClose();
    };

    const handleClose = () => {
        onCancel();
    };

    const isDuplicateFeature = () => {
        return features.some(feature => feature.title.toUpperCase() === title.toUpperCase()) && title !== defaultFeatureTitle;
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        setTitle(defaultFeatureTitle);
    }, [open]);

    return (
        <React.Fragment>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <DialogContent dividers={true} ref={descriptionElementRef} tabIndex={-1}>
                    <DialogContentText id="alert-dialog-description">
                        Give a brief description about feature for your idea
                    </DialogContentText>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="featureTitle"
                                name="featureTitle"
                                label="Feature details"
                                variant="outlined"
                                inputProps={{ maxLength: FEATURE_TITLE_SIZE_ALLOWED }}
                                placeholder="Enter feature details"
                                multiline
                                error={isDuplicateFeature()}
                                helperText={isDuplicateFeature() && `feature already exists`}
                                rows={3}
                                autoFocus
                                value={title}
                                fullWidth
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" fullWidth>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} fullWidth disabled={title === '' || isDuplicateFeature()} variant="contained" color="secondary">
                        {data ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

ManageFeatureDialog.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    features: PropTypes.array,
    onCancel: PropTypes.func,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        features: idx(currentProjectData, _ => _.features) || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageFeatureDialog);