import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';

import CssBaseline from '@material-ui/core/CssBaseline';

import ScrollToTop from '../components/Common/ScrollToTop'
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./../config/authConfig";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

const Root = ({ store, history }) => {

  return (
    <MsalProvider instance={msalInstance}>
      <CssBaseline />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop />   
            <App />
        </ConnectedRouter>
      </Provider>
    </MsalProvider>
  );
}
export default Root;

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
