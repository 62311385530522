import axios from 'axios';
import routes from '../constants/routes';
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { UNAUTHENTICATED_ENDPOINTS } from "../constants/apiEndpoints";
import { loginRequest, msalConfig } from "../config/authConfig.js";
import { setSeverityStatus } from '../actions/planActions';
import { ERROR_SEVERITY, INFO_SEVERITY } from '../constants/static';

const interceptor = (store) => {
    axios.interceptors.request.use(async (request) => {
        if (request.headers['Content-Type'] === '' || request.headers['Accept'] === '') {
            request.headers['Content-Type'] = 'application/json';
            request.headers['Accept'] = 'application/json';
        }
        if (!UNAUTHENTICATED_ENDPOINTS.includes(request.url)) {
            const instance = new PublicClientApplication(msalConfig);
            const accounts = instance.getAllAccounts();
            if (accounts === null || accounts === undefined || accounts.length === 0 || accounts[0] === null || accounts[0] === undefined) {
                instance.logoutPopup();
            } else {
                if (!instance.interactionInProgress()) {
                    const accessTokenRequest = {
                        scopes: loginRequest.scopes,
                        account: accounts[0]
                    }
                    await instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                        // Acquire token silent success
                        const accessToken = accessTokenResponse.accessToken;
                        // Call your API with token
                        request.headers['Authorization'] = 'Bearer ' + accessToken;
                    }).catch((error) => {
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
                                // Acquire token interactive success
                                const accessToken = accessTokenResponse.accessToken;
                                // Call your API with token
                                request.headers['Authorization'] = 'Bearer ' + accessToken;
                            }).catch(function () {
                                // Acquire token interactive failure
                                store.dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops!! some issue has occured, please try refreshing the page'));
                            });
                        }
                        store.dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops!! some issue has occured, please try refreshing the page'));
                    })
                }
            }
            return request;
        }
    });

    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (!error.response) {
            store.dispatch(setSeverityStatus(ERROR_SEVERITY, 'Please check your internet connection and try again'));
        }
        else if (error.response && error.response.status === 401) {
            const instance = new PublicClientApplication(msalConfig);
            instance.logoutPopup();
            window.location.replace(window.location.origin + routes.notAuthorized.url)
        } else if (error.response && error.response.status === 403) {
            store.dispatch(setSeverityStatus(INFO_SEVERITY, 'Access Denied!! You don\'t have permission to access the resource you are trying to access'));
        }
        return Promise.reject(error);
    });
};
export default { interceptor };