
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Chip, Grid, CardActionArea, CardActions, Button, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { fetchProjectTitle, fetchDisplayStatus } from '../../utils/projectUtil';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { COMPLETED, IN_PROGRESS } from '../../constants/projectTypes';
import { ALERT_DATE_FORMAT } from '../../constants/static';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
        cursor: 'pointer'
    },
    chip: {
        marginBottom: theme.spacing(2),
        height: '20px',
        padding: '4px 0px',
        fontSize: '85%',
        marginRight: theme.spacing(0.5)
    },
    greenChip: {
        backgroundColor: 'rgb(76, 175, 80)',
        color: 'rgb(255, 255, 255)',
    },
    orangeChip: {
        backgroundColor: 'rgb(255, 152, 0)',
        color: 'rgb(255, 255, 255)',
    },
    deleteButton: {
        color: 'rgb(255,0,0)'
    },
    datesItem: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse'
    },
    dateIcon: {
        marginRight: theme.spacing(1)
    },
}));

const ProjectCard = (props) => {
    const classes = useStyles();
    const { project, onClick, onDelete, onExport, ...rest } = props;
    return (
        <Card className={classes.card} {...rest}>
            <CardActionArea onClick={() => onClick(project.id)}>
                <CardContent color="primary">
                    <Typography variant="h5" gutterBottom>{project.idea ? project.idea.title : ''}</Typography>
                    <Chip className={clsx(classes.chip,
                        { [classes.greenChip]: project.status.toUpperCase() === COMPLETED },
                        { [classes.orangeChip]: project.status.toUpperCase() === IN_PROGRESS })} label={fetchDisplayStatus(project.status)} />
                    <Chip className={classes.chip} label={fetchProjectTitle(project.type)} />
                    <Typography variant="body1" color="textSecondary">{project.idea ? project.idea.description : ''}</Typography>
                    <br />
                    <Grid container justify="space-between">
                        <Grid item>
                            <Typography variant="caption" color="textSecondary" display="inline">{`Created on ${moment(project.createdOn).format(ALERT_DATE_FORMAT)}`}</Typography>
                        </Grid>
                        <Grid item>
                            <div className={classes.datesItem}>
                                <Typography variant="caption" color="textSecondary" display="inline">{`Updated ${moment(project.updatedOn).fromNow()}`}</Typography>
                                <AccessTimeIcon className={classes.dateIcon} color="action" />
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <Divider />
            <CardActions>
                <Button startIcon={<ImportExportIcon />} onClick={() => onExport(project.id)} fullWidth size="small" color="primary">
                    Export project
                </Button>
                <Button startIcon={<DeleteIcon />} onClick={() => onDelete(project.id)} fullWidth size="small" className={classes.deleteButton}>
                    Delete project
                </Button>
            </CardActions>
        </Card>
    );
};

ProjectCard.propTypes = {
    project: PropTypes.object,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    onExport: PropTypes.func
};

export default ProjectCard;
