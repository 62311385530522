import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { DialogContentText, DialogContent, Dialog, DialogActions, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CustomDialogTitle from './CustomDialogTitle';

const useStyles = makeStyles(() => ({
    actions: {
        padding: '0 24px 24px 24px'
    },
}));

const styles = (theme) => ({
    root: {
        color: theme.palette.colors.feijoa,
        '&$checked': {
            color: theme.palette.colors.feijoa,
        },
    },
    checked: {},
});

const GreenCheckbox = withStyles(styles)((props) => (
    <Checkbox color="default" {...props} />
));

const ExportProjectConfirmation = (props) => {
    const classes = useStyles();
    const { open, deleteProject, onExport, onCancel, handleChangeDeleteProject } = props;
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CustomDialogTitle id="alert-dialog-title" onClose={onCancel}>
                Export project
            </CustomDialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Project will be exported to JSON format and you can use our portal to import the project the using JSON file downloaded
                </DialogContentText>
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            checked={deleteProject}
                            onChange={handleChangeDeleteProject}
                            name="checkedG"
                        />
                    }
                    label={
                        <div
                            dangerouslySetInnerHTML={{ __html: 'Delete project' }}
                        ></div>
                    }
                />

            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onCancel} variant="outlined" color="primary" fullWidth size="large">
                    Cancel
                </Button>
                <Button startIcon={<ImportExportIcon />} onClick={onExport} variant="contained"
                    fullWidth size="large" autoFocus color="secondary">
                    Export project
                </Button>
            </DialogActions>
        </Dialog>
    );
}
ExportProjectConfirmation.propTypes = {
    open: PropTypes.bool,
    deleteProject: PropTypes.bool,
    handleChangeDeleteProject: PropTypes.func,
    onExport: PropTypes.func,
    onCancel: PropTypes.func
};

export default ExportProjectConfirmation;