export const UNAUTHENTICATED_ENDPOINTS = [];

// user related
export const UPDATE_DISPLAYNAME_URL = process.env.PAYMENT_BASE_URL + '/api/v1/users/displayname';
export const DELETE_PROFILE_URL = process.env.PAYMENT_BASE_URL + '/api/v1/users';
export const UPDATE_TNC_STATUS_URL = process.env.PAYMENT_BASE_URL + '/api/v1/users/terms-conditions';

// plan related
export const FETCH_USER_PLAN = process.env.PAYMENT_BASE_URL + '/api/v1/subscription/current';
export const INITIATE_BUY_PLAN = process.env.PAYMENT_BASE_URL + '/api/v1/subscription/session';
export const VALIDATE_SESSION_ID = process.env.PAYMENT_BASE_URL + '/api/v1/subscription/session/validate';
export const CUSTOMER_PORTAL_URL = process.env.PAYMENT_BASE_URL + '/api/v1/customer-portal/session';
export const UPDATE_PLAN_URL = process.env.PAYMENT_BASE_URL + '/api/v1/subscriptions';

// project related
export const FETCH_PROJECTS_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/projects';
export const DELETE_PROJECT_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/projects';
export const CREATE_PROJECT_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/projects';
export const EXPORT_PROJECT_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/project/export';
export const IMPORT_PROJECT_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/project/import';
export const UPDATE_IDEA_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/projects/idea';
export const ADD_SELECTED_OPTION_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/projects/option';
export const UPDATE_PROJECT_STATUS_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/projects/status';

// Feature related endpoints
export const CREATE_FEATURE_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/features';
export const UPDATE_FEATURE_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/features';
export const DELETE_FEATURE_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/features';
export const FETCH_ALL_FEATURE_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/features';
export const CREATE_FEATURE_LABEL_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/feature/labels';
export const DELETE_FEATURE_LABEL_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/feature/labels';
export const ASSIGN_FEATURE_LABEL_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/feature/label/assign';
export const CREATE_FEATURE_EVALUATION_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/feature/evaluators';
export const UPDATE_FEATURE_EVALUATION_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/feature/evaluators';
export const DELETE_FEATURE_EVALUATION_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/feature/evaluators';
export const EVALUATOR_FEATURE_RATING_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/evaluator/feature/ratings';
export const ASSIGN_FEATURE_ITERATION_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/option/feature/iterations';
export const OPTION_FEATURE_RATING_URL = process.env.CECI_FEATURE_BASE_URL + '/api/v1/project/option/feature/ratings';

// Goal related endpoints
export const CREATE_GOAL_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goals';
export const UPDATE_GOAL_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goals';
export const DELETE_GOAL_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goals';
export const FETCH_ALL_GOAL_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goals';
export const CREATE_GOAL_LABEL_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goal/labels';
export const DELETE_GOAL_LABEL_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goal/labels';
export const ASSIGN_GOAL_LABEL_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goal/label/assign';
export const CREATE_GOAL_EVALUATION_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goal/evaluators';
export const UPDATE_GOAL_EVALUATION_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goal/evaluators';
export const DELETE_GOAL_EVALUATION_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/goal/evaluators';
export const EVALUATOR_GOAL_RATING_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/evaluator/goal/ratings';
export const OPTION_GOAL_RATING_URL = process.env.CECI_GOAL_BASE_URL + '/api/v1/project/option/goal/ratings';

// Option related endpoints
export const CREATE_OPTION_URL = process.env.CECI_OPTION_BASE_URL + '/api/v1/project/options';
export const UPDATE_OPTION_URL = process.env.CECI_OPTION_BASE_URL + '/api/v1/project/options';
export const DELETE_OPTION_URL = process.env.CECI_OPTION_BASE_URL + '/api/v1/project/options/delete';
export const FETCH_ALL_OPTION_URL = process.env.CECI_OPTION_BASE_URL + '/api/v1/project/options';
export const UPLOAD_OPTION_IMAGE_URL = process.env.CECI_OPTION_BASE_URL + '/api/v1/project/options/image/upload';
export const UPDATE_OPTION_IMAGE_URL = process.env.CECI_OPTION_BASE_URL + '/api/v1/project/options/image/upload';
export const FETCH_OPTION_IMAGE_URL = process.env.CECI_OPTION_BASE_URL + '/api/v1/project/options/image';
export const DELETE_OPTION_IMAGE_URL = process.env.CECI_OPTION_BASE_URL + '/api/v1/project/options/image';

// Exports endpoints
export const EXPORT_IDEA_SUMMARY_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/project/export/idea-summary';
export const EXPORT_PROJECT_SUMMARY_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/project/export/project-summary';
export const EXPORT_PROJECT_DETAIL_SCORE_URL = process.env.CECI_PROJECT_BASE_URL + '/api/v1/user/project/export/project-detailed-score';