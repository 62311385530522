
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { Grid, Card, CardContent, Typography, CardActions, Chip } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { fetchLabelTitleLabelId, fetchRatingByOptionId } from '../../utils/projectUtil';
import { OPTION_RATING_LABEL } from '../../constants/evaluation/evaluationMetaData';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
    },
    cardAction: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`
    },
    feature: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    rating: {
        padding: 'inherit'
    },
    chips: {
        padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

const RateOptionCard = (props) => {
    const classes = useStyles();
    const { optionId, feature, hoveredFeature, ratingHoverState, handleRatingUpdateHoverState, labels, handleHoveredFeature, handleFeatureRating, ...rest } = props;
    const labelTitles = fetchLabelTitleLabelId(labels, feature.labels);

    return (
        <Card className={classes.card} {...rest}>
            <Grid container spacing={3} justify="flex-end" className={classes.cardAction}>
                <Grid item>
                    <CardActions disableSpacing>
                        {hoveredFeature === feature.id && <Typography className={classes.rating} variant="body1">{OPTION_RATING_LABEL[ratingHoverState !== -1 ? ratingHoverState : 2]}</Typography>}
                        <Rating name={`ratings_${feature.id}`} size="large"
                            value={fetchRatingByOptionId(optionId, feature.optionsRating)}
                            onChangeActive={(e, newHover) => {
                                handleRatingUpdateHoverState(newHover);
                            }}
                            onMouseOver={() => handleHoveredFeature(feature.id)}
                            onMouseOut={() => handleHoveredFeature('')}
                            onChange={(e, newValue) => {
                                handleFeatureRating(feature.id, newValue);
                            }}
                        />
                    </CardActions>
                </Grid>
            </Grid>
            <CardContent className={classes.feature}>
                <Typography variant="h5"> {feature.title}</Typography>
            </CardContent>
            {labelTitles.length != 0 && <CardContent className={classes.chips}>
                {labelTitles.map((title, index) => {
                    return (
                        <Chip key={`chip${index}`} variant="outlined" color="primary" label={title} />
                    )
                })}
            </CardContent>
            }
        </Card >
    );
};

RateOptionCard.propTypes = {
    optionId: PropTypes.string,
    feature: PropTypes.object,
    hoveredFeature: PropTypes.string,
    ratingHoverState: PropTypes.any,
    handleRatingUpdateHoverState: PropTypes.func,
    labels: PropTypes.array,
    handleFeatureRating: PropTypes.func,
    handleHoveredFeature: PropTypes.func
};

export default RateOptionCard;
