import React from 'react';
import DashboardBody from '../../../components/Body/DashboardBody';
import DashboardTitle from '../../../components/Body/DashboardTitle';
import { Container, Grid } from '@material-ui/core';
import { MetaDashboardBilling } from './Seo/MetaDashboardBilling';
import { admin } from '../../../constants/routes';
import * as actions from '../../../actions/planActions';
import Plan from './plan';
import CustomerPortal from './customerPortal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import UpgradePlan from './upgradePlan';
import WaitingLoader from '../../../components/Loader/WaitingLoader';
import NotFound from '../../../components/Common/NotFound';

const Billing = ({ actions }) => {
  let breadcrumb = [{ title: admin.dashboard.label, link: admin.dashboard.url }]
  const [planDetails, setPlanDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    fetchUserPlan();
  }, []);

  const fetchUserPlan = () => {
    actions.fetchPlanData()
      .then(data => {
        setPlanDetails(data);
      })
      .catch(() => { setHasError(true) })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <DashboardBody metaData={MetaDashboardBilling}>
        <Container maxWidth="lg">
          <DashboardTitle title={admin.billing.label} breadcrumbs={breadcrumb} />
          {loading && <WaitingLoader />}
          {hasError && <NotFound image={"/static/svg/dashboard/5xxError.svg"}
            imageAlt={"Error"}
            title={"Error while fetching data"}
            subTitle={"We have got an error while your fetching data. Try refreshing the page, if issue persists please try logging in again after some time"} />}
          {!loading && !hasError &&
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Plan planDetails={planDetails} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomerPortal planDetails={planDetails} />
              </Grid>
              <Grid item xs={12}>
                <UpgradePlan planDetails={planDetails} fetchUserPlan={fetchUserPlan}/>
              </Grid>
            </Grid>
          }
        </Container>
      </DashboardBody>
    </>
  );
};

Billing.propTypes = {
  actions: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(Billing);