import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    marginTop: theme.header.height,
    backgroundColor: theme.palette.colors.reseneSmaltBlue
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0
  },
  sectionContainer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(6),
    },
  },
  title: {
    textTransform: 'none',
    marginBottom: theme.spacing(6)
  },
});

function Body(props) {
  const { classes, children, title = '' } = props;

  return (
    <main className={classes.root}>
      <Container className={classes.container}>
        <Container className={classes.sectionContainer}>
          {title && (
            <Typography
              variant="h1"
              align="center"
              className={classes.title}
              gutterBottom
            >
              {title}
            </Typography>
          )}
          {children}
        </Container>
      </Container>
    </main>
  );
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
};

export default withStyles(styles)(Body);
