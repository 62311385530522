import React from 'react';
import { Button, Grid, TextField, IconButton, FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types'
import { HighlightOff, Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    removeIcon: {
        padding: '5px',
        marginTop: '10px',
        '& svg': {
            fontSize: 25,
            color: 'red',
            padding: '0px'
        }
    },
    textWithAddIcon: {
        width: '85%',
    },
    fieldset: {
        borderColor: 'rgba(0, 0, 0, 0.23)'
    }
}));

const AddListComponent = (props) => {
    const classes = useStyles();
    const { legendText, fieldValues, setFieldValues, limit, buttonText, variant, placeholder, maxInputLength, ...rest } = props;

    const disabled = () => {
        return fieldValues.length >= limit;
    }

    return (
        <FormControl fullWidth {...rest}>
            <fieldset className={classes.fieldset}>
                <FormLabel component="legend">{legendText}</FormLabel>
                <Grid container spacing={1}>
                    {fieldValues.map((fieldValue, index) => {
                        return (
                            <Grid item xs={12} id={index} key={index}
                                name={`grid_${index}`}>
                                <TextField
                                    id={legendText + index}
                                    name={`${variant}_${index}`}
                                    variant="outlined"
                                    key={`${variant}_${index}`}
                                    placeholder={placeholder}
                                    className={classes.textWithAddIcon}
                                    value={fieldValue}
                                    inputProps={{ maxLength: maxInputLength }}
                                    fullWidth
                                    onChange={(e) => {
                                        const updatedFieldValues = [...fieldValues];
                                        updatedFieldValues.splice(index, 1, e.target.value);
                                        setFieldValues(updatedFieldValues);
                                    }} />
                                <IconButton className={classes.removeIcon} onClick={() => {
                                    const newFieldValue = fieldValues.filter(value => value !== fieldValue);
                                    setFieldValues(newFieldValue);
                                }}>
                                    <HighlightOff />
                                </IconButton>
                            </Grid>
                        )
                    })}
                    <Grid item xs={12}>
                        <FormControl>
                            <Button
                                disabled={disabled()}
                                color="primary"
                                size="small"
                                startIcon={<Add />}
                                onClick={() => {
                                    setFieldValues([...fieldValues, '']);
                                }}>
                                {buttonText}
                            </Button>
                            {disabled() && <FormHelperText>Max {limit} {variant} are allowed</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </fieldset>
        </FormControl>
    );
}
AddListComponent.propTypes = {
    legendText: PropTypes.string,
    fieldValues: PropTypes.array,
    setFieldValues: PropTypes.func,
    limit: PropTypes.number,
    buttonText: PropTypes.string,
    variant: PropTypes.string,
    placeholder: PropTypes.string,
    maxInputLength: PropTypes.number
};

export default AddListComponent;