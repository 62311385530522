import { admin } from "../constants/routes";
import { TERMS_CONDTIONS_LOCAL_STORAGE_KEY } from "../constants/static";

export const fetchName = (account) => {
    if (account === undefined || account === null) return '';
    const claims = account.idTokenClaims;
    return claims.given_name + ' ' + claims.family_name;
}

export const fetchRole = (account) => {
    if (account === undefined || account === null) return '';
    const claims = account.idTokenClaims;
    return claims.extension_Role;
}

export const hasUserAcceptedTermsAndConditions = (account) => {
    if (account === undefined || account === null) return true;
    const claims = account.idTokenClaims;
    const termsStatus = window.localStorage.getItem(TERMS_CONDTIONS_LOCAL_STORAGE_KEY);
    if (termsStatus) {
        return termsStatus;
    } else {
        return claims.extension_Termsandconditions ? claims.extension_Termsandconditions : false;
    }
}

export const fetchAccountDetails = (account) => {
    if (account === undefined || account == null) return {};
    const claims = account.idTokenClaims;
    return {
        firstName: claims.given_name,
        lastName: claims.family_name,
        role: claims.jobTitle,
        emailId: account.username,
        usage: claims.extension_Usage,
        industryVertical: claims.extension_IndustryVertical
    };
}

export const logout = (instance, history, actions) => {
    instance.logoutPopup().then(() => {
        history.push(admin.ceciStudio.url);
        actions.resetState();
        localStorage.clear();
    });
}