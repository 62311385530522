export const MetaAboutUsPage = {
  title: 'About Us - Feijoa8',
  pageTitle: 'About Us',
  meta: [
    {
      name: 'title',
      content: 'About Us - Feijoa8',
    },
    {
      name: 'description',
      content: 'We develop tools that help you develop your idea and take it from a simple concept to a well structured product definition.',
    },
    {
      name: 'keywords',
      content: 'About Feijoa8,About Us, Agile, Design Thinking, Business, Product Development,Lean Six Sigma, Structure Problem Solving Tool, Collaboration, Business Analysis, Product Strategy, Scum, SAFe',
    },
    {
      name: 'robots',
      content: 'index, follow',
    },
  ],
};
