import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import global from './globalReducer';
import projects from './projectReducer';
import plan from './planReducer';

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  plan,
  userProjects: projects,
  global
});

export default rootReducer;
