import React from 'react';
import PropTypes from 'prop-types'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { CECI_DATE_FORMAT } from '../../constants/static';

const DateTextbox = (props) => {
    const { name, label, inputValue, onDateChange, ...rest } = props;
    const dateFormatter = str => {
        return str;
    };

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
                {...rest}
                autoOk={true}
                showTodayButton={true}
                format={CECI_DATE_FORMAT}
                id={name}
                name={name}
                fullWidth
                label={label}
                inputValue={inputValue}
                onChange={onDateChange}
                inputVariant="outlined"
                rifmFormatter={dateFormatter}
            />
        </MuiPickersUtilsProvider>
    );
}

DateTextbox.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    inputValue: PropTypes.any,
    onDateChange: PropTypes.func
};

export default DateTextbox;