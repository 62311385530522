import * as XLSX from "xlsx";
import { FIXED_COST_TYPE, RANGE_COST_TYPE, RESOURCE_COST_TYPE } from "../constants/evaluation/options";
import { EVALUATION_AGILE, EVALUATION_GENERIC } from "../constants/projectTypes";
import { convertHTMLToText, fetchDaysBetween, fetchResourceCost, formatCost } from "./common";
import { fetchLabelTitleLabelId } from "./projectUtil";

export const exportFeaturesExcel = (idea, features, evaluators, type) => {
    var workbook = XLSX.utils.book_new();
    const ideaData = [[idea.title], [idea.description]];
    const featuresData = generateFeaturesSheetData(features, evaluators);
    const data = [...ideaData, [], [], [], ...featuresData];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, type);
    XLSX.writeFile(workbook, type + ' - ' + idea.title + '.xlsx');
}

export const exportOptionsExcel = (idea, options, projectType) => {
    var workbook = XLSX.utils.book_new();
    const ideaData = [[idea.title], [idea.description]];
    const optionsData = generateOptionsSheetData(options, projectType);
    const data = [...ideaData, [], [], [], ...optionsData];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Options');
    XLSX.writeFile(workbook, 'Options - ' + idea.title + '.xlsx');
}

export const exportOptionEval = (idea, type, options, features) => {
    var workbook = XLSX.utils.book_new();
    const ideaData = [[idea.title], [idea.description]];
    const evalData = generateEvalSheetData(options, features);
    const data = [...ideaData, [], [], [], ...evalData];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, type + ' vs Options');
    XLSX.writeFile(workbook, type + '_evaluation - ' + idea.title + '.xlsx');
}

export const exportSummaryExcel = (projectType, idea, features, goals, options, selectedOption, featureEvaluators, goalEvaluators, strength) => {
    var workbook = XLSX.utils.book_new();

    const ideaData = [['Idea title', idea.title],
    ['Opportunity/Problem statement', idea.problemStatement],
    ['Idea description', idea.description],
    ['Key assumptions', idea.assumptions ? convertHTMLToText(idea.assumptions) : '-'],
    ['Customer benefits', idea.customerBenefit ? convertHTMLToText(idea.customerBenefit) : '-'],
    ['Target segment', idea.targetSegment ? convertHTMLToText(idea.targetSegment) : '-'],
    ['Value proposition', idea.valueProposition ? idea.valueProposition : '-'],
    ['Competitive Landscape', idea.competitiveLandscape ? convertHTMLToText(idea.competitiveLandscape) : '-'],
    ['Business impact summary', idea.businessImpactSummary ? idea.businessImpactSummary : '-'],
    ['Key metrics', idea.keyMetrics ? convertHTMLToText(idea.keyMetrics) : '-'],
    ['Cost structure (High level callouts)', idea.costStructure ? convertHTMLToText(idea.costStructure) : '-'],
    ['Revenue streams', idea.revenueStreams ? convertHTMLToText(idea.revenueStreams) : '-'],
    ['Notes', idea.notes ? idea.notes : '-']];
    const summary = generateSummary(options, projectType, strength, goals.length !== 0);

    const summarySheetData = [...ideaData, [], [], ['Selected Option', selectedOption === '' ? '' : options.find((option) => option.id === selectedOption).summary], [], [], ...summary];
    const summarySheet = XLSX.utils.aoa_to_sheet(summarySheetData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, 'Summary');

    const featuresData = generateFeaturesSheetData(features, featureEvaluators);
    const featuresSheet = XLSX.utils.aoa_to_sheet(featuresData);
    XLSX.utils.book_append_sheet(workbook, featuresSheet, 'Features');

    if (goals.length !== 0) {
        const goalsData = generateFeaturesSheetData(goals, goalEvaluators);
        const goalsSheet = XLSX.utils.aoa_to_sheet(goalsData);
        XLSX.utils.book_append_sheet(workbook, goalsSheet, 'Goals');
    }
    const optionsData = generateOptionsSheetData(options, projectType);
    const optionsSheet = XLSX.utils.aoa_to_sheet(optionsData);
    XLSX.utils.book_append_sheet(workbook, optionsSheet, 'Options');

    const featureEvalData = generateEvalSheetData(options, features);
    const featureEvalSheet = XLSX.utils.aoa_to_sheet(featureEvalData);
    XLSX.utils.book_append_sheet(workbook, featureEvalSheet, 'Features vs Options');

    if (goals.length !== 0) {
        const goalEvalData = generateEvalSheetData(options, goals);
        const goalEvalSheet = XLSX.utils.aoa_to_sheet(goalEvalData);
        XLSX.utils.book_append_sheet(workbook, goalEvalSheet, 'Goals vs Options');
    }

    XLSX.writeFile(workbook, idea.title + '.xlsx');
}

const generateSummary = (options, projectType, strength, hasGoals) => {
    const data = [];

    const heading = ['Options', 'Cost', 'Timeframe', 'Feature evaluation score'];
    if (hasGoals) {
        heading.push('Goal evaluation score')
    }
    data.push(heading);

    options.forEach(option => {
        const optionEvalData = [];
        const optionStrength = strength.find(op => op.optionId === option.id);
        optionEvalData.push(option.summary);
        optionEvalData.push(fetchOptionCost(option));
        optionEvalData.push(fetchOptionTimeframe(projectType, option));
        optionEvalData.push(optionStrength.featureOptionStrength);
        if (hasGoals) {
            optionEvalData.push(optionStrength.goalOptionStrength);
        }
        data.push(optionEvalData);
    })

    return data;
}

const generateEvalSheetData = (options, features) => {
    const data = [];

    const optionsSummary = options.map((option) => {
        return option.summary;
    })
    optionsSummary.unshift('');
    data.push(optionsSummary);

    features.forEach((feature) => {
        const optionsRatingAggregation = options.map((option) => {
            return feature.optionsRating.find((evalRat) => evalRat.optionId === option.id).rating;
        })
        const featureData = [feature.title, ...optionsRatingAggregation];
        data.push(featureData);
    })

    return data;
}

const fetchOptionCost = (option) => {
    if (option.cost.type === FIXED_COST_TYPE) {
        return '$ ' + formatCost(option.cost.value.fixedCost)
    } else if (option.cost.type === RANGE_COST_TYPE) {
        return '$ ' + formatCost(option.cost.value.minPrice) + ' - $ ' + formatCost(option.cost.value.maxPrice)
    } else if (option.cost.type === RESOURCE_COST_TYPE) {
        return '$ ' + fetchResourceCost(option.cost.value.resourceCost, fetchDaysBetween(option.startDate, option.endDate))
    }
}

const fetchOptionTimeframe = (projectType, option) => {
    return projectType === EVALUATION_AGILE ? option.duration + ' sprints' : fetchDaysBetween(option.startDate, option.endDate) + ' days';
}

const generateOptionsSheetData = (options, projectType) => {
    const data = [];

    const optionsSummary = options.map((option) => {
        return option.summary;
    })
    optionsSummary.unshift('Summary');
    data.push(optionsSummary);

    const optionsDescription = options.map((option) => {
        return option.description;
    })
    optionsDescription.unshift('Description');
    data.push(optionsDescription);

    const optionsCost = options.map((option) => {
        return fetchOptionCost(option);
    })
    optionsCost.unshift('Cost');
    data.push(optionsCost);

    const optionsTime = options.map((option) => {
        return fetchOptionTimeframe(projectType, option);
    })
    optionsTime.unshift('Timeframe');
    data.push(optionsTime);

    const optionsBenefits = options.map((option) => {
        return option.benefits.toString();
    })
    optionsBenefits.unshift('Key benefits');
    data.push(optionsBenefits);

    const optionsConstraints = options.map((option) => {
        return option.constraints.toString();
    })
    optionsConstraints.unshift('Major constraints');
    data.push(optionsConstraints);

    if (projectType !== EVALUATION_GENERIC) {
        const optionsTechDebt = options.map((option) => {
            return option.technicalDebt;
        })
        optionsTechDebt.unshift('Technical debt');
        data.push(optionsTechDebt);

        const optionsResources = options.map((option) => {
            return option.resourcesAvailable ? 'Yes' : 'No';
        })
        optionsResources.unshift('Are resources available');
        data.push(optionsResources);

        const optionsSquadAvail = options.map((option) => {
            return option.squadAvailable ? 'Yes' : 'No';
        })
        optionsSquadAvail.unshift('Is squad available');
        data.push(optionsSquadAvail);
    }

    return data;
}

const generateFeaturesSheetData = (features, evaluators) => {
    const data = [];

    const evaluatorsTitle = evaluators.map((evaluator) => {
        return evaluator.name;
    })
    evaluatorsTitle.unshift('');
    data.push(evaluatorsTitle);

    features.forEach((feature) => {
        const evaluatorsRatingAggregation = evaluators.map((evaluator) => {
            return feature.evaluatorsRating.find((evalRat) => evalRat.evaluatorId === evaluator.id).rating;
        })
        const featureData = [feature.title, ...evaluatorsRatingAggregation];
        data.push(featureData);
    })

    return data;
}

export const exportCSVForAzure = (ideaTitle, features, featureLabels) => {
    var workbook = XLSX.utils.book_new();
    const headers = [['Work Item Type'], ['Title'], ['Tags']];
    const featuresData = [];
    features.forEach(feature => {
        const featureData = [];
        const labels = fetchLabelTitleLabelId(featureLabels, feature.labels);
        featureData.push('Epic', feature.title);
        let tags = '';
        for (let index = 0; index < labels.length; index++) {
            if (index + 1 === labels.length) {
                tags = tags + labels[index];
            } else {
                tags = tags + labels[index] + ';';
            }
        }
        featureData.push(tags);
        featuresData.push(featureData);
    });
    const data = [headers, ...featuresData];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'AzureDevops');
    XLSX.writeFile(workbook, ideaTitle + '-AzureDevops.csv');
}

export const exportCSVForJIRA = (ideaTitle, features, featureLabels) => {
    const maxFeatureLabelsCount = countMaxLabelsForAnyFeature(features);
    var workbook = XLSX.utils.book_new();
    const headers = [['Summary']];
    for (let v = 0; v < maxFeatureLabelsCount; v++) {
        headers.push(['Labels'])
    }
    const featuresData = [];
    features.forEach(feature => {
        const featureData = [];
        const labels = fetchLabelTitleLabelId(featureLabels, feature.labels);
        featureData.push(feature.title);
        featureData.push(...labels);
        for (let v = 0; v < (maxFeatureLabelsCount - labels.length); v++) {
            featureData.push('')
        }
        featuresData.push(featureData);
    });
    const data = [headers, ...featuresData];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'JIRA');
    XLSX.writeFile(workbook, ideaTitle + '-JIRA.csv');
}

const countMaxLabelsForAnyFeature = (features) => {
    let count = 0;
    features.forEach((feature) => {
        if (feature.labels !== undefined && feature.labels !== null && feature.labels.length !== 0) {
            const labelCount = feature.labels.length;
            if (labelCount > count) {
                count = labelCount;
            }
        }
    })
    return count;
}