import React from 'react';
import clsx from 'clsx';
import { Card, CardHeader, Box, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Chip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import WaitingLoader from '../../../components/Loader/WaitingLoader';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useHistory } from 'react-router-dom';
import { admin } from '../../../constants/routes';
import { fetchDisplayStatus, fetchProjectTitle, filterAndSortByProjects } from '../../../utils/projectUtil';
import AddIcon from '@material-ui/icons/Add';
import NavigationButton from '../../../components/Button/NavigationButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions/projectActions';
import { ALL, COMPLETED, IN_PROGRESS, UPDATED_ASC } from '../../../constants/projectTypes';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: theme.spacing(2),
        margin: `${theme.spacing(3)}px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
    },
    tableFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2)
    },
    createProjectbutton: {
        display: "flex",
        padding: theme.spacing(4),
        textAlign: "center",
        justifyContent: "center"
    },
    noProjects: {
        textAlign: "center"
    },
    chip: {
        height: theme.spacing(3),
        fontSize: '85%',
    },
    greenChip: {
        backgroundColor: 'rgb(76, 175, 80)',
        color: 'rgb(255, 255, 255)',
    },
    orangeChip: {
        backgroundColor: 'rgb(255, 152, 0)',
        color: 'rgb(255, 255, 255)',
    },
}));

const StyledTableCell = withStyles(() => ({
    head: {
        fontSize: 16,
    },
    body: {
        fontSize: 14,
        cursor: 'pointer'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ProjectsSummary = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { projects, isLoading, actions } = props;

    const [projectsData, setProjectsData] = React.useState([]);

    const viewProject = (projectId) => {
        actions.fetchProjectData(projectId)
            .then(() => {
                actions.setCurrentProject(projectId);
                history.push({ pathname: admin.editProject.url })
            })
    }

    React.useEffect(() => {
        setProjectsData(filterAndSortByProjects(projects, '', ALL, UPDATED_ASC).slice(0, 5));
    }, [projects]);

    return (
        <React.Fragment>
            <Card className={classes.card}>
                <CardHeader title="Latest Projects" />
                <Box>
                    {isLoading && <WaitingLoader loadingText="Loading projects" />}
                    {(projectsData.length === 0 && !isLoading) ?
                        <div className={classes.noProjects}>
                            <Typography variant="body1">You haven&apos;t created any project. Create a project now to start evaluation</Typography>
                            <NavigationButton title={"Create Project"}
                                navigateTo={admin.newProjectType.url}
                                className={classes.createProjectbutton}
                                icon={AddIcon} />
                        </div> :
                        <Table>
                            {!isLoading && <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">Idea</StyledTableCell>
                                    <StyledTableCell align="center">Type</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            }
                            <TableBody>

                                {projectsData.map((project, index) => (
                                    <StyledTableRow key={index} id={index} onClick={() => viewProject(project.id)}>
                                        <StyledTableCell align="left" width="60%">{project.idea ? project.idea.title : ''}</StyledTableCell>
                                        <StyledTableCell align="left" width="20%">{fetchProjectTitle(project.type)}</StyledTableCell>
                                        <StyledTableCell align="center" width="20%">
                                            <Chip className={clsx(classes.chip,
                                                { [classes.greenChip]: project.status.toUpperCase() === COMPLETED },
                                                { [classes.orangeChip]: project.status.toUpperCase() === IN_PROGRESS })} label={fetchDisplayStatus(project.status)} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>}
                </Box>
                {projectsData.length !== 0 && !isLoading && <Box className={classes.tableFooter}>
                    <Button color="primary" endIcon={<ArrowRightIcon fontSize="small" />}
                        size="small" variant="text" onClick={() => history.push(admin.projects.url)}>
                        View all
                    </Button>
                </Box>}
            </Card>
            {projectsData.length !== 0 && !isLoading && <Typography variant="caption">* showing latest 5 updated projects only</Typography>}
        </React.Fragment>
    );
};

ProjectsSummary.propTypes = {
    projects: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    actions: PropTypes.object
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(ProjectsSummary);