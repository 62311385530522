import { INSERT_PLAN_DATA, CLEAR_PLAN_DATA, UPDATE_PLAN_PRICE_PROJECTS, RESET_STATE } from '../constants/actionTypes';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function userReducer(state = initialState.plan, action) {
    switch (action.type) {
        case INSERT_PLAN_DATA:
            return action.payload;
        case CLEAR_PLAN_DATA:
            return {};
        case UPDATE_PLAN_PRICE_PROJECTS: {
            const updatedPlan = { ...state, projectsAllowed: action.payload.projectsAllowed, planPrice: action.payload.planPrice, planName: action.payload.planName };
            return objectAssign({}, updatedPlan);
        }
        case RESET_STATE: {
            return initialState.plan;
        }
        default:
            return state;
    }
}
