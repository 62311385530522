import React from 'react';
import PropTypes from 'prop-types';
import { Button, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { exportSummaryExcel } from '../../utils/exportEvaluation';
import FeaturesToolExport from '../Dialog/FeaturesToolExport';
import * as actions from '../../actions/projectActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translateDetailScoreExport, translateProjectSummaryExport } from '../../utils/projectUtil';

const IterativeExport = ((props) => {
    const { idea, projectType, features, goals, options, selectedOption, featureEvaluators, goalEvaluators, strength, actions } = props;

    const handleExportExcel = () => {
        exportSummaryExcel(projectType, idea, features, goals, options, selectedOption, featureEvaluators, goalEvaluators, strength);
    }

    const [open, setOpen] = React.useState(false);
    const [showToolDialog, setShowToolDialog] = React.useState(false);

    const anchorRef = React.useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleToolExport = () => {
        setOpen(false);
        setShowToolDialog(true);
    }

    const exportIdeaSummary = () => {
        actions.exportIdeaSummary();
    }

    const exportProjectSummary = () => {
        const requestBody = options.map((option) => { return translateProjectSummaryExport(option, selectedOption, strength); });
        actions.exportProjectSummary(requestBody);
    }

    const exportProjectDetailScore = () => {
        const requestBody = options.map((option) => { return translateDetailScoreExport(option, selectedOption, strength); });
        actions.exportProjectDetailScore(requestBody);
    }

    return (
        <div>
            <Button
                ref={anchorRef}
                variant="outlined"
                fullWidth
                size="large"
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={handleToggle}
            >
                Export
            </Button>
            <Popper style={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleExportExcel}>Excel (Export all)</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={exportIdeaSummary}>PDF (Export idea summary)</MenuItem>
                                    <MenuItem onClick={exportProjectSummary}>PDF (Export project summary)</MenuItem>
                                    <MenuItem onClick={exportProjectDetailScore}>PDF (Export project detail score)</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={handleToolExport}>CSV (Features) - JIRA</MenuItem>
                                    <MenuItem onClick={handleToolExport}>CSV (Features) - Azure DevOps</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <FeaturesToolExport ideaTitle={idea.title} open={showToolDialog} onCancel={() => setShowToolDialog(false)} />
        </div>
    );
});

IterativeExport.propTypes = {
    projectType: PropTypes.string.isRequired,
    idea: PropTypes.object.isRequired,
    features: PropTypes.array.isRequired,
    goals: PropTypes.array,
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.string,
    featureEvaluators: PropTypes.array,
    goalEvaluators: PropTypes.array,
    strength: PropTypes.array,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(IterativeExport);