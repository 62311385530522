export const MetaTNCPage = {
    title: 'Terms and Conditions - Privacy Policy - Feijoa8',
    pageTitle: 'Terms & conditions',
    meta: [
      {
        name: 'title',
        content: 'Terms and Conditions - Privacy Policy - Feijoa8',
      },
      {
        name: 'description',
        content: 'Feijoa8 terms and conditions, privacy policy',
      },
      {
        name: 'keywords',
        content: 'Feijoa8,terms and conditions,privacy policy',
      },
      {
        name: 'robots',
        content: 'index, follow',
      }
    ],
  };
  