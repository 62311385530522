import React from 'react';
import { Typography, TableContainer, Paper, Table, TableHead, TableRow, Tooltip, TableCell, TableBody, Chip, IconButton, Grid } from '@material-ui/core';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import { fetchLabelTitleLabelId, fetchRatingByOptionId } from '../../utils/projectUtil';
import InfoIcon from '@material-ui/icons/Info';
import OptionSummaryDialog from '../Dialog/OptionSummaryDialog';

const useStyles = makeStyles((theme) => ({
    chips: {
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    optionTitle: {
        display: 'inline-block',
    }
}))

const OptionComparisonComponent = (props) => {
    const classes = useStyles();
    const { options, features, projectType, handleRatingChange, featureLabels } = props;

    const [showOptionDetails, setShowOptionDetails] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState();

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ position: 'sticky', left: 0, backgroundColor: 'white' }}></TableCell>
                            {options.map((option, index) => {
                                return (
                                    <TableCell key={index} id={index} align="center">
                                        <Grid container justify="center">
                                            <Grid item xs={10}>
                                                <Typography variant="h6" gutterBottom className={classes.optionTitle}>{option.summary}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Tooltip title="view option details">
                                                    <IconButton onClick={() => {
                                                        setShowOptionDetails(true);
                                                        setSelectedOption(option);
                                                    }}>
                                                        <InfoIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {features.map((feature, index) => {
                            const labelTitles = fetchLabelTitleLabelId(featureLabels, feature.labels);
                            return (
                                <TableRow key={index} id={index}>
                                    <TableCell component="th" scope="row" style={{ position: 'sticky', left: 0, backgroundColor: 'white', zIndex: 1 }}>
                                        <Typography variant="subtitle1"> {feature.title}</Typography>
                                        {labelTitles != 0 && <div className={classes.chips}>
                                            {labelTitles.map((title, index) => {
                                                return (
                                                    <Chip key={`chip${index}`} variant="outlined" color="primary" label={title} />
                                                )
                                            })}
                                        </div>}
                                    </TableCell>
                                    {options.map((option, optionIndex) => {
                                        const rating = fetchRatingByOptionId(option.id, feature.optionsRating);
                                        return (
                                            <TableCell align="center" key={optionIndex} id={optionIndex}>
                                                <Rating name={`ratings_${index + 1}_${optionIndex + 1}`}
                                                    key={`ratings_${optionIndex + 1}`}
                                                    id={`ratings_${optionIndex + 1}`}
                                                    value={rating}
                                                    onChange={(e, newValue) => {
                                                        handleRatingChange(option.id, feature.id, newValue);
                                                    }} /> {rating}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <OptionSummaryDialog option={selectedOption} open={showOptionDetails} projectType={projectType} onCancel={() => setShowOptionDetails(false)} />
        </React.Fragment>
    );
}
OptionComparisonComponent.propTypes = {
    options: PropTypes.array,
    features: PropTypes.array,
    projectType: PropTypes.string,
    handleRatingChange: PropTypes.func,
    featureLabels: PropTypes.array
};

export default OptionComparisonComponent;