
import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: theme.palette.error.main
    }
}));

const DeleteSelectedButton = (props) => {
    const classes = useStyles();
    const { displayText, handleDeleteSelected, className } = props;
    return (
        <div className={className}>
            <IconButton className={classes.iconButton} onClick={handleDeleteSelected}>
                <Delete />
                <Typography variant="body1">{displayText}</Typography>
            </IconButton>
        </div>
    );
};

DeleteSelectedButton.propTypes = {
    displayText: PropTypes.string,
    handleDeleteSelected: PropTypes.func,
    className: PropTypes.string
};

export default DeleteSelectedButton;
