import axios from 'axios';
import * as types from '../constants/actionTypes';
import {
    CREATE_PROJECT_URL, DELETE_PROJECT_URL, EXPORT_PROJECT_URL, FETCH_PROJECTS_URL, FETCH_ALL_FEATURE_URL,
    FETCH_ALL_GOAL_URL, FETCH_ALL_OPTION_URL, IMPORT_PROJECT_URL, EXPORT_IDEA_SUMMARY_URL,
    EXPORT_PROJECT_SUMMARY_URL, EXPORT_PROJECT_DETAIL_SCORE_URL
} from '../constants/apiEndpoints';
import { setLoading, setSeverityStatus } from './planActions';
import { ERROR_SEVERITY, SUCCESS_SEVERITY, WARNING_SEVERITY, INFO_SEVERITY } from '../constants/static';
import { translateFeaturesToReduxStructure, translateGoalsToReduxStructure, translateOptionsToReduxStructure, translateProjectsToReduxStructure, translateProjectToReduxStructure } from './util/projectUtility';
import { fetchProjectIdFromState } from './utility';

export const setCurrentProject = (payload) => {
    return {
        type: types.SET_CURRENT_PROJECT,
        payload: payload
    };
}

const addProject = (payload) => {
    return {
        type: types.CREATE_PROJECT,
        payload
    };
}

const deleteProjectInRedux = (projectId) => {
    return {
        type: types.DELETE_PROJECT,
        payload: { projectId }
    }
}

export const resetState = () => {
    return {
        type: types.RESET_STATE
    }
}

export const replaceProjectsInRedux = (data) => {
    return {
        type: types.REPLACE_PROJECTS,
        payload: { projects: data }
    }
}

export const clearProjectsInRedux = () => {
    return {
        type: types.CLEAR_PROJECTS
    }
}

export const createProject = (projectType) => (dispatch) => {
    dispatch(setLoading(true, 'Creating your project'));
    return axios
        .post(CREATE_PROJECT_URL, {
            type: projectType
        })
        .then(({ data }) => {
            dispatch(addProject(translateProjectToReduxStructure(data)))
            return data.projectId;
        })
        .catch((err) => {
            const errorCode = err.response.status || 500;
            if (errorCode === 402) {
                dispatch(setSeverityStatus(WARNING_SEVERITY, 'You have used all the projects allowed. Upgrade your plan to create new project'));
            } else {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            }
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const deleteProject = (projectId) => (dispatch) => {
    dispatch(setLoading(true, 'Deleting your project'));
    const headers = { projectId: projectId };
    return axios
        .delete(DELETE_PROJECT_URL, {
            headers: headers
        })
        .then(() => {
            dispatch(deleteProjectInRedux(projectId))
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Congratulations!! Your project has been successfully deleted')
            );
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const exportProject = (projectId, deleteProjectFlag) => (dispatch) => {
    dispatch(setLoading(true, 'Exporting your project'));
    const requestheaders = { projectId: projectId, deleteProject: deleteProjectFlag };
    return axios
        .get(EXPORT_PROJECT_URL, {
            headers: requestheaders
        })
        .then(({ headers, data }) => {
            if (deleteProjectFlag) {
                dispatch(deleteProjectInRedux(projectId))
            }
            const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)])); // you can mention a type if you wish
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", headers['exportedfilename']); //this is the name with which the file will be downloaded
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Congratulations!! Your project has been successfully exported'));
        })
        .catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const fetchProjects = () => (dispatch, getState) => {
    if (!getState().userProjects.projects || getState().userProjects.projects.length === 0) {
        return axios
            .get(FETCH_PROJECTS_URL)
            .then(({ data }) => {
                const userProjects = translateProjectsToReduxStructure(data);
                dispatch(replaceProjectsInRedux(userProjects))
                return userProjects;
            })
            .catch(() => {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            });
    } else {
        return Promise.resolve(getState().userProjects.projects);
    }
}

const updateProjectDataInRedux = (payload) => {
    return {
        type: types.INJECT_FEATURES_GOALS_OPTIONS_IN_PROJECT,
        payload
    }
}

export const fetchProjectData = (projectId) => (dispatch) => {
    dispatch(setLoading(true, 'Fetching project data'));
    return Promise.all([fetchFeatures(projectId), fetchGoals(projectId), fetchOptions(projectId)])
        .then((data) => {
            dispatch(updateProjectDataInRedux({
                id: projectId,
                features: translateFeaturesToReduxStructure(data[0].data),
                goals: translateGoalsToReduxStructure(data[1].data),
                options: translateOptionsToReduxStructure(data[2].data)
            }))
            return data;
        }).catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            return Promise.reject();
        }).finally(() => {
            dispatch(setLoading(false, ''));
        });
}

const fetchFeatures = (projectId) => {
    const headers = { projectId: projectId };
    return axios
        .get(FETCH_ALL_FEATURE_URL, {
            headers: headers
        });
}

const fetchGoals = (projectId) => {
    const headers = { projectId: projectId };
    return axios
        .get(FETCH_ALL_GOAL_URL, {
            headers: headers
        });
}

const fetchOptions = (projectId) => {
    const headers = { projectId: projectId };
    return axios
        .get(FETCH_ALL_OPTION_URL, {
            headers: headers
        });
}

export const importProjectJson = (exportVersion, file) => (dispatch) => {
    dispatch(setLoading(true, 'Storing project'));
    const headers = { version: exportVersion };
    const formData = new FormData();
    formData.append('file', file);
    return axios
        .post(IMPORT_PROJECT_URL, formData, { headers: headers })
        .then(() => {
            dispatch(clearProjectsInRedux())
            dispatch(setSeverityStatus(SUCCESS_SEVERITY, 'Project successfully uploaded'));
            return "";
        })
        .catch((err) => {
            const errorCode = err.response.status || 500;
            const errorMessage = err.response.data.message;
            if (errorCode === 400 && errorMessage === 'File content was changed') {
                dispatch(setSeverityStatus(INFO_SEVERITY, 'Sorry, your file has been changed or corrupted and is no longer in a supported file format'));
            } else if (errorCode === 400) {
                dispatch(setSeverityStatus(WARNING_SEVERITY, 'File data structure is not in supported format'));
            } else {
                dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            }
            return Promise.reject();
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        });
}

export const exportIdeaSummary = () => (dispatch, getState) => {
    dispatch(setLoading(true, ''));
    return axios
        .get(EXPORT_IDEA_SUMMARY_URL + '/' + fetchProjectIdFromState(getState))
        .then(({ data }) => {
            window.open(data.downloadUrl);
        }).catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            return Promise.reject();
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        })
}

export const exportProjectSummary = (options) => (dispatch, getState) => {
    dispatch(setLoading(true, ''));
    return axios
        .post(EXPORT_PROJECT_SUMMARY_URL + '/' + fetchProjectIdFromState(getState), options)
        .then(({ data }) => {
            window.open(data.downloadUrl);
        }).catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            return Promise.reject();
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        })
}

export const exportProjectDetailScore = (options) => (dispatch, getState) => {
    dispatch(setLoading(true, ''));
    return axios
        .post(EXPORT_PROJECT_DETAIL_SCORE_URL + '/' + fetchProjectIdFromState(getState), options)
        .then(({ data }) => {
            window.open(data.downloadUrl);
        }).catch(() => {
            dispatch(setSeverityStatus(ERROR_SEVERITY, 'Oops! There\'s a problem. Please try again later'));
            return Promise.reject();
        })
        .finally(() => {
            dispatch(setLoading(false, ''));
        })
}