import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import * as actions from '../../../../actions/evaluationAction';
import { bindActionCreators } from 'redux';
import idx from 'idx';
import PropTypes from 'prop-types';
import { checkOptionImageOperationType, findCurrentProjectDetails } from '../../../../utils/projectUtil';
import AddListComponent from '../../../../components/List/AddListComponent';
import CustomDialogTitle from '../../../../components/Dialog/CustomDialogTitle';
import DateTextbox from '../../../../components/DisplayText/DateTextbox';
import OptionCostComponent from '../../../../components/Option/OptionCostComponent';
import SimpleDropDown from '../../../../components/Dropdown/SimpleDropDown';
import { DELETE_IMAGE_OPERATION_TYPE, FIXED_COST_TYPE, MAX_IMAGE_FILE_SIZE, OPTION_BENEFITS_ALLOWED, OPTION_BENEFIT_TEXT_LENGTH_ALLOWED, OPTION_CONSTRAINTS_ALLOWED, OPTION_CONSTRAINT_TEXT_LENGTH_ALLOWED, OPTION_DESCRIPTION_TEXT_LENGTH_ALLOWED, OPTION_SUMMARY_TEXT_LENGTH_ALLOWED, TECHNICAL_DEBTS } from '../../../../constants/evaluation/options';
import { Dialog, Grid, TextField, Typography, Button, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@material-ui/core';
import { fetchDaysBetween } from '../../../../utils/common';
import { CECI_DATE_FORMAT } from '../../../../constants/static';
import { EVALUATION_ITERATIVE } from '../../../../constants/projectTypes';
import Dropzone from '../../../../components/Dropzone';
function ManageIterativeOptionDialog({ data, open, options, onSave, onCancel, actions }) {

    const defaultSummary = idx(data, _ => _.summary) || '';
    const defaultDescription = idx(data, _ => _.description) || '';
    const defaultIterationSummary = idx(data, _ => _.iterationSummary) || '';
    const defaultIterationDescription = idx(data, _ => _.iterationdescription) || '';
    const defaultBenefits = idx(data, _ => _.benefits) || [];
    const defaultConstraints = idx(data, _ => _.constraints) || [];
    const defaultStartDate = idx(data, _ => _.startDate) || moment().format(CECI_DATE_FORMAT);
    const defaultEndDate = idx(data, _ => _.endDate) || moment().format(CECI_DATE_FORMAT);
    const defaultResourcesAvailable = idx(data, _ => _.resourcesAvailable) || false;
    const defaultTechnicalDebt = idx(data, _ => _.technicalDebt) || TECHNICAL_DEBTS[0].value;
    const defaultCost = idx(data, _ => _.cost) || { type: FIXED_COST_TYPE, value: { fixedCost: 0 } };
    const defaultOptionImage = idx(data, _ => _.image) || '';

    const [summary, setSummary] = React.useState(defaultSummary);
    const [description, setDescription] = React.useState(defaultDescription);
    const [iterationSummary, setIterationSummary] = React.useState(defaultIterationSummary);
    const [iterationDescription, setIterationDescription] = React.useState(defaultIterationDescription);
    const [benefits, setBenefits] = React.useState(defaultBenefits);
    const [constraints, setConstraints] = React.useState(defaultConstraints);
    const [startDate, setStartDate] = React.useState(defaultStartDate);
    const [endDate, setEndDate] = React.useState(defaultEndDate);
    const [resourcesAvailable, setResourcesAvailable] = React.useState(defaultResourcesAvailable);
    const [technicalDebt, setTechnicalDebt] = React.useState(defaultTechnicalDebt);
    const [cost, setCost] = React.useState(defaultCost);
    const [optionImage, setOptionImage] = React.useState(defaultOptionImage);

    const handleSave = () => {
        const id = data ? data.id : '';
        data ? actions.updateOption({
            summary, description, iterationSummary, iterationDescription, benefits, constraints, startDate,
            endDate, resourcesAvailable, technicalDebt, cost, id, projectType: EVALUATION_ITERATIVE, optionImage
        }) :
            actions.addOption({
                summary, description, iterationSummary, iterationDescription, benefits, constraints, startDate,
                endDate, resourcesAvailable, technicalDebt, cost, projectType: EVALUATION_ITERATIVE, optionImage
            });
        onSave && onSave();
        handleClose();
    };

    const handleClose = () => {
        onCancel();
    };

    const isDuplicateOption = () => {
        return options.some(option => option.summary.toUpperCase() === summary.toUpperCase()) && summary !== defaultSummary;
    }

    const hasRequiredValues = () => {
        return summary !== '' && fetchDaysBetween(startDate, endDate) >= 0;
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        setSummary(defaultSummary);
        setDescription(defaultDescription);
        setIterationSummary(defaultIterationSummary);
        setIterationDescription(defaultIterationDescription);
        setBenefits(defaultBenefits);
        setConstraints(defaultConstraints);
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setCost(defaultCost);
        setResourcesAvailable(defaultResourcesAvailable);
        setTechnicalDebt(defaultTechnicalDebt);
        setOptionImage(defaultOptionImage);
    }, [open]);

    return (
        <React.Fragment>
            <Dialog maxWidth={"md"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Typography component="div" align="center">
                        <strong>{data ? "Edit" : "Add"} Option Details</strong>
                    </Typography>
                </CustomDialogTitle>
                <DialogContent dividers={true} ref={descriptionElementRef} tabIndex={-1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField required id="optionSummary" name="optionSummary" label="Option summary" fullWidth
                                error={isDuplicateOption()} variant="outlined" placeholder="Start typing the option summary"
                                autoFocus helperText={`Total characters ${summary.length}/${OPTION_SUMMARY_TEXT_LENGTH_ALLOWED}`}
                                inputProps={{ maxLength: OPTION_SUMMARY_TEXT_LENGTH_ALLOWED }} value={summary}
                                onChange={(e) => {
                                    setSummary(e.target.value);
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="optionDescription" name="optionDescription" label="Option Description" variant="outlined"
                                placeholder="Option description" multiline rows={3} value={description} fullWidth
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }} inputProps={{ maxLength: OPTION_DESCRIPTION_TEXT_LENGTH_ALLOWED }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="iterationSummary" name="iterationSummary" label="Iteration summary" fullWidth
                                variant="outlined" placeholder="Start typing the iteration summary"
                                helperText={`Total characters ${iterationSummary.length}/${OPTION_SUMMARY_TEXT_LENGTH_ALLOWED}`}
                                inputProps={{ maxLength: OPTION_SUMMARY_TEXT_LENGTH_ALLOWED }} value={iterationSummary}
                                onChange={(e) => {
                                    setIterationSummary(e.target.value);
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="iterationDescription" name="iterationDescription" label="Iteration Description" variant="outlined"
                                placeholder="Iteration description" multiline rows={3} value={iterationDescription} fullWidth
                                onChange={(e) => {
                                    setIterationDescription(e.target.value);
                                }} inputProps={{ maxLength: OPTION_DESCRIPTION_TEXT_LENGTH_ALLOWED }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AddListComponent id="benefits" name="benefits" legendText="Key benefits" fieldValues={benefits}
                                setFieldValues={(value) => setBenefits(value)} limit={OPTION_BENEFITS_ALLOWED} maxInputLength={OPTION_BENEFIT_TEXT_LENGTH_ALLOWED}
                                buttonText="Add key benefits" variant="benefits" placeholder="Type key benefit" />
                        </Grid>
                        <Grid item xs={12}>
                            <AddListComponent id="constraints" name="constraints" legendText="Major constraints" fieldValues={constraints}
                                setFieldValues={(value) => setConstraints(value)} limit={OPTION_CONSTRAINTS_ALLOWED} maxInputLength={OPTION_CONSTRAINT_TEXT_LENGTH_ALLOWED}
                                buttonText="Add major constraints" variant="constraints" placeholder="Type major constraint" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTextbox required name="startDate" label="Start date" inputValue={startDate} onDateChange={(d, v) => setStartDate(v)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTextbox required name="deliveryDate" minDateMessage="Delivery date should be after start date" label="Estimated delivery date" minDate={new Date(startDate)} inputValue={endDate} onDateChange={(d, v) => setEndDate(v)} />
                        </Grid>
                        <Grid item xs={12}>
                            <OptionCostComponent cost={cost} handleCost={(value) => setCost(value)} deliveryDays={fetchDaysBetween(startDate, endDate)} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={<Checkbox checked={resourcesAvailable} onChange={(event) => { setResourcesAvailable(event.target.checked) }} name="resourcesAvailable" />}
                                label="Are resources available?"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SimpleDropDown fullWidth text="Technical debt" value={technicalDebt} options={TECHNICAL_DEBTS} onChange={(e) => {
                                setTechnicalDebt(e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Dropzone
                                acceptedFiles={['image/*']}
                                dropzoneText={"Drag and drop an image here or click"}
                                filesLimit={1}
                                initialFiles={optionImage === '' ? [] : [optionImage]}
                                maxFileSize={MAX_IMAGE_FILE_SIZE}
                                showAlerts={true}
                                showPreviewsInDropzone={true}
                                onDelete={() => {
                                    setOptionImage({ operation: DELETE_IMAGE_OPERATION_TYPE })
                                }}
                                onUploadFile={({ data, file }) => {
                                    if (file === '') {
                                        setOptionImage({ operation: DELETE_IMAGE_OPERATION_TYPE });
                                    } else {
                                        setOptionImage({ data, file, operation: checkOptionImageOperationType(data, defaultOptionImage) });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" fullWidth>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} fullWidth disabled={!hasRequiredValues() || isDuplicateOption()} variant="contained" color="secondary">
                        {data ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

ManageIterativeOptionDialog.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func,
    options: PropTypes.array,
    onCancel: PropTypes.func,
    actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = findCurrentProjectDetails(state.userProjects.global.currentProjectId, state.userProjects.projects)
    return {
        options: idx(currentProjectData, _ => _.options) || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageIterativeOptionDialog);