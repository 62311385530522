import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types'
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    starIcon: {
        color: '#ffb400',
        position: 'absolute'
    },
    total: {
        fontSize: '18px',
        wordBreak: 'break-word'
    },
    title: {
        color: 'grey',
        fontSize: '12px'
    }
}));

const RatingSummary = (props) => {
    const classes = useStyles();

    const { total, title } = props;

    return (
        <React.Fragment>
            <Typography component="div" align="center" className={classes.total}>
                {total}<StarIcon className={classes.starIcon} />
            </Typography>
            <Typography align="center" className={classes.title}>
                {title}
            </Typography>
        </React.Fragment>
    );
}

RatingSummary.propTypes = {
    total: PropTypes.string,
    title: PropTypes.string
};

export default RatingSummary;