export const RATING_MAPPING = { null: 1, '0': 1, '1': 2, '2': 3, '3': 5, '4': 8, '5': 13 }

export const MULTIPLICATION_FACTOR = 13

export const tourSteps = [
    {
        target: '#stepTitle',
        title: 'Summary',
        content: 'Welcome to your Evaluation Summary. From here you can complete your analysis and present you findings',
        disableBeacon: true
    }, {
        target: '#ideaDetails',
        content: 'Your Idea Title and Description are shown here. This is from the Idea step. If you need to edit this, you will need to navigate back to Idea on the ribbon bar',
        disableBeacon: true
    }, {
        target: '#evaluationStatus',
        content: 'Once you have completed your analysis, you can mark the initiative/project as completed. This will show in the Dashboard, but does not remove the item.',
        disableBeacon: true
    }, {
        target: '#exportData',
        content: 'At any stage, you can export this summary',
        disableBeacon: true
    }, {
        target: '#evaluationViews',
        content: 'You can change the views between a grid, list or roller deck',
        disableBeacon: true
    }, {
        target: '#ideaSummary',
        content: 'Click here to view idea details',
        disableBeacon: true
    }, {
        target: '#card_0',
        content: 'Each Option is presented as a scorecard. This summarises the Option. Clicking on the card will mark the item as the preferred option. This can easily be changed by re-clicking or clicking another option',
        disableBeacon: true
    }, {
        target: '#ratingGauge_0',
        content: 'Feature and Goal performance is shown as a graphical dial. As the scoring is using a Fibonacci sequence, the numbers presented are deliberately skewed to rate high value and high performance items higher. It is quite normal for the optimal option to not necessarily be the top performing item in all areas. The intent is to drive an informed trade-off discussion with decision makers on what is the best option to proceed with',
        disableBeacon: true
    }];