import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Grid, Container, Box } from '@material-ui/core';
import Fullpage from '../Loader/Fullpage';
import routes from '../../constants/routes'
import OperationSnackbar from '../Alert/OperationSnackbar';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/planActions';
import { NavLink } from 'react-router-dom';

const Copyright = () => {
  return (
    <Typography variant="body1" align="center">
      {'© '}
      {new Date().getFullYear()}
      {' Feijoa8 Limited. All rights reserved. '}
      <br />
      <Link color="inherit" href={routes.termsCondtions.url}>
        Terms & Conditions
      </Link>
      {' | '}
      <Link color="inherit" href={routes.privacy.url}>
        Privacy Policy
      </Link>
      {' | '}
      <Link color="inherit" href={routes.contact.url}>
        Contact Us
      </Link>
      {' | '}
      <Link color="inherit" href={routes.feedback.url}>
        Feedback
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.colors.reseneSmaltBlue,
    padding: `${theme.spacing(2)}px 0`,
    borderTop: `1px solid ${theme.palette.colors.lightBeige}`
  },
  mainGrid: {
    textAlign: 'center'
  },
  footerHeader: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  social: {
    padding: '10px'
  },
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    li: {
      margin: '5px 0 5px 0'
    }
  },
}));

function Footer({ isGlobalLoading, loadingGlobalText, globalSeverity, globalMessage, actions }) {
  const classes = useStyles();

  const handleClose = () => {
    actions.setSeverityStatus('', '');
  }
  return (
    <React.Fragment>
      <footer className={classes.footer}>
        <Container>
          <Grid container className={classes.mainGrid} spacing={1} alignItems="center">
            <Grid item lg={3} xs={12}>
              <NavLink className={classes.social} to={routes.ceciHome.url}>
                <img src="/static/svg/ceci.svg" alt="CECI App" height="75px" />
              </NavLink>
              <NavLink className={classes.social} to={routes.ceciStudio.url}>
                <img src="/static/svg/cecistudio.svg" alt="CECI Studio" height="75px" />
              </NavLink>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box>
                <Copyright />
              </Box>
              <br />
              <Grid container justify="center">
                <Grid item>
                  <Link className={classes.social} href="https://www.instagram.com/feijoa8/" target="_blank">
                    <img src="/static/svg/footer/instagram.svg" alt="CECI" width="35px" height="35px" />
                  </Link>
                  <Link className={classes.social} href="https://www.facebook.com/feijoa81/" target="_blank">
                    <img src="/static/svg/footer/facebook.svg" alt="CECI" width="35px" height="35px" />
                  </Link>
                  {/* <Link className={classes.social} href="https://twitter.com/Feijoa81" target="_blank">
                <img src="/static/svg/footer/twitter.svg" alt="CECI" width="35px" height="35px" />
              </Link> */}
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item>
                  <img src="/static/images/footer/allCards.jpg" alt="Payment methods supported" width="300px" height="35px"></img>
                  <img src="/static/images/footer/applePay.png" alt="Apple pay" width="35px" height="35px"></img>
                  <img src="/static/images/footer/googlePay.png" width="35px" alt="Google pay" height="35px"></img>
                  <img src="/static/svg/footer/pciCompliant.svg" alt="PCI Compliant" width="50px" height="35px"></img>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} xs={12}>
              <NavLink className={classes.social} to={routes.home.url}>
                <img src="/static/svg/feijoa8.svg" alt="CECI Studio" height="75px" />
              </NavLink>
            </Grid>
          </Grid>

        </Container>
      </footer>
      {isGlobalLoading && <Fullpage loadingText={loadingGlobalText} />}
      <OperationSnackbar open={globalSeverity !== '' && globalMessage !== ''} handleClose={handleClose}
        severity={globalSeverity} message={globalMessage} />
    </React.Fragment>
  );
}

Footer.propTypes = {
  isGlobalLoading: PropTypes.bool,
  loadingGlobalText: PropTypes.string,
  globalSeverity: PropTypes.string,
  globalMessage: PropTypes.string,
  actions: PropTypes.any
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

function mapStateToProps(state) {
  return {
    isGlobalLoading: state.global.isLoading,
    loadingGlobalText: state.global.loadingText || '',
    globalSeverity: state.global.severity || '',
    globalMessage: state.global.message || ''
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
