import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const HeaderImage = () => {
    return (
        <Card>
            <CardMedia
                image="/static/images/ceci/home/ideaBoard.jpg"
                title={'IdeaBoard'}
                style={{
                    height: 0,
                    paddingTop: '50%'
                }}
            />
        </Card>
    );
};

export default HeaderImage;
