export const MetaDashboardProjects = {
  title: 'Projects',
  pageTitle: 'Projects',
  meta: [
    {
      name: 'title',
      content: 'Projects',
    },
    {
      name: 'description',
      content: 'Get in touch with us by email or filling up form',
    },
    {
      name: 'keywords',
      content: 'Contact Feijoa8,Contact Us,email',
    },
    {
      name: 'robots',
      content: 'index, follow',
    }
  ],
};
