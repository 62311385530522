import React from "react";
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { generateStepperHeader } from "../../utils/stepperMetadata";
import PropTypes from "prop-types";
import Tour from "../Tour/index";

const useStyles = makeStyles((theme) => ({
    header: {
        textAlign: 'center',
        fontWeight: '500'
    },
    headerHelper: {
        textAlign: 'center',
        marginBottom: theme.spacing(3)
    },
    tour: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

const EvaluationStepperTitle = (props) => {
    const classes = useStyles();
    const { steps, startTour } = props;
    const { title, subTitle } = generateStepperHeader(props.projectType, props.stage);
    return (
        <React.Fragment>
            {steps && steps.length !== 0 && <div className={classes.tour}>
                <Tour toolTipText="Click here for quick tour" steps={steps} styles={{
                    options: {
                        zIndex: 1000
                    }
                }} startTour={startTour} />
            </div>}
            <div id="stepTitle">
                <Typography className={classes.header} variant="h4">{title}</Typography>
                <Typography className={classes.headerHelper} component="div">{subTitle}</Typography>
            </div>
            <Divider />
            <br />
        </React.Fragment>
    );
};

EvaluationStepperTitle.propTypes = {
    projectType: PropTypes.string,
    stage: PropTypes.object,
    steps: PropTypes.array,
    startTour: PropTypes.bool
};

export default EvaluationStepperTitle;