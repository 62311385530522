export const MetaHomePage = {
    title: 'Home - CECI.Studio',
    pageTitle: 'Home',
    meta: [
      {
        name: 'title',
        content: 'CECI-Studio Home',
      },
      {
        name: 'description',
        content: 'A powerful but simple cloud based application that you can use to take your idea from simple concepts to a clearly specefied product defintion',
      },
      {
        name: 'keywords',
        content: 'Product Owner, Product Manager, Product Development, Quality Function Deployment, QFD,  Product Strategy, Product Tools, CECI,Continous Evaluation and Continuous Ideation,evaluation, ideation, QFD, Customer and Business Needs, Business Requirement, Discovery,  Option evaluation, Agile, Design Thinking, Lean, Structured Problem Solving, Cloud, Portal, Pro, Professional',
      },
      {
        name: 'robots',
        content: 'index, follow',
      }
    ],
  };
  