import React from 'react';
import Header from '../../components/HeaderV2/Header';
import HeaderLinks from '../../components/HeaderV2/HeaderLinks';
import Footer from '../../components/Footer/Footer';
import Body from '../../components/Body';
import { Typography, Divider } from '@material-ui/core';
import { SectionContainer } from '../../components/Body/Containers';
import { MetaAboutUsPage } from './Seo/MetaAboutUsPage';
import MetaTags from '../../components/Seo/MetaTags';
import { feijoa8Menu } from '../../constants/routes';

const AboutPage = () => {
  return (
    <>
      <Header
        brand="feijoa8"
        rightLinks={<HeaderLinks links={feijoa8Menu} />}
        fixed
      />
      <MetaTags {...MetaAboutUsPage} />
      <Body title={MetaAboutUsPage.pageTitle}>
        <Divider />
        <SectionContainer>
          <Typography variant="h4" gutterBottom>
            Background
          </Typography>
          <Typography variant="body1" gutterBottom paragraph>
            When I was growing up, we had two very large Feijoa trees
            in our backyard. These trees constantly produced copious quantities
            of fruit every year which we were able to share with friends and
            family. My mother was able to create many different delicious items
            from the fruit; jams, deserts (many different kinds), preserves etc.<br /><br />
            Every few years, the trees would get overgrown and my father would
            cut them back. The harder the trees were cut back, the more
            bountiful the trees became. So I name the company after my family tree.<br /><br />
            The “8” simply comes from the infinity
            symbol which denotes continuous evolution and improvement. So for
            me, Feijoa8 represents a sense of a resilience, bountifulness,
            reusability and continuous learning and improvement.
          </Typography>
        </SectionContainer>
        <SectionContainer>
          <Typography variant="h4" gutterBottom>
            Our Purpose
          </Typography>
          <Typography variant="body1" gutterBottom paragraph>
            Develop applications using human centred applications and
            methodologies for both personal and business customers using
            simple modern coding standards and cloud based architecture. Build a
            business that can grow and adapt to a changing market and continues
            to look for adjacent opportunities.
          </Typography>
        </SectionContainer>
        <SectionContainer>
          <Typography variant="h4" gutterBottom>
            Our Principles
          </Typography>
          <Typography variant="body1" gutterBottom paragraph>
            We will use Design Thinking to understand our customers and design
            to their needs. We will not mine or sell user data to others.
            Privacy and security of our user content will be our prime concern.
            We will not bombard customers with intrusive advertising within the
            applications. Only simple advertisement will be used on free
            applications. User feedback resulting in continuous improvement will
            be incorporated into every application we created. Modern
            microservice capability deployed on Cloud architecture will enable
            rapid response to changing market conditions and enable us to scale
            with demand.
          </Typography>
        </SectionContainer>
      </Body>
      <Footer />
    </>
  );
};

export default AboutPage;
