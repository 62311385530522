import {
    CREATE_PROJECT, DELETE_PROJECT, SET_CURRENT_PROJECT, INCREMENT_STEP, ADD_FEATURE, UPDATE_FEATURE, REMOVE_FEATURE,
    DECREMENT_STEP, SKIP_STEP, CREATE_IDEA, ADD_FEATURE_EVALUATOR, UPDATE_FEATURE_EVALUATOR
    , REMOVE_FEATURE_EVALUATOR, ADD_FEATURE_LABEL, REMOVE_FEATURE_LABEL, ASSIGN_LABEL_TO_FEATURE,
    UPDATE_FEATURE_RATING_BY_EVALUATOR, ADD_GOAL, UPDATE_GOAL, REMOVE_GOAL, ASSIGN_LABEL_TO_GOAL,
    ADD_GOAL_LABEL, REMOVE_GOAL_LABEL, ADD_GOAL_EVALUATOR, UPDATE_GOAL_EVALUATOR, REMOVE_GOAL_EVALUATOR,
    UPDATE_GOAL_RATING_BY_EVALUATOR, OPTION_SELECTED_FOR_PROJECT, ADD_OPTION, REMOVE_OPTION, UPDATE_OPTION,
    UPDATE_GOAL_RATING_BY_OPTION, UPDATE_FEATURE_RATING_BY_OPTION, EVALUATION_STATUS_FOR_PROJECT, SET_STEP,
    MARK_FEATURE_AS_DELIVERED, RESET_STATE, REPLACE_PROJECTS, INJECT_FEATURES_GOALS_OPTIONS_IN_PROJECT, CLEAR_PROJECTS,
    UPDATE_OPTION_IMAGE
} from '../constants/actionTypes';
import { findCurrentProjectDetails } from '../utils/projectUtil';
import initialState from './initialState';

export default function projectReducer(state = initialState.initialProjects, action) {
    const { global: { currentProjectId } } = state
    switch (action.type) {
        case CREATE_PROJECT: {
            const { id, createdOn, updatedOn, type, status, createdBy } = action.payload;
            const projectData = Object.assign({}, { projects: state.projects.concat({ id, createdOn, updatedOn, type, status, createdBy, currentStep: 0, isStepSkipped: false }) });
            return Object.assign({}, state, {
                ...projectData,
                global: Object.assign({}, state.global, { currentProjectId: id })
            });
        }
        case DELETE_PROJECT: {
            const { projectId } = action.payload;
            const projectData = Object.assign({}, { projects: state.projects.filter((project) => project.id !== projectId) });
            return Object.assign({}, state, {
                ...projectData,
                global: Object.assign({}, state.global)
            });
        }
        case REPLACE_PROJECTS: {
            const projectData = Object.assign({}, { projects: action.payload.projects });
            return Object.assign({}, state, {
                ...projectData,
                global: Object.assign({}, state.global)
            });
        }
        case CLEAR_PROJECTS: {
            const projectData = Object.assign({}, { projects: [] });
            return Object.assign({}, state, {
                ...projectData,
                global: Object.assign({}, state.global)
            });
        }
        case SET_CURRENT_PROJECT: {
            const projectData = Object.assign({}, { projects: state.projects });
            return Object.assign({}, state, {
                ...projectData,
                global: Object.assign({}, state.global, { currentProjectId: action.payload })
            });
        }
        case INJECT_FEATURES_GOALS_OPTIONS_IN_PROJECT: {
            const { id, features, goals, options } = action.payload;
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === id ? {
                        ...project, features: features, goals: goals
                        , options: options
                    } : project
                )
            };
        }
        case INCREMENT_STEP: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, currentStep: project.isStepSkipped && project.currentStep + 1 === 5 ? project.currentStep + 2 : project.currentStep + 1 } : project
                )
            };
        }
        case DECREMENT_STEP: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, currentStep: project.isStepSkipped && project.currentStep - 1 === 5 ? project.currentStep - 2 : project.currentStep - 1 } : project
                )
            };
        }
        case SKIP_STEP: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, isStepSkipped: action.payload } : project
                )
            };
        }
        case SET_STEP: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, currentStep: action.step } : project
                )
            };
        }
        case CREATE_IDEA: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, idea: action.payload, updatedOn: Date.now() } : project
                )
            };
        }
        case ADD_FEATURE: {
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            // Add the existing evaluators
            const featureEvaluatorsRating = []
            currentProject.featureEvaluators.forEach((evaluator) => featureEvaluatorsRating.push({ evaluatorId: evaluator.id, rating: 3 }))
            action.payload.evaluatorsRating = featureEvaluatorsRating;
            // Add the existing options
            const optionsRating = [];
            const options = currentProject.options || [];
            options.forEach(option => {
                optionsRating.push({ optionId: option.id, rating: 0 })
            })
            action.payload.optionsRating = optionsRating;
            const updatedFeatures = currentProject.features === undefined ? [action.payload] : [action.payload, ...currentProject.features];
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, features: updatedFeatures, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_FEATURE: {
            const { title, id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const index = currentProject.features.findIndex(feature => feature.id === id);
            const updatedFeatures = [...currentProject.features];
            updatedFeatures[index] = { ...updatedFeatures[index], title: title }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, features: updatedFeatures, updatedOn: Date.now() } : project
                )
            };
        }
        case MARK_FEATURE_AS_DELIVERED: {
            const { featuresToUpdate, version } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const features = currentProject.features;
            const updatedFeatures = [];
            features.forEach(feature => {
                if (featuresToUpdate.includes(feature.id)) {
                    const updatedFeature = { ...feature, deliveryVersion: version }
                    updatedFeatures.push(updatedFeature);
                } else {
                    updatedFeatures.push(feature);
                }
            })
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, features: updatedFeatures, updatedOn: Date.now() } : project
                )
            };
        }
        case REMOVE_FEATURE: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? {
                        ...project,
                        features: project.features.filter(feature => feature.id !== action.payload),
                        updatedOn: Date.now()
                    } : project
                )
            };
        }
        case ASSIGN_LABEL_TO_FEATURE: {
            const { featureId, labelIds } = action.payload
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const index = currentProject.features.findIndex(feature => feature.id === featureId);
            const updatedFeatures = [...currentProject.features];
            updatedFeatures[index] = { ...updatedFeatures[index], labels: labelIds }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, features: updatedFeatures, updatedOn: Date.now() } : project
                )
            };
        }
        case ADD_FEATURE_LABEL: {
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updatedFeatureLabels = currentProject.featureLabels === undefined ? [action.payload] : [action.payload, ...currentProject.featureLabels];
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, featureLabels: updatedFeatureLabels, updatedOn: Date.now() } : project
                )
            };
        }
        case REMOVE_FEATURE_LABEL: {
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updateFeatureLabels = currentProject.featureLabels.filter(label => label.id !== action.payload);
            const features = currentProject.features !== undefined ? [...currentProject.features] : [];
            const updatedFeatures = [];
            features.forEach((feature) => {
                if (feature.labels !== undefined && feature.labels.includes(action.payload)) {
                    const updatedLabels = feature.labels.filter(label => label !== action.payload);
                    const updatedFeature = { ...feature, labels: updatedLabels };
                    updatedFeatures.push(updatedFeature);
                } else {
                    updatedFeatures.push(feature);
                }
            })
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        {
                            ...project, featureLabels: updateFeatureLabels,
                            features: updatedFeatures, updatedOn: Date.now()
                        } : project
                )
            };
        }
        case ADD_FEATURE_EVALUATOR: {
            const { id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updatedFeatureEvaluators = currentProject.featureEvaluators === undefined ? [action.payload] : [action.payload, ...currentProject.featureEvaluators];
            const updatedFeatures = [];
            if (currentProject.features !== undefined) {
                currentProject.features.forEach((feature) => {
                    let evaluatorsRating = feature.evaluatorsRating;
                    evaluatorsRating = [...evaluatorsRating, { evaluatorId: id, rating: 3 }];
                    const updatedFeature = { ...feature, evaluatorsRating };
                    updatedFeatures.push(updatedFeature);
                })
            }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, features: updatedFeatures, featureEvaluators: updatedFeatureEvaluators, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_FEATURE_EVALUATOR: {
            const { name, group, influence, id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const index = currentProject.featureEvaluators.findIndex(feature => feature.id === id);
            const updatedFeatureEvaluators = [...currentProject.featureEvaluators];
            updatedFeatureEvaluators[index] = { ...updatedFeatureEvaluators[index], name: name, group: group, influence: influence }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, featureEvaluators: updatedFeatureEvaluators, updatedOn: Date.now() } : project
                )
            };
        }
        case REMOVE_FEATURE_EVALUATOR: {
            const { id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updatedFeatureEvaluators = currentProject.featureEvaluators.filter((evaluator) => evaluator.id !== id);
            const updatedFeatures = [];
            if (currentProject.features !== undefined) {
                currentProject.features.forEach((feature) => {
                    const evaluatorsRating = feature.evaluatorsRating.filter((evaluator) => evaluator.evaluatorId !== id);
                    const updatedFeature = { ...feature, evaluatorsRating };
                    updatedFeatures.push(updatedFeature);
                })
            }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, featureEvaluators: updatedFeatureEvaluators, features: updatedFeatures, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_FEATURE_RATING_BY_EVALUATOR: {
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const { featureId, rating, evaluatorId } = action.payload;
            const index = currentProject.features.findIndex(feature => feature.id === featureId);
            const updatedEvaluatorRatings = [];
            currentProject.features[index].evaluatorsRating.forEach((evaluator) => {
                if (evaluator.evaluatorId === evaluatorId) {
                    const updatedrating = { ...evaluator, rating: rating };
                    updatedEvaluatorRatings.push(updatedrating);
                } else {
                    updatedEvaluatorRatings.push(evaluator);
                }
            })
            const updatedFeatures = [...currentProject.features];
            updatedFeatures[index] = { ...updatedFeatures[index], evaluatorsRating: updatedEvaluatorRatings }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        {
                            ...project,
                            features: updatedFeatures,
                            updatedOn: Date.now()
                        } : project
                )
            };
        }
        case ADD_GOAL: {
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            // Add the existing evaluators
            const goalEvaluatorsRating = [];
            currentProject.goalEvaluators.forEach((evaluator) => goalEvaluatorsRating.push({ evaluatorId: evaluator.id, rating: 3 }))
            action.payload.evaluatorsRating = goalEvaluatorsRating;
            // Add the existing options
            const optionsRating = [];
            const options = currentProject.options || [];
            options.forEach(option => {
                optionsRating.push({ optionId: option.id, rating: 0 })
            })
            action.payload.optionsRating = optionsRating;
            const updatedGoals = currentProject.goals === undefined ? [action.payload] : [action.payload, ...currentProject.goals];
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, goals: updatedGoals, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_GOAL: {
            const { title, id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const index = currentProject.goals.findIndex(goal => goal.id === id);
            const updatedGoals = [...currentProject.goals];
            updatedGoals[index] = { ...updatedGoals[index], title: title }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, goals: updatedGoals, updatedOn: Date.now() } : project
                )
            };
        }
        case REMOVE_GOAL: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? {
                        ...project,
                        goals: project.goals.filter(goal => goal.id !== action.payload),
                        updatedOn: Date.now()
                    } : project
                )
            };
        }
        case ASSIGN_LABEL_TO_GOAL: {
            const { goalId, labelIds } = action.payload
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const index = currentProject.goals.findIndex(goal => goal.id === goalId);
            const updatedGoals = [...currentProject.goals];
            updatedGoals[index] = { ...updatedGoals[index], labels: labelIds }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, goals: updatedGoals, updatedOn: Date.now() } : project
                )
            };
        }
        case ADD_GOAL_LABEL: {
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updatedGoalLabels = currentProject.goalLabels === undefined ? [action.payload] : [action.payload, ...currentProject.goalLabels];
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, goalLabels: updatedGoalLabels, updatedOn: Date.now() } : project
                )
            };
        }
        case REMOVE_GOAL_LABEL: {
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updateGoalLabels = currentProject.goalLabels.filter(label => label.id !== action.payload);
            const goals = currentProject.goals !== undefined ? [...currentProject.goals] : [];
            const updatedGoals = [];
            goals.forEach((goal) => {
                if (goal.labels !== undefined && goal.labels.includes(action.payload)) {
                    const updatedLabels = goal.labels.filter(label => label !== action.payload);
                    const updatedGoal = { ...goal, labels: updatedLabels };
                    updatedGoals.push(updatedGoal);
                } else {
                    updatedGoals.push(goal);
                }
            })
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        {
                            ...project, goalLabels: updateGoalLabels,
                            goals: updatedGoals, updatedOn: Date.now()
                        } : project
                )
            };
        }
        case ADD_GOAL_EVALUATOR: {
            const { id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updatedGoalEvaluators = currentProject.goalEvaluators === undefined ? [action.payload] : [action.payload, ...currentProject.goalEvaluators];
            const updatedGoals = [];
            if (currentProject.goals !== undefined) {
                currentProject.goals.forEach((goal) => {
                    let evaluatorsRating = goal.evaluatorsRating;
                    evaluatorsRating = [...evaluatorsRating, { evaluatorId: id, rating: 3 }];
                    const updatedGoal = { ...goal, evaluatorsRating };
                    updatedGoals.push(updatedGoal);
                })
            }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, goals: updatedGoals, goalEvaluators: updatedGoalEvaluators, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_GOAL_EVALUATOR: {
            const { name, group, influence, id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const index = currentProject.goalEvaluators.findIndex(goal => goal.id === id);
            const updatedGoalEvaluators = [...currentProject.goalEvaluators];
            updatedGoalEvaluators[index] = { ...updatedGoalEvaluators[index], name: name, group: group, influence: influence }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, goalEvaluators: updatedGoalEvaluators, updatedOn: Date.now() } : project
                )
            };
        }
        case REMOVE_GOAL_EVALUATOR: {
            const { id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updatedGoalEvaluators = currentProject.goalEvaluators.filter((evaluator) => evaluator.id !== id);
            const updatedGoals = [];
            if (currentProject.goals !== undefined) {
                currentProject.goals.forEach((goal) => {
                    const evaluatorsRating = goal.evaluatorsRating.filter((evaluator) => evaluator.evaluatorId !== id);
                    const updatedGoal = { ...goal, evaluatorsRating };
                    updatedGoals.push(updatedGoal);
                })
            }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, goalEvaluators: updatedGoalEvaluators, goals: updatedGoals, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_GOAL_RATING_BY_EVALUATOR: {
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const { goalId, rating, evaluatorId } = action.payload;
            const index = currentProject.goals.findIndex(goal => goal.id === goalId);
            const updatedEvaluatorRatings = [];
            currentProject.goals[index].evaluatorsRating.forEach((evaluator) => {
                if (evaluator.evaluatorId === evaluatorId) {
                    const updatedrating = { ...evaluator, rating: rating };
                    updatedEvaluatorRatings.push(updatedrating);
                } else {
                    updatedEvaluatorRatings.push(evaluator);
                }
            })
            const updatedGoals = [...currentProject.goals];
            updatedGoals[index] = { ...updatedGoals[index], evaluatorsRating: updatedEvaluatorRatings }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        {
                            ...project,
                            goals: updatedGoals,
                            updatedOn: Date.now()
                        } : project
                )
            };
        }
        case ADD_OPTION: {
            const { id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updatedOptions = currentProject.options === undefined ? [action.payload] : [action.payload, ...currentProject.options];
            // Add new option to feature rating
            const updatedFeatures = [];
            if (currentProject.features !== undefined) {
                currentProject.features.forEach((feature) => {
                    let optionsRating = feature.optionsRating;
                    optionsRating = optionsRating !== undefined ? [...optionsRating, { optionId: id, rating: 0 }] : [{ optionId: id, rating: 0 }];
                    const updatedFeature = { ...feature, optionsRating };
                    updatedFeatures.push(updatedFeature);
                })
            }
            // Add new option to goal rating
            const updatedGoals = [];
            if (currentProject.goals !== undefined) {
                currentProject.goals.forEach((goal) => {
                    let optionsRating = goal.optionsRating;
                    optionsRating = optionsRating !== undefined ? [...optionsRating, { optionId: id, rating: 0 }] : [{ optionId: id, rating: 0 }];
                    const updatedGoal = { ...goal, optionsRating };
                    updatedGoals.push(updatedGoal);
                })
            }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, features: updatedFeatures, goals: updatedGoals, options: updatedOptions, updatedOn: Date.now() } : project
                )
            };
        }
        case REMOVE_OPTION: {
            const { id } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const updatedOptions = currentProject.options.filter((option) => option.id !== id);
            const updatedFeatures = [];
            if (currentProject.features !== undefined) {
                currentProject.features.forEach((feature) => {
                    const optionsRating = feature.optionsRating.filter((option) => option.optionId !== id);
                    const updatedFeature = { ...feature, optionsRating };
                    updatedFeatures.push(updatedFeature);
                })
            }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, options: updatedOptions, features: updatedFeatures, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_OPTION: {
            const { id } = action.payload;
            const payload = action.payload;
            delete payload.id;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const index = currentProject.options.findIndex(option => option.id === id);
            const updatedOptions = [...currentProject.options];
            updatedOptions[index] = { ...updatedOptions[index], ...action.payload }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, options: updatedOptions, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_OPTION_IMAGE: {
            const { image, optionId } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const index = currentProject.options.findIndex(option => option.id === optionId);
            const updatedOptions = [...currentProject.options];
            updatedOptions[index] = { ...updatedOptions[index], image }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, options: updatedOptions, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_FEATURE_RATING_BY_OPTION: {
            const { optionId, featureId, rating } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const currentFeatureIndex = currentProject.features.findIndex(feature => feature.id === featureId);
            const currentFeature = currentProject.features.find(feature => feature.id === featureId);
            const currentOptionsRatingIndex = currentFeature.optionsRating.findIndex(option => option.optionId === optionId);
            const updatedOptionsRating = [...currentFeature.optionsRating];
            updatedOptionsRating[currentOptionsRatingIndex] = { ...updatedOptionsRating[currentOptionsRatingIndex], rating };
            const updatedFeatures = [...currentProject.features];
            updatedFeatures[currentFeatureIndex] = { ...updatedFeatures[currentFeatureIndex], optionsRating: updatedOptionsRating }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, features: updatedFeatures, updatedOn: Date.now() } : project
                )
            };
        }
        case UPDATE_GOAL_RATING_BY_OPTION: {
            const { optionId, goalId, rating } = action.payload;
            const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
            const currentGoalIndex = currentProject.goals.findIndex(goal => goal.id === goalId);
            const currentGoal = currentProject.goals.find(goal => goal.id === goalId);
            const currentOptionsRatingIndex = currentGoal.optionsRating.findIndex(option => option.optionId === optionId);
            const updatedOptionsRating = [...currentGoal.optionsRating];
            updatedOptionsRating[currentOptionsRatingIndex] = { ...updatedOptionsRating[currentOptionsRatingIndex], rating };
            const updatedGoals = [...currentProject.goals];
            updatedGoals[currentGoalIndex] = { ...updatedGoals[currentGoalIndex], optionsRating: updatedOptionsRating }
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ?
                        { ...project, goals: updatedGoals, updatedOn: Date.now() } : project
                )
            };
        }
        case OPTION_SELECTED_FOR_PROJECT: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, selectedOption: action.payload } : project
                )
            };
        }
        case EVALUATION_STATUS_FOR_PROJECT: {
            return {
                ...state,
                projects: state.projects.map(
                    (project) => project.id === currentProjectId ? { ...project, status: action.payload } : project
                )
            };
        }
        case RESET_STATE: {
            return initialState.initialProjects;
        }
        default:
            return state;
    }
}