import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import SignInButton from "../../../components/Identity/SignInButton";
import ImageCarousel from '../../Home/ImageCarousel';
import { makeStyles } from '@material-ui/core/styles';
import content from './content';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
    listDisc: {
        listStyle: 'disc',
        paddingLeft: theme.spacing(3)
    },
    parentGrid: {
        marginBottom: theme.spacing(4)
    },
    table: {
        color: theme.palette.colors.lightBeige
    },
    tableRow: {
        color: theme.palette.colors.lightBeige,
        border: `1px solid ${theme.palette.colors.lightBeige}`
    },
    underline: {
        textDecoration: 'underline'
    },
    image: {
        width: '100%',
        height: '100%'
    }
}));


function Home() {

    const classes = useStyles();

    const { homeSection, aboutSection, planPriceSection, featureSection } = content;

    return (
        <>
            <Grid id="home" className={classes.parentGrid} container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {homeSection.main.title}
                    </Typography>

                    {homeSection.main.para.map((text) =>
                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                    )}
                </Grid>

                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {homeSection.account.title}
                    </Typography>
                    <Typography variant="body1" gutterBottom paragraph>
                        {homeSection.account.subTitle} <span className={classes.underline}><SignInButton label='here' /></span>
                    </Typography>
                </Grid>
            </Grid>


            <Grid id="aboutus" container spacing={3} alignItems="center">
                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom> {aboutSection.title}</Typography>
                    {aboutSection.para.map((text) =>
                        <Typography key={text} variant="body1" gutterBottom paragraph
                            dangerouslySetInnerHTML={{ __html: text }} />
                    )}
                </Grid>

                <Grid item lg={6} xs={12}>
                    {aboutSection.images.length > 1 ?
                        <ImageCarousel />
                        : <img className={classes.image} src={aboutSection.images[0].image} alt={aboutSection.images[0].title} />
                    }
                </Grid>
            </Grid>

            <Grid id="plan" className={classes.parentGrid} container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {planPriceSection.title}
                    </Typography>

                    <Typography variant="subtitle2" gutterBottom>
                        {planPriceSection.subTitle}
                        <ul className={classes.listDisc}>
                            {planPriceSection.planList.map((text) =>
                                <li key={text}> {text} </li>
                            )}
                        </ul>
                    </Typography>
                    {planPriceSection.para.map((text) =>
                        <Typography key={text} variant="body1" gutterBottom paragraph> {text} </Typography>
                    )}
                </Grid>

                <Grid item lg={6} xs={12}>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {planPriceSection.priceSection.header.map((header) =>
                                        <TableCell key={header} className={classes.tableRow}>{header}</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {planPriceSection.priceSection.data.map((row) => (
                                    <TableRow key={row.plan}>
                                        <TableCell className={classes.tableRow}>
                                            {row.plan}
                                        </TableCell>
                                        <TableCell className={classes.tableRow}>
                                            {row.pricePerMonth}
                                        </TableCell>
                                        <TableCell className={classes.tableRow}>
                                            {row.introductoryPriceThreeMonths}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        * use coupon WELCOME5 by 30th June 2023 for a discounted price for the first 3 months
                    </TableContainer>
                </Grid>
            </Grid>


            <Grid id="feature" className={classes.parentGrid} container spacing={3}>
                <Grid item lg={12} xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {featureSection.title}
                    </Typography>
                    <TableContainer>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {featureSection.features.header.map((header) =>
                                        <TableCell key={header} className={classes.tableRow}>{header}</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {featureSection.features.data.map((row, key) => (
                                    <TableRow key={`row-${key}`}>
                                        <TableCell className={classes.tableRow}>
                                            {row.feature}
                                        </TableCell>
                                        <TableCell className={classes.tableRow}>
                                            {row.desc}
                                        </TableCell>
                                        <TableCell className={classes.tableRow}>
                                            {typeof row.proPlan !== 'string' ?
                                                <>
                                                    <ul>
                                                        {row.proPlan.map((planList) => (
                                                            <li key={planList}>{planList}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                                : row.proPlan}
                                        </TableCell>

                                        <TableCell className={classes.tableRow}>
                                            {row.enterprisePlan}
                                        </TableCell>

                                        <TableCell className={classes.tableRow}>
                                            {row.consultantPlan}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid id="how-does-it-work" className={classes.parentGrid} container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <video width="100%" controls>
                        <source src={featureSection.video.mp4} type="video/mp4" />
                        <source src={featureSection.video.ogg} type="video/ogg" />
                                Your browser does not support HTML video.
                    </video>
                </Grid>
            </Grid>

        </>
    )
}

export default Home
