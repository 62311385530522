import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { exportOptionsExcel } from '../../utils/exportEvaluation';

const ExportOptions = ((props) => {
    const { projectType, idea, options } = props;

    const handleOptionsDownload = () => {
        exportOptionsExcel(idea, options, projectType);
    }

    return (
        <Button
            variant="outlined"
            fullWidth
            color="primary"
            startIcon={<GetAppIcon />}
            size="large"
            onClick={handleOptionsDownload}
        >
            Export options to Excel
        </Button>
    );
});

ExportOptions.propTypes = {
    projectType: PropTypes.string.isRequired,
    idea: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
};

export default ExportOptions;