import React from 'react';
import { Button, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types'
import { PersonAdd } from '@material-ui/icons';

const AddEvaluatorButton = (props) => {

    const { label, onClick, maxAllowed, disabled, maxWhatAllowed, ...rest } = props;

    return (
        <FormControl>
            <Button color="primary" onClick={onClick} disabled={disabled}
                fullWidth size="large" style={{ minHeight: '53px' }}
                variant="outlined" startIcon={<PersonAdd />} {...rest}>{label}</Button>
            {disabled && <FormHelperText>Max {maxAllowed} {maxWhatAllowed} are allowed</FormHelperText>}
        </FormControl>
    );
}
AddEvaluatorButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    maxAllowed: PropTypes.number,
    disabled: PropTypes.bool,
    maxWhatAllowed: PropTypes.string
};

export default AddEvaluatorButton;