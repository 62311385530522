const content = {
    homeSection: {
        main: {
            title: 'Welcome to Ceci.studio',
            para: [
                'We have created a tool that we hope will become central to your professional life. Something that enables you to digitise your ideas, evaluate them with contributors, identify the best solution and then export the preferred options, users stories and features in multiple formats.',
                'It enables you to establish a single source of the truth for you project intellectual property and format for specific stakeholder groups without necessarily having to rewrite an entire new artefact'
            ]
        },
        account: {
            title: 'Get CECI studio',
            subTitle: 'Create an account or log in'
        }
    },
    aboutSection: {
        title: 'About Ceci.studio',
        para: [
            'Ceci.studio is a tool that can help you to capture needs, features, goals and requirements, evaluate and iterate them and then publish the outcomes.',
            'It is intended to be used across the design and specification part of a initiative where you may have already completed a Discovery phase, but are trying to determine what solutions can best address your customer and business needs before you commit to a course of action.',
            'Ceci.studio is a cloud based solution build on Azure and targeted at professionals requiring secure cloud based solution. Ceci.app is its sister application, but is a simpler free version that is available "on device" only and available on iOS or Android.',
            'Check out the <a class="navUnderline" href="/ceci-studio#how-does-it-work">video</a> and features below.',
            'There is currently one version of Ceci.studio (with two plans), with Enterprise and Consultant versions in development.',
            'The Pro version is intended to be used by  individual Business Owners, Business Analysts, Product Manager and Owners and similar.'
        ],
        images: [
            { image: "/static/images/home/image1.jpg", title: "Engage your team and work collaboratively from anywhere" },
        ]
    },
    planPriceSection: {
        title: 'Plans and Pricing',
        subTitle: 'Ceci.studio Pro has two available plans:',
        planList: [
            'Pro5 (up to 5 concurrent projects).',
            'Pro10 (up to 10 concurrent projects).',
            'Pro20 (up to 20 concurrent projects).'
        ],
        para: [
            'Note that Pro5 and Pro10 offer the same features.'
        ],
        priceSection: {
            header: ['', 'Regular price per month', 'Introductory Price for three months and then reverts to Regular price'],
            data: [
                {
                    plan: 'Pro5',
                    pricePerMonth: '$14.99',
                    introductoryPriceThreeMonths: '$9.99'
                },
                {
                    plan: 'Pro10',
                    pricePerMonth: '$19.99',
                    introductoryPriceThreeMonths: '$14.99'
                },
                {
                    plan: 'Pro20',
                    pricePerMonth: '$24.99',
                    introductoryPriceThreeMonths: '$19.99'
                }
            ]
        }
    },
    featureSection: {
        title: 'Features',
        features: {
            header: [
                'Feature',
                'Description',
                'Pro',
                'Enterprise',
                'Consultant'
            ],
            data: [
                {
                    feature: 'Users',
                    desc: 'The initiative facilitator/user',
                    proPlan: '1',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Admin',
                    desc: 'The account holder who is able to add users and approve addition add on\'s',
                    proPlan: 'N/A',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Projects',
                    desc: 'The default number of projects that can be created, managed and stored',
                    proPlan: ['Pro5 (5 Projects)', 'Pro10 (10 Projects)', 'Pro20 (20 Projects)'],
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Features',
                    desc: 'The customer outcomes, needs, features or requirements that are created in for the initiative',
                    proPlan: '100',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Goals',
                    desc: 'The business outcomes or needs. May also be referred to as the strategic objectives',
                    proPlan: '10',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Evaluators',
                    desc: 'The contributors and/or those that are evaluating the importance of what is being created from their specialist area',
                    proPlan: ['5 Feature Evaluators', '5 Goal Evaluators'],
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Options',
                    desc: 'The potential options that are being assessed',
                    proPlan: '5',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Export (Project)',
                    desc: 'The ability to export your project as a JSON file so that you can archive your project and archive your project',
                    proPlan: 'JSON file',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Import (Project)',
                    desc: 'The ability to import your previously exported JSON project file',
                    proPlan: 'JSON file',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Export',
                    desc: 'The ability to export the current tab you are working on so that you can use this content else where',
                    proPlan: 'CSV',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                },
                {
                    feature: 'Users',
                    desc: 'The ability to publish a summary of your completed  project',
                    proPlan: 'CSV | PDF | RTF',
                    enterprisePlan: 'TBC',
                    consultantPlan: 'TBC'
                }
            ]
        },
        video: {
            title: 'Have a quick look at the concept behind CECI',
            mp4: '/static/videos/ceci_work_flow.mp4',
            ogg: '/static/videos/ceci_work_flow.mp4'
        }
    }
};

export default content;