import React from 'react';
import { Grid, FormControl, FormLabel, MenuItem, Select, Typography } from '@material-ui/core';
import PropTypes from 'prop-types'
import { COST_TYPES, FIXED_COST_TYPE, RANGE_COST_TYPE, RESOURCE_COST_TYPE } from '../../constants/evaluation/options';
import CostTextbox from '../DisplayText/CostTextbox';
import { makeStyles } from '@material-ui/core/styles';
import { fetchResourceCost } from '../../utils/common';

const useStyles = makeStyles(() => ({
    fieldset: {
        borderColor: 'rgba(0, 0, 0, 0.23)'
    },
    centerGrid: {
        display: 'flex',
        alignItems: 'center'
    }
}))

const OptionCostComponent = (props) => {
    const classes = useStyles();
    const { cost, handleCost, deliveryDays, options } = props;
    let costOptions = options;
    if (options === undefined || options.length === 0) {
        costOptions = COST_TYPES;
    }
    const { type, value } = cost;

    return (
        <FormControl fullWidth>
            <fieldset className={classes.fieldset}>
                <FormLabel component="legend">Estimated cost</FormLabel>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={3}>
                        <FormControl fullWidth variant="outlined">
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={type}
                                onChange={(e) => {
                                    handleCost({ ...cost, type: e.target.value })
                                }}>
                                {costOptions.map((type) => {
                                    return (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        {type === FIXED_COST_TYPE && <CostTextbox fullWidth required id="fixedCost" name="fixedCost" label="Fixed cost" value={value.fixedCost} handleChange={(value) => handleCost({ type: type, value: { ...cost.value, fixedCost: value } })} />}
                        {type === RANGE_COST_TYPE &&
                            <Grid container spacing={3} justify="flex-end">
                                <Grid item>
                                    <CostTextbox required
                                        id="minPrice" name="minPrice" label="Min cost"
                                        value={value.minPrice} handleChange={(value) => handleCost({ type: type, value: { ...cost.value, minPrice: value } })} />
                                </Grid>
                                <Grid item><CostTextbox required
                                    id="maxPrice" name="maxPrice" label="Max cost"
                                    value={value.maxPrice} handleChange={(value) => handleCost({ type: type, value: { ...cost.value, maxPrice: value } })} />
                                </Grid>
                            </Grid>
                        }
                        {type === RESOURCE_COST_TYPE &&
                            <Grid container spacing={3}>
                                <Grid item>
                                    <CostTextbox required
                                        id="resourceCost" name="resourceCost" label="Normalized resource cost per day"
                                        value={value.resourceCost} handleChange={(value) => handleCost({ type: type, value: { ...cost.value, resourceCost: value } })} />
                                </Grid>
                                <Grid item className={classes.centerGrid}>
                                    <Typography component="div">*</Typography>
                                </Grid>
                                <Grid item className={classes.centerGrid} >
                                    <Typography component="div">{deliveryDays} days</Typography>
                                </Grid>
                                <Grid item className={classes.centerGrid}>
                                    <Typography component="div">=</Typography>
                                </Grid>
                                <Grid item className={classes.centerGrid}>
                                    <Typography component="div">${fetchResourceCost(value.resourceCost, deliveryDays)}</Typography>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </fieldset>
        </FormControl >
    );
}
OptionCostComponent.propTypes = {
    cost: PropTypes.object,
    handleCost: PropTypes.func,
    deliveryDays: PropTypes.number,
    options: PropTypes.array
};

export default OptionCostComponent;